import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort,
    filterTable,
    setActiveRestaurant,
} from '../../../actions/restaurantActions';
import RestaurantsFilter from "./RestaurantsFilter";

const mapStateToProps = (state) => {
    let { page, perPage, totalRecords, data, isFetching, sortDirection, sortField, filter } = state.restaurant;


    const fields = {
        "Name": "name",
        "Typ": "type",
        "Postleitzahl":"postal_code",
        "Ort": "city",
        "Straße": "street",
        "Status": "status_text"
    };

    const idField = "id";
    const click = true;

    return {
        page,
        perPage,
        totalRecords,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        filter,
        fields,
        idField,
        filterComponent: () => <RestaurantsFilter/>,
        click
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable,
        nextPage: ()=> { dispatch(nextPage())},
        previousPage: () => { dispatch(dispatch(previousPage()))},
        changePerPage: (value) => { dispatch(changePerPage(value))},
        sort: (field, value)=>{ dispatch(sort(field,value))} ,
        setActiveRestaurant,
        onClickRow: (rowData) => {
            ownProps.history.push("/intern/restaurants/" + rowData._id);
        },
        filterTable,
        sortFields: ['name']
    };
};



export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
