import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort,
    setActiveHello
} from '../../../actions/helloActions';
import HellosFilter from "./HellosFilter";


const mapStateToProps = (state) => {
    let { page, perPage, totalRecords, isFetching, sortDirection, sortField } = state.hello;
    let data = state.hello.data ? state.hello.data : [];

    const fields = {
        "Erstellt am": "hello_created_at",
        "Restaurant": "restaurant_name",
        "Adresse" : "restaurant_address",
        "Status": "hello_status"
    };

    const idField = "id";

    const click = true;

    return {
        page,
        perPage,
        totalRecords,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        fields,
        idField,
        filterComponent: () => <HellosFilter/>,
        click,
        hideSort: true,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable,
        nextPage: () => { dispatch(nextPage())},
        previousPage: () => {dispatch(previousPage())},
        changePerPage: (value => { dispatch(changePerPage(value))}),
        sort: (field, value) => { dispatch(sort(field, value))},
        onClickRow: (rowData) => {
            dispatch(setActiveHello(rowData));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
