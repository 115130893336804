import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem} from '@material-ui/core'
import {
    deleteUserRequest, patchUserRequest, saveUserRequest, unsetActiveUser,
    unsetNewUser
} from "../../../actions/userActions";
import SelectGroup from '../../../components/SelectGroup';
import {isAllowed} from "../../../utils/permissionHelper";

class UserForm extends Component {
    constructor(props) {
        super(props);

    }

    required = value => value ? undefined : 'Feld erforderlich';
    passwordRequired = value => {
        const { isNewUser } = this.props;
        if(isNewUser){
            return value ? undefined : 'Feld erforderlich';
        } else {
            return undefined;
        }
    };



    render() {
        const { handleSubmit, classes, user, isNewUser, loggedInUser } = this.props;

        const submitAll = (values) => {
            if(isNewUser === false && user._id && user._id.length){
                this.props.saveUser({
                    data:{
                        _id: user._id,
                        firstname: values.firstname,
                        lastname: values.lastname,
                        email: values.email,
                        role: values.role,
                        password: values.password&&values.password.length>0?values.password:undefined,

                    }
                });
                this.props.unsetActiveUser();
            } else {
                this.props.saveUser({
                    data:{
                        firstname: values.firstname,
                        lastname: values.lastname,
                        email: values.email,
                        password: values.password,
                        role: values.role
                    }
                })
                this.props.unsetNewUser()
            }
        };

        const deleteUser = () => {
            if( user._id && user._id.length) {
                this.props.patchUser({
                    data:{
                        _id: user._id,
                        action: user.status === 'active' ? 'disable' : 'enable'
                    }
                });
                this.props.unsetActiveUser();
            }
        };

        const patchUser = (action) => {
            if( user._id ) {
                this.props.patchUser({
                    data:{
                        _id: user._id,
                        action: action
                    }
                });
            }
        };

        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '100px'}}>
                <Grid xs={12}>
                    <form>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="firstname"
                                    label="Vorname"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="lastname"
                                    label="Nachname"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="email"
                                    label="Email"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Field
                                    name="password"
                                    label="Passwort"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Neues Passwort vergeben"}
                                    validate={[this.passwordRequired]}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Field
                                    name="role"
                                    label="Rolle"
                                    component={SelectGroup}
                                    margin="dense"
                                    fullWidth
                                    validate={[ this.required ]}
                                >
                                    <MenuItem value={'admin'}>Administrator</MenuItem>
                                    <MenuItem value={'finance'}>Finanz</MenuItem>
                                    <MenuItem value={'marketing'}>Marketing</MenuItem>
                                    <MenuItem value={'reader'}>Abfrage</MenuItem>

                                </Field>
                            </Grid>
                            {!isNewUser &&
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="status"
                                        label="Status"
                                        component={TextFieldGroup}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                            }
                        </Grid>



                        {isNewUser === true ?
                            <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(submitAll)} variant={'outlined'} color="primary">
                                User erstellen
                            </Button>
                            :
                            <Grid container spacing={5}>
                                {isAllowed(loggedInUser, 'backendUsers', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(submitAll)}
                                                variant={'outlined'} color="primary">
                                            User sichern
                                        </Button>
                                    </Grid>
                                }
                                { (user.status == 'active' && isAllowed(loggedInUser, 'backendUsers', 'update')) &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={() => {patchUser('suspend')}} variant={'outlined'} color="default">
                                            Beurlauben
                                        </Button>
                                    </Grid>
                                }
                                { (user.status == 'suspended'  && isAllowed(loggedInUser, 'backendUsers', 'update')) &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={() => {patchUser('enable')}} variant={'outlined'} color="default">
                                            Reaktivieren
                                        </Button>
                                    </Grid>
                                }
                                {isAllowed(loggedInUser, 'backendUsers', 'delete') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={deleteUser}
                                                variant={'outlined'} color="error">
                                            {user.status == 'active' ? 'User inaktiv setzen' : 'User aktiv setzen' }
                                        </Button>
                                    </Grid>
                                }
                            </Grid>

                        }
                    </form>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {

    const user = state.user.activeUserData;

    return{
        user: user,
        loggedInUser: state.auth.user,

        initialValues:{
            firstname: user && state.user.newUser === false ? state.user.activeUserData.firstname : '',
            lastname: user && state.user.newUser === false ? state.user.activeUserData.lastname : '',
            email: user && state.user.newUser === false ? state.user.activeUserData.email : '',
            role: user && state.user.newUser === false ? state.user.activeUserData.role : 'user',
            status: (function(){
                if(user && state.user.newUser === false ){
                    if(state.user.activeUserData.status == 'active'){
                        return 'Aktiv';
                    } else if(state.user.activeUserData.status == 'suspended'){
                        return 'Beurlaubt';
                    }
                } else {
                   return '';
                }
            }()),
        }
    }
}
const mapDispatchToProps = {
    saveUser: saveUserRequest,
    patchUser: patchUserRequest,
    unsetActiveUser: unsetActiveUser,
    unsetNewUser: unsetNewUser,
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'user-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(UserForm)