import React from "react";
import {Field} from "redux-form";
import DatePickerGroup from "../DatePickerGroup";

const DateRangeFilter = (props) => {
    return [
        <Field
            name={props.fieldNameFrom}
            label={props.labelFrom}
            component={DatePickerGroup}
        />,
        <Field
            name={props.fieldNameTo}
            label={props.labelTo}
            component={DatePickerGroup}
            containerStyle={{marginTop: 10}}
        />
    ]
};

export default DateRangeFilter;