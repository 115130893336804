import React from "react";
import {Field} from "redux-form";
import MultipleSelect from "../MultipleSelect";
import DatePickerGroup from "../DatePickerGroup";
import {Grid} from "@material-ui/core";
import TextFieldGroup from "../TextFieldGroup";

const NumericRangeFilter = (props) => {
    return [
        <Field
            name={props.fieldNameFrom}
            label={props.labelFrom}
            component={TextFieldGroup}
            type={'number'}
        />,
        <Field
            name={props.fieldNameTo}
            label={props.labelTo}
            component={TextFieldGroup}
            containerStyle={{marginTop: 10}}
            type={'number'}
        />
    ]
};

export default NumericRangeFilter;