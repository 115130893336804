import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import LiveView from './LiveView';
import {Dialog, IconButton, AppBar, Toolbar, Typography, Grid} from "@material-ui/core";
import {loadCheckinsRequest, unsetActiveCheckin} from "../../../actions/checkinActions";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {isAllowed} from "../../../utils/permissionHelper";
import CheckinForm from './CheckinForm';


class Live extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        this.props.dispatch(loadCheckinsRequest());
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <LiveView/>
                
                {this.props.activeCheckin === true &&
                    <Dialog fullScreen open={true} >
                        <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={()=>this.props.dispatch(unsetActiveCheckin())} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    User
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Grid container style={{justifyContent:'center'}}>
                            <Grid item xs={6}>
                                <CheckinForm />
                            </Grid>
                        </Grid>
                    </Dialog>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        activeCheckin: state.checkin.activeCheckin,
        checkin: state.checkin.activeCheckinData,
    }
}

export default connect(mapStateToProps) (withStyles(style)(Live));