import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Select, FormControl, InputLabel, withStyles, MenuItem, CircularProgress} from "@material-ui/core";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {TextField} from "@material-ui/core/es/index";
import { Autocomplete } from '@material-ui/lab';
const AsyncSelectSearch = ({ onInputChange, onChange, input, label, variant, disabled, classes, fullWidth, margin, options, loading, open, setOpen, meta: { touched, error, warning }}) => {
    let selectTheme = false;

    return (
        <MuiThemeProvider theme={selectTheme}>
                <FormControl
                    variant={variant}
                    fullWidth={fullWidth}
                    margin={margin}
                    classes={{
                        root: classes.input,
                    }}
                >
                    <Autocomplete
                        value={input.value}
                        disabled={disabled}
                        id="asynchronous-demo"
                        style={{ width: '100%', marginTop: '10px' }}
                        open={open}
                        onInputChange={(event, value) => {onInputChange(value)}}
                        onChange={(event, value) => {input.onChange(value)}}
                        onOpen={() => { setOpen(true); }}
                        onClose={() => { setOpen(false);}}
                        getOptionSelected={(option, value) => {
                            return option.value === value.value}}
                        getOptionLabel={(option) => option.label}
                        options={options}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <small>
                        {touched && ((error && <span style={{color: '#f44336'}}>{error}</span>) || (warning && <span>{warning}</span>))}
                    </small>
                </FormControl>
        </MuiThemeProvider>
    )
};

const styles = theme => ({
    input: {
        backgroundColor: "#transparent!important"
    },
    disabledInput: {
        backgroundColor: "transparent !important",
        "&::before": {
            borderColor: "transparent",
        },
    }
});



export default withStyles(styles)(AsyncSelectSearch);