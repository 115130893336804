import {
    GET_NOTIFICATION_ERROR,
    GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS,
    SEND_NOTIFICATION_ERROR, SEND_NOTIFICATION_REQUEST,
    SEND_NOTIFICATION_SUCCESS
} from "../reducers/notificationReducer";

export const sendNotificationRequest = (notificationType, values) => {
    return {
        type: SEND_NOTIFICATION_REQUEST,
        notificationType: notificationType,
        ...values
    };
}

export const sendNotificationSuccess = () => ({
    type: SEND_NOTIFICATION_SUCCESS
});

export const sendNotificationError = () => ({
    type: SEND_NOTIFICATION_ERROR
});

export const getNotificationRequest = () =>({
    type: GET_NOTIFICATION_REQUEST
});

export const getNotificationSuccess = (values) => ({
    type: GET_NOTIFICATION_SUCCESS,
    ...values
});

export const getNotificationError = () => ({
    type: GET_NOTIFICATION_ERROR
});