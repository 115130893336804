import React, {Component, Fragment} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, FieldArray, reduxForm} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, IconButton} from '@material-ui/core'
import {saveRestaurantRksvRequest} from "../../../actions/restaurantActions";
import description from './../../../assets/rksv_description.png'
import {isAllowed} from "../../../utils/permissionHelper";
import DeleteIcon from '@material-ui/icons/Delete';

class RestaurantsRKSVForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    required = value => {
        return (value != undefined && value != '') ? undefined : 'Feld erforderlich';
    }

    submitAll = (values) => {
        this.props.saveRestaurantRksv({
            _id: this.props.restaurant._id,
            ...values
        })
    };

    handleAddCashregister = (fields) => {
        fields.push({
            cashregister_id: '',
            certificate: ''
        })
    };

    renderCashregisterSettings = ({ fields, meta: { error, submitFailed } }) => {
        return(
            <div>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        {fields.map((entry, index) => (
                            <div key={index}>
                                <Grid container style={{position: 'relative'}}>
                                    <Grid md={4} xs={12}>
                                        <Field
                                            name={`${entry}.certificate`}
                                            label="Zertifikat"
                                            component={TextFieldGroup}
                                            margin="dense"
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                        />
                                    </Grid>
                                    <Grid md={2} xs={0}></Grid>
                                    <Grid md={4} xs={12}>
                                        <Field
                                            name={`${entry}.cashregister_id`}
                                            label="Kassa-ID"
                                            component={TextFieldGroup}
                                            margin="dense"
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                        />
                                    </Grid>
                                    <IconButton color="inherit" onClick={() => fields.remove(index)} style={{position: 'absolute', right: 10, top: 10}}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={'outlined'} color={'primary'} onClick={() => {this.handleAddCashregister(fields)}}>
                            Kassa hinzufügen
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    };

    render() {
        const {handleSubmit, classes} = this.props;
        return (
                <Grid container className={classes.fragmentContainer}>
                    <Grid xs={12}>
                        <form>
                            <Grid container>
                                <Grid md={12}>
                                    <h2>Kassenidentifikation</h2>
                                </Grid>
                                <Grid xs={12}>
                                    <FieldArray name={`cashregisters`} component={this.renderCashregisterSettings} />
                                </Grid>
                                <Grid xs={12}>
                                    <Grid md={12}>
                                        <h2>Zuweisungen der Steuersätze für das Restaurant</h2>
                                    </Grid>
                                    <Grid md={2} xs={4}>
                                        <Field
                                            name={`ust_1`}
                                            label="UST-1 (%)"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                    <Grid md={2} xs={4}>
                                        <Field
                                            name={`ust_2`}
                                            label="UST-2 (%)"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                    <Grid md={2} xs={4}>
                                        <Field
                                            name={`ust_3`}
                                            label="UST-3 (%)"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                    <Grid md={2} xs={4}>
                                        <Field
                                            name={`ust_4`}
                                            label="UST-4 (%)"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                    <Grid md={2} xs={4}>
                                        <Field
                                            name={`ust_5`}
                                            label="UST-5 (%)"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isAllowed(this.props.user, 'restaurants', 'update') &&
                                <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)}
                                        variant={'outlined'} color="primary">
                                    Einstellungen speichern
                                </Button>
                            }
                        </form>
                    </Grid>
                    <Grid xs={12}>
                        <Grid md={12}>
                            <h3>Beschreibung</h3>
                        </Grid>
                        <Grid md={12}>
                            <img style={{height: '300px', borderTop: '1px solid black'}} src={description} alt="Beschreibung"/>
                        </Grid>
                    </Grid>
                </Grid>
        );
    }
}

function mapStateToProps(state) {
    const restaurant = state.restaurant.activeRestaurant === true ? state.restaurant.activeRestaurantData : {};
    const rksv = restaurant && restaurant.rksv_setting ? restaurant.rksv_setting : {};

    if(rksv){
        if(rksv.ust_1 == null || rksv.ust_1 == undefined){
            rksv.ust_1 = '20';
        }
        if(rksv.ust_2 == null || rksv.ust_2 == undefined){
            rksv.ust_2 = '10';
        }
        if(rksv.ust_3 == null || rksv.ust_3 == undefined){
            rksv.ust_3 = '13';
        }
        if(rksv.ust_4 == null || rksv.ust_4 == undefined){
            rksv.ust_4 = '0';
        }
        if(rksv.ust_5 == null || rksv.ust_5 == undefined){
            rksv.ust_5 = '19';
        }
    }

    return {
        initialValues: {
            ...rksv,
        },
        user: state.auth.user,
        restaurant: restaurant,
    }
}

const mapDispatchToProps = {
    saveRestaurantRksv: saveRestaurantRksvRequest
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'restaurants-rksv-form',
        enableReinitialize:true,
    }),
    withStyles(style)
)(RestaurantsRKSVForm)