import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../containers/app/AppContainerStyles";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import {Table, TableBody, TableCell , TableHead, TableRow, Grid, TablePagination, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Chip} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from "moment";
import {formatCheckinStatus, formatDateTime, formatMoney, formatMoneyFromApi} from "../utils/helper";
import {isAllowed} from "../utils/permissionHelper";
import {compose} from "redux";
import StripeLogs from "../views/app/Accountings/StripeLogs";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const pageInputStyle = {
    marginLeft: '5px',
    marginRight: '5px'
}
const sortLinkStyle = {
    marginLeft: '10px',
    color: 'darkgray'
}

class DataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showStripeLogs: false,
            stripeLogs: [],
        }
    }

    componentWillMount() {
        this.timer = null;
        this.props.initializeDataTable({
            options:{
                page: this.props.page,
                perPage: this.props.perPage,
                sortDirection: this.props.sortDirection,
                sortField: this.props.sortField
            }});
    }



    columnSize() {
        const tableSize = Object.keys(this.props.fields).length;
        let colSize = Math.floor(12 / tableSize);
        if (colSize === 0)
            colSize = 1;
        return 'col-md-' + colSize;
    }

    renderHead() {
        const fields = this.props.fields;
        const useSort = this.props.hideSort?false:true;
        const keys = Object.keys(fields);
        const th = keys.map((key) => {
            return(<TableCell key={key} className={this.columnSize()}>{key}<span>{useSort && this.renderSort(fields[key])}</span></TableCell>);
        });
        return (
            <TableHead>
            <TableRow>
                {th}
            </TableRow>
            </TableHead>
        );
    }

    renderSort(key) {
        const sortFields = this.props.sortFields;
        const ascActive = this.props.sortField === key && this.props.sortDirection === 'asc';
        const descActive = this.props.sortField === key && this.props.sortDirection === 'desc';
        const ascColor = ascActive ? 'black' : 'darkgray';
        const descColor = descActive ? 'black' : 'darkgray';

        if(!!(sortFields?sortFields:[]).find(entry => entry === key)){
            return (
                <a style={sortLinkStyle} onClick={() => this.onSortClick(key, ascActive, descActive)}>
                    <ArrowUpwardIcon key={key + '-up'} fontSize={'small'} style={{color: ascColor}} />
                    <ArrowDownwardIcon key={key + '-down'} fontSize={'small'} style={{color: descColor}}/>
                </a>
            );
        }
    }

    onSortClick(field, ascActive, descActive) {
        if (ascActive) {
            this.props.sort(field, 'desc');
        } else if (descActive) {
            this.props.sort(null);
        } else {
            this.props.sort(field, 'asc')
        }
    }

    getTotalPages() {
        return Math.ceil(this.props.totalRecords / this.props.perPage);
    }

    renderPageButtons() {
        const totalPages = this.getTotalPages();
        const page = this.props.page?this.props.page:0;
        const rowPerPage = this.props.perPage?this.props.perPage:0
        const totalRecords = parseInt(this.props.totalRecords, 10);

        return (
            <div>
                <TablePagination
                    component="nav"
                    page={page - 1}
                    count={totalRecords}
                    rowsPerPage={rowPerPage}
                    onPageChange={()=>{}}
                    labelRowsPerPage={'Zeilen pro Seite'}

                    backIconButtonProps = {{
                        'onClick': this.props.previousPage,
                    }}
                    nextIconButtonProps={{
                        'onClick': this.props.nextPage,
                    }}
                    onRowsPerPageChange={(event)=>{this.props.changePerPage(event.target.value)}}
                />

            </div>
        );
    }

    formatDateTime(value){
        if(value){
            return moment(value).format('DD.MM.YYYY HH:mm')
        } else {
            return '';
        }
    }

    renderBody() {
        const {classes} = this.props;
        const values = Object.values(this.props.fields);
        const data = this.props.data;
        const tr = data.map((datum, index) => {
            const td = values.map((field, index) => {


                var tdId = String(datum[this.props.idField]) + "-" + field + '-' +index;

                if(field === 'created_at' || field === 'paid_at'){
                    return(<TableCell key={tdId}>{moment(datum[field]).format('DD.MM.YYYY')}</TableCell>);
                } else if(field === 'type'){
                    return(<TableCell key={tdId}>{datum[field] && datum[field].label ? datum[field].label : ''}</TableCell>);
                } else if(field === 'public') {
                    const status = datum.status===1?'Nicht veröffentlicht':'veröffentlicht';
                    return (<TableCell key={tdId}>{status}</TableCell>);
                }  else if(field === 'waiter_status') {
                    let status = '';

                    if(datum.status === 'pending'){
                        status = 'Noch keine Aktivierung'
                    } else if(datum.status === 'activation_code_sent') {
                        status = 'Aktivierungsode wurde versendet'
                    } else if(datum.status === 'active') {
                        status = 'Aktiviert'
                    } else if(datum.status === 'inactive'){
                        status = 'Inaktiv'
                    }
                    return (<TableCell key={tdId}>{status}</TableCell>);
                } else if(field === 'role') {
                    let role = '';

                    if(datum.role === 'admin'){
                        role = 'Administrator'
                    } else if(datum.role === 'finance'){
                        role = 'Finanz'
                    } else if(datum.role === 'marketing'){
                        role = 'Marketing'
                    } else if(datum.role === 'reader'){
                        role = 'Abfrage'
                    } else {
                        role = 'Unbekannt';
                    }
                    return (<TableCell key={tdId}>{role}</TableCell>);
                } else if(field === 'total_price' || field === 'discount_price' || field === 'sum7_restaurant' || field === 'sum7stat_restaurant' || field === 'provision' || field === 'sum7' || field === 'sum7stat' || field === 'c7_provision_net' || field === 'c7_to_pay_gross' || field === 'sum7_diff_net' || field === 'sum_net_with_discount') {
                    return (<TableCell key={tdId}>{formatMoneyFromApi(datum[field])}</TableCell>);
                } else if(field === 'custom_sum7'){
                    return (
                        <TableCell key={tdId}>
                            <div>{formatMoneyFromApi(datum['sum7_restaurant'])}</div>
                            <div style={{fontSize: '10px'}}>Ges: {formatMoneyFromApi(datum['sum7_total'])}</div>
                        </TableCell>);
                } else if(field === 'custom_sum7stat'){
                    return (
                        <TableCell key={tdId}>
                            <div>{formatMoneyFromApi(datum['sum7stat_restaurant'])}</div>
                            <div style={{fontSize: '10px'}}>Ges: {formatMoneyFromApi(datum['sum7stat_total'])}</div>
                        </TableCell>);
                } else if(field === 'custom_sum_net_with_discount'){
                    return (
                        <TableCell key={tdId}>
                            <div>{formatMoneyFromApi(datum['sum_net_with_discount_restaurant'])}</div>
                            <div style={{fontSize: '10px'}}>Ges: {formatMoneyFromApi(datum['sum_net_with_discount_total'])}</div>
                        </TableCell>);
                } else if(field === 'pdf_action'){
                    return (
                        <TableCell key={tdId} >
                            { this.state.showStripeLogs &&
                                <StripeLogs logs={this.state.stripeLogs} close={() => {this.setState({showStripeLogs: false, stripeLogs: []})}}/>
                            }


                            <IconButton onClick={() => this.props.accountingPdf(datum)} label={'Rechnung'}>
                                <PictureAsPdfIcon />
                            </IconButton>
                            <IconButton onClick={() => this.props.accountingDetailsPdf(datum)}>
                                <PlaylistAddCheck />
                            </IconButton>
                            {(isAllowed(this.props.user, 'accountings', 'create') && datum['c7_to_pay_gross'] != 0) &&
                                <Button onClick={() => this.props.accountingStripeTransfer(datum)} disabled={datum['stripe_transfer'].status  == 'transferred'}>
                                    Stripe
                                </Button>
                            }

                            {(isAllowed(this.props.user, 'accountings', 'create') && datum['stripe_transfer'].logs && datum['stripe_transfer'].logs.length > 0) &&
                                <Button onClick={() => this.setState({showStripeLogs: true, stripeLogs: datum['stripe_transfer'].logs})}>
                                    Logs
                                </Button>
                            }

                        </TableCell>

                    );
                } else if(field === 'total_price_taxes'){
                    return (<TableCell key={tdId} style={{fontSize: '12px'}}>
                        {formatMoneyFromApi(datum.taxes['20']) +' / '+ formatMoneyFromApi(datum.taxes['19']) +' / '+ formatMoneyFromApi(datum.taxes['13']) +' / '+ formatMoneyFromApi(datum.taxes['10']) +' / '+ formatMoneyFromApi(datum.taxes['0'])  }
                    </TableCell>);
                } else if(field === 'total_price_19'){
                    return (<TableCell key={tdId}>
                        {formatMoneyFromApi(datum.taxes['19'])}
                    </TableCell>);
                } else if(field === 'total_price_13'){
                    return (<TableCell key={tdId}>
                        {formatMoneyFromApi(datum.taxes['13'])}
                    </TableCell>);
                } else if(field === 'total_price_10'){
                    return (<TableCell key={tdId}>
                        {formatMoneyFromApi(datum.taxes['10'])}
                    </TableCell>);
                } else if(field === 'total_price_0') {
                    return (<TableCell key={tdId}>
                        {formatMoneyFromApi(datum.taxes['0'])}
                    </TableCell>);
                } else if(field === 'voucher_rest'){
                    let value = formatMoneyFromApi(datum['rest']);
                    if(datum['basic_type'] === 'percentage' || datum['basic_type'] === 'status'){
                        value = '-';
                    } else {
                        if(datum['rest'] == 0){
                            value = 'verbraucht';
                        }
                    }
                    return (<TableCell key={tdId}>
                        {value}
                    </TableCell>);
                } else if(field === 'voucher_customer') {
                    return (<TableCell key={tdId}>
                        <div>{datum['customer']['firstname']} {datum['customer']['lastname']} ({datum['customer']['number']})</div>
                        <div>Gesamtguthaben: {formatMoneyFromApi(datum['customer']['voucherSum'])}</div>
                    </TableCell>);
                } else if(field === 'voucher_email') {
                    return (<TableCell key={tdId}>
                        {datum['customer']['email']}
                    </TableCell>);
                } else if(field === 'voucher_value') {

                    let value = formatMoneyFromApi(datum['value']);
                    if(datum['basic_type'] == 'percentage'){
                        value = (datum['value'] / 100) + '%';
                    } else if(datum['basic_type'] == 'status'){
                        value = datum['value_status'];
                    }

                    return (<TableCell key={tdId}>
                        {value}
                    </TableCell>);
                } else if(field === 'voucher_status') {
                    let value = 'Aktiv';
                    if(datum['status'] == 'solved' || datum['status'] == 'cashed'){
                        value = 'Eingelöst';
                    } else if(datum['status'] == 'inactive'){
                        value = 'Gelöscht';
                    }
                    return (<TableCell key={tdId}>
                        {value}
                    </TableCell>);
                } else if(field === 'voucher_created_at') {
                    return (<TableCell key={tdId}>
                        {this.formatDateTime(datum['created_at'])}
                    </TableCell>);
                } else if(field === 'voucher_cashed_at') {
                    return (<TableCell key={tdId}>
                        {this.formatDateTime(datum['cashed_at'])}
                    </TableCell>);
                } else if(field === 'voucher_basic_type'){
                    let value = (() => {
                        if(datum['basic_type'] == 'percentage'){
                            return 'Rabattgutschein';
                        } else if(datum['basic_type'] == 'absolute'){
                            return 'Wertgutschein';
                        } else if(datum['basic_type'] == 'status'){
                            return "Statusänderung"
                        }
                    })();
                    return (<TableCell key={tdId}>
                        {value}
                    </TableCell>);
                } else if(field === 'voucher_sent_at') {
                    return (<TableCell key={tdId}>
                        {this.formatDateTime(datum['sent_at'])}
                    </TableCell>);
                } else if(field === 'customers.discount'){
                    return (<TableCell key={tdId}>
                        {(datum['discount']&&datum['discount']['discount_value'])?datum['discount']['discount_value']:0}%
                    </TableCell>);
                } else if(field === 'checkin_status'){
                    let s = formatCheckinStatus(datum['status']);
                    return (<TableCell key={tdId}>
                        { s  }
                    </TableCell>);
                } else if(field === 'check_in_at'){
                    return (<TableCell key={tdId}>
                        { this.formatDateTime(datum['check_in_at']) }
                    </TableCell>);
                } else if(field === 'restaurant.name'){
                    return (<TableCell key={tdId}>
                        { datum['restaurant'] && (datum['restaurant'].name ) }
                    </TableCell>);
                } else if(field === 'customer.name'){
                    return (<TableCell key={tdId}>
                        { datum['customer'] && (datum['customer'].firstname + ' ' + datum['customer'].lastname) }
                    </TableCell>);
                } else if(field === 'customers.turnover') {
                    return (<TableCell key={tdId}>
                        <div style={{fontSize: '10px'}}>
                            Mon: {formatMoneyFromApi((datum['turnover'] && datum['turnover']['month']) ? datum['turnover']['month'] : 0)}</div>
                        <div style={{fontSize: '10px'}}>
                            Ges: {formatMoneyFromApi((datum['turnover'] && datum['turnover']['total']) ? datum['turnover']['total'] : 0)}</div>
                    </TableCell>);
                } else if(field === 'stripe_transfer'){
                    if(datum['stripe_transfer']){

                        const status = function(){
                            if(datum['stripe_transfer'].status  == 'created'){
                                return 'Erstellt';
                            } else if(datum['stripe_transfer'].status  == 'transferred'){
                                return 'Transferiert';
                            } else if(datum['stripe_transfer'].status  == 'cancelled'){
                                return 'Abgebrochen';
                            } else if(datum['stripe_transfer'].status  == 'missing_data'){
                                return 'Fehlende Daten';
                            } else if(datum['stripe_transfer'].status  == 'error'){
                                return 'Fehler';
                            }
                        }();

                        return (<TableCell key={tdId}>
                            <div>{formatMoneyFromApi(datum['stripe_transfer'].amount)}</div>
                            <div>{status}</div>
                        </TableCell>);
                    } else {
                        return (<TableCell key={tdId}>

                        </TableCell>);
                    }

                } else if(field === 'hello_status') {
                    let value = 'Aktiv';
                    if(datum['status'] == 'disabled'){
                        value = 'Gelöscht';
                    } else if(datum['status'] == 'seen'){
                        value = 'Gesehen';
                    }
                    return (<TableCell key={tdId}>
                        {value}
                    </TableCell>);

                } else if(field === 'hello_created_at') {
                    return (<TableCell key={tdId}>
                        {formatDateTime(datum['created_at'])}
                    </TableCell>);
                } else if(field === 'validity'){
                    return (<TableCell key={tdId}>
                        {formatDateTime(datum['validity'], false)}
                    </TableCell>);
                } else if(field === 'status'){
                    let toReturn = "Aktiv";
                    if(datum['status']){
                        switch(datum['status']){
                            case 'active': toReturn = 'Aktiv'; break;
                            case 'inactive': toReturn = 'Inaktiv'; break;
                            case 'deleted': toReturn = 'Gelöscht'; break;
                        }
                    }
                    return (<TableCell key={tdId}>{toReturn}</TableCell>);
                } else if(field === 'c7_provision_net' || field === 'c7_provision_diff_net' || field === 'sum_gross_total_restaurant' || field === 'sum_vouchers' || field === 'c7_provision_diff_gross'){
                    return <TableCell>{formatMoneyFromApi(datum[field])}</TableCell>
                } else {
                    return(<TableCell key={tdId}>{datum[field]}</TableCell>);
                }
            });


            if(this.props.click){
                return(
                    <TableRow className={classes.tableRow} key={datum[this.props.idField] || index} onClick={() => this.props.onClickRow(datum)}>
                        {td}
                    </TableRow>
                );
            }else{
                return(
                    <TableRow key={datum[this.props.idField] || index}>
                        {td}
                    </TableRow>
                );
            }

        });
        return (
            <TableBody>
            {tr}
            </TableBody>
        );
    }

    render() {
        return (
            <div className='table-wrapper' >
                <Grid xs={8} md={12}>
                    {this.props.filterComponent()}
                </Grid>
                <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                    {this.renderHead()}
                    {this.renderBody()}
                </Table>

                <Grid xs={12}>
                    {this.renderPageButtons()}
                </Grid>
            </div>
        );
    }
}

DataTable.propTypes = {
    fields: PropTypes.object,
    data: PropTypes.array,
    idField: PropTypes.string,
    totalRecords: PropTypes.number,
    perPage: PropTypes.number,
    page: PropTypes.number
}

export default compose(withStyles(style))(DataTable);