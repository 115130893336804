import React from 'react';
import {Switch, FormControlLabel, withStyles} from "@material-ui/core";
import { green, grey } from '@material-ui/core/colors';

const SwitchGroup = ({ input, disabled, label, classes, OnChange }) => {
    return (

        <FormControlLabel
            control={
                <Switch
                    {...input}
                    checked={input.value}
                    onClick={() => input.onChange(!input.value)}
                    disabled={disabled}
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    onChange={OnChange}
                />
            }
            label={label}
        />

    )
}

export default withStyles({
    switchBase: {
        color: grey[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(SwitchGroup);