import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from 'redux';
import {Field, FieldArray, formValueSelector, reduxForm} from 'redux-form'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Grid, IconButton, Button, MenuItem, Divider } from "@material-ui/core";
import SelectGroup from "../../../components/SelectGroup";
import DatePickerGroup from "../../../components/DatePickerGroup";
import { saveOpeningRequest } from "../../../actions/openingActions";
import AddIcon from '@material-ui/icons/Add';
import SwitchGroup from "../../../components/SwitchGroup";
import {isAllowed} from "../../../utils/permissionHelper";


const createOptions = (value, start, end) => {
    const options =[];

    while(start <= end){
        let time = start * 60 * 60;

        options.push(time);
        start = start + value;
    }
   return options;

}

const renderOptions = (options) => {
    return options.map( option => renderOption(option))
}

const renderOption = (option) => {

    let seconds = option;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);

    return <MenuItem value={option}>{hours < 10 ? '0' + hours : hours}: {minutes > 0 ? minutes : '00'}</MenuItem>
};


const renderOpeningHours = ({ fields, meta: { error, submitFailed }, changeall }) => {
    const options = createOptions(0.5, 0.5, 24);

    return(
        <div>
            <Grid container spacing={5}>
                <Grid item xs={10}>
                    {fields.map((openingHours, index) => (
                        <div key={index}>
                            <Grid container>
                                <Grid xs={5}>
                                    <Field
                                        name={`${openingHours}.start`}
                                        component={SelectGroup}
                                        fullWidth={true}
                                        children={renderOptions(options)}
                                        disabled={changeall}
                                    />
                                </Grid>
                                <Grid xs={2}>
                                </Grid>
                                <Grid xs={5}>
                                    <Field
                                        name={`${openingHours}.end`}
                                        component={SelectGroup}
                                        fullWidth={true}
                                        children={renderOptions(options)}
                                        disabled={changeall}
                                    />
                                </Grid>
                                {!changeall &&
                                    <Grid xs={12} style={{textAlign: 'center'}}>
                                        <Button onClick={() => fields.remove(index)}> Entfernen </Button>
                                    </Grid>
                                }

                            </Grid>
                        </div>
                    ))}
                </Grid>
                <Grid item xs={2}>
                    <IconButton color={'primary'} onClick={() => fields.push({})}>
                        <AddIcon/>
                    </IconButton>
                    {submitFailed && error && <span>{error}</span>}
                </Grid>
            </Grid>
        </div>
    );
}

const renderDay = ({ fields, meta: { error, submitFailed } }) => (  
    <div>
        <Grid container>
            <Grid item xs={12}>
            {fields.map((spezial, index) => (
                <div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Field
                                name={`${spezial}.date`}
                                fullWidth={true}
                                component={DatePickerGroup}
                                label="Datum"
                                helperText={''}
                                error={false}
                            />
                            <Button onClick={() => fields.remove(index)}>Entfernen </Button>
                            <FieldArray name={`${spezial}.openingHours`} component={renderOpeningHours} />

                        </Grid>
                    </Grid>
                    <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                </div>
            ))}
            </Grid>
            <Grid item xs={12}>
                <Button color={'primary'} onClick={() => fields.push({})}>
                    Feiertag hinzufügen
                </Button>
            </Grid>
        </Grid>
    </div>
)

class OpeningHours extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const {classes, handleSubmit, restaurantData, openHours, reset, values} = this.props;

        const submit = (values) =>{
            if(openHours && openHours._id){
                this.props.saveOpeningHours({
                    data: {
                        id: openHours._id ? openHours._id : '',
                        restaurant_id: restaurantData._id,
                        data: values,
                    }})
            } else {
                this.props.saveOpeningHours({
                    data: {
                        restaurant_id: restaurantData._id,
                        data: values,
                    }})
            }

        };

        return (
            <Fragment>
                <form>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <h2>Öffnungszeiten</h2>
                            <Field
                                name={`changeall`}
                                fullWidth={true}
                                component={SwitchGroup}
                                label="Alle Wochentage ändern"
                            />
                            {
                                (this.props.changeall) && [<div>
                                    <h4>Alle Wochentage</h4>
                                    <div style={{marginTop: '-25px'}}> <FieldArray name="all" component={renderOpeningHours} changeall={false} /> </div>
                                </div>,
                                <Divider style={{border: '1px solid #000000', marginTop: '20px', marginBottom: '20px', marginLeft: '-20px', marginRight: '-20px'}}/>
                                ]
                            }

                            <div>
                                <h4>Montag</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="monday" component={renderOpeningHours} changeall={this.props.changeall} /> </div>
                            </div>
                            <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                            <div>
                                <h4>Dienstag</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="tuesday" component={renderOpeningHours} changeall={this.props.changeall}/> </div>
                            </div>
                            <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                            <div>
                                <h4>Mittwoch</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="wednesday" component={renderOpeningHours} changeall={this.props.changeall}/> </div>
                            </div>
                            <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                            <div>
                                <h4>Donnerstag</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="thursday" component={renderOpeningHours} changeall={this.props.changeall}/> </div>
                            </div>
                            <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                            <div>
                                <h4>Freitag</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="friday" component={renderOpeningHours} changeall={this.props.changeall} /> </div>
                            </div>
                            <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                            <div>
                                <h4>Samstag</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="saturday" component={renderOpeningHours} changeall={this.props.changeall} /> </div>
                            </div>
                            <Divider style={{border: '1px solid #cccccc5e', marginTop: '20px', marginBottom: '20px'}}/>
                            <div>
                                <h4>Sonntag</h4>
                                <div style={{marginTop: '-25px'}}> <FieldArray name="sunday" component={renderOpeningHours} changeall={this.props.changeall} /> </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <h2>Öffnungszeiten zu Feiertagen</h2>
                            <div>
                                <span><FieldArray name="spezial" component={renderDay} /></span>
                            </div>
                        </Grid>
                        {isAllowed(this.props.user, 'restaurants', 'update') &&
                            <Grid item xs={12}>
                                <div>
                                    <Button fullWidth style={{marginTop: '20px'}} variant={'outlined'} color={'primary'}
                                            disabled={false} onClick={handleSubmit(submit)}>
                                        Sichern
                                    </Button>
                                </div>
                            </Grid>
                        }

                    </Grid>
                </form>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {

    let monday = [];
    let tuesday = [];
    let wednesday = [];
    let thursday = [];
    let friday = [];
    let saturday = [];
    let sunday = [];

    let special_days = [];

    if(state.restaurant.activeRestaurant === true && state.opening.data && state.opening.reset === false){
        if(state.opening.data.normal_days){
            monday = state.opening.data.normal_days.mon;
            tuesday = state.opening.data.normal_days.tue;
            wednesday = state.opening.data.normal_days.wed;
            thursday = state.opening.data.normal_days.thu;
            friday = state.opening.data.normal_days.fri;
            saturday = state.opening.data.normal_days.sat;
            sunday = state.opening.data.normal_days.sun;
        }

        if(state.opening.data.special_days){
            special_days = state.opening.data.special_days
        }
    }

    const selector = formValueSelector('opening-hours-form')

    return{
        restaurant: state.restaurant.activeRestaurant,
        restaurantData: state.restaurant.activeRestaurantData,
        reset: state.opening.reset,
        openHours: state.opening.data,
        opening: state.opening.opening,
        user: state.auth.user,
        initialValues: {
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            spezial: special_days,
        },
        changeall: selector(state, 'changeall')
    }
}
const mapDispatchToProps = {
    saveOpeningHours: saveOpeningRequest,
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'opening-hours-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props) => {
            if(values && values['changeall'] && values['changeall'] == true){
                if(values['all']){
                    values['monday'] = values['all'];
                    values['tuesday'] = values['all'];
                    values['wednesday'] = values['all'];
                    values['thursday'] = values['all'];
                    values['friday'] = values['all'];
                    values['saturday'] = values['all'];
                    values['sunday'] = values['all'];
                }
            }
        }
    }),
    withStyles(style)
)(OpeningHours)