import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import RestaurantsPostView from './RestaurantsPostView';

class RestaurantsPost extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { classes } = this.props;
        return (
           <Fragment>
                <RestaurantsPostView/>
           </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
    }
}
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(RestaurantsPost));