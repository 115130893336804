import {
    FILTER_CHANGE_PER_PAGE,
    FILTER_FILTER_TABLE,
    FILTER_INITIALIZE_DATATABLE,
    FILTER_NEXT_PAGE,
    FILTER_PREVIOUS_PAGE,
    FILTER_SORT_TABLE,
    LOAD_EXTENDED_FILTERS_ERROR,
    LOAD_EXTENDED_FILTERS_REQUEST,
    LOAD_EXTENDED_FILTERS_SUCCESS,
    LOAD_FILTERBYID_ERROR,
    LOAD_FILTERBYID_REQUEST,
    LOAD_FILTERBYID_SUCCESS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_REQUEST,
    LOAD_FILTERS_SUCCESS,
    PATCH_FILTER_ERROR,
    PATCH_FILTER_REQUEST,
    PATCH_FILTER_SUCCESS,
    SAVE_FILTERS_ERROR,
    SAVE_FILTERS_REQUEST,
    SAVE_FILTERS_SUCCESS,
    SET_ACTIVE_FILTER
} from "../reducers/filtersReducer";

export const loadExtendedFiltersRequest = (values) => ({
    type: LOAD_EXTENDED_FILTERS_REQUEST,
    ...values
});

export const loadExtendedFiltersSuccess = (values) => ({
    type: LOAD_EXTENDED_FILTERS_SUCCESS,
    ...values
});

export const loadExtendedFiltersError = (values) => ({
    type: LOAD_EXTENDED_FILTERS_ERROR,
    ...values
});

export const loadFiltersRequest = (values) => ({
    type: LOAD_FILTERS_REQUEST,
    ...values
});

export const loadFiltersSuccess = (values) => ({
    type: LOAD_FILTERS_SUCCESS,
    ...values
});

export const loadFiltersError = (values) => ({
    type: LOAD_FILTERS_ERROR,
    ...values
});

export const loadFilterByIdRequest = (values) => ({
    type: LOAD_FILTERBYID_REQUEST,
    ...values
});

export const loadFilterByIdSuccess = (values) => ({
    type: LOAD_FILTERBYID_SUCCESS,
    ...values
});

export const loadFilterByIdError = (values) => ({
    type: LOAD_FILTERBYID_ERROR,
    ...values
});

export const patchFilterRequest = (values) => ({
    type: PATCH_FILTER_REQUEST,
    ...values
});

export const patchFilterSuccess = (values) => ({
    type: PATCH_FILTER_SUCCESS,
    ...values
});

export const patchFilterError = (values) => ({
    type: PATCH_FILTER_ERROR,
    ...values
});

export const saveFiltersRequest = (values) => ({
    type: SAVE_FILTERS_REQUEST,
    ...values
});

export const saveFiltersSuccess = (values) => ({
    type: SAVE_FILTERS_SUCCESS,
    ...values
});

export const saveFiltersError = (values) => ({
    type: SAVE_FILTERS_ERROR,
    ...values
});

export const initializeDataTable = ({options}) => {
   return ({
       type: FILTER_INITIALIZE_DATATABLE,
       ...options,
   });
}

export const nextPage = () => ({
    type: FILTER_NEXT_PAGE,
});

export const previousPage = () => ({
    type: FILTER_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: FILTER_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: FILTER_SORT_TABLE,
    field,
    value
});

export const filterTable = ({values}) => ({
    type: FILTER_FILTER_TABLE,
    values
});

export const setActiveFilter = (filter) => ({
    type: SET_ACTIVE_FILTER,
    filter: filter
});