import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm, reset} from 'redux-form';
import moment from 'moment';
import {Grid, FormControl, Select, InputLabel, MenuItem} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {filterTable} from "../../../actions/filterActions";
import MultipleSelect from "../../../components/MultipleSelect";


function FiltersFilter(props){

    const statusOptions = () => {
        return [
            {_id: 'active', name: 'Aktiv'},
            {_id: 'inactive', name: 'Inaktiv'},
        ]
    };

    return  <Grid container spacing={5}>
                <Grid item md={3} xs={12}>
                    <Field
                        format={value => Array.isArray(value) ? value : []}
                        name="status"
                        component={MultipleSelect}
                        label={'Status'}
                        fullWidth
                        accessorValue={"_id"}
                        accessorLabel={'name'}
                        options={statusOptions()}
                    />
                </Grid>
    </Grid>
}

function mapStateToProps(state) {
    return {
        initialValues: {
            status: ['active']
        }
    }
}

const mapDispatchToProps = {
    filterTable: filterTable,
    reset: reset('filterFilter-form'),
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'filterFilter-form',
        onChange: (values, dispatch, props)=>{
            dispatch(props.filterTable({
                values: {
                    status: values.status?values.status:null
                }
            }))
        },

    }),
    withStyles(style)
)(FiltersFilter)
