import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import VouchersView from './VouchersView';
import {Dialog, IconButton, AppBar, Toolbar, Typography, Grid} from "@material-ui/core";
import VouchersForm from './VouchersForm';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
    filterTable, loadVouchersRequest, setNewVoucher, unsetActiveVoucher,
    unsetNewVoucher
} from "../../../actions/voucherActions";
import {loadCustomersRequest, loadSimpleCustomersRequest} from "../../../actions/customerActions";
import {isAllowed} from "../../../utils/permissionHelper";


class Vouchers extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if(this.props.customer_id){
            this.props.setFilter({ values: { customer_id: this.props.customer_id }});
        }

        this.props.loadVouchers();
        this.props.loadSimpleCustomers();
    }

    render() {
        const { classes, activeVoucher, newVoucher, customer_id } = this.props;

        return (
            <Fragment>
                {isAllowed(this.props.user, 'vouchers', 'create') &&
                    <IconButton onClick={this.props.setNewVoucher} style={{float: 'right'}}>
                        <AddIcon fontSize={'large'}/> <span style={{fontSize: '18px'}}>Gutschein hinzufügen</span>
                    </IconButton>
                }
                <VouchersView customer_id={customer_id}/>
                <Dialog fullScreen open={activeVoucher || newVoucher} >
                    <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={activeVoucher?this.props.unsetActiveVoucher:this.props.unsetNewVoucher} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {activeVoucher ? 'Gutschein bearbeiten' : 'Neuen Gutschein erstellen'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container style={{justifyContent:'center'}}>
                        <Grid item xs={6}>
                            <VouchersForm isNewVoucher={!activeVoucher} customer_id={customer_id}/>
                        </Grid>
                    </Grid>
                </Dialog>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        activeVoucher: state.voucher.activeVoucher,
        newVoucher: state.voucher.newVoucher,
        user: state.auth.user
    }
}
const mapDispatchToProps = {
    setNewVoucher: setNewVoucher,
    unsetActiveVoucher: unsetActiveVoucher,
    unsetNewVoucher: unsetNewVoucher,
    loadVouchers: loadVouchersRequest,
    loadSimpleCustomers: loadSimpleCustomersRequest,
    setFilter: filterTable,
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Vouchers));