import React, {useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import MultipleSelect from "../MultipleSelect";
import {loadRestaurantRequest} from "../../actions/restaurantActions";
import {connect} from "react-redux";
import {compose} from "redux";



function RestaurantSelect(props){

    useEffect(() => {
        props.loadRestaurants();
    }, [props]);

    const restaurantOptions = (props.restaurants?props.restaurants:[]).map(restaurant => {
        return {
            ...restaurant
        }
    });

    return <Field
        format={value => Array.isArray(value) ? value : []}
        name={props.fieldName}
        component={MultipleSelect}
        label={'Restaurant'}
        fullWidth
        accessorValue={"_id"}
        accessorLabel={'name'}
        options={restaurantOptions}
    />;
};


function mapStateToProps(state) {
    return {
        restaurants: state.restaurant.data,
    }
}
const mapDispatchToProps = {
   loadRestaurants: loadRestaurantRequest,
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(RestaurantSelect)