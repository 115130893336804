import {put, call, select} from "@redux-saga/core/effects";
import {
    loadSettingsError,
    loadSettingsSuccess, loadStripeSessionsError,
    loadStripeSessionsSuccess,
    stripePriceError,
    stripePriceSuccess,
    updateSettingsError,
    updateSettingsSuccess, updateStripeSessionError, updateStripeSessionSuccess
} from "../actions/settingsActions";
import {addFlashMessage} from "../actions/flashMessageActions";

export function* loadSettingsRequest (api) {
    try {
        let result = yield call(api.loadSettings);
        if (result && result.data && result.data.success) {
            yield put(loadSettingsSuccess({settings: result.data.settings}));
        } else {
            yield put(loadSettingsError({}));
            yield put(addFlashMessage({message:'Fehler beim Laden der Einstellungen', style:'error'}))

        }
    } catch (e) {
        console.log(e)
    }
}

export function* updateSettingsRequest (api, data) {
    try {
        let result = yield call(api.updateSettings, data);

        if (result && result.data && result.data.success) {
            yield put(updateSettingsSuccess({}));
            yield put(addFlashMessage({message:'Einstellungen erfolgreich gespeichert', style:'success'}))
        } else {
            yield put(updateSettingsError({}));
            yield put(addFlashMessage({message:'Fehler beim Ändern der Einstellungen', style:'error'}))
        }
    } catch (e) {
        console.log(e)
    }
}

export function* stripePriceRequest (api, data) {
    let result = yield call(api.stripePrice, data);

    if (result && result.data && result.data.success) {
        yield put(stripePriceSuccess({sessionId: result.data.sessionId}));
    } else {
        yield put(stripePriceError({}));
    }
}

export function* loadStripeSessionsRequest (api, data) {
    let result = yield call(api.stripeSessions, data);

    if (result && result.data && result.data.success) {
        yield put(loadStripeSessionsSuccess({sessions: result.data.sessions}));
    } else {
        yield put(loadStripeSessionsError({}));
    }
}

export function* updateStripeSessionRequest (api, {id, data}) {
    console.log('id: ', id);
    console.log('data: ', data)
    let result = yield call(api.updateStripeSession, id, data);

    if (result && result.data && result.data.success) {
        yield put(updateStripeSessionSuccess({}));
    } else {
        yield put(updateStripeSessionError({}));
    }
}