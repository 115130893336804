import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Tabs, Tab, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomersForm from "./CustomersForm";
import Payments from "../Payments/Payments";
import CustomersDiscountView from "./CustomersDiscountView";
import CustomersStatisticsView from "./CustomersStatisticsView";
import CustomersVoucherView from "./CustomersVoucherView";
import Vouchers from "../Vouchers/Vouchers";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{maxWidth: "1200px"}}
            {...other}
        >
            <Fragment>{children}</Fragment>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



class EditCustomers extends Component {
    constructor(props) {
        super(props);
        this.state = {value: 0}
    }

    componentDidMount() {}

    render() {

        const { classes, customer } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Fragment>

                <div className={classes.root}>

                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                        indicatorColor="primary"
                    >
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Stammdaten" {...a11yProps(0)} />
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Umsätze" {...a11yProps(1)} />
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Statistiken" {...a11yProps(2)} />
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Rabatte" {...a11yProps(3)} />
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Gutscheine" {...a11yProps(4)} />
                    </Tabs>


                    <TabPanel className={classes.tabContainer} value={this.state.value} index={0}>
                       <CustomersForm/>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={1}>
                        <Grid container className={classes.fragmentContainer}>
                            <Grid xs={12}>
                                <Payments showTotal={false} customer_id={customer._id} showCsvDownload={false} customerView={true}/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={2}>
                        <Grid container className={classes.fragmentContainer}>
                            <Grid xs={12}>
                                <CustomersStatisticsView data={customer.turnover.grouped}/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={3}>
                        <Grid container className={classes.fragmentContainer}>
                            <Grid xs={12}>
                                <CustomersDiscountView data={customer.discounts}/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={4}>
                        <Grid container className={classes.fragmentContainer}>
                            <Grid xs={12}>
                                <Vouchers customer_id={customer._id}/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const customer = state.customer.activeCustomer;
    return {
        customer: customer,
    }
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EditCustomers));