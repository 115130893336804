import {
  LOAD_CHECKINS_REQUEST,
  LOAD_CHECKINS_SUCCESS,
  CHECKIN_INITIALIZE_DATATABLE,
  CHECKIN_NEXT_PAGE,
  CHECKIN_PREVIOUS_PAGE,
  CHECKIN_CHANGE_PER_PAGE,
  CHECKIN_SORT_TABLE,
  CHECKIN_FILTER_TABLE,
  SET_ACTIVE_CHECKIN,
  UNSET_ACTIVE_CHECKIN,
  PATCH_CHECKIN_REQUEST,
  PATCH_CHECKIN_SUCCESS,
  PATCH_CHECKIN_ERROR,
} from '../reducers/checkinReducer';


export const loadCheckinsRequest = () => ({
  type: LOAD_CHECKINS_REQUEST,
});

export const loadCheckinsSuccess = ({checkin, totalRecords}) => ({
  type: LOAD_CHECKINS_SUCCESS,
  checkin, 
  totalRecords,
});

export const initializeDataTable = ({options}) => ({
  type: CHECKIN_INITIALIZE_DATATABLE,
  ...options,
});

export const filter = (values) => ({
  type: CHECKIN_FILTER_TABLE,
  values
})

export const sort = (field, value) => ({
  type: CHECKIN_SORT_TABLE,
  field,
  value
});

export const nextPage = () => ({
  type: CHECKIN_NEXT_PAGE,
});

export const previousPage = () => ({
  type: CHECKIN_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
  type: CHECKIN_CHANGE_PER_PAGE,
  value
});


export const setActiveCheckin = (checkin) => ({
  type: SET_ACTIVE_CHECKIN,
  checkin
});

export const unsetActiveCheckin = () => ({
  type: UNSET_ACTIVE_CHECKIN
});

export const patchCheckinRequest = (data) => ({
  type: PATCH_CHECKIN_REQUEST,
  data,
});

export const patchCheckinSuccess = (values) => ({
  type: PATCH_CHECKIN_SUCCESS,
  ...values
});

export const patchCheckinError = () => ({
  type: PATCH_CHECKIN_ERROR,
});
