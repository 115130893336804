import MyTable from '../../../components/MyTable';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort,
} from '../../../actions/paymentActions';
import {TableCell, TableHead, TablePagination, TableRow, TableBody, Grid, Table} from "@material-ui/core";
import {formatMoneyFromApi, renderSort} from "../../../utils/helper";
import LocalPizzaRounded from '@material-ui/icons/LocalPizzaRounded';
import LocalTaxi from '@material-ui/icons/LocalTaxi';
import moment from "moment";

class PaymentsTable extends Component {

    componentWillMount() {
        this.props.initializeDataTable({
            options:{
                page: this.props.page,
                perPage: this.props.perPage,
                sortDirection: this.props.sortDirection,
                sortField: this.props.sortField
            }});
    }

    renderHead() {
        const {restaurantView, customerView} = this.props;
        const sortOptions = {
            sortFields: this.props.sortFields,
            sortField: this.props.sortField,
            sortDirection: this.props.sortDirection,
            onSortClick: this.onSortClick
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell key={'0'} className={'col-md-1'}>{''}</TableCell>
                    <TableCell key={'1'} className={'col-md-1'}>{'Datum/Uhrzeit'}<span>{renderSort('created_at', sortOptions)}</span></TableCell>
                    {!restaurantView && <TableCell key={'2'} className={'col-md-1'}>{'Restaurant'}</TableCell> }
                    {!customerView && <TableCell key={'2'} className={'col-md-1'}>{'7user'}</TableCell>}
                    <TableCell key={'3'} className={'col-md-1'}>{'Status'}</TableCell>
                    <TableCell key={'4'} className={'col-md-1'}>{'Brutto'}</TableCell>
                    <TableCell key={'5'} className={'col-md-1'}>{'USt.%'}</TableCell>
                    {!customerView && <TableCell key={'6'} className={'col-md-1'}>{'Netto'}</TableCell>}
                    <TableCell key={'7'} className={'col-md-1'}>{'Rabatt %'}</TableCell>
                    <TableCell key={'8'} className={'col-md-1'}>{'Rabatt brutto'}</TableCell>
                    {!customerView && <TableCell key={'10'} className={'col-md-1'}>{'Rabatt netto'}</TableCell>}
                    <TableCell key={'11'} className={'col-md-1'}>{'Kundenpreis'}</TableCell>
                    {!customerView && <TableCell key={'12'} className={'col-md-1'}>{'USt.'}</TableCell>}
                    {!customerView && <TableCell key={'13'} className={'col-md-1'}>{'Netto'}</TableCell>}
                </TableRow>
            </TableHead>
        );
    }

    onSortClick = (field, ascActive, descActive) => {
        if (ascActive) {
            this.props.sort(field, 'desc');
        } else if (descActive) {
            this.props.sort(null);
        } else {
            this.props.sort(field, 'asc')
        }
    }

    getTotalPages() {
        return Math.ceil(this.props.totalRecords / this.props.perPage);
    }

    renderPageButtons() {
        const totalPages = this.getTotalPages();
        const page = this.props.page?this.props.page:0;
        const rowPerPage = this.props.perPage?this.props.perPage:0

        return (
            <div>
                <TablePagination
                    component="nav"
                    page={page - 1}
                    count={this.props.totalRecords}
                    rowsPerPage={rowPerPage}
                    labelRowsPerPage={'Zeilen pro Seite'}

                    backIconButtonProps = {{
                        'onClick': this.props.previousPage,
                    }}
                    nextIconButtonProps={{
                        'onClick': this.props.nextPage,
                    }}
                    onChangeRowsPerPage={(event)=>{this.props.changePerPage(event.target.value)}}
                />

            </div>
        );
    }

    formatDateTime(value){
        if(value){
            return moment(value).format('DD.MM.YYYY HH:mm')
        } else {
            return '';
        }
    }

    addRowPerTax(row, positions, value, key = null){
        const entry = positions.find(p => {return (p.tax == value && ((!p.key && !key) || (p.key && p.key === key)))});
        const {restaurantView, customerView} = this.props;
        if(entry && entry['sum_gross_with_discount']){
            return <TableRow className={['position-row', row['open'] ? 'show' : 'hide']}>
                <TableCell></TableCell>
                <TableCell>{this.formatDateTime(row['created_at'])}</TableCell>
                {!restaurantView && <TableCell>{row['restaurant']}</TableCell> }
                {!customerView && <TableCell>{row['customer']}</TableCell>}
                <TableCell>{row['discount_name']}</TableCell>
                <TableCell>{formatMoneyFromApi(entry['sum_gross_without_discount'])}</TableCell>
                <TableCell>{entry['tax']}% {entry['key'] === 'tips' ? ' (Trinkgeld)' : (entry['key'] === 'voucher' && ' (Gutschein)')}</TableCell>
                {!customerView && <TableCell>{formatMoneyFromApi(entry['sum_net_without_discount'])}</TableCell>}
                <TableCell>{entry['key'] === 'tips' ? '0' : row['discount_value']}%</TableCell>
                <TableCell>{formatMoneyFromApi(entry['sum_gross_discount'])}</TableCell>
                {!customerView && <TableCell>{formatMoneyFromApi(entry['sum_net_discount'])}</TableCell>}
                <TableCell>{key == 'voucher' ? formatMoneyFromApi(entry['sum_gross_with_discount']*-1) : formatMoneyFromApi(entry['sum_gross_with_discount'])}</TableCell>
                {!customerView && <TableCell>{formatMoneyFromApi(key != 'voucher' ? (entry['sum_gross_with_discount'] - entry['sum_net_with_discount']) : 0)}</TableCell>}
                {!customerView && <TableCell>{key == 'voucher' ? formatMoneyFromApi(0) : formatMoneyFromApi(entry['sum_net_with_discount'])}</TableCell>}
            </TableRow>
        };
    }

    renderBody() {
        const {classes, restaurantView, customerView} = this.props;
        const data = this.props.data;

        const tr = data.map((row) => {
            const toReturn = [];

            toReturn.push(this.addRowPerTax(row, row.positions, row.rksv_setting?row.rksv_setting.ust_1:20));
            toReturn.push(this.addRowPerTax(row, row.positions, row.rksv_setting?row.rksv_setting.ust_5:19));
            toReturn.push(this.addRowPerTax(row, row.positions, row.rksv_setting?row.rksv_setting.ust_3:13));
            toReturn.push(this.addRowPerTax(row, row.positions, row.rksv_setting?row.rksv_setting.ust_2:10));
            toReturn.push(this.addRowPerTax(row, row.positions, row.rksv_setting?row.rksv_setting.ust_4:0));
            toReturn.push(this.addRowPerTax(row, row.positions, 0, 'tips'));
            toReturn.push(this.addRowPerTax(row, row.positions, 0, 'voucher'));

            toReturn.push(<TableRow className={'sum-row'} onClick={() => {console.log('open row');}}>
                <TableCell>{row['check_in_type']&&row['check_in_type'] == 'take7' ? <LocalTaxi/> : <LocalPizzaRounded/>}</TableCell>
                <TableCell>{this.formatDateTime(row['created_at'])}</TableCell>
                {!restaurantView && <TableCell>{row['restaurant']}</TableCell> }
                {!customerView && <TableCell>{row['customer']}</TableCell> }
                <TableCell>{row['discount_name']}</TableCell>
                <TableCell>{formatMoneyFromApi(row['sum_gross_without_discount'] + row['tips_value'])}</TableCell>
                <TableCell>{row['tax']}</TableCell>
                {!customerView && <TableCell>{formatMoneyFromApi(row['sum_net_without_discount'] + row['tips_value'])}</TableCell>}
                <TableCell>{''}</TableCell>
                <TableCell>{formatMoneyFromApi(row['sum_gross_discount'])}</TableCell>
                {!customerView && <TableCell>{formatMoneyFromApi(row['sum_net_discount'])}</TableCell>}
                <TableCell>{formatMoneyFromApi((row['sum_gross_total']?row['sum_gross_total']:0))}</TableCell>
                {!customerView && <TableCell>{formatMoneyFromApi(row['sum_gross_with_discount'] - row['sum_net_with_discount'])}</TableCell>}
                {!customerView && <TableCell>{formatMoneyFromApi(row['sum_net_with_discount'] + row['tips_value'])}</TableCell>}
            </TableRow>);

            row['open'] = false;


            return toReturn;
        });
        return (
            <TableBody>
                {tr}
            </TableBody>
        );
    }

    render() {
        return (
            <div className='table-wrapper' >
                <Grid xs={8} md={12} mdOffset={8}>
                    {this.props.filterComponent()}
                </Grid>
                <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                    {this.renderHead()}
                    {this.renderBody()}
                </Table>

                <Grid xs={12}>
                    {this.renderPageButtons()}
                </Grid>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    let { page, perPage, totalRecords, isFetching, sortDirection, sortField } = state.payment;
    let data = state.payment.data && state.payment.data.payments ? state.payment.data.payments : [];

    const fields = {
        "Datum/Uhrzeit": "restaurant",
        "Restaurant": "restaurant",
        "7user": "customer",
        "Status": "total_price",
        "Brutto": "total_price_taxes",
        "USt.%": "discount_price",
        "Netto": "created_at",
        "Rabatt%": "created_at",
        "Rabatt brutto": "created_at",
        "Rabatt netto": "created_at",
        "Kundenpreis": "created_at",
        "USt." : "created_at",
        "Netto ": "created_at"

    };

    const click = true;

    return {
        page,
        perPage,
        totalRecords,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        fields,
        filterComponent: () => {},
        click
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable,
        nextPage: () => { dispatch(nextPage())},
        previousPage: () => {dispatch(previousPage())},
        changePerPage: (value => { dispatch(changePerPage(value))}),
        sort: (field, value) => {
            dispatch(sort(field, value))},
        onClickRow: (rowData) => {},
        sortFields: ['created_at']
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PaymentsTable)
