import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import CustomersView from './CustomersView';
import {Dialog, IconButton, AppBar, Toolbar, Typography, Grid} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {loadCustomersRequest, setActiveCustomer} from "../../../actions/customerActions";
import EditCustomers from "./EditCustomers";
import {isDirty} from "redux-form";


class Customers extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.loadCustomers({extended: true});
    }

    onClose = () => {
        if(this.props.isFormDirty){
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Sie haben ungespeicherte Änderungen, wollen Sie die Seite trotzdem verlassen?')) {
                this.props.setActiveCustomer({activeCustomer: null});
            }
        } else {
            this.props.setActiveCustomer({activeCustomer: null});
        }
    };

    render() {
        const { classes, activeCustomer } = this.props;

        return (
            <Fragment>
                <CustomersView/>

                {activeCustomer != null &&
                    <Dialog fullScreen open={true} >
                        <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={() => {this.onClose()}} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    7user ({activeCustomer&&activeCustomer.lastname ? activeCustomer.lastname : 'Neu'})
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Grid container style={{justifyContent:'center', paddingTop: '80px'}}>
                            <Grid item xs={10}>
                                <EditCustomers/>
                            </Grid>
                        </Grid>
                    </Dialog>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        activeCustomer: state.customer.activeCustomer,
        isFormDirty: isDirty('customer-form')(state),
    }
}
const mapDispatchToProps = {
    setActiveCustomer: setActiveCustomer,
    loadCustomers: loadCustomersRequest
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Customers));