import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Paper, Grid, IconButton, GridList, GridListTile} from "@material-ui/core";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {getFileRequest} from "../../../actions/fileActions";
import {setPostPictures} from "../../../actions/postActions";
import getBaseUrl from "../../../utils/getBaseUrl";


class PostPictures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removedImage: false,
        }
    }

    deleteFromPost = (pic) => {
        const {postPictures} = this.props;
        const items = postPictures;

        items.forEach(item  =>{
            if(pic === item) items.splice( items.indexOf(item), 1 );
        });

        this.props.setPostPictures({pictures: items});
        this.setState({removedImage: !this.state.removedImage})
    };


    render() {
        const {classes, postPictures} = this.props;
        return (
            <Fragment>
                <Grid container spacing={10} item xs={12}>
                    <Grid item xs={12}>
                        {((postPictures && postPictures.length) ? postPictures:[]).map(pic => {
                            return (
                                <span className={classes.imgContainer}>
                                    <IconButton className={classes.imgBtn} onClick={() => this.deleteFromPost(pic)} > <HighlightOffIcon/></IconButton>
                                    <img src={getBaseUrl()+"/api/files/" + pic + "/file"} height="auto" width="200"/>
                                </span>
                            )})}
                    </Grid>
                </Grid>


            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        postPictures: state.post.activePostPictures
    }
}
const mapDispatchToProps = {
    getImages: getFileRequest,
    setPostPictures: setPostPictures
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(PostPictures));