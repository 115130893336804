import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";
import {
    loadCheckinsSuccess,
    patchCheckinSuccess,
    patchCheckinError,
} from "../actions/checkinActions";

export function* loadCheckinRequest (api) {
  try {
      const DataTableOptions = state => state.checkin;
      const TableOptions = yield select(DataTableOptions);
      const options = {};

      if(TableOptions.sortDirection) {
          options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
          options['order_id'] = TableOptions.sortField;
      }

      options['page'] = TableOptions.page;
      options['page_size'] = TableOptions.perPage;
      options['filter'] = TableOptions.filter;

      const result = yield call(api.loadCheckIns, options);
      if (result) {
          yield put(loadCheckinsSuccess({checkin: result.data.checkins, totalRecords: result.data.total_records}));
      } else {
          console.log("FAIL")
      }
  } catch (e) {
      console.log(e)
  }
}


export function* patchCheckinRequest (api, {data}) {
    try {
        const result = yield call(api.patchCheckin, data._id, {...data} );

        if (result && result.data && result.data.success) {
            yield put(patchCheckinSuccess({checkin: result.data.checkin}));
            yield put(addFlashMessage({message:'Status wurde geändert', style:'success'}))
        } else {
            yield put(patchCheckinError());
            yield put(addFlashMessage({message:'Fehler beim Löschen', style:'error'}))
        }
    } catch (e) {
        console.log(e)
    }
}

