import React, {Fragment, useEffect } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { withRouter } from "react-router-dom";
import {createAccountRequest, getAccountRequest} from "../../../actions/stripeActions";
import {Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem} from '@material-ui/core'
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {formatMoneyFromApi} from "../../../utils/helper";
import {isAllowed} from "../../../utils/permissionHelper";

function RestaurantsStripeView(props) {

    useEffect(() => {
        if(props && props.restaurant && props.restaurant._id){
            props.getAccount({restaurant_id: props.restaurant._id})
        }
    }, [props, props.restaurant]);

    useEffect(() => {
        if(props.link){
            console.log('redirecting to...'+props.link);
            window.location = props.link;
        }
    }, [props.link]);

    const actionDisabled = () => {
        return props && props.restaurant && !props.restaurant.iban;
    };

    const getValidStateIcon = (status) => {
        if(status){
            return <CheckCircle style={{color: '#4CD964', marginRight: '5px', marginTop: '5px', fontSize: '18px'}}/>;
        } else {
            return <Error style={{color: '#FF3B30', marginRight: '5px', marginTop: '5px', fontSize: '18px'}}/>;
        }
    }

    return <Fragment>
                <Paper style={{padding: '20px'}}>
                    <h3>Mit Stripe verbinden</h3>
                    <p>Damit Kundenzahlungen und Abrechnungen mit diesem Restaurant durchgeführt werden können, muss ein Stripe Konto für dieses Restaurant erstellt werden.</p>
                    <p style={{fontSize: '12px'}}>Info: Sie werden durch Klick auf den Button weitergeleitet und bei erfolgreicher Eingabe wieder zurück zu c7 geleitet.</p>
                    { isAllowed(props.user, 'restaurants', 'update') && (
                        (props.restaurant && props.restaurant.stripe_account_id) ?
                            <Button disabled={actionDisabled()} onClick={() => {props.createAccount({restaurant_id: props.restaurant._id, return_link: true})}} variant="contained" color="primary" >Stripe Konto verifizieren</Button> :
                            <Button disabled={actionDisabled()} onClick={() => {props.createAccount({restaurant_id: props.restaurant._id, return_link: true})}} variant="contained" color="primary" >Stripe Konto erstellen</Button>
                    )
                    }
                    {
                        actionDisabled() && <p style={{color: 'red', fontSize: '12px'}}>Fehler: IBAN muss hinterlegt sein.</p>
                    }


                </Paper>

            { props.account &&
                <Paper style={{padding: '20px', marginTop: '20px'}}>
                    <h3>Stripe Kontoinformation</h3>
                    <h5>Status</h5>
                    <div>Zahlungen aktiviert {getValidStateIcon(props.account.charges_enabled)}</div>
                    <div>Auszahlungen aktiviert {getValidStateIcon(props.account.payouts_enabled)} Bankdaten müssen dafür auf Stripe hinterlegt werden</div>

                    <h5>Kontostand</h5>
                    { props.account.balance && <Fragment>
                           <div>Verfügbar: {formatMoneyFromApi(props.account.balance.available[0].amount)}</div>
                           <div>Wartend: {formatMoneyFromApi(props.account.balance.pending[0].amount)}</div>
                        </Fragment>
                    }
                </Paper>}

            </Fragment>;
}

function mapStateToProps(state) {
    return {
        link:  state.stripe.link,
        user: state.auth.user,
        account: state.stripe.account,
    }
}
const mapDispatchToProps = {
    createAccount: createAccountRequest,
    getAccount: getAccountRequest,
};


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(style)
)(RestaurantsStripeView)