import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";

import {
    loadOpeningSuccess, saveOpeningSuccess
} from "../actions/openingActions";

export function* loadOpeningHoursRequest (api,{id}) {
    try {
        let result = false;

        result = yield call(api.loadOpening, id);

        if (result) {
            yield put(loadOpeningSuccess({openingHours: result.data.openingHours}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveOpeningHoursRequest (api, {data}) {
    try {
        let result = false;

        if(data.id && data.id.length) {
            result = yield call(api.updateOpening,data.id, data.restaurant_id, data.data);
        } else {
            result = yield call(api.createOpening,data.restaurant_id, data.data);
        }

        if (result) {
            yield put(saveOpeningSuccess())
            yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}




