const PREFIX = "filters/";

export const LOAD_FILTERS_REQUEST = PREFIX + "LOAD_FILTERS_REQUEST";
export const LOAD_FILTERS_SUCCESS = PREFIX + "LOAD_FILTERS_SUCCESS";
export const LOAD_FILTERS_ERROR = PREFIX + "LOAD_FILTERS_ERROR";

export const LOAD_EXTENDED_FILTERS_REQUEST = PREFIX + "LOAD_EXTENDED_FILTERS_REQUEST";
export const LOAD_EXTENDED_FILTERS_SUCCESS = PREFIX + "LOAD_EXTENDED_FILTERS_SUCCESS";
export const LOAD_EXTENDED_FILTERS_ERROR = PREFIX + "LOAD_EXTENDED_FILTERS_ERROR";

export const LOAD_FILTERBYID_REQUEST = PREFIX + "LOAD_FILTERBYID_REQUEST";
export const LOAD_FILTERBYID_SUCCESS = PREFIX + "LOAD_FILTERBYID_SUCCESS";
export const LOAD_FILTERBYID_ERROR = PREFIX + "LOAD_FILTERBYID_ERROR";

export const PATCH_FILTER_REQUEST = PREFIX + "PATCH_FILTER_REQUEST";
export const PATCH_FILTER_SUCCESS = PREFIX + "PATCH_FILTER_SUCCESS";
export const PATCH_FILTER_ERROR = PREFIX + "PATCH_FILTER_ERROR";

export const SAVE_FILTERS_REQUEST = PREFIX + "SAVE_FILTERS_REQUEST";
export const SAVE_FILTERS_SUCCESS = PREFIX + "SAVE_FILTERS_SUCCESS";
export const SAVE_FILTERS_ERROR = PREFIX + "SAVE_FILTERS_ERROR";

export const FILTER_INITIALIZE_DATATABLE = PREFIX + "FILTER_INITIALIZE_DATATABLE";
export const FILTER_NEXT_PAGE = PREFIX + "FILTER_NEXT_PAGE";
export const FILTER_PREVIOUS_PAGE = PREFIX + "FILTER_PREVIOUS_PAGE";
export const FILTER_CHANGE_PER_PAGE = PREFIX + "FILTER_CHANGE_PER_PAGE";
export const FILTER_SORT_TABLE = PREFIX + "FILTER_SORT_TABLE";
export const FILTER_FILTER_TABLE = PREFIX + "FILTER_FILTER_TABLE";

export const SET_ACTIVE_FILTER = PREFIX + "SET_ACTIVE_FILTER";

/* initial state */
const initialState = {
    data: null,
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'created_at',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    customers: [],
    activeFilter: null,
    saved: null,
    dataExtended: null,
};

/* Reducer */
const filtersReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_FILTERBYID_REQUEST:
            return {
                ...state,
            }
        case LOAD_FILTERBYID_SUCCESS:
            return {
                ...state,
                customers: action.customers
            }
        case LOAD_FILTERBYID_ERROR:
            return {
                ...state,
                customers: null
            }
        case LOAD_EXTENDED_FILTERS_REQUEST:
            return {
                ...state,
            };
        case LOAD_EXTENDED_FILTERS_SUCCESS:
            return {
                ...state,
                dataExtended: action.filters,
                totalRecords: action.totalRecords,
            };
        case LOAD_EXTENDED_FILTERS_ERROR:
            return {
                ...state,
                dataExtended: null,
                totalRecords: 0
            };
        case LOAD_FILTERS_REQUEST:
            return {
                ...state,
            };
        case LOAD_FILTERS_SUCCESS:
            return {
                ...state,
                data: action.filters,
                totalRecords: action.totalRecords,
            };
        case LOAD_FILTERS_ERROR:
            return {
                ...state,
                data: null,
                totalRecords: 0
            };
        case SAVE_FILTERS_REQUEST:
            return {
                ...state,
                saved: null,
                customers: null
            };
        case SAVE_FILTERS_SUCCESS:
            return {
                ...state,
                saved: action.saved,
                customers: action.customers
            };
        case SAVE_FILTERS_ERROR:
            return {
                ...state,
            };
        case FILTER_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case FILTER_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case FILTER_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case FILTER_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case FILTER_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case FILTER_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        case SET_ACTIVE_FILTER:
            return {
                ...state,
                activeFilter: action.filter
            };
        default:
            return state;
    }
};
export default filtersReducer;