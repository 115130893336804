import jwtDecode from 'jwt-decode';
import { refreshToken } from './actions/authActions';
import setAuthorizationToken from "./utils/setAuthorizationToken";
import getBaseUrl from './utils/getBaseUrl';

var axios = require('axios');

var axiosInstance = axios.create({
baseURL: getBaseUrl() + 'api/',
});

axiosInstance.interceptors.request.use((config) => {
    if (config.responseType != "arraybuffer") {
        config.headers.common['Pragma'] = 'no-cache';
    }
    let originalRequest = config;
    if (originalRequest.headers.common['Authorization']) {
        let token = originalRequest.headers.common['Authorization'].split(' ')[1];
        let decoded = jwtDecode(token);
        let current_time = new Date().getTime() / 1000;

        if (current_time > decoded.exp && !originalRequest.url.includes('/auth') && !originalRequest.url.includes('/companies') && !originalRequest.url.includes('/businessfields')) {
            return axiosInstance.post('/auth/refresh', { token: token }).then(res => {
                const token = res.data.token;
                localStorage.setItem('jwtToken', token);
                setAuthorizationToken(token);

                originalRequest.headers.common['Authorization'] = 'Bearer ' + token;
                return Promise.resolve(originalRequest);
            });
        }
    }
        return config;
    }, (err) => {
        return Promise.reject(err);
});

export default axiosInstance;