import {
    LOAD_WAITER_REQUEST,
    LOAD_WAITER_SUCCESS,
    DELETE_WAITER_REQUEST,
    DELETE_WAITER_SUCCESS,
    SAVE_WAITER_REQUEST,
    SAVE_WAITER_SUCCESS,
    WAITER_INITIALIZE_DATATABLE,
    WAITER_NEXT_PAGE,
    WAITER_PREVIOUS_PAGE,
    WAITER_CHANGE_PER_PAGE,
    WAITER_SORT_TABLE,
    WAITER_FILTER_TABLE,
    SET_NEW_WAITER,
    UNSET_NEW_WAITER,
    SET_ACTIVE_WAITER,
    UNSET_ACTIVE_WAITER,
    SEND_WAITER_WELCOME_REQUEST,
    SEND_WAITER_WELCOME_SUCCESS,
    GET_WAITER_SUCCESS,
    GET_WAITER_REQUEST
} from "../reducers/waiterReducer";

export const loadWaitersRequest = (data) => ({
    type: LOAD_WAITER_REQUEST,
    data
});

export const loadWaitersSuccess = ({waiters, totalRecords}) => ({
    type: LOAD_WAITER_SUCCESS,
    waiters,
    totalRecords,
});

export const saveWaiterRequest = ({data}) => ({
    type: SAVE_WAITER_REQUEST,
    data
});

export const saveWaiterSuccess = ({waiter}) => ({
    type: SAVE_WAITER_SUCCESS,
    waiter
});

export const deleteWaiterRequest = ({data}) => ({
    type: DELETE_WAITER_REQUEST,
    data
});

export const deleteWaiterSuccess = () => ({
    type: DELETE_WAITER_SUCCESS,
});


export const setNewWaiter = () => ({
    type: SET_NEW_WAITER,

});

export const unsetNewWaiter = () => ({
    type: UNSET_NEW_WAITER
});

export const setActiveWaiter = (activeWaiter) => ({
    type: SET_ACTIVE_WAITER,
    activeWaiter

});

export const unsetActiveWaiter = () => ({
    type: UNSET_ACTIVE_WAITER
});

export const sendWaiterWelcomeRequest = ({data}) => ({
    type: SEND_WAITER_WELCOME_REQUEST,
    data
});

export const sendWaiterWelcomeSuccess = () => ({
    type: SEND_WAITER_WELCOME_SUCCESS,
});

export const getWaiterRequest = () => ({
    type: GET_WAITER_REQUEST,
});

export const getWaiterSuccess = ({waiter}) => ({
    type: GET_WAITER_SUCCESS,
    waiter
});

export const initializeDataTable = ({options}) => ({
    type: WAITER_INITIALIZE_DATATABLE,
    ...options,
});

export const nextPage = () => ({
    type: WAITER_NEXT_PAGE,
});

export const previousPage = () => ({
    type: WAITER_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: WAITER_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: WAITER_SORT_TABLE,
    field,
    value
});

export const filterTable = ({values}) => ({
    type: WAITER_FILTER_TABLE,
    values
});



