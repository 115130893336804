import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem, Tabs, Tab} from '@material-ui/core'
import {
    deleteUserRequest, patchUserRequest, saveUserRequest, unsetActiveUser,
    unsetNewUser
} from "../../../actions/userActions";
import SelectGroup from '../../../components/SelectGroup';
import SwitchGroup from "../../../components/SwitchGroup";
import {updateSettingsRequest} from "../../../actions/settingsActions";
import {a11yProps, round, TabPanel} from "../../../utils/helper";
import DatePickerGroup from "../../../components/DatePickerGroup";

class SettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {  value: 0 }
    }

    required = value => value ? undefined : 'Feld erforderlich';

    number = value => {
        let _value = Number(value);
        return Number.isInteger(_value) && Number(_value) >= 0 && Number(_value) <= 1000 ? undefined : 'Eingabe muss eine Zahl sein.';
    };

    maxScanAfterCheckin = value => value < 1 || value > 1000 ? 'min: 1, max: 1000' : undefined;
    maxInvoices = value => value < 1 || value > 1000 ? 'min: 1, max: 1000' : undefined;
    maxInvoicesPer = value => value < 1 || value > 1000 ? 'min: 1, max: 1000' : undefined;

    validateDiscount = value => {
        let _value = Number(value);
        return Number.isInteger(_value) && Number(_value) >= 0 && Number(_value) <= 100 ? undefined : 'Ganzzahl zwischen 0 und 100';
    };

    validateTurnover = value => {
        let _value = Number(value);
        return Number.isInteger(_value) && Number(_value) >= 0 && Number(_value) <= 10000 ? undefined : 'Ganzzahl zwischen 0 und 10000';
    };

    typeOptions = () => {
        return [
            <MenuItem value='absolute'>Wertgutschein</MenuItem>,
            <MenuItem value='percentage'>Rabattgutschein</MenuItem>,
        ];
    };

    actionOptions = () => {
        return [
            <MenuItem value='registration'>Registrierung</MenuItem>,
            <MenuItem value='hello'>Empfehlung</MenuItem>
        ];
    };

    handleAddVoucherSetting = (fields) => {
        fields.push({
            basic_type: 'absolute',
            value: 10,
            reason: '',
            action: 'registration',
            max_count: 0
        })
    };

    renderRksvSettings = () => {
        return <Grid xs={12}>
            {/*<Grid md={12} xs={12}>
                <Field
                    name="max_invoices"
                    label="Max Rechnungen"
                    component={TextFieldGroup}
                    margin="dense"
                    placeholder={"Bitte ausfüllen..."}
                    type="number"
                    validate={[this.required, this.maxInvoices]}
                />
            </Grid>
            <Grid md={12} xs={12}>
                <Field
                    name="max_invoices_per_day"
                    label="pro X Tage"
                    component={TextFieldGroup}
                    margin="dense"
                    placeholder={"Bitte ausfüllen..."}
                    type="number"
                    validate={[this.required, this.maxInvoicesPer]}
                />
            </Grid>*/}
            <Grid md={12} xs={12}>
                <Field
                    name="max_scan_after_checkin"
                    label="Scan max. X Minuten nach Rechnungsdatum/-Uhrzeit am Rechnungsbeleg“"
                    component={TextFieldGroup}
                    margin="dense"
                    type="number"
                    placeholder={"Bitte ausfüllen..."}
                    validate={[this.required, this.maxScanAfterCheckin]}
                />
            </Grid>
        </Grid>
    };

    renderClub7Settings = ({ fields, meta: { error, submitFailed } }) => {
        return(
            <div>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        {fields.map((entry, index) => (
                            <div key={index}>
                                <Grid container>
                                    <Grid xs={2}>
                                        <Field
                                            label={'Name'}
                                            name={`${entry}.name`}
                                            component={TextFieldGroup}
                                            fullWidth={true}
                                            validate={[ this.required ]}
                                        />
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={2}>
                                        <Field
                                            disabled={false}
                                            label={'Rabatt (%)'}
                                            name={`${entry}.value`}
                                            component={TextFieldGroup}
                                            type='number'
                                            fullWidth={true}
                                            validate={[ this.required, this.validateDiscount ]}
                                        />
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={3}>
                                        <Field
                                            disabled={false}
                                            label={'Mindestumsatz (€)'}
                                            name={`${entry}.turnover`}
                                            component={TextFieldGroup}
                                            type='number'
                                            fullWidth={true}
                                            validate={[ this.required, this.validateTurnover ]}
                                        />
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={2}>
                                        <Field
                                            disabled={false}
                                            label={'Default'}
                                            name={`${entry}.default`}
                                            component={SwitchGroup}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </div>
        );
    };

    renderVoucherSettings = ({ fields, meta: { error, submitFailed } }) => {
        const {classes} = this.props;
        return(
            <div>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        {fields.map((entry, index) => (
                            <div key={index}>
                                <Grid container className={classes.fragmentContainer} style={{position: 'relative', marginTop: '10px'}}>
                                    <Button style={{top: '10px', right: '10px', position: 'absolute'}} variant={'outlined'} color={'error'} onClick={() => fields.remove(index)}>
                                        Entfernen
                                    </Button>
                                    <Grid xs={5}>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.basic_type`}
                                                label="Typ"
                                                component={SelectGroup}
                                                margin="dense"
                                                fullWidth
                                                validate={[ this.required ]}
                                            >
                                                {this.typeOptions()}
                                            </Field>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.value`}
                                                label={"Wert (€ / %)"}
                                                component={TextFieldGroup}
                                                margin="dense"
                                                type="number"
                                                min={1}
                                                max={1000}
                                                placeholder={"Bitte ausfüllen..."}
                                                validate={[ this.required, this.number ]}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.reason`}
                                                label="Grund"
                                                component={TextFieldGroup}
                                                margin="dense"
                                                placeholder={"Bitte ausfüllen..."}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={6}>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.action`}
                                                label="Aktion"
                                                component={SelectGroup}
                                                margin="dense"
                                                fullWidth
                                                validate={[ this.required ]}
                                            >
                                                {this.actionOptions()}
                                            </Field>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.max_count`}
                                                label={"max. Anzahl an Benutzern (wenn 0 existiert kein Limit)"}
                                                component={TextFieldGroup}
                                                margin="dense"
                                                type="number"
                                                min={1}
                                                max={1000}
                                                placeholder={"Bitte ausfüllen..."}
                                                validate={[this.number ]}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.time_from`}
                                                label={"Im Zeitraum von (wenn Leer kein Limit)"}
                                                component={DatePickerGroup}
                                                margin="dense"
                                                style={{width: '100%'}}
                                                containerStyle={{marginTop: '5px'}}
                                                validate={[ ]}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field
                                                name={`${entry}.time_to`}
                                                label={"Im Zeitraum bis (wenn Leer kein Limit)"}
                                                component={DatePickerGroup}
                                                margin="dense"
                                                style={{width: '100%'}}
                                                containerStyle={{marginTop: '5px'}}
                                                validate={[ ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant={'outlined'} color={'primary'} onClick={() => {this.handleAddVoucherSetting(fields)}}>
                            Gutschein-Regel hinzufügen
                        </Button>
                    </Grid>

                </Grid>
            </div>
        );
    };

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };


    submitAll = (values) => {


        const voucher = (values?values.voucher:[]).map(v => {
            let x = {...v};
            x.value = x.value * 100;
            return x;
        });


        this.props.updateSettings({
            club7: values.club7,
            voucher: voucher,
            club7settings: values.club7settings,
            rksv: {
                max_invoices: values.max_invoices,
                max_invoices_per_day: values.max_invoices_per_day,
                max_scan_after_checkin: values.max_scan_after_checkin,
            }
        });
    };

    getDiscounts = () => {
        let result = [<MenuItem value=''>Kein</MenuItem>];
        (this.props.settings && this.props.settings.club7?this.props.settings.club7:[]).forEach(d => {
            result.push(<MenuItem value={d.value}>{d.name} ({d.value}%)</MenuItem>);
        });

        return result;
    };


    render() {
        const { handleSubmit, classes, settings } = this.props;


        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '50px', padding: '30px'}}>
                <Grid xs={2}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.value}
                        onChange={this.handleChange}
                        className={classes.tabs}
                        indicatorColor="primary"
                    >
                        <Tab style={{fontSize: '1rem'}} label="Rabatte" {...a11yProps(0)} />
                        <Tab style={{fontSize: '1rem'}} label="Gutscheine" {...a11yProps(1)} />
                        <Tab style={{fontSize: '1rem'}} label="RKSV" {...a11yProps(2)} />
                    </Tabs>
                </Grid>
                <Grid xs={10}>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={0}>
                        <Grid item xs={12}>
                            <h3>Status</h3>
                            <FieldArray name={`club7`} component={this.renderClub7Settings} />

                            <h3>Default-Einstellungen überschreiben</h3>
                            <Grid>
                                <Grid xs={12}>
                                    <Field
                                        name="club7settings.discount"
                                        label="Rabatt"
                                        component={SelectGroup}
                                        margin="dense"
                                        fullWidth
                                    >
                                        {this.getDiscounts()}
                                    </Field>
                                </Grid>
                                <Grid xs={12}>
                                    <Field
                                        name={`club7settings.max_count`}
                                        label={"max. Anzahl an Benutzern (wenn 0 existiert kein Limit)"}
                                        component={TextFieldGroup}
                                        margin="dense"
                                        type="number"
                                        min={1}
                                        max={1000}
                                        placeholder={"Bitte ausfüllen..."}
                                        validate={[this.number ]}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Field
                                        name={`club7settings.time_from`}
                                        label={"Im Zeitraum von (wenn Leer kein Limit)"}
                                        component={DatePickerGroup}
                                        margin="dense"
                                        style={{width: '100%'}}
                                        containerStyle={{marginTop: '5px'}}
                                        validate={[ ]}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Field
                                        name={`club7settings.time_to`}
                                        label={"Im Zeitraum bis (wenn Leer kein Limit)"}
                                        component={DatePickerGroup}
                                        margin="dense"
                                        style={{width: '100%'}}
                                        containerStyle={{marginTop: '5px'}}
                                        validate={[ ]}
                                    />
                                </Grid>
                            </Grid>


                            <Button disabled={(settings && settings.service_mode == false)} style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)} variant={'outlined'} color="primary">
                                Einstellungen speichern
                            </Button>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={1}>
                        <Grid item xs={12}>
                            <FieldArray name={`voucher`} component={this.renderVoucherSettings} />
                            <Button disabled={(settings && settings.service_mode == false)} style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)} variant={'outlined'} color="primary">
                                Einstellungen speichern
                            </Button>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={2}>
                        <Grid item xs={12}>
                            {this.renderRksvSettings()}
                            <Button disabled={(settings && settings.service_mode == false)} style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)} variant={'outlined'} color="primary">
                                Einstellungen speichern
                            </Button>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {

    const settings = state.settings.data;
    const voucher = (settings?settings.voucher:[]).map(v => {
        let x = {...v};
        x.value = round((v.value / 100), 0);
        return x;
    });

    return{
        settings: settings,
        initialValues:{
            club7: settings?settings.club7:[],
            club7settings: settings?settings.club7settings:{},
            voucher: voucher,
            max_invoices: (settings && settings.rksv) ? settings.rksv.max_invoices : 0,
            max_invoices_per_day: (settings && settings.rksv) ? settings.rksv.max_invoices_per_day : 0,
            max_scan_after_checkin: (settings && settings.rksv) ? settings.rksv.max_scan_after_checkin : 0
        }
    }
}

const mapDispatchToProps = {
    updateSettings: updateSettingsRequest,
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'settings-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(SettingsForm)