import React from "react";
import {MenuItem} from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../SelectGroup";

const discountOptions = (settings) => [<MenuItem value={null}>Alle</MenuItem>].concat(((settings&&settings.club7?settings.club7:[])).map(d => {
    return <MenuItem value={d.value}>{d.name} ({d.value}%)</MenuItem>;
}));

const required = value => value ? null : 'Feld erforderlich';

const DiscountSelect = (props) => {
    return <Field
        name={props.fieldName}
        label="Rabatt"
        disabled={!!props.disabled}
        component={SelectGroup}
        margin="dense"
        fullWidth
        validate={props.required && [ required ]}
    >
        {discountOptions(props.settings)}
    </Field>;
};

export default DiscountSelect;