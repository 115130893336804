import React from 'react';
import { connect } from 'react-redux';
import { Snackbar, SnackbarContent, Icon } from '@material-ui/core';
import { amber, green, red } from '@material-ui/core/colors';
import { deleteFlashMessage } from '../../actions/flashMessageActions'
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


const FlashMessage = ({ id, style, text, timeout, deleteFlashMessage }) => {
    if (timeout) {
       setTimeout(() => {
           deleteFlashMessage(id);
       }, timeout == "fast" ? 5000 : (timeout == "medium" ? 9000 : 15000));
    }

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
    };

    const useStyles1 = makeStyles(theme => ({
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[700],
        },
        warning: {
            backgroundColor: amber[800],
        },
        icon: {
            fontSize: 25,
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing(1),
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        },
        margin: {
            margin: theme.spacing(1),
        },
    }));

    const classes = useStyles1();
    const Icon = variantIcon[style];
    const vertical = "bottom";
    const horizontal = "left";

    return (
        <Snackbar
            anchorOrigin={{
                vertical,
                horizontal
            }}
            open= {true}
        >
            <SnackbarContent
                className={classes[style]}
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {text}
                     </span>
                }
            />
        </Snackbar>
    )
}

export default connect(null, { deleteFlashMessage })(FlashMessage)