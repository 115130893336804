import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";
import {
    loadUsersSuccess, patchUserError, patchUserSuccess,
    saveUserSuccess
} from "../actions/userActions";

export function* loadUsersRequest (api) {
    try {

        const DataTableOptions = state => state.user;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        const result = yield call(api.loadUsers, options);

        if (result) {
            yield put(loadUsersSuccess({users: result.data.users, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveUserRequest (api, {data}) {
    try {
        let result = false;

        if(data._id && data._id.length){
            result = yield call(api.updateUser, data._id, data);
        }else{
            result = yield call(api.createUser, data);
        }

        if (result) {
            yield put(saveUserSuccess({user: result.data.user}));
            yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
        } else {
            yield put(addFlashMessage({message:'Fehler beim Speichern', style:'error'}))
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}

export function* patchUserRequest (api, {data}) {
    try {
        const result = yield call(api.patchUser, data._id, {...data} );

        if (result && result.data && result.data.success) {
            yield put(patchUserSuccess({user: result.data.user}));
            yield put(addFlashMessage({message:'Status wurde geändert', style:'success'}))
        } else {
            yield put(patchUserError());
            yield put(addFlashMessage({message:'Fehler beim Löschen', style:'error'}))
        }
    } catch (e) {
        console.log(e)
    }
}








