import {
    LOAD_CUSTOMERS_REQUEST,
    LOAD_CUSTOMERS_SUCCESS
} from "../reducers/customerReducer";
import {
    LOAD_SETTINGS_ERROR,
    LOAD_SETTINGS_REQUEST,
    LOAD_SETTINGS_SUCCESS,
    LOAD_STRIPE_SESSION_ERROR,
    LOAD_STRIPE_SESSION_REQUEST,
    LOAD_STRIPE_SESSION_SUCCESS,
    STRIPE_PRICE_ERROR,
    STRIPE_PRICE_REQUEST,
    STRIPE_PRICE_SUCCESS,
    UPDATE_SETTINGS_ERROR,
    UPDATE_SETTINGS_REQUEST,
    UPDATE_SETTINGS_SUCCESS, UPDATE_STRIPE_SESSION_ERROR, UPDATE_STRIPE_SESSION_REQUEST, UPDATE_STRIPE_SESSION_SUCCESS
} from "../reducers/settingsReducer";

export const loadSettingsRequest = (values) => ({
    type: LOAD_SETTINGS_REQUEST,
    ...values
});

export const loadSettingsSuccess = (values) => ({
    type: LOAD_SETTINGS_SUCCESS,
    ...values,
});

export const loadSettingsError = (values) => ({
    type: LOAD_SETTINGS_ERROR,
    ...values,
});

export const updateSettingsRequest = (values) => ({
    type: UPDATE_SETTINGS_REQUEST,
    ...values,
});

export const updateSettingsSuccess = (values) => ({
    type: UPDATE_SETTINGS_SUCCESS,
    ...values,
});

export const updateSettingsError = (values) => ({
    type: UPDATE_SETTINGS_ERROR,
    ...values,
});

export const stripePriceRequest = (values) => ({
    type: STRIPE_PRICE_REQUEST,
    ...values
});

export const stripePriceSuccess = (values) => ({
    type: STRIPE_PRICE_SUCCESS,
    ...values,
});

export const stripePriceError = (values) => ({
    type: STRIPE_PRICE_ERROR,
    ...values,
});

export const loadStripeSessionsRequest = (values) => ({
    type: LOAD_STRIPE_SESSION_REQUEST,
    ...values
});

export const loadStripeSessionsSuccess = (values) => ({
    type: LOAD_STRIPE_SESSION_SUCCESS,
    ...values,
});

export const loadStripeSessionsError = (values) => ({
    type: LOAD_STRIPE_SESSION_ERROR,
    ...values,
});

export const updateStripeSessionRequest = (values) => ({
    type: UPDATE_STRIPE_SESSION_REQUEST,
    ...values
});

export const updateStripeSessionSuccess = (values) => ({
    type: UPDATE_STRIPE_SESSION_SUCCESS,
    ...values,
});

export const updateStripeSessionError = (values) => ({
    type: UPDATE_STRIPE_SESSION_ERROR,
    ...values,
});