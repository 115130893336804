import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Grid} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import MonthSelect from "../../../components/Filter/MonthSelect";
import YearSelect from "../../../components/Filter/YearSelect";
import {monthsFilterTable} from "../../../actions/accountingActions";
import MonthYearSelect from "../../../components/Filter/MonthYearSelect";
import TextFilter from "../../../components/Filter/TextFilter";
import DateFilter from "../../../components/Filter/DateFilter";
import DateRangeFilter from "../../../components/Filter/DateRangeFilter";

function CustomerDiscountFilter(){

    return (
        <Fragment>
            <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                <Grid item md={3} xs={12}>
                    <DateRangeFilter
                        fieldNameFrom={'valid_from'}
                        fieldNameTo={'valid_to'}
                        labelFrom={'Gütlig von'}
                        labelTo={'Gültig bis'}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextFilter
                        fieldName={'discount_name'}
                        label={'Status (Name)'}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        initialValues: {
            discount_name: '',
            valid_from: null,
            valid_to: null
        },
    }
}
const mapDispatchToProps = {};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'customer-discounts-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {
            props.setFilter({
                values: {
                    discount_name: values.discount_name,
                    valid_from: values.valid_from,
                    valid_to: values.valid_to
                }
            })
        },
    }),
    (withStyles(style)),
)(CustomerDiscountFilter)