import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";
import {createAccountError, createAccountSuccess, getAccountError, getAccountSuccess} from "../actions/stripeActions";

export function* createAccount (api, params) {
    let result = yield call(api.createStripeAccount, {...params} );
    if (result && result.data && result.data.success) {
        yield put(createAccountSuccess({link: result.data.link}));
    } else {
        yield put(createAccountError());
        yield put(addFlashMessage({message:'Fehler, bitte kontaktieren Sie den Support', style: 'error'}))
    }
}

export function* getAccount (api, params) {
    let result = yield call(api.getStripeAccount, {...params} );
    if (result && result.data && result.data.success) {
        yield put(getAccountSuccess({account: result.data.account}));
    } else {
        yield put(getAccountError());
        //yield put(addFlashMessage({message:'Fehler, bitte kontaktieren Sie den Support', style: 'error'}))
    }
}