
/* Action types */
const PREFIX = "auth/";
export const LOGIN_REQUEST = PREFIX + "LOGIN_REQUEST";
export const LOGIN_SUCCESS = PREFIX + "LOGIN_SUCCESS";
export const LOGIN_FAILED = PREFIX + "LOGIN_FAILED";
export const LOGOUT = PREFIX + "LOGOUT";

//TODO set user on action SET_CURRENT_USER
export const SET_CURRENT_USER = PREFIX + "SET_CURRENT_USER";
export const LOAD_CURRENT_USER_REQUEST = PREFIX + "LOAD_CURRENT_USER_REQUEST";
export const LOAD_CURRENT_USER_SUCCESS = PREFIX + "LOAD_CURRENT_USER_SUCCESS";
/* initial state */
const initialState = {
    user: {},
    isAuthenticated: false,
    isFetching: false,
    error: false,
};

/* Reducer */
const authReducer = (state = initialState, action) => {
    const { type, ...payload } = action;

    switch(type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isFetching: false,
                error: false
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                isFetching: false,
                error: action.error
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                isFetching: false,
                error: false
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.user
            };

        case LOAD_CURRENT_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                user: action.user,
            };
        default:
            return state;
    }
};

/* Selectors */
export const isAuthenticated = state => state.auth.isAuthenticated;
export const isFetching = state => state.auth.isFetching;
export const error = state => state.auth.error;

export const authSelectors = {
    isAuthenticated,
    isFetching,
    error,
};

export default authReducer;