import {
    LOAD_RESTAURANT_REQUEST,
    LOAD_RESTAURANT_SUCCESS,
    SAVE_RESTAURANT_REQUEST,
    SAVE_RESTAURANT_SUCCESS,
    DELETE_RESTAURANT_REQUEST,
    DELETE_RESTAURANT_SUCCESS,
    RESTAURANT_INITIALIZE_DATATABLE,
    RESTAURANT_NEXT_PAGE,
    RESTAURANT_PREVIOUS_PAGE,
    RESTAURANT_CHANGE_PER_PAGE,
    RESTAURANT_SORT_TABLE,
    RESTAURANT_FILTER_TABLE,
    SET_ACTIVE_RESTAURANT,
    UNSET_ACTIVE_RESTAURANT,
    SET_DELETE_MODAL,
    UNSET_DELETE_MODAL,
    SET_RESTAURANT,
    SET_PROFILPICTURE_REQUEST,
    SET_PROFILPICTURE_SUCCESS,
    SAVE_RESTAURANT_ERROR,
    SAVE_RESTAURANT_RKSV_ERROR,
    SAVE_RESTAURANT_RKSV_SUCCESS,
    SAVE_RESTAURANT_RKSV_REQUEST,
    SAVE_RESTAURANT_USER_SUCCESS,
    SAVE_RESTAURANT_USER_ERROR,
    SAVE_RESTAURANT_USER_REQUEST,
    LOAD_RESTAURANT_USER_ERROR,
    LOAD_RESTAURANT_USER_SUCCESS,
    LOAD_RESTAURANT_USER_REQUEST,
} from "../reducers/restaurantReducer";

export const loadRestaurantRequest = () => ({
    type: LOAD_RESTAURANT_REQUEST,
});

export const loadRestaurantSuccess = ({restaurants, totalRecords}) => ({
    type: LOAD_RESTAURANT_SUCCESS,
    restaurants,
    totalRecords,
});

export const saveRestaurantRequest = ({data}) => ({
    type: SAVE_RESTAURANT_REQUEST,
    data
});

export const saveRestaurantSuccess = ({restaurant}) => ({
    type: SAVE_RESTAURANT_SUCCESS,
    restaurant
});

export const saveRestaurantError = (values) => ({
    type: SAVE_RESTAURANT_ERROR,
    ...values
});

export const saveRestaurantRksvRequest = (values) => ({
    type: SAVE_RESTAURANT_RKSV_REQUEST,
    ...values
});

export const saveRestaurantRksvSuccess = (values) => ({
    type: SAVE_RESTAURANT_RKSV_SUCCESS,
    ...values
});

export const saveRestaurantRksvError = (values) => ({
    type: SAVE_RESTAURANT_RKSV_ERROR,
    ...values
});

export const deleteRestaurantRequest = (id) => ({
    type: DELETE_RESTAURANT_REQUEST,
    id,
});

export const deleteRestaurantSuccess = () => ({
    type: DELETE_RESTAURANT_SUCCESS,
});

export const initializeDataTable = ({options}) => ({
    type: RESTAURANT_INITIALIZE_DATATABLE,
    ...options,
});

export const nextPage = () => ({
    type: RESTAURANT_NEXT_PAGE,
});

export const previousPage = () => ({
    type: RESTAURANT_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: RESTAURANT_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: RESTAURANT_SORT_TABLE,
    field,
    value
});

export const filterTable = ({values}) => ({
    type: RESTAURANT_FILTER_TABLE,
    values
});


export const setActiveRestaurant = (id) => ({
    type: SET_ACTIVE_RESTAURANT,
    id
});

export const setRestaurant = ({restaurant}) => ({
    type: SET_RESTAURANT,
    restaurant
});

export const unsetActiveRestaurant = () => ({
    type: UNSET_ACTIVE_RESTAURANT
});

export const setDeleteModal = () => ({
    type: SET_DELETE_MODAL,
});

export const unsetDeleteModal = () => ({
    type: UNSET_DELETE_MODAL
});

export const setProfilPictureRequest = ({restaurant_id, picture_id}) => ({
    type: SET_PROFILPICTURE_REQUEST,
    restaurant_id,
    picture_id
});

export const setProfilPictureSuccess = () => ({
    type: SET_PROFILPICTURE_SUCCESS
});

export const saveRestaurantUserRequest = (values) => ({
    type: SAVE_RESTAURANT_USER_REQUEST,
    ...values
});

export const saveRestaurantUserSuccess = (values) => ({
    type: SAVE_RESTAURANT_USER_SUCCESS,
    ...values
});

export const saveRestaurantUserError = (values) => ({
    type: SAVE_RESTAURANT_USER_ERROR,
    ...values
});

export const loadRestaurantUserRequest = (values) => ({
    type: LOAD_RESTAURANT_USER_REQUEST,
    ...values
});

export const loadRestaurantUserSuccess = (values) => ({
    type: LOAD_RESTAURANT_USER_SUCCESS,
    ...values
});

export const loadRestaurantUserError = (values) => ({
    type: LOAD_RESTAURANT_USER_ERROR,
    ...values
});
