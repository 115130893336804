import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Select, FormControl, InputLabel, Input, MenuItem, Checkbox, ListItemText, withStyles} from "@material-ui/core";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const MultipleSelect = ({ input, label, options, placeholder, children, variant, disabled, classes, fullWidth, margin, autoWidth, accessorValue = "value", accessorLabel = "label"}) => {

    let selectTheme = {};

    if(disabled === true){
        selectTheme = createTheme({
            overrides: {
                MuiFilledInput: {
                    root: {
                        backgroundColor: "transparent !important",
                        "&:hover": {
                            backgroundColor: "transparent !important"
                        }
                    }
                },
                MuiInputBase: {
                    root: {
                        "&:before": {
                            display: "none"
                        },
                    }
                }
            }
        })
    }
    
    return (
        <MuiThemeProvider theme={selectTheme}>
            <FormControl className={classes.formControl} fullWidth={fullWidth} hiddenLabel={true} margin={"dense"}>
                <InputLabel shrink={true}>{label}</InputLabel>
                <Select
                    {...input}
                    multiple
                    placeholder={placeholder}
                    input={<Input/>}
                    renderValue={selected => options.filter(option => selected.indexOf(option[accessorValue]) !== -1).map(item => item[accessorLabel]).join(", ")}
                    disabled={disabled}
                    style={{paddingTop: '5px', paddingLeft: '1px'}}
                    classes={{ disabled: classes.disabledInput}}
                >
                    {options.map(option => (
                        <MenuItem key={option[accessorValue]} value={option[accessorValue]}>
                            <Checkbox checked={input.value.indexOf(option[accessorValue]) > -1}/>
                            <ListItemText primary={option[accessorLabel]}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </MuiThemeProvider>
    );
}

const styles = theme => ({
    input: {
        backgroundColor: "#e8e8e8!important"
    },
    disabledInput: {
        backgroundColor: "#transparent!important",
        color: theme.palette.grey[900],
        "&::before": {
            borderColor: "transparent"
        }
    }
});

export default withStyles(styles)(MultipleSelect);