import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {TableCell, TableHead, TableRow, TableBody, Table} from "@material-ui/core";
import {formatDateTime} from "../../../utils/helper";
import CustomerDiscountFilter from "./CustomersDiscountFilter";
import moment from "moment";

class CustomersDiscountView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {}

    renderHead() {
        return (
            <TableHead>
                <TableRow>
                    <TableCell key={'1'} className={'col-md-2'}>{'Qualifikationszeitpunkt'}</TableCell>
                    <TableCell key={'1'} className={'col-md-3'}>{'Gültig von'}</TableCell>
                    <TableCell key={'2'} className={'col-md-3'}>{'Gültig bis'}</TableCell>
                    <TableCell key={'3'} className={'col-md-3'}>{'Status (Name)'}</TableCell>
                    <TableCell key={'4'} className={'col-md-1'}>{'Rabatt (%)'}</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderBody(data) {
        const tr = data.map((row) => {
            const toReturn = [];

            toReturn.push(<TableRow>
                <TableCell>{formatDateTime(row['created_at'])}</TableCell>
                <TableCell>{formatDateTime(row['date_from'])}</TableCell>
                <TableCell>{formatDateTime(row['date_to'])}</TableCell>
                <TableCell>{row['discount_name']}</TableCell>
                <TableCell>{row['discount_value']}%</TableCell>
            </TableRow>);

            return toReturn;
        });
        return (
            <TableBody>
                {tr}
            </TableBody>
        );
    }

    setFilter = (params) => {
        this.setState({
            filter: params?params.values:null
        });
    }

    render() {
        const { data } = this.props;
        const { filter } = this.state;
        let filtered = [...data];
        if(!!filter && !!filter.discount_name){
            filtered = data.filter(e => !!(new RegExp(filter.discount_name)).test(e['discount_name']));
        }
        if(!!filter && !!filter.valid_from){
            filtered = filtered.filter(e => moment(filter.valid_from).isSameOrBefore(moment(e['date_from'])));
        }

        if(!!filter && !!filter.valid_to){
            filtered = filtered.filter(e => moment(filter.valid_to).isSameOrAfter(moment(e['date_to'])));
        }

        return (
            <Fragment>
                <CustomerDiscountFilter setFilter={this.setFilter}/>
                <div className='table-wrapper' >
                    <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                        {this.renderHead()}
                        {this.renderBody(filtered)}
                    </Table>
                </div>
            </Fragment>

        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CustomersDiscountView)
