import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Grid, Paper, Dialog, IconButton, AppBar, Toolbar, Typography } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import PaymentsRestaurantsView from "./PaymentsRestaurantsView";
import {formatMoneyFromApi} from "../../../utils/helper";
import {loadRestaurantRequest} from "../../../actions/restaurantActions";
import {loadCustomersRequest, loadSimpleCustomersRequest} from "../../../actions/customerActions";
import PaymentFilter from './PaymentsFilter';
import {downloadPaymentsRequest} from "../../../sagas/paymentSagas";
import {downloadPaymentRequest} from "../../../actions/paymentActions";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

class Payments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount(){
        this.props.loadRestaurants();
        this.props.loadSimpleCustomers();
    }

    render() {
        const {classes, payments, showTotal = false, restaurant_id, customer_id, restaurantView = false, showCsvDownload = true, customerView = false} = this.props;

        return (
            <Fragment>
                { showCsvDownload &&
                    <IconButton onClick={() => {this.props.downloadPayments()}} style={{float: 'right'}}>
                        <CloudDownloadIcon fontSize={'large'}/> <span style={{fontSize: '18px', marginLeft: '5px'}}>CSV Download</span>
                    </IconButton>
                }

                <PaymentFilter restaurant_id={restaurant_id} customer_id={customer_id}/>
                <Grid container spacing={5}>
                    <Grid item md={12}>
                        <h2>Umsatzliste</h2>
                        <PaymentsRestaurantsView restaurantView={restaurantView} customerView={customerView}/>
                    </Grid>
                    {showTotal &&
                        <Grid item md={3}>
                            <Paper style={{marginTop: 50, padding: 20, flexDirection: 'column'}}>
                                <div style={{flex: 1}}>
                                    <h2> Umsätze und Rabatte </h2>
                                </div>
                                <div style={{flex: 1}}>
                                    <h4>Gesamt Umsätze: {formatMoneyFromApi(payments.total_payments ? payments.total_payments.payments_total : 0)}</h4>
                                </div>
                                <div style={{flex: 1}}>
                                    <h4>Gesamt Rabatte: {formatMoneyFromApi(payments.total_payments ? payments.total_payments.discounts_total : 0)}</h4>
                                </div>
                            </Paper>
                        </Grid>
                    }
                </Grid>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        payments: state.payment.data,
    }
}
const mapDispatchToProps = {
    loadRestaurants: loadRestaurantRequest,
    loadSimpleCustomers: loadSimpleCustomersRequest,
    downloadPayments: downloadPaymentRequest
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(style)(Payments));