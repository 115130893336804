import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {Grid, Avatar, Typography, TextField, FormControlLabel, Checkbox, Button, withStyles} from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import LoginForm from "./LoginForm";

class LoginScreen extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Avatar className={classes.avatar}>
                    <AccessAlarmIcon  />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Einloggen
                </Typography>
                <LoginForm/>
            </Fragment>
        )
    }
}


export default compose(
    withStyles({})
)(LoginScreen);
