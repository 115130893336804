import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import {Field, reduxForm, reset } from "redux-form";
import { Container, FormGroup, Label, Input, Row, Col, Collapse } from 'reactstrap';

import {SortableContainer, SortableElement } from 'react-sortable-hoc';
import Dropzone from 'react-dropzone';
import {getFileRequest, loadFileTypeRequest, updateFileRequest, uploadFileRequest} from "../actions/fileActions";
import DescriptionIcon from '@material-ui/icons/Description';
import { IconButton, Grid, Button, Dialog, DialogContent, DialogTitle, DialogActions, MenuItem} from "@material-ui/core"
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldGroup from "../components/TextFieldGroup";
import SelectGroup from "../components/SelectGroup";
import { closeEdit } from "../actions/fileActions";


class EditFile extends Component {
    constructor(props) {
        super(props);

    }

    submit= (values) => {
        this.props.updateFile({
            data: {
                id: this.props.activeFile._id,
                title: values.title,
                type: values.type,
            }
        })
        this.props.closeEdit();
    }


    render() {

        const { types, handleSubmit } = this.props;

        const renderOptions = (options) => {
            return options.map( option => renderOption(option))
        }

        const renderOption = (option) => {
            return <MenuItem key={option.name} value={option._id}>{option.name}</MenuItem>
        }

        return (
                <Dialog open={true} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Bearbeiten Sie Ihr Dokument</DialogTitle>
                    <DialogContent>
                        <form>
                            <Field
                                name="title"
                                label="Dateiname"
                                component={TextFieldGroup}
                                margin="dense"
                                fullWidth={true}
                            />
                            <Field
                                name="type"
                                label="Dateityp"
                                component={SelectGroup}
                                margin="dense"
                                fullWidth={true}
                            >
                                {renderOptions(types)}
                            </Field>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeEdit} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleSubmit(this.submit)} color="primary">
                            Sichern
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        types: state.file.fileTypes,
        activeFile: state.file.activeFile,

        initialValues:{
            title: state.file.activeFile.title,
            type: state.file.activeFile.type
        }
    }
}

const mapDispatchToProps = {
    closeEdit: closeEdit,
    updateFile: updateFileRequest
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'file-form',
        enableReinitialize: true,
    }),

)(EditFile)
