import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types"

import {isAuthenticated} from "../reducers/authReducer";
import {Redirect} from "react-router";


export default function(ComposedComponent) {
	class Authenticate extends React.Component {
		render() {
			const {isAuthenticated} = this.props;

            if (isAuthenticated === false) {
                return <Redirect to="/login"/>
            }

            return <ComposedComponent {...this.props} />
		}
	}

	Authenticate.propTypes = {
		isAuthenticated: PropTypes.bool.isRequired
	};

	function mapStateProps(state) {
		return {
			isAuthenticated: isAuthenticated(state)
		};
	}

	return connect(mapStateProps, null )(Authenticate);
}