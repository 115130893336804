
/* Action types */
import {WAITER_CHANGE_PER_PAGE} from "./waiterReducer";
import {VOUCHER_FILTER_TABLE} from "./voucherReducer";

const PREFIX = "user/";
export const LOAD_USERS_REQUEST = PREFIX + "LOAD_USERS_REQUEST";
export const LOAD_USERS_SUCCESS = PREFIX + "LOAD_USERS_SUCCESS";

export const SAVE_USER_REQUEST = PREFIX + "SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = PREFIX + "SAVE_USER_SUCCESS";

export const PATCH_USER_REQUEST = PREFIX + "PATCH_USER_REQUEST";
export const PATCH_USER_SUCCESS = PREFIX + "PATCH_USER_SUCCESS";
export const PATCH_USER_ERROR = PREFIX + "PATCH_USER_ERROR";

export const SET_NEW_USER = PREFIX + "SET_NEW_USER";
export const UNSET_NEW_USER = PREFIX + "UNSET_NEW_USER";

export const SET_ACTIVE_USER = PREFIX + "SET_ACTIVE_USER";
export const UNSET_ACTIVE_USER = PREFIX + "UNSET_ACTIVE_USER";

export const USER_INITIALIZE_DATATABLE = PREFIX + "USER_INITIALIZE_DATATABLE";
export const USER_NEXT_PAGE = PREFIX + "USER_NEXT_PAGE";
export const USER_PREVIOUS_PAGE = PREFIX + "USER_PREVIOUS_PAGE";
export const USER_CHANGE_PER_PAGE = PREFIX + "USER_CHANGE_PER_PAGE";
export const USER_SORT_TABLE = PREFIX + "USER_SORT_TABLE";
export const USER_FILTER_TABLE = PREFIX + "USER_FILTER_TABLE";


/* initial state */
const initialState = {
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'asc',
    sortField: 'lastname',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    data: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,

    activeUser: false,
    activeUserData: {},
    newUser: false,

};

/* Reducer */
const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_USERS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_USERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.users,
                totalRecords: action.totalRecords,
                newUser: false,
            };
        case SAVE_USER_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_USER_SUCCESS:
            return {
                ...state,
                isSaving: false,
                activeUser: false,
                activeUserData: action.user
            };
        case PATCH_USER_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case PATCH_USER_SUCCESS:
            return {
                ...state,
                isDeleting: false,
                activeUserData: action.user
            };
        case PATCH_USER_ERROR:
            return {
                ...state,
                isDeleting: false,
            };
        case USER_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
            };
        case USER_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case USER_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case USER_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case USER_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case SET_NEW_USER:
            return {
                ...state,
                newUser: true
            };
        case UNSET_NEW_USER:
            return {
                ...state,
                newUser: false
            };
        case SET_ACTIVE_USER:
            return {
                ...state,
                activeUser: true,
                activeUserData: action.activeUser
            };
        case UNSET_ACTIVE_USER:
            return {
                ...state,
                activeUser: false
            };
        case USER_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        default:
            return state;
    }
};
export default userReducer;