import {
    ACCOUNTING_DETAIL_PDF_ERROR,
    ACCOUNTING_DETAIL_PDF_REQUEST,
    ACCOUNTING_DETAIL_PDF_SUCCESS,
    ACCOUNTING_DETAILS_CHANGE_PER_PAGE,
    ACCOUNTING_DETAILS_FILTER_TABLE,
    ACCOUNTING_DETAILS_INITIALIZE_DATATABLE,
    ACCOUNTING_DETAILS_NEXT_PAGE,
    ACCOUNTING_DETAILS_PREVIOUS_PAGE,
    ACCOUNTING_DETAILS_SORT_TABLE,
    ACCOUNTING_ERROR,
    ACCOUNTING_MONTHS_CHANGE_PER_PAGE,
    ACCOUNTING_MONTHS_FILTER_TABLE,
    ACCOUNTING_MONTHS_INITIALIZE_DATATABLE,
    ACCOUNTING_MONTHS_NEXT_PAGE,
    ACCOUNTING_MONTHS_PREVIOUS_PAGE,
    ACCOUNTING_MONTHS_SORT_TABLE,
    ACCOUNTING_PDF_ERROR,
    ACCOUNTING_PDF_REQUEST,
    ACCOUNTING_PDF_SUCCESS,
    ACCOUNTING_PDF_SUMMARY_ERROR, ACCOUNTING_PDF_SUMMARY_REQUEST, ACCOUNTING_PDF_SUMMARY_SUCCESS,
    ACCOUNTING_PDF_ZIP_ERROR,
    ACCOUNTING_PDF_ZIP_REQUEST,
    ACCOUNTING_PDF_ZIP_SUCCESS,
    ACCOUNTING_REQUEST,
    ACCOUNTING_STRIPE_TRANSFER_ERROR,
    ACCOUNTING_STRIPE_TRANSFER_REQUEST,
    ACCOUNTING_STRIPE_TRANSFER_SUCCESS,
    ACCOUNTING_SUCCESS,
    LOAD_ACCOUNTED_MONTHS_REQUEST,
    LOAD_ACCOUNTED_MONTHS_SUCCESS,
    LOAD_ACTIVE_MONTH_REQUEST,
    LOAD_ACTIVE_MONTH_SUCCESS,
    SET_ACCOUNTED_MONTH,
    UNSET_ACCOUNTED_MONTH
} from "../reducers/accountingReducer";

export const accountingRequest = (values) => ({
    type: ACCOUNTING_REQUEST,
    ...values
});

export const accountingSuccess = () => ({
    type: ACCOUNTING_SUCCESS,
});

export const accountingError = () => ({
    type: ACCOUNTING_ERROR,
});

export const accountingStripeTransferRequest = (values) => ({
    type: ACCOUNTING_STRIPE_TRANSFER_REQUEST,
    ...values
});

export const accountingStripeTransferSuccess = (values) => ({
    type: ACCOUNTING_STRIPE_TRANSFER_SUCCESS,
    ...values
});

export const accountingStripeTransferError = () => ({
    type: ACCOUNTING_STRIPE_TRANSFER_ERROR,
});

export const accountingPdfRequest = (values) => ({
    type: ACCOUNTING_PDF_REQUEST,
    ...values
});

export const accountingPdfSuccess = (values) => ({
    type: ACCOUNTING_PDF_SUCCESS,
    ...values
});

export const accountingPdfError = () => ({
    type: ACCOUNTING_PDF_ERROR,
});

export const accountingDetailsPdfRequest = (values) => ({
    type: ACCOUNTING_DETAIL_PDF_REQUEST,
    ...values
});

export const accountingDetailsPdfSuccess = (values) => ({
    type: ACCOUNTING_DETAIL_PDF_SUCCESS,
    ...values
});

export const accountingDetailsPdfError = () => ({
    type: ACCOUNTING_DETAIL_PDF_ERROR,
});

export const accountingPdfZipRequest = (values) => ({
    type: ACCOUNTING_PDF_ZIP_REQUEST,
    ...values
});

export const accountingPdfZipSuccess = (values) => ({
    type: ACCOUNTING_PDF_ZIP_SUCCESS,
    ...values
});

export const accountingPdfZipError = () => ({
    type: ACCOUNTING_PDF_ZIP_ERROR,
});

export const loadAccountedMonthsRequest = () => ({
    type: LOAD_ACCOUNTED_MONTHS_REQUEST,
});

export const loadAccountedMonthsSuccess = (values) => ({
    type: LOAD_ACCOUNTED_MONTHS_SUCCESS,
    ...values
});

export const setAccountedMonth = ({month}) => ({
    type: SET_ACCOUNTED_MONTH,
    month,
});

export const unsetAcctountedMonth = () => ({
    type: UNSET_ACCOUNTED_MONTH,
});

export const loadActiveMonthRequest = ({month}) => ({
    type: LOAD_ACTIVE_MONTH_REQUEST,
    month
});

export const loadActiveMonthSuccess = ({accountings, totalRecords}) => ({
    type: LOAD_ACTIVE_MONTH_SUCCESS,
    accountings,
    totalRecords
});

export const monthsInitializeDataTable = ({options}) => ({
    type: ACCOUNTING_MONTHS_INITIALIZE_DATATABLE,
    ...options,
});

export const monthsNextPage = () => ({
    type: ACCOUNTING_MONTHS_NEXT_PAGE,
});

export const monthsPreviousPage= () => ({
    type: ACCOUNTING_MONTHS_PREVIOUS_PAGE,
});

export const monthsChangePerPage = (value) => ({
    type: ACCOUNTING_MONTHS_CHANGE_PER_PAGE,
    value
});

export const monthsSort = (field, value) => ({
    type: ACCOUNTING_MONTHS_SORT_TABLE,
    field,
    value
});

export const monthsFilterTable= (values) => ({
    type: ACCOUNTING_MONTHS_FILTER_TABLE,
    ...values
});

export const detailsInitializeDataTable = ({options}) => ({
    type: ACCOUNTING_DETAILS_INITIALIZE_DATATABLE,
    ...options,
});

export const detailsNextPage = () => ({
    type: ACCOUNTING_DETAILS_NEXT_PAGE,
});

export const detailsPreviousPage= () => ({
    type: ACCOUNTING_DETAILS_PREVIOUS_PAGE,
});

export const detailsChangePerPage = (value) => ({
    type: ACCOUNTING_DETAILS_CHANGE_PER_PAGE,
    value
});

export const detailsSort = (field, value) => ({
    type: ACCOUNTING_DETAILS_SORT_TABLE,
    field,
    value
});

export const detailsFilterTable= ({data}) => ({
    type: ACCOUNTING_DETAILS_FILTER_TABLE,
    data
});

export const accountingPdfSummaryRequest = (values) => ({
    type: ACCOUNTING_PDF_SUMMARY_REQUEST,
    ...values
});

export const accountingPdfSummarySuccess = (values) => ({
    type: ACCOUNTING_PDF_SUMMARY_SUCCESS,
    ...values
});

export const accountingPdfSummaryError = () => ({
    type: ACCOUNTING_PDF_SUMMARY_ERROR,
});
