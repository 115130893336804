
/* Action types */
const PREFIX = "file/";
export const UPLOAD_FILE_REQUEST = PREFIX + "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = PREFIX + "UPLOAD_FILE_SUCCESS";

export const GET_FILE_REQUEST = PREFIX + "GET_FILE_REQUEST";
export const GET_FILE_SUCCESS = PREFIX + "GET_FILE_SUCCESS";

export const LOAD_FILE_TYPE_REQUEST = PREFIX + "LOAD_FILE_TYPE_REQUEST";
export const LOAD_FILE_TYPE_SUCCESS = PREFIX + "LOAD_FILE_TYPE_SUCCESS";

export const OPEN_EDIT = PREFIX + "OPEN_EDIT";
export const CLOSE_EDIT = PREFIX + "CLOSE_EDIT";

export const UPDATE_FILE_REQUEST = PREFIX + "UPDATE_FILE_REQUEST";
export const UPDATE_FILE_SUCCESS = PREFIX + "UPDATE_FILE_SUCCESS";

export const DELETE_FILE_REQUEST = PREFIX + "UPLOAD_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = PREFIX + "UPLOAD_FILE_SUCCESS";

export const LOAD_PICTURES_REQUEST = PREFIX + "LOAD_PICTURES_REQUEST";
export const LOAD_PICTURES_SUCCESS = PREFIX + "LOAD_PICTURES_SUCCESS";

export const MEDIA_LIBRARY_REQUEST = PREFIX + "MEDIA_LIBRARY_REQUEST";
export const MEDIA_LIBRARY_SUCCESS = PREFIX + "MEDIA_LIBRARY_SUCCESS";
export const SET_MEDIA_LIBRARY = PREFIX + "SET_MEDIA_LIBRARY";
export const UNSET_MEDIA_LIBRARY = PREFIX + "UNSET_MEDIA_LIBRARY";


/* initial state */
const initialState = {

    file: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,
    fileTypes: [],
    openEdit: false,
    activeFile: '',
    pictures: [],
    mediaLibrary: false,

};

/* Reducer */
const fileReducer = (state = initialState, action) => {

    switch(action.type) {
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        case GET_FILE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_FILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case LOAD_FILE_TYPE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_FILE_TYPE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fileTypes: action.types
            };
        case OPEN_EDIT:
            return {
                ...state,
                isFetching: false,
                openEdit: true,
                activeFile: action.file
            };
        case CLOSE_EDIT:
            return {
                ...state,
                isFetching: false,
                openEdit: false
            };
        case UPDATE_FILE_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case UPDATE_FILE_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        case DELETE_FILE_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                isDeleting: false,
            };
        case LOAD_PICTURES_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_PICTURES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                pictures: action.pictures
            };
        case SET_MEDIA_LIBRARY:
            return {
                ...state,
                mediaLibrary: true
            };
        case MEDIA_LIBRARY_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case MEDIA_LIBRARY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                pictures: action.pictures
            };
        case UNSET_MEDIA_LIBRARY:
            return {
                ...state,
                mediaLibrary: false
            };

        default:
            return state;
    }
};
export default fileReducer;