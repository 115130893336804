import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Grid, Paper, Button, Select } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import AccountedMonthsView from "./AccountedMonthsView";
import {
    accountingPdfSummaryRequest,
    accountingRequest,
    loadAccountedMonthsRequest
} from "../../../actions/accountingActions";
import moment from 'moment';
import {isAllowed} from "../../../utils/permissionHelper";


class Accountings extends Component {
    constructor(props) {
        super(props);
        this.state = {month: moment().add('month', -1).format("MM-YYYY")}
    }

    componentDidMount(){
        this.props.loadAccountedMonths();
    }

    handleAccounting = () => {
        const { month } = this.state;
        this.props.accountingRequest({month});
    };

    handlePdf = () => {
        const { month } = this.state;
        this.props.getPdf({month});
    }

    getMonthOptions = () => {
        /*generate months fo filter*/
        const monthOptions = [];
        let i = 1;
        while (i < 13) {
            const monthObj = moment().set("date", 1).subtract({months: i});
            let text = monthObj.format("MMMM YYYY");
            monthOptions.push(
                <option value={ monthObj.format('MM-YYYY') }>{ text }</option>
            );
            i++
        }
        return monthOptions;
    };

    monthChanged = (event) => {
        this.setState({month: event.target.value});
    };

    monthEnabled = () => {
        return !this.accountingExists();
    }

    accountingExists = () => {
        const { accountings } = this.props;
        if(accountings){
            return !!accountings.find(a => a.month === this.state.month);
        }
        return false;
    }

    render() {
        const { month } = this.state;

        return (
            <Fragment>
                <Grid container spacing={5} >
                    {(this.props.user && this.props.user.role !== 'restaurant') &&
                        <div style={{right: '10px', marginLeft: 'auto', top: '20px', padding: '20px', display: 'flex'}}>
                            <Select
                                native
                                value={month}
                                onChange={this.monthChanged}
                                style={{marginRight: '15px'}}
                            >
                                {this.getMonthOptions()}
                            </Select>

                            <Button onClick={this.handlePdf} color="primary" variant={'outlined'} disabled={!this.accountingExists()}>
                                Abrechnung downloaden
                            </Button>
                            {isAllowed(this.props.user, 'accountings', 'create') && <div>
                                <Button onClick={this.handleAccounting} color="primary" variant={'outlined'} style={{marginLeft: '15px'}} disabled={!month || !this.monthEnabled()}>
                                    Monat abrechnen
                                </Button>
                            </div>

                            }
                        </div>
                    }

                    <Grid item md={12}>
                        <h2>Abrechnungen</h2>
                        <AccountedMonthsView/>
                    </Grid>
                </Grid>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        accountings: state.accounting.monthsData,
        user: state.auth.user
    }
}
const mapDispatchToProps = {
    loadAccountedMonths: loadAccountedMonthsRequest,
    accountingRequest: accountingRequest,
    getPdf: accountingPdfSummaryRequest
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(style)(Accountings));