import React from "react";
import {Field} from "redux-form";
import MultipleSelect from "../MultipleSelect";
import DatePickerGroup from "../DatePickerGroup";
import {Grid} from "@material-ui/core";
import TextFieldGroup from "../TextFieldGroup";

const TextFilter = (props) => {
    return [
        <Field
            name={props.fieldName}
            label={props.label}
            component={TextFieldGroup}
        />
    ]
};

export default TextFilter;