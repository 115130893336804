import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";

import {
    savePostSuccess, loadPostSuccess, setActivePost, deletePostSuccess, loadPostRequest
} from "../actions/postActions";

export function* loadPostsRequest (api,{id}) {
    try {

        const DataTableOptions = state => state.post;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        if(!id){
            const restaurant = state => state.restaurant;
            const {activeRestaurantData} = yield select(restaurant)
            id = activeRestaurantData._id
        }

        let result = false;
        result = yield call(api.loadPost, id, options);

        if (result) {
            yield put(loadPostSuccess({posts: result.data.posts, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* savePostRequest (api, {data}) {
    try {
        let result = false;

        if(data._id && data._id.length){
            result = yield call(api.updatePost, data);
        }else {
            result = yield call(api.createPost, data);
        }

        if (result) {
            yield put(savePostSuccess());
            yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}

export function* reloadActivePost () {
    try {
        const post = state => state.post;
        const {activePostData} = yield select(post);
        yield put(setActivePost(activePostData))
    } catch (e) {
        console.log(e)
    }
}

export function* deletePostRequest (api, {data}) {
    try {
        let result = false;

        result = yield call(api.deletePost, data.id, data.restaurant_id);

        if (result) {
            yield put(deletePostSuccess())
            yield put(addFlashMessage({message:'Beitrag wurde gelöscht', style:'success'}))
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}


