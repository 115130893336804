import {
    LOAD_USERS_REQUEST,
    LOAD_USERS_SUCCESS,
    PATCH_USER_REQUEST,
    PATCH_USER_SUCCESS,
    PATCH_USER_ERROR,
    SAVE_USER_REQUEST,
    SAVE_USER_SUCCESS,
    USER_INITIALIZE_DATATABLE,
    USER_NEXT_PAGE,
    USER_PREVIOUS_PAGE,
    USER_CHANGE_PER_PAGE,
    USER_SORT_TABLE,
    SET_NEW_USER,
    UNSET_NEW_USER,
    SET_ACTIVE_USER,
    UNSET_ACTIVE_USER, USER_FILTER_TABLE,
} from "../reducers/userReducer";
import {VOUCHER_FILTER_TABLE} from "../reducers/voucherReducer";

export const loadUsersRequest = () => ({
    type: LOAD_USERS_REQUEST,
});

export const loadUsersSuccess = ({users, totalRecords}) => ({
    type: LOAD_USERS_SUCCESS,
    users,
    totalRecords,
});

export const saveUserRequest = ({data}) => ({
    type: SAVE_USER_REQUEST,
    data
});

export const saveUserSuccess = ({user}) => ({
    type: SAVE_USER_SUCCESS,
    user
});

export const patchUserRequest = ({data}) => ({
    type: PATCH_USER_REQUEST,
    data
});

export const patchUserSuccess = (values) => ({
    type: PATCH_USER_SUCCESS,
    ...values
});

export const patchUserError = () => ({
    type: PATCH_USER_ERROR,
});

export const setNewUser = () => ({
    type: SET_NEW_USER
});

export const unsetNewUser = () => ({
    type: UNSET_NEW_USER
});

export const setActiveUser = (activeUser) => ({
    type: SET_ACTIVE_USER,
    activeUser
});

export const unsetActiveUser = () => ({
    type: UNSET_ACTIVE_USER
});

export const initializeDataTable = ({options}) => ({
    type: USER_INITIALIZE_DATATABLE,
    ...options,
});

export const nextPage = () => ({
    type: USER_NEXT_PAGE,
});

export const previousPage = () => ({
    type: USER_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: USER_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: USER_SORT_TABLE,
    field,
    value
});

export const filterTable = ({values}) => ({
    type: USER_FILTER_TABLE,
    values
});



