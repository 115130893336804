import {  DOWNLOAD_PAYMENTS_ERROR, DOWNLOAD_PAYMENTS_REQUEST,
    DOWNLOAD_PAYMENTS_SUCCESS,
    LOAD_PAYMENTS_REQUEST,
    LOAD_PAYMENTS_SUCCESS, PAYMENT_CHANGE_PER_PAGE, PAYMENT_FILTER_TABLE, PAYMENT_INITIALIZE_DATATABLE,
    PAYMENT_NEXT_PAGE,
    PAYMENT_PREVIOUS_PAGE, PAYMENT_SORT_TABLE
} from "../reducers/paymentReducer";


export const downloadPaymentRequest = (values) => ({
    type: DOWNLOAD_PAYMENTS_REQUEST,
    ...values
});

export const downloadPaymentSuccess = (values) => ({
    type: DOWNLOAD_PAYMENTS_SUCCESS,
    ...values
});

export const downloadPaymentError = (values) => ({
    type: DOWNLOAD_PAYMENTS_ERROR,
    ...values
});


export const loadPaymentsRequest = ({data}) => ({
    type: LOAD_PAYMENTS_REQUEST,
    data
});

export const loadPaymentsSuccess = ({payments, totalRecords}) => ({
    type: LOAD_PAYMENTS_SUCCESS,
    payments,
    totalRecords
});


export const initializeDataTable = ({options}) => ({
    type: PAYMENT_INITIALIZE_DATATABLE,
    ...options,
});

export const nextPage = () => ({
    type: PAYMENT_NEXT_PAGE,
});

export const previousPage = () => ({
    type: PAYMENT_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: PAYMENT_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: PAYMENT_SORT_TABLE,
    field,
    value
});

export const filterTable = ({data}) => ({
    type: PAYMENT_FILTER_TABLE,
    data
});
