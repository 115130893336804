import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";
import {
    loadHelloError, loadHellosError, loadHellosSuccess, loadHelloSuccess,
    patchHelloError, patchHelloSuccess
} from "../actions/helloActions";

export function* loadHellosRequest (api) {
    try {

        const DataTableOptions = state => state.hello;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        let result = yield call(api.loadHellos, options);

        if (result) {
            yield put(loadHellosSuccess({hellos: result.data.hellos, totalRecords: result.data.totalRecords}));
        } else {
            yield put(loadHellosError({}));
        }
    } catch (e) {
        console.log(e)
    }
}

export function* loadHelloRequest (api, id) {
    try {
        let result = yield call(api.loadHello, id);

        if (result && result.data && result.data.success) {
            yield put(loadHelloSuccess({hello: result.data.hello}));
        } else {
            yield put(loadHelloError({}));
        }
    } catch (e) {
        console.log(e)
    }
}

export function* patchHelloRequest (api, data) {
    try {

        let result = yield call(api.patchHello, data._id, data.action);

        if (result && result.data && result.data.success) {
            yield put(patchHelloSuccess());
            yield put(addFlashMessage({message:'Die Empfehlung wurde erfolgreich bearbeitet', style:'success'}))
        } else {
            yield put(patchHelloError());
            yield put(addFlashMessage({message:'Fehler beim Löschen der Empfehlung', style:'error'}))
        }

    } catch (e) {
        console.log(e)
    }
}

