import React, { Component } from 'react';


import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, FormGroup, Label, Input, Row, Col, Collapse } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { getFileRequest, loadFileTypeRequest, openEdit, unsetMediaLibrary,
    uploadFileRequest
} from "../actions/fileActions";
import FileEdit from "./FileEdit"

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.onDropImg = this.onDropImg.bind(this);
        this.newFileArrived = this.newFileArrived.bind(this);
    }

    componentDidMount(){
        this.props.loadFileTypes();
    }

    onDropImg(files) {
        files.map(file => this.newFileArrived(file));
    }

    newFileArrived(file) {
        this.props.uploadFile({files: [{
                reference_id: this.props.ref_id,
                reference_type: this.props.ref_type,
                type: this.props.types[0]._id ? this.props.types[0]._id :'',
                file: file.path,
                content_type: file.type,
                title: file.name,
                data: file
            }]});
    }

    getFile(item) {
        this.props.downloadFile({ id:item._id, download: true});
    }

    render() {
        const { edit } = this.props;
        return (
            <Container>
                    <Row className="form-box">
                    <Col sm={12}>
                            <div className="dropzone" style={{padding: '20px', border: '0.5px dotted grey', marginTop: '40px', height: '150px', minHeight: '150px', textAlign: 'center'}}>
                                <Dropzone onDrop={this.onDropImg}>
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            {this.props.saving === true ?
                                                <span>
                                                    LOADING...
                                                </span>
                                            :
                                                <span>
                                                    <h5>Dokumente (.docx, .pdf, .png, ...)</h5>
                                                    <p> hier ablegen, oder hier klicken um Bilder hinzuzufügen</p>
                                                </span>
                                            }

                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                    </Col>
                </Row>

                {edit === true &&
                    <FileEdit/>
                }

            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        file: state.file.file,
        types: state.file.fileTypes,
        edit: state.file.openEdit,
        saving: state.file.isSaving
    }
}

const mapDispatchToProps = {
    uploadFile: uploadFileRequest,
    downloadFile: getFileRequest,
    loadFileTypes: loadFileTypeRequest,
    openEdit: openEdit,
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),

)(FileUpload)