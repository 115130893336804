import React from "react";
import {Field} from "redux-form";
import { formatCheckinStatus } from "../../utils/helper";
import MultipleSelect from "../MultipleSelect";

const options = [
    {_id: 'to_invoice', name: formatCheckinStatus('to_invoice')},
    {_id: 'to_pay', name: formatCheckinStatus('to_pay')},
    {_id: 'active', name: formatCheckinStatus('active')},
    {_id: 'cancel', name: formatCheckinStatus('cancel')},
    {_id: 'paid', name: formatCheckinStatus('paid')},
];

const CheckinStatusSelect = (props) => {
    return  <Field
        format={value => Array.isArray(value) ? value : []}
        name={props.fieldName}
        component={MultipleSelect}
        label={'Status'}
        fullWidth
        accessorValue={"_id"}
        accessorLabel={'name'}
        options={options}
    />;
};

export default CheckinStatusSelect;