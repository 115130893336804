
/* Action types */
const PREFIX = "voucher/";

export const LOAD_VOUCHERS_REQUEST = PREFIX + "LOAD_VOUCHERS_REQUEST";
export const LOAD_VOUCHERS_SUCCESS = PREFIX + "LOAD_VOUCHERS_SUCCESS";
export const LOAD_VOUCHERS_ERROR = PREFIX + "LOAD_VOUCHERS_ERROR";

export const LOAD_VOUCHER_REQUEST = PREFIX + "LOAD_VOUCHER_REQUEST";
export const LOAD_VOUCHER_SUCCESS = PREFIX + "LOAD_VOUCHER_SUCCESS";
export const LOAD_VOUCHER_ERROR = PREFIX + "LOAD_VOUCHER_ERROR";

export const CREATE_VOUCHER_REQUEST = PREFIX + "CREATE_VOUCHER_REQUEST";
export const CREATE_VOUCHER_SUCCESS = PREFIX + "CREATE_VOUCHER_SUCCESS";
export const CREATE_VOUCHER_ERROR = PREFIX + "CREATE_VOUCHER_ERROR";

export const MAIL_VOUCHER_REQUEST = PREFIX + "MAIL_VOUCHER_REQUEST";
export const MAIL_VOUCHER_SUCCESS = PREFIX + "MAIL_VOUCHER_SUCCESS";
export const MAIL_VOUCHER_ERROR = PREFIX + "MAIL_VOUCHER_ERROR";

export const PATCH_VOUCHER_REQUEST = PREFIX + "PATCH_VOUCHER_REQUEST";
export const PATCH_VOUCHER_SUCCESS = PREFIX + "PATCH_VOUCHER_SUCCESS";
export const PATCH_VOUCHER_ERROR = PREFIX + "PATCH_VOUCHER_ERROR";

export const VOUCHER_INITIALIZE_DATATABLE = PREFIX + "VOUCHER_INITIALIZE_DATATABLE";
export const VOUCHER_NEXT_PAGE = PREFIX + "VOUCHER_NEXT_PAGE";
export const VOUCHER_PREVIOUS_PAGE = PREFIX + "VOUCHER_PREVIOUS_PAGE";
export const VOUCHER_CHANGE_PER_PAGE = PREFIX + "VOUCHER_CHANGE_PER_PAGE";
export const VOUCHER_SORT_TABLE = PREFIX + "VOUCHER_SORT_TABLE";
export const VOUCHER_FILTER_TABLE = PREFIX + "VOUCHER_FILTER_TABLE";

export const SET_NEW_VOUCHER = PREFIX + "SET_NEW_VOUCHER";
export const UNSET_NEW_VOUCHER = PREFIX + "UNSET_NEW_VOUCHER";

export const SET_ACTIVE_VOUCHER = PREFIX + "SET_ACTIVE_VOUCHER";
export const UNSET_ACTIVE_VOUCHER = PREFIX + "UNSET_ACTIVE_VOUCHER";

/* initial state */
const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'created_at',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    activeVoucher: null,
    newVoucher: false,
};

/* Reducer */
const voucherReducer = (state = initialState, action) => {

    switch(action.type) {

        case LOAD_VOUCHERS_REQUEST:
            return {
                ...state,
            };
        case LOAD_VOUCHERS_SUCCESS:
            return {
                ...state,
                data: action.vouchers,
                totalRecords: action.totalRecords,
            };
        case LOAD_VOUCHERS_ERROR:
            return {
                ...state,
                data: action.vouchers,
                totalRecords: 0,
            };
        case CREATE_VOUCHER_REQUEST:
            return {
                ...state,
            };
        case CREATE_VOUCHER_SUCCESS:
            return {
                ...state,
            };

        case CREATE_VOUCHER_ERROR:
            return {
                ...state,
            };

        case PATCH_VOUCHER_REQUEST:
            return {
                ...state,
            };
        case PATCH_VOUCHER_SUCCESS:
            return {
                ...state,
            };
        case PATCH_VOUCHER_ERROR:
            return {
                ...state,
            };
        case LOAD_VOUCHER_REQUEST:
            return {
                ...state,
            };
        case LOAD_VOUCHER_SUCCESS:
            return {
                ...state,
                activeVoucher: action.voucher
            };
        case LOAD_VOUCHER_ERROR:
            return {
                ...state,
                activeVoucher: null,
            };

        case VOUCHER_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case VOUCHER_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case VOUCHER_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case VOUCHER_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case VOUCHER_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case VOUCHER_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };

        case SET_NEW_VOUCHER:
            return {
                ...state,
                newVoucher: true
            };
        case UNSET_NEW_VOUCHER:
            return {
                ...state,
                newVoucher: false
            };
        case SET_ACTIVE_VOUCHER:
            return {
                ...state,
                activeVoucher: action.activeVoucher,
            };
        case UNSET_ACTIVE_VOUCHER:
            return {
                ...state,
                activeVoucher: null
            };
        default:
            return state;
    }
};
export default voucherReducer;