import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Paper, Grid, IconButton, Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {loadRestaurantRequest} from "../../../actions/restaurantActions";
import RestaurantsView from "./RestaurantsView";
import {loadTypeRequest} from "../../../actions/typeActions";
import {loadUsersRequest} from "../../../actions/userActions";
import {loadTagRequest} from "../../../actions/tagActions";
import {isAllowed} from "../../../utils/permissionHelper";


class Restaurants extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        this.props.loadRestaurants();
        this.props.loadTypes();
        this.props.loadUsers();
        this.props.loadTags();
    }

    render() {
        const {classes, restaurant, restaurantData} = this.props;

        return (
            <Fragment>
                { isAllowed(this.props.user, 'restaurants', 'create') &&
                    <Link style={{textDecoration: 'none', float: 'right'}} to={'/intern/restaurants/new'}>
                        <IconButton className={classes.newRestaurantBtn}>
                            <AddIcon fontSize={'large'}  /> <span style={{fontSize: '18px'}}>Restaurant hinzufügen</span>
                        </IconButton>
                    </Link>
                }

                <RestaurantsView/>


                {restaurant !== false &&
                    <Link to={"/intern/restaurants/" + restaurantData._id} />
                }

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        restaurant: state.restaurant.activeRestaurant,
        restaurantData: state.restaurant.activeRestaurantData,
        user: state.auth.user
    }
}
const mapDispatchToProps = {
    loadRestaurants: loadRestaurantRequest,
    loadTypes: loadTypeRequest,
    loadUsers: loadUsersRequest,
    loadTags: loadTagRequest
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Restaurants));