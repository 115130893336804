import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Grid, Button} from '@material-ui/core'
import {
    loadRestaurantUserRequest,
    saveRestaurantRksvRequest,
    saveRestaurantUserRequest
} from "../../../actions/restaurantActions";
import {isAllowed} from "../../../utils/permissionHelper";

class RestaurantsLoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    required = value => {
        return (value != undefined && value != '') ? undefined : 'Feld erforderlich';
    }

    submitAll = (values) => {
        this.props.saveRestaurantUser({
            _id: this.props.restaurant._id,
            username: values.username,
            password: values.password
        })
    };

    render() {
        const {handleSubmit, classes} = this.props;
        return (
                <Grid container className={classes.fragmentContainer}>
                    <Grid xs={12}>
                        <form>
                            <Grid container>
                                <Grid md={12}>
                                    <h2>Anmeldeinformationen</h2>
                                </Grid>
                                <Grid xs={12}>
                                    <Grid md={6} xs={6}>
                                        <Field
                                            name={`username`}
                                            label="Benutzername"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                    <Grid md={6} xs={6}>
                                        <Field
                                            name={`password`}
                                            label="Passwort"
                                            component={TextFieldGroup}
                                            placeholder={"Bitte ausfüllen..."}
                                            validate={[this.required]}
                                            style={{paddingRight: 5}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isAllowed(this.props.user, 'restaurants', 'update') &&
                                <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)}
                                        variant={'outlined'} color="primary">
                                    Einstellungen speichern
                                </Button>
                            }
                        </form>
                    </Grid>
                </Grid>
        );
    }
}

function mapStateToProps(state) {
    const restaurant = state.restaurant.activeRestaurant === true ? state.restaurant.activeRestaurantData : {};
    const user = state.restaurant.activeRestaurantUser?state.restaurant.activeRestaurantUser:{};
    return {
        initialValues: {
            ...user,
        },
        user: state.auth.user,
        restaurant: restaurant,
    }
}

const mapDispatchToProps = {
    saveRestaurantUser: saveRestaurantUserRequest
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'restaurants-login-form',
        enableReinitialize:true,
    }),
    withStyles(style)
)(RestaurantsLoginForm)