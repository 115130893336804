import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Field, reduxForm, reset } from "redux-form";
import {compose} from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextFieldGroup from "../../../components/TextFieldGroup";
import {filterTable} from "../../../actions/restaurantActions";
import MultipleSelect from "../../../components/MultipleSelect";

class RestaurantsFilter extends Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount(){
        this.props.filterTable({
            values:{
                searchValue: null,
                fields: ['name', 'city', 'street', 'postal_code'],
                table: 'restaurants',
                tags: null,
                types: null
            }
        })
    }

    statusOptions = () => {
        return [
            {_id: true, name: 'Aktiv'},
            {_id: false, name: 'Inaktiv'},
        ]
    };

    render() {
        const {classes, types, tags, kitchen } = this.props;

        let typeOptions = (types?types:[]).map(type =>{
            return {
                ...type,
            }
        });

        return (

            <div>
                <form>
                    <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                        <Grid item md={3} xs={12}>
                            <Field
                                name="search"
                                label="Suche"
                                component={TextFieldGroup}
                                margin="dense"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Field
                                format={value => Array.isArray(value) ? value : []}
                                name="types"
                                component={MultipleSelect}
                                label={'Typ'}
                                fullWidth
                                accessorValue={"_id"}
                                accessorLabel={'name'}
                                options={typeOptions}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Field
                                format={value => Array.isArray(value) ? value : []}
                                name="tags"
                                component={MultipleSelect}
                                label={'Küchenstil'}
                                fullWidth
                                accessorValue={"value"}
                                accessorLabel={'label'}
                                options={kitchen}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Field
                                name="postal_code"
                                label="PLZ"
                                component={TextFieldGroup}
                                margin="dense"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Field
                                name="city"
                                label="Ort"
                                component={TextFieldGroup}
                                margin="dense"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Field
                                format={value => Array.isArray(value) ? value : []}
                                name="status"
                                component={MultipleSelect}
                                label={'Status'}
                                fullWidth
                                accessorValue={"_id"}
                                accessorLabel={'name'}
                                options={this.statusOptions()}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Button variant={'outlined'} style={{marginTop: '20px'}} secondary="true" onClick={this.props.reset}>
                               Reset
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>


        );
    }
}

function mapStateToProps(state) {
    return {
        types: state.type.data ? state.type.data : [],
        tags: state.tag.data ? state.tag.data : [],
        kitchen: state.tag.kitchen ? state.tag.kitchen : [],
        initialValues: {
            status: [true]
        }
    }
}

const mapDispatchToProps = {
    filterTable: filterTable,
    reset: reset('restaurantsFilter-form'),
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'restaurantsFilter-form',
        onChange: (values, dispatch, props ) => {

            const fields = (() => {
                if(values && !!values['postal_code']){
                    return ['postal_code'];
                } else if(values && !!values['city']){
                    return ['city'];
                } else {
                    return ['name', 'city', 'street', 'postal_code'];
                }
            })();

            const searchValue = (() => {
                if(values && !!values['postal_code']){
                    return values['postal_code'];
                } else if(values && !!values['city']){
                    return values['city'];
                } else {
                    return values.search;
                }
            })();

            console.log('fields: ', fields);
            console.log('searchValue: ', searchValue)

            dispatch(props.filterTable({
                values:{
                    searchValue: searchValue,
                    fields: fields,
                    table: 'restaurants',
                    tags: values.tags?values.tags:null,
                    types: values.types?values.types:null,
                    status: values.status?values.status:null
                }
            }))
        },

    }),
    withStyles(style)
)(RestaurantsFilter)


