import { takeLatest, all, takeEvery, debounce } from 'redux-saga/effects';


import {setCurrentUser} from "../actions/authActions";
import {LOAD_CURRENT_USER_REQUEST, LOAD_CURRENT_USER_SUCCESS, LOGIN_REQUEST, LOGIN_SUCCESS, SET_CURRENT_USER} from "../reducers/authReducer"
import {login, success, loadUser, loadUserSuccess} from "./authSagas";


import {
    LOAD_RESTAURANT_REQUEST,
    RESTAURANT_INITIALIZE_DATATABLE,
    RESTAURANT_NEXT_PAGE,
    RESTAURANT_PREVIOUS_PAGE,
    RESTAURANT_CHANGE_PER_PAGE,
    RESTAURANT_SORT_TABLE,
    RESTAURANT_FILTER_TABLE,
    SAVE_RESTAURANT_REQUEST,
    DELETE_RESTAURANT_REQUEST,
    SET_ACTIVE_RESTAURANT,
    SET_PROFILPICTURE_SUCCESS,
    SET_PROFILPICTURE_REQUEST,
    SAVE_RESTAURANT_RKSV_REQUEST,
    LOAD_RESTAURANT_USER_REQUEST,
    SAVE_RESTAURANT_USER_REQUEST,
    SAVE_RESTAURANT_USER_SUCCESS
} from "../reducers/restaurantReducer";
import {
    loadRestaurantRequest,
    saveRestaurantRequest,
    deleteRestaurantRequest,
    getActiveRestaurant,
    reloadActiveRestaurant,
    setProfilPictureRequest,
    saveRestaurantRksvRequest,
    loadRestaurantUserRequest,
    saveRestaurantUserRequest
} from "./restaurantSagas";

import {
    LOAD_FILE_TYPE_REQUEST,
    UPLOAD_FILE_REQUEST,
    GET_FILE_REQUEST,
    UPDATE_FILE_REQUEST,
    DELETE_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPDATE_FILE_SUCCESS,
    DELETE_FILE_SUCCESS,
    MEDIA_LIBRARY_REQUEST,
} from "../reducers/fileReducer";
import {uploadFileRequest, loadFileTypeRequest, getFileRequest, updateFileRequest, deleteFileRequest, loadMediaLibraryRequest} from "./fileSagas";

import api from "../services/api";
import {SAVE_POST_REQUEST, SAVE_POST_SUCCESS, LOAD_POST_REQUEST, SET_POST_PICTURES, DELETE_POST_REQUEST, DELETE_POST_SUCCESS, POST_FILTER_TABLE, POST_SORT_TABLE, POST_CHANGE_PER_PAGE, POST_PREVIOUS_PAGE, POST_NEXT_PAGE, POST_INITIALIZE_DATATABLE, } from "../reducers/postReducer";
import {savePostRequest, reloadActivePost, loadPostsRequest, deletePostRequest} from "./postSagas";
import {LOAD_OPENING_REQUEST, LOAD_OPENING_SUCCESS, SAVE_OPENING_REQUEST} from "../reducers/openingReducer";
import {loadOpeningHoursRequest, saveOpeningHoursRequest} from "./openingSagas";

import {LOAD_TAGS_REQUEST, SAVE_TAGS_SUCCESS, SAVE_TAGS_REQUEST} from "../reducers/tagReducer";
import {loadTagsRequest, saveTagsRequest} from "./tagSagas";
import {LOAD_TYPE_REQUEST} from "../reducers/typeReducer";
import {loadTypeRequest} from "./typeSagas";
import {
    DELETE_WAITER_REQUEST, LOAD_WAITER_REQUEST, SAVE_WAITER_REQUEST,
    SAVE_WAITER_SUCCESS, WAITER_CHANGE_PER_PAGE, WAITER_FILTER_TABLE, WAITER_INITIALIZE_DATATABLE, WAITER_NEXT_PAGE,
    WAITER_PREVIOUS_PAGE,
    WAITER_SORT_TABLE,
    SEND_WAITER_WELCOME_SUCCESS, DELETE_WAITER_SUCCESS, SEND_WAITER_WELCOME_REQUEST, UNSET_NEW_WAITER,
    GET_WAITER_SUCCESS
} from "../reducers/waiterReducer";
import {
    loadWaitersRequest, saveWaiterRequest, deleteWaiterRequest, sendWaiterWelcomeRequest,
    getWaiterRequest
} from "./waiterSagas";
import {
    PATCH_USER_REQUEST,
    PATCH_USER_SUCCESS,
    PATCH_USER_ERROR,
    LOAD_USERS_REQUEST,
    SAVE_USER_REQUEST,
    SAVE_USER_SUCCESS,
    USER_CHANGE_PER_PAGE,
    USER_INITIALIZE_DATATABLE,
    USER_NEXT_PAGE,
    USER_PREVIOUS_PAGE,
    USER_SORT_TABLE,
    USER_FILTER_TABLE
} from "../reducers/userReducer";
import {deleteUserRequest, loadUsersRequest, patchUserRequest, saveUserRequest} from "./userSagas";
import { loadCheckinRequest, patchCheckinRequest } from "./checkinSagas";
import { DOWNLOAD_PAYMENTS_REQUEST,
    LOAD_PAYMENTS_REQUEST, PAYMENT_CHANGE_PER_PAGE, PAYMENT_FILTER_TABLE, PAYMENT_INITIALIZE_DATATABLE,
    PAYMENT_NEXT_PAGE,
    PAYMENT_PREVIOUS_PAGE, PAYMENT_SORT_TABLE
} from "../reducers/paymentReducer";
import {createPaymentsRequest, downloadPaymentsRequest, loadPaymentsRequest} from "./paymentSagas";
import {
    CUSTOMER_CHANGE_PER_PAGE,
    CUSTOMER_FILTER_TABLE,
    CUSTOMER_INITIALIZE_DATATABLE,
    CUSTOMER_NEXT_PAGE,
    CUSTOMER_PREVIOUS_PAGE,
    CUSTOMER_SORT_TABLE,
    LOAD_CUSTOMERS_REQUEST,
    LOAD_SIMPLE_CUSTOMERS_REQUEST,
    PATCH_CUSTOMER_REQUEST,
    PATCH_CUSTOMER_RESULT,
    SAVE_CUSTOMER_REQUEST,
    SAVE_CUSTOMER_RESULT,
} from "../reducers/customerReducer";
import {
    loadCustomersFilterByIdRequest,
    loadCustomersFilterRequest,
    loadCustomersRequest, loadCustomersSimpleRequest, patchCustomerRequest, patchCustomersFilterRequest,
    saveCustomerRequest, saveCustomersFilterRequest
} from "./customerSagas";
import {
    GET_NOTIFICATION_REQUEST, SEND_NOTIFICATION_REQUEST,
    SEND_NOTIFICATION_SUCCESS
} from "../reducers/notificationReducer";
import {getNotificationRequest, sendNotificationRequest} from "./notificationSagas";
import {
    ACCOUNTING_DETAIL_PDF_REQUEST, ACCOUNTING_MONTHS_FILTER_TABLE, ACCOUNTING_MONTHS_SORT_TABLE,
    ACCOUNTING_PDF_REQUEST, ACCOUNTING_PDF_SUMMARY_REQUEST, ACCOUNTING_PDF_ZIP_REQUEST,
    ACCOUNTING_REQUEST, ACCOUNTING_STRIPE_TRANSFER_REQUEST, ACCOUNTING_STRIPE_TRANSFER_SUCCESS, ACCOUNTING_SUCCESS,
    LOAD_ACCOUNTED_MONTHS_REQUEST,
    LOAD_ACTIVE_MONTH_REQUEST
} from "../reducers/accountingReducer";
import {
    accountingDetailsPdfRequest,
    accountingPdfRequest,
    accountingPdfSummaryRequest,
    accountingPdfZipRequest,
    accountingRequest,
    accountingStripeTransferRequest,
    loadAccountedMonthsRequest,
    loadActiveMonthRequest
} from "./accountingSagas";
import {
    CREATE_VOUCHER_REQUEST, CREATE_VOUCHER_SUCCESS, LOAD_VOUCHER_REQUEST, LOAD_VOUCHERS_REQUEST, MAIL_VOUCHER_REQUEST,
    MAIL_VOUCHER_SUCCESS,
    PATCH_VOUCHER_REQUEST,
    PATCH_VOUCHER_SUCCESS,
    VOUCHER_CHANGE_PER_PAGE, VOUCHER_FILTER_TABLE,
    VOUCHER_INITIALIZE_DATATABLE, VOUCHER_NEXT_PAGE, VOUCHER_PREVIOUS_PAGE, VOUCHER_SORT_TABLE
} from "../reducers/voucherReducer";
import {
    loadVoucherRequest, loadVouchersRequest, mailVoucherRequest, patchVoucherRequest,
    saveVoucherRequest
} from "./voucherSagas";
import {
    loadSettingsRequest,
    loadStripeSessionsRequest,
    stripePriceRequest,
    updateSettingsRequest, updateStripeSessionRequest
} from "./settingsSagas";
import {
    LOAD_SETTINGS_REQUEST, LOAD_STRIPE_SESSION_REQUEST,
    STRIPE_PRICE_REQUEST,
    UPDATE_SETTINGS_REQUEST,
    UPDATE_SETTINGS_SUCCESS, UPDATE_STRIPE_SESSION_ERROR, UPDATE_STRIPE_SESSION_REQUEST, UPDATE_STRIPE_SESSION_SUCCESS
} from "../reducers/settingsReducer";
import {CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, GET_ACCOUNT_REQUEST} from "../reducers/stripeReducer";
import {createAccount, getAccount} from "./stripeSagas";
import {loadHelloRequest, loadHellosRequest, patchHelloRequest} from "./helloSagas";
import {
    HELLO_CHANGE_PER_PAGE, HELLO_FILTER_TABLE, HELLO_INITIALIZE_DATATABLE, HELLO_NEXT_PAGE, HELLO_PREVIOUS_PAGE,
    HELLO_SORT_TABLE, LOAD_HELLO_REQUEST,
    LOAD_HELLOS_REQUEST,
    PATCH_HELLO_REQUEST, PATCH_HELLO_SUCCESS
} from "../reducers/helloReducer";
import {
    FILTER_CHANGE_PER_PAGE,
    FILTER_FILTER_TABLE,
    FILTER_INITIALIZE_DATATABLE,
    FILTER_NEXT_PAGE,
    FILTER_PREVIOUS_PAGE,
    FILTER_SORT_TABLE,
    LOAD_EXTENDED_FILTERS_REQUEST,
    LOAD_FILTERBYID_REQUEST,
    LOAD_FILTERS_REQUEST,
    PATCH_FILTER_REQUEST, PATCH_FILTER_SUCCESS,
    SAVE_FILTERS_REQUEST, SAVE_FILTERS_SUCCESS
} from "../reducers/filtersReducer";
import {
    loadExtendedFilterRequest,
    loadFilterByIdRequest,
    loadFilterRequest,
    patchFilterRequest,
    saveFilterRequest
} from "./filterSagas";
import { 
    LOAD_CHECKINS_REQUEST, 
    CHECKIN_FILTER_TABLE, 
    CHECKIN_SORT_TABLE, 
    CHECKIN_NEXT_PAGE, 
    CHECKIN_PREVIOUS_PAGE, 
    CHECKIN_CHANGE_PER_PAGE,
    PATCH_CHECKIN_REQUEST,
    PATCH_CHECKIN_SUCCESS,
} from '../reducers/checkinReducer';

export default function* root() {
    yield all([

        /* Login */
        takeLatest(LOGIN_REQUEST, login, api),
        takeLatest(LOGIN_SUCCESS, success),
        takeLatest(LOGIN_SUCCESS, loadUser, api),
        takeLatest(SET_CURRENT_USER, setCurrentUser),
        takeLatest(LOAD_CURRENT_USER_REQUEST, loadUser, api),
        takeLatest(LOAD_CURRENT_USER_SUCCESS, loadUserSuccess),

        /* Restaurants */
        takeLatest(LOAD_RESTAURANT_REQUEST, loadRestaurantRequest, api),
        takeLatest(SAVE_RESTAURANT_REQUEST, saveRestaurantRequest, api),
        takeLatest(SAVE_RESTAURANT_RKSV_REQUEST, saveRestaurantRksvRequest, api),
        takeLatest(DELETE_RESTAURANT_REQUEST, deleteRestaurantRequest, api),
        takeLatest(SET_ACTIVE_RESTAURANT, getActiveRestaurant, api),

        takeLatest(SET_PROFILPICTURE_REQUEST, setProfilPictureRequest, api),

        takeLatest([
            RESTAURANT_INITIALIZE_DATATABLE,
            RESTAURANT_NEXT_PAGE,
            RESTAURANT_PREVIOUS_PAGE,
            RESTAURANT_CHANGE_PER_PAGE,
            RESTAURANT_SORT_TABLE,
            RESTAURANT_FILTER_TABLE
        ], loadRestaurantRequest, api),

        takeLatest([
            SET_PROFILPICTURE_SUCCESS
        ], reloadActiveRestaurant, api),

        takeLatest([LOAD_RESTAURANT_USER_REQUEST], loadRestaurantUserRequest, api),
        takeLatest(SAVE_RESTAURANT_USER_REQUEST, saveRestaurantUserRequest, api),

        /* Files */
        takeLatest(UPLOAD_FILE_REQUEST, uploadFileRequest, api),
        takeLatest(LOAD_FILE_TYPE_REQUEST, loadFileTypeRequest, api),
        takeLatest(GET_FILE_REQUEST, getFileRequest, api),
        takeLatest(UPDATE_FILE_REQUEST, updateFileRequest, api),
        takeLatest(DELETE_FILE_REQUEST, deleteFileRequest, api),

        takeLatest( MEDIA_LIBRARY_REQUEST, loadMediaLibraryRequest, api),

        takeLatest([
            UPLOAD_FILE_SUCCESS,
            UPDATE_FILE_SUCCESS,
            DELETE_FILE_SUCCESS,
        ], reloadActiveRestaurant, api),

        /* Posts */
        takeLatest(LOAD_POST_REQUEST, loadPostsRequest, api),
        takeLatest(SAVE_POST_REQUEST, savePostRequest, api),
        takeLatest(DELETE_POST_REQUEST, deletePostRequest, api),

        takeLatest([
            POST_INITIALIZE_DATATABLE,
            POST_NEXT_PAGE,
            POST_PREVIOUS_PAGE,
            POST_CHANGE_PER_PAGE,
            POST_SORT_TABLE,
            POST_FILTER_TABLE
        ], loadPostsRequest, api),


        takeLatest([
            DELETE_POST_SUCCESS,
            SAVE_POST_SUCCESS,
        ], loadPostsRequest, api),


        takeLatest([ SET_POST_PICTURES,
        ], reloadActivePost),

        /* OpeningHours */

        takeLatest( LOAD_OPENING_REQUEST, loadOpeningHoursRequest, api),

        takeLatest(SAVE_OPENING_REQUEST, saveOpeningHoursRequest, api),

        /* Tags */

        takeLatest(LOAD_TAGS_REQUEST, loadTagsRequest, api),

        takeLatest(SAVE_TAGS_REQUEST, saveTagsRequest, api),

        takeLatest([
            SAVE_TAGS_SUCCESS,
            ], loadTagsRequest, api),

        /* Types */

        takeLatest(LOAD_TYPE_REQUEST, loadTypeRequest, api),

        /*Waiters*/
        takeLatest([
            LOAD_WAITER_REQUEST,
            SAVE_WAITER_SUCCESS,
            DELETE_WAITER_SUCCESS,
            WAITER_INITIALIZE_DATATABLE,
            WAITER_NEXT_PAGE,
            WAITER_PREVIOUS_PAGE,
            WAITER_CHANGE_PER_PAGE,
            WAITER_SORT_TABLE,
            WAITER_FILTER_TABLE,
            SEND_WAITER_WELCOME_SUCCESS,
        ], loadWaitersRequest, api),

        takeLatest(SAVE_WAITER_REQUEST, saveWaiterRequest, api),
        takeLatest(DELETE_WAITER_REQUEST, deleteWaiterRequest, api),
        takeLatest(SEND_WAITER_WELCOME_REQUEST, sendWaiterWelcomeRequest, api),
        takeLatest(SEND_WAITER_WELCOME_SUCCESS, getWaiterRequest, api),

        /*Users*/
        takeLatest([
            LOAD_USERS_REQUEST,
            SAVE_USER_SUCCESS,
            PATCH_USER_SUCCESS,
            USER_INITIALIZE_DATATABLE,
            USER_NEXT_PAGE,
            USER_PREVIOUS_PAGE,
            USER_CHANGE_PER_PAGE,
            USER_SORT_TABLE,
        ], loadUsersRequest, api),

        takeLatest(SAVE_USER_REQUEST, saveUserRequest, api),
        takeLatest(PATCH_USER_REQUEST, patchUserRequest, api),

        takeLatest(USER_FILTER_TABLE, loadUsersRequest, api),

        /* Check Ins */
        takeLatest([LOAD_CHECKINS_REQUEST, 
            CHECKIN_FILTER_TABLE,
            CHECKIN_SORT_TABLE,
            CHECKIN_NEXT_PAGE,
            CHECKIN_PREVIOUS_PAGE,
            CHECKIN_CHANGE_PER_PAGE,
            PATCH_CHECKIN_SUCCESS,
        ], loadCheckinRequest, api),

        takeLatest([PATCH_CHECKIN_REQUEST], patchCheckinRequest, api),

        /*Payments*/
        takeLatest([
            LOAD_PAYMENTS_REQUEST,
            PAYMENT_INITIALIZE_DATATABLE,
            PAYMENT_NEXT_PAGE,
            PAYMENT_PREVIOUS_PAGE,
            PAYMENT_CHANGE_PER_PAGE,
            PAYMENT_SORT_TABLE,
            PAYMENT_FILTER_TABLE
        ],loadPaymentsRequest, api),
        takeLatest(DOWNLOAD_PAYMENTS_REQUEST, downloadPaymentsRequest, api),

        /*Customers*/
        takeLatest([LOAD_CUSTOMERS_REQUEST,
            SAVE_CUSTOMER_RESULT,
            PATCH_CUSTOMER_RESULT,
            CUSTOMER_INITIALIZE_DATATABLE,
            CUSTOMER_NEXT_PAGE,
            CUSTOMER_PREVIOUS_PAGE,
            CUSTOMER_CHANGE_PER_PAGE,
            CUSTOMER_FILTER_TABLE,
            CUSTOMER_SORT_TABLE], loadCustomersRequest, api),
        takeLatest(SAVE_CUSTOMER_REQUEST, saveCustomerRequest, api),
        takeLatest(PATCH_CUSTOMER_REQUEST, patchCustomerRequest, api),
        takeLatest([LOAD_SIMPLE_CUSTOMERS_REQUEST], loadCustomersSimpleRequest, api),

        /* Filters */
        takeLatest([LOAD_FILTERS_REQUEST,
            FILTER_INITIALIZE_DATATABLE,
            FILTER_NEXT_PAGE,
            FILTER_PREVIOUS_PAGE,
            FILTER_CHANGE_PER_PAGE,
            FILTER_FILTER_TABLE,
            PATCH_FILTER_SUCCESS,
            FILTER_SORT_TABLE], loadFilterRequest, api),
        takeLatest(SAVE_FILTERS_REQUEST, saveFilterRequest, api),
        takeLatest(PATCH_FILTER_REQUEST, patchFilterRequest, api),
        takeLatest(LOAD_FILTERBYID_REQUEST, loadFilterByIdRequest, api),

        takeLatest([LOAD_EXTENDED_FILTERS_REQUEST, SAVE_FILTERS_SUCCESS, PATCH_FILTER_SUCCESS], loadExtendedFilterRequest, api),
        takeLatest([PATCH_FILTER_SUCCESS], loadFilterRequest, api),



        /* Notifications */
        takeLatest(SEND_NOTIFICATION_REQUEST, sendNotificationRequest, api),
        takeLatest(GET_NOTIFICATION_REQUEST, getNotificationRequest, api),
        takeLatest(SEND_NOTIFICATION_SUCCESS, getNotificationRequest, api),

        /* Accountings*/
        takeLatest([ACCOUNTING_MONTHS_FILTER_TABLE, ACCOUNTING_MONTHS_SORT_TABLE, LOAD_ACCOUNTED_MONTHS_REQUEST, ACCOUNTING_SUCCESS], loadAccountedMonthsRequest, api),
        takeLatest([LOAD_ACTIVE_MONTH_REQUEST], loadActiveMonthRequest, api),
        takeLatest(ACCOUNTING_REQUEST, accountingRequest, api),
        takeLatest(ACCOUNTING_PDF_REQUEST, accountingPdfRequest, api),
        takeLatest(ACCOUNTING_PDF_ZIP_REQUEST, accountingPdfZipRequest, api),
        takeLatest(ACCOUNTING_STRIPE_TRANSFER_REQUEST, accountingStripeTransferRequest, api),
        takeLatest(ACCOUNTING_DETAIL_PDF_REQUEST, accountingDetailsPdfRequest, api),
        takeLatest(ACCOUNTING_PDF_SUMMARY_REQUEST, accountingPdfSummaryRequest, api),


        /* Vouchers */
        takeLatest([LOAD_VOUCHERS_REQUEST, MAIL_VOUCHER_SUCCESS], loadVouchersRequest, api),
        takeLatest(CREATE_VOUCHER_REQUEST, saveVoucherRequest, api),
        takeLatest(PATCH_VOUCHER_REQUEST, patchVoucherRequest, api),
        takeLatest(MAIL_VOUCHER_REQUEST, mailVoucherRequest, api),

        takeLatest(CREATE_VOUCHER_SUCCESS, loadVouchersRequest, api),
        takeLatest(PATCH_VOUCHER_SUCCESS, loadVouchersRequest, api),

        takeLatest(LOAD_VOUCHER_REQUEST, loadVoucherRequest, api),

        /* Settings */
        takeLatest(LOAD_SETTINGS_REQUEST, loadSettingsRequest, api),
        takeLatest(UPDATE_SETTINGS_REQUEST, updateSettingsRequest, api),
        takeLatest(UPDATE_SETTINGS_SUCCESS, loadSettingsRequest, api),
        takeLatest(STRIPE_PRICE_REQUEST, stripePriceRequest, api),
        takeLatest([LOAD_STRIPE_SESSION_REQUEST, UPDATE_STRIPE_SESSION_SUCCESS, UPDATE_STRIPE_SESSION_ERROR], loadStripeSessionsRequest, api),
        takeLatest(UPDATE_STRIPE_SESSION_REQUEST, updateStripeSessionRequest, api),



        takeLatest([
            VOUCHER_INITIALIZE_DATATABLE,
            VOUCHER_NEXT_PAGE,
            VOUCHER_PREVIOUS_PAGE,
            VOUCHER_CHANGE_PER_PAGE,
            VOUCHER_SORT_TABLE,
            VOUCHER_FILTER_TABLE
        ], loadVouchersRequest, api),

        /* Stripe */
        takeLatest(CREATE_ACCOUNT_REQUEST, createAccount, api),
        takeLatest(GET_ACCOUNT_REQUEST, getAccount, api),
        takeLatest(CREATE_ACCOUNT_SUCCESS, getAccount, api),


        /* Hellos */
        takeLatest([LOAD_HELLOS_REQUEST, PATCH_HELLO_SUCCESS], loadHellosRequest, api),
        takeLatest(PATCH_HELLO_REQUEST, patchHelloRequest, api),
        takeLatest([LOAD_HELLO_REQUEST], loadHelloRequest, api),

        takeLatest([
            HELLO_INITIALIZE_DATATABLE,
            HELLO_NEXT_PAGE,
            HELLO_PREVIOUS_PAGE,
            HELLO_CHANGE_PER_PAGE,
            HELLO_SORT_TABLE,
            HELLO_FILTER_TABLE
        ], loadHellosRequest, api),

    ]);
}