import React from 'react';
import {
    Group as GroupIcon,
    House as HouseIcon,
    Settings as SettingsIcon,
    Person as PersonIcon,
    Receipt as ReceiptIcon,
    PieChart as PieChartIcon,
    InsertPhoto as InsertPhotoIcon,
    Send as SendIcon,
    More as MoreIcon,
    LocalOffer as LocalOfferIcon,
    NewReleases as NewReleasesIcon,
    FilterList as FilterListIcon,
    Euro as EuroIcon,
    AvTimer,
    SettingsPower,
} from '@material-ui/icons';

export default {
    items: [
        {
            name: 'Restaurants',
            url: '/intern/restaurants',
            icon: <HouseIcon/>,
            key: 'restaurants'
        },
        {
            name: '7user',
            url: '/intern/customers',
            icon: <GroupIcon/>,
            key: 'users',
        },
        {
            name: 'Gutscheine',
            url: '/intern/vouchers',
            icon: <LocalOfferIcon/>,
            key: 'vouchers'
        },
        {
            name: 'Umsätze',
            url: '/intern/payments',
            icon: <ReceiptIcon/>,
            key: 'payments',
        },
        {
            name: 'Mein Restaurant',
            url: '/intern/mein-restaurant',
            icon: <HouseIcon/>,
            key: 'my-restaurant'
        },
        {
            name: 'Abrechnung',
            url: '/intern/accountings',
            icon: <PieChartIcon/>,
            key: 'accountings'
        },
        {
            name: 'Live',
            url: '/intern/live',
            icon: <AvTimer/>,
            key: 'live'
        },
        {
            divider: true,
            key: 'divider'
        },
        {
            name: 'Notifications',
            url: '/intern/notifications',
            icon: <SendIcon/>,
            key: 'notifications',
        },
        {
            name: 'Filtermanagement',
            url: '/intern/filters',
            icon: <FilterListIcon/>,
            key: 'filters',
        },
        {
            name: 'Empfehlungen',
            url: '/intern/hellos',
            icon: <NewReleasesIcon/>,
            key: 'hellos',
        },
        {
            divider: true,
            key: 'divider'
        },
        {
            name: 'Tags',
            url: '/intern/tags',
            icon: <MoreIcon/>,
            key: 'tags',
        },
        {
            name: 'Backend Users',
            url: '/intern/users',
            icon: <PersonIcon/>,
            key: 'backendUsers',
        },
        {
            name: 'Einstellungen',
            url: '/intern/settings',
            icon: <SettingsIcon/>,
            key: 'settings'
        },        
        {
            name: 'Wartung',
            url: '/intern/wartung',
            icon: <SettingsPower/>,
            key: 'maintenance'
        },
        {
            name: 'Cash Management',
            url: '/intern/cash',
            icon: <EuroIcon/>,
            key: 'cash'
        },
    ]
};
