import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Grid} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import MonthSelect from "../../../components/Filter/MonthSelect";
import YearSelect from "../../../components/Filter/YearSelect";
import {monthsFilterTable} from "../../../actions/accountingActions";
import MonthYearSelect from "../../../components/Filter/MonthYearSelect";

function CustomerStatisticsFilter(){

    return (
        <Fragment>
            <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                <Grid item md={3} xs={12}>
                    <YearSelect
                        fieldName={'year'}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        initialValues: {
            year: 'Alle'
        },
    }
}
const mapDispatchToProps = {};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'customer-statistics-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {
            props.setFilter({
                values: {
                    year: values.year && values.year != 'Alle' ? values.year : null
                }
            })
        },
    }),
    (withStyles(style)),
)(CustomerStatisticsFilter)