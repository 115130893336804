import {put, call, select} from "@redux-saga/core/effects";
import {
    loadTagSuccess, saveTagSuccess
} from "../actions/tagActions";
import {addFlashMessage} from "../actions/flashMessageActions";

export function* loadTagsRequest (api) {
    try {
        let result = yield call(api.loadTag);
        if (result) {

            yield put(loadTagSuccess({tags: result.data.tags, kitchen: result.data.kitchen}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveTagsRequest (api, data) {
    try {
        console.log(data);
        let result = yield call(api.saveTag, data);

        if (result && result.data && result.data.success) {
            yield put(saveTagSuccess());
            yield put(addFlashMessage({message:'Tags wurden gespeichert', style:'success'}))
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}
