import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import HellosView from './HellosView';
import {Dialog, IconButton, AppBar, Toolbar, Typography, Grid} from "@material-ui/core";
import HellosForm from './HellosForm';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {loadHellosRequest, setNewHello, unsetActiveHello, unsetNewHello} from "../../../actions/helloActions";
import {loadCustomersRequest, loadSimpleCustomersRequest} from "../../../actions/customerActions";


class Hellos extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.loadHellos();
    }

    render() {
        const { classes, activeHello, newHello } = this.props;

        return (
            <Fragment>
                <HellosView/>
                <Dialog fullScreen open={activeHello || newHello} >
                    <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={activeHello?this.props.unsetActiveHello:this.props.unsetNewHello} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {activeHello ? 'Empfehlung bearbeiten' : 'Neue Empfehlung erstellen'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container style={{justifyContent:'center'}}>
                        <Grid item xs={6}>
                            <HellosForm isNew={!activeHello}/>
                        </Grid>
                    </Grid>
                </Dialog>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        activeHello: state.hello.activeHello,
        newHello: state.hello.newHello,

    }
}
const mapDispatchToProps = {
    setNewHello: setNewHello,
    unsetActiveHello: unsetActiveHello,
    unsetNewHello: unsetNewHello,
    loadHellos: loadHellosRequest,
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Hellos));