import { call, put } from 'redux-saga/effects'
import {loginSuccess, loginFailed, setCurrentUser, loadCurrentUserSuccess} from '../actions/authActions';
import setAuthorizationToken from "../utils/setAuthorizationToken";


export function* login (api, { email, password }) {
    try {
        const result = yield call(api.login, email, password);

        if (result.data.success) {
            yield put(loginSuccess(result.data))
        } else {
            yield put(loginFailed(result.data.error))
        }
    } catch (e) {
        yield put(loginFailed(e))
    }
}

export function* success (data) {
    //set auth token to local storage
    localStorage.setItem('jwtToken', data.result.token);

    //set auth token to axios req header
    setAuthorizationToken(data.result.token);

    //dispatch action to set user in state
    yield put (setCurrentUser(data.result.user));

}

export function* loadUser(api){
    try{
        const result = yield call(api.loadUser);
        if (result.data.success) {
            yield put (loadCurrentUserSuccess(result.data.user))
        }
    } catch(e) {
        yield put (loginFailed(e))
    }
}

export function* loadUserSuccess({user}) {
    yield put (setCurrentUser(user))
}