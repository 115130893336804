import React from "react";
import {MenuItem} from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../SelectGroup";
import moment from "moment";

const required = value => value ? null : 'Feld erforderlich';

const MonthSelect = (props) => {

    const options = [<MenuItem value={'Alle'}>Alle</MenuItem>];
    let now = moment();
    for(var i=0; i<12; i++){
        now.set('month', i);
        options.push(<MenuItem value={i+1}>{now.format('MMMM')}</MenuItem>)
    }

    return <Field
        name={props.fieldName}
        label="Monat"
        disabled={!!props.disabled}
        component={SelectGroup}
        margin="dense"
        fullWidth
        validate={props.required && [ required ]}
    >
        {options}
    </Field>;

};

export default MonthSelect;