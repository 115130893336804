import React, { Component, Fragment } from 'react';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {Grid, Button} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {loadSettingsRequest, updateSettingsRequest} from "../../../actions/settingsActions";
import {connect} from "react-redux";

class Maintenance extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.loadSettings();
  }
  startServiceTime = () => {
    this.props.updateSettings({service_mode: true});
  };

  stopServiceTime = () => {
      this.props.updateSettings({service_mode: false});
  };
  
  render(){
    const { classes, settings } = this.props;
    return (
      <Fragment>
          {(settings && settings.service_mode == false) ?
              <Grid container className={classes.fragmentContainer}>
                  <Grid item xs={12}>
                      <h1><CheckCircle style={{color: '#4CD964', marginRight: '5px', fontSize: '25px'}}/>System aktiv</h1>
                  </Grid>
                  <Grid item xs={12}>
                      <div>Das System läuft im Moment. Um ein Wartungsfenster zu starten, klicken Sie auf den Button.
                          Achtung: während eines Wartungsfensters können die mobilen Apps nicht verwendet werden.
                      </div>
                  </Grid>
                  <Grid item xs={12}>
                      <Button fullWidth style={{marginTop: '50px', backgroundColor: '#FF3B30', color: 'white'}}
                              onClick={this.startServiceTime}>
                          Wartung starten
                      </Button>
                  </Grid>

              </Grid> : [
                  <Grid container className={classes.fragmentContainer} style={{}}>
                      <Grid item xs={12}>
                          <h1><Error style={{color: '#FF3B30', marginRight: '5px', fontSize: '25px'}}/>Wartungsmodus</h1>
                      </Grid>
                      <Grid item xs={12}>
                          <div>Das System befindet sich im Wartungsmodus. In dieser Zeit können Sie spezielle Einstellungen tätigen.
                          </div>
                      </Grid>
                      <Grid item xs={12}>
                          <Button fullWidth style={{marginTop: '50px'}} onClick={this.stopServiceTime} variant={'outlined'} color="default">
                              Wartung beenden
                          </Button>
                      </Grid>

                  </Grid>,
              ]
          }
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
      settings: state.settings.data
  }
}

const mapDispatchToProps = {
  loadSettings: loadSettingsRequest,
  updateSettings: updateSettingsRequest
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Maintenance));