import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Field, reduxForm, reset } from "redux-form";
import {compose} from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextFieldGroup from "../../../components/TextFieldGroup";
import {filterTable} from "../../../actions/customerActions";
import MultipleSelect from "../../../components/MultipleSelect";
import DateRangeFilter from "../../../components/Filter/DateRangeFilter";
import DiscountSelect from "../../../components/Filter/DiscountSelect";
import {loadSettingsRequest} from "../../../actions/settingsActions";

class CustomersFilter extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.loadSettings();
    }

    changeSearch = (values) => {
        this.props.filterTable({
            values:{
                searchValue: values.search,
                fields: ['firstname', 'lastname', 'email'],
                table: 'customers'
            }
        });
    };

    statusOptions = () => {
      return [
          {_id: 'active', name: 'Aktiv'},
          {_id: 'registration_code_sent', name: 'Registrierungs Mail versendet'},
          {_id: 'activation_code_sent', name: 'Mail versendet'},
          {_id: 'registered', name: 'Registriert'},
          {_id: 'inactive', name: 'Inaktiv'},
          {_id: 'deleted', name: 'Gelöscht'},
      ]
    };

    render() {
        const { settings } = this.props;
        return (
            <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                <Grid item md={'auto'} xs={'auto'}>
                    <Field
                        name="search"
                        label="Suche"
                        component={TextFieldGroup}
                        margin="dense"
                        fullWidth={true}
                        onChange = {this.changeSearch}
                    />
                </Grid>
                <Grid item md={'auto'} xs={'auto'}  style={{minWidth: 100}}>
                    <Field
                        format={value => Array.isArray(value) ? value : []}
                        name="status"
                        component={MultipleSelect}
                        label={'Status'}
                        fullWidth
                        accessorValue={"_id"}
                        accessorLabel={'name'}
                        options={this.statusOptions()}
                    />
                </Grid>
                <Grid item md={'auto'} xs={'auto'}>
                    <Field
                        name="number"
                        label="Kundennummer"
                        component={TextFieldGroup}
                        margin="dense"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item md={'auto'} xs={'auto'} style={{minWidth: 150}}>
                    <DiscountSelect
                        fieldName={'discount'}
                        settings={settings}
                    />
                </Grid>
                <Grid item md={'auto'} xs={'auto'}>
                    <DateRangeFilter
                        labelFrom={'Erstellt am von'}
                        labelTo={'Erstellt am bis'}
                        fieldNameFrom={'created_at_from'}
                        fieldNameTo={'created_at_to'}
                    />
                </Grid>
                <Grid item md={3}>
                    <Button variant={'outlined'} style={{marginTop: '20px'}} secondary onClick={this.props.reset}>
                        Reset
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        initialValues: {
            status: ['active', 'activation_code_sent'],
            discount: null,
        },
        settings: state.settings.data,
    }
}

const mapDispatchToProps = {
    filterTable: filterTable,
    reset: reset('customersFilter-form'),
    loadSettings: loadSettingsRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'customersFilter-form',
        onChange: (values, dispatch, props) => {

            let created_at_time_range = [];
            if(values.created_at_from && values.created_at_to){
                created_at_time_range = [values.created_at_from, values.created_at_to];
            }

            dispatch(props.filterTable({
                values:{
                    searchValue: values.search,
                    fields: ['firstname', 'lastname', 'email'],
                    table: 'customers',
                    status: values.status?values.status:null,
                    discount: values.discount?values.discount:null,
                    created_at: created_at_time_range,
                    number: values.number?values.number:null,
                }
            }))
        },

    }),
    withStyles(style)
)(CustomersFilter)


