
/* Action types */
import {PAYMENT_CHANGE_PER_PAGE, PAYMENT_PREVIOUS_PAGE, PAYMENT_SORT_TABLE} from "./paymentReducer";

const PREFIX = "accounting/";

export const LOAD_ACCOUNTED_MONTHS_REQUEST = PREFIX + "LOAD_ACCOUNTED_MONTHS_REQUEST";
export const LOAD_ACCOUNTED_MONTHS_SUCCESS = PREFIX + "LOAD_ACCOUNTED_MONTHS_SUCCESS";

export const SET_ACCOUNTED_MONTH = PREFIX + "SET_ACCOUNTED_MONTH";
export const UNSET_ACCOUNTED_MONTH = PREFIX + "UNSET_ACCOUNTED_MONTH";

export const LOAD_ACTIVE_MONTH_REQUEST = PREFIX + "LOAD_ACTIVE_MONTH_REQUEST";
export const LOAD_ACTIVE_MONTH_SUCCESS = PREFIX + "LOAD_ACTIVE_MONTH_SUCCESS";

export const ACCOUNTING_REQUEST = PREFIX + "ACCOUNTING_REQUEST";
export const ACCOUNTING_SUCCESS = PREFIX + "ACCOUNTING_SUCCESS";
export const ACCOUNTING_ERROR = PREFIX + "ACCOUNTING_ERROR";

export const ACCOUNTING_MONTHS_INITIALIZE_DATATABLE = PREFIX + "ACCOUNTING_MONTHS_INITIALIZE_DATATABLE";
export const ACCOUNTING_MONTHS_NEXT_PAGE = PREFIX + "ACCOUNTING_MONTHS_NEXT_PAGE";
export const ACCOUNTING_MONTHS_PREVIOUS_PAGE = PREFIX + "ACCOUNTING_MONTHS_PREVIOUS_PAGE";
export const ACCOUNTING_MONTHS_CHANGE_PER_PAGE = PREFIX + "ACCOUNTING_MONTHS_CHANGE_PER_PAGE";
export const ACCOUNTING_MONTHS_SORT_TABLE = PREFIX + "ACCOUNTING_MONTHS_SORT_TABLE";
export const ACCOUNTING_MONTHS_FILTER_TABLE = PREFIX + "ACCOUNTING_MONTHS_FILTER_TABLE";

export const ACCOUNTING_DETAILS_INITIALIZE_DATATABLE = PREFIX + "ACCOUNTING_DETAILS_INITIALIZE_DATATABLE";
export const ACCOUNTING_DETAILS_NEXT_PAGE = PREFIX + "ACCOUNTING_DETAILS_NEXT_PAGE";
export const ACCOUNTING_DETAILS_PREVIOUS_PAGE = PREFIX + "ACCOUNTING_DETAILS_PREVIOUS_PAGE";
export const ACCOUNTING_DETAILS_CHANGE_PER_PAGE = PREFIX + "ACCOUNTING_DETAILS_CHANGE_PER_PAGE";
export const ACCOUNTING_DETAILS_SORT_TABLE = PREFIX + "ACCOUNTING_DETAILS_SORT_TABLE";
export const ACCOUNTING_DETAILS_FILTER_TABLE = PREFIX + "ACCOUNTING_DETAILS_FILTER_TABLE";

export const ACCOUNTING_PDF_REQUEST = PREFIX + "ACCOUNTING_PDF_REQUEST";
export const ACCOUNTING_PDF_SUCCESS = PREFIX + "ACCOUNTING_PDF_SUCCESS";
export const ACCOUNTING_PDF_ERROR = PREFIX + "ACCOUNTING_PDF_ERROR";

export const ACCOUNTING_DETAIL_PDF_REQUEST = PREFIX + "ACCOUNTING_DETAIL_PDF_REQUEST";
export const ACCOUNTING_DETAIL_PDF_SUCCESS = PREFIX + "ACCOUNTING_DETAIL_PDF_SUCCESS";
export const ACCOUNTING_DETAIL_PDF_ERROR = PREFIX + "ACCOUNTING_DETAIL_PDF_ERROR";

export const ACCOUNTING_PDF_ZIP_REQUEST = PREFIX + "ACCOUNTING_PDF_ZIP_REQUEST";
export const ACCOUNTING_PDF_ZIP_SUCCESS = PREFIX + "ACCOUNTING_PDF_ZIP_SUCCESS";
export const ACCOUNTING_PDF_ZIP_ERROR = PREFIX + "ACCOUNTING_PDF_ZIP_ERROR";

export const ACCOUNTING_STRIPE_TRANSFER_REQUEST = PREFIX + "ACCOUNTING_STRIPE_TRANSFER_REQUEST";
export const ACCOUNTING_STRIPE_TRANSFER_SUCCESS = PREFIX + "ACCOUNTING_STRIPE_TRANSFER_SUCCESS";
export const ACCOUNTING_STRIPE_TRANSFER_ERROR = PREFIX + "ACCOUNTING_STRIPE_TRANSFER_ERROR";

export const ACCOUNTING_PDF_SUMMARY_REQUEST = PREFIX + "ACCOUNTING_PDF_SUMMARY_REQUEST";
export const ACCOUNTING_PDF_SUMMARY_SUCCESS = PREFIX + "ACCOUNTING_PDF_SUMMARY_SUCCESS";
export const ACCOUNTING_PDF_SUMMARY_ERROR = PREFIX + "ACCOUNTING_PDF_SUMMARY_ERROR";

/* initial state */
const initialState = {

    isFetching: false,
    isSaving: false,
    isDeleting: false,

    monthsData: [],
    monthsPage: 1,
    monthsPerPage: 10,
    monthsTotalRecords: '',
    monthsSortDirection: 'desc',
    monthsSortField: 'month',

    accountedMonth: '',
    detailsData: [],
    detailsPage: 1,
    detailsPerPage: 10,
    detailsTotalRecords: '',
    detailsSortDirection: 'desc',
    detailsSortField: 'month',
    filterValues: [],

    pdf: null,
};

/* Reducer */
const paymentReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_ACCOUNTED_MONTHS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_ACCOUNTED_MONTHS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                monthsData: action.accountings,
                monthsTotalRecords: action.totalRecords
            };
        case SET_ACCOUNTED_MONTH:
            return {
                ...state,
                isSaving: true,
                accountedMonth: action.month
            };
        case UNSET_ACCOUNTED_MONTH:
            return {
                ...state,
                isSaving: false,
            };
        case LOAD_ACTIVE_MONTH_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_ACTIVE_MONTH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                detailsData: action.accountings,
                detailsTotalRecords: action.totalRecords
            };
        case ACCOUNTING_MONTHS_INITIALIZE_DATATABLE:
            return {
                ...state,
                monthsPage: action.page,
                monthsPerPage: action.perPage,
                monthsSortDirection: action.sortDirection,
                monthsSortField: action.sortField,
                monthsFilter: action.filter,
            };
        case ACCOUNTING_MONTHS_NEXT_PAGE:
            return {
                ...state,
                monthsPage: state.monthsPage + 1,
            };
        case ACCOUNTING_MONTHS_PREVIOUS_PAGE:
            return {
                ...state,
                monthsPage: state.monthsPage - 1,
            };
        case ACCOUNTING_MONTHS_CHANGE_PER_PAGE:
            return {
                ...state,
                monthsPerPage: action.value,
                monthsPage: 1
            };
        case ACCOUNTING_MONTHS_SORT_TABLE:
            return {
                ...state,
                monthsSortField: action.field,
                monthsSortDirection: action.value,
            };
        case ACCOUNTING_MONTHS_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        case ACCOUNTING_DETAILS_INITIALIZE_DATATABLE:
            return {
                ...state,
                detailsPage: action.page,
                detailsPerPage: action.perPage,
                detailsSortDirection: action.sortDirection,
                detailsSortField: action.sortField,
                detailsFilter: action.filter,
            };
        case ACCOUNTING_DETAILS_NEXT_PAGE:
            return {
                ...state,
                detailsPage: state.detailsPage + 1,
            };
        case ACCOUNTING_DETAILS_PREVIOUS_PAGE:
            return {
                ...state,
                detailsPage: state.detailsPage - 1,
            };
        case ACCOUNTING_DETAILS_CHANGE_PER_PAGE:
            return {
                ...state,
                detailsPerPage: action.value,
                detailsPage: 1
            };
        case ACCOUNTING_DETAILS_SORT_TABLE:
            return {
                ...state,
                detailsSortField: action.field,
                detailsSortDirection: action.value,
            };
        case ACCOUNTING_DETAILS_FILTER_TABLE:
            return {
                ...state,
            };
        case ACCOUNTING_PDF_REQUEST:
            return {
                ...state,
            };
        case ACCOUNTING_PDF_SUCCESS:
            return {
                ...state,
                pdf: action.pdf
            };
        default:
            return state;
    }
};
export default paymentReducer;