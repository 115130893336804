import {put, call, select} from "@redux-saga/core/effects";
import {
  loadTypeSuccess
} from "../actions/typeActions";

export function* loadTypeRequest (api) {
    try {
        let result = false;
        result = yield call(api.loadTypes );

        if (result) {
            yield put(loadTypeSuccess({types: result.data.types}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}


