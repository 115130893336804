import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";


import {
    deleteRestaurantSuccess,
    loadRestaurantSuccess, loadRestaurantUserError,
    loadRestaurantUserSuccess,
    saveRestaurantError,
    saveRestaurantRksvError,
    saveRestaurantRksvSuccess,
    saveRestaurantSuccess,
    saveRestaurantUserError,
    saveRestaurantUserSuccess,
    setActiveRestaurant,
    setProfilPictureSuccess,
    setRestaurant
} from "../actions/restaurantActions";

export function* loadRestaurantRequest (api) {
    try {

        const DataTableOptions = state => state.restaurant;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        const result = yield call(api.loadRestaurant, options);

        if (result) {
            yield put(loadRestaurantSuccess({restaurants: result.data.restaurant, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* getActiveRestaurant (api, id) {
    try {
        const result = yield call(api.getRestaurant, id);

        if (result) {
           yield put(setRestaurant({restaurant: result.data.restaurant}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveRestaurantRequest (api, {data}) {
    let result = false;

    if(data._id && data._id.length){
        result = yield call(api.updateRestaurant, data._id, data);
    }else{
        result = yield call(api.createRestaurant, data);
    }

    if (result && result.data && result.data.success) {
        yield put(saveRestaurantSuccess({restaurant: result.data.restaurant}));
        yield put(setActiveRestaurant(result.data.restaurant._id));
        if(result.data && result.data.warning && result.data.warning.length > 0){
            yield put(addFlashMessage({message: result.data.warning[0].message, style:'warning'}))
        } else {
            yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
        }
    } else {
        yield put(saveRestaurantError({}));
        if(result && result.data && result.data.error == "IBAN_VALIDATION"){
            yield put(addFlashMessage({message:'Fehler beim Speichern - IBAN ungültig', style:'error' }))
        } else {
            yield put(addFlashMessage({message:'Fehler beim Speichern', style:'error'}))
        }
    }
}

export function* saveRestaurantRksvRequest (api, data) {
    let result = yield call(api.updateRestaurantRksv, data._id, data);

    if (result && result.data && result.data.success) {
        yield put(saveRestaurantRksvSuccess({}));
        yield put(setActiveRestaurant(data._id));
        yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
    } else {
        yield put(saveRestaurantRksvError({}));
        yield put(addFlashMessage({message:'Fehler beim Speichern', style:'error'}))
    }
}

export function* saveRestaurantUserRequest (api, data) {
    let result = yield call(api.updateRestaurantUser, data._id, data);

    if (result && result.data && result.data.success) {
        yield put(saveRestaurantUserSuccess({user: result.data.user}));
        yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
    } else {
        yield put(saveRestaurantUserError({}));
        yield put(addFlashMessage({message:'Fehler beim Speichern', style:'error'}))
    }
}

export function* loadRestaurantUserRequest (api, data) {
    let result = yield call(api.loadRestaurantUser, data._id);

    if (result && result.data && result.data.success) {
        yield put(loadRestaurantUserSuccess({user: result.data.user}));
    } else {
        yield put(loadRestaurantUserError({}));
    }
}

export function* deleteRestaurantRequest (api, data) {
    try {
        const result = yield call(api.deleteRestaurant, data.id);

        if (result) {
            yield put(deleteRestaurantSuccess())
            yield put(addFlashMessage({message:'Status wurde geändert', style:'success'}))
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* reloadActiveRestaurant () {
    try {
        const restaurant = state => state.restaurant
        const { activeRestaurantData} = yield select(restaurant)

        if(!!activeRestaurantData && !!activeRestaurantData._id){
            yield put(setActiveRestaurant(activeRestaurantData._id))
        }

    } catch (e) {
        console.log(e)
    }
}

export function* setProfilPictureRequest (api, {restaurant_id, picture_id}) {
    try {
        let result = false;
        if(restaurant_id && restaurant_id && picture_id && picture_id.length ){
            result = yield call(api.setProfilPicture, restaurant_id, picture_id );
        } else {
            result = yield call(api.setProfilPicture, restaurant_id, picture_id );
        }

        if (result) {
            yield put(setProfilPictureSuccess())
            yield put(addFlashMessage({message:'Profilbild wurde geändert', style:'success'}))
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}





