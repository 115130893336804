import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, FieldArray, getFormSubmitErrors, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem,Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import SelectGroup from '../../../components/SelectGroup';
import {patchCustomerRequest, saveCustomerRequest, setActiveCustomer} from "../../../actions/customerActions";
import {formatMoneyFromApi} from "../../../utils/helper";
import {loadSettingsRequest} from "../../../actions/settingsActions";
import {isAllowed} from "../../../utils/permissionHelper";
import {Link} from "react-router-dom";

class CustomersForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            deactivateModal: false
        };
    }

    componentDidMount(){
        this.props.loadSettings();
    }

    required = value => value ? undefined : 'Feld erforderlich';

    getPaymentMethods = () => {
        const { customer } = this.props;
        if(customer && customer.payment_methods && customer.payment_methods.length > 0){
            return customer.payment_methods.map(pm => {
                return <div>{pm.brand} - letzten 4 Stellen: {pm.last4}</div>
            });
        }
        return <div>Keine</div>
    };


    render() {
        const { handleSubmit, classes, customer, settings, pristine, submitFailed, valid } = this.props;

        const submitAll = (values) => {
            if(customer._id && customer._id.length){

                let discount = null;
                if(values.discount != (customer.discount?customer.discount.discount_value:-1)){
                    discount = settings.club7.find(s => {return s.value === values.discount});
                }

                this.props.saveCustomer({
                    _id: customer._id,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    //email: values.email,
                    discount: discount,
                    mobil: values.mobil,
                    address_object: {
                        street: values.street,
                        house_number: values.house_number,
                        zip: values.zip,
                        city: values.city,
                    }
                });
                this.props.setActiveCustomer({activeCustomer: null});
            }
        };

        const patchUser = (action) => {
            if( customer._id ) {
                this.props.patchCustomer({
                    _id: customer._id,
                    action: action
                });
                this.props.setActiveCustomer({activeCustomer: null});
            }
        };

        const discounts = ((settings&&settings.club7?settings.club7:[])).map(d => {
            return <MenuItem value={d.value}>{d.name} ({d.value}%)</MenuItem>;
        });

        return (
            <Grid container className={classes.fragmentContainer}>
                <Grid xs={12}>
                    <form>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="firstname"
                                    label="Vorname"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="lastname"
                                    label="Nachname"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="email"
                                    label="Email"
                                    component={TextFieldGroup}
                                    disabled={true}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="mobil"
                                    label="Telefon"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="number"
                                    label="Kundennummer"
                                    component={TextFieldGroup}
                                    disabled={true}
                                    margin="dense"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={6}>
                                <Field
                                    name="street"
                                    label="Strasse"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                />
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Field
                                    name="house_number"
                                    label="Hausnummer"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="zip"
                                    label="Postleitzahl"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="city"
                                    label="Stadt"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={10}>

                            <Grid item md={6} xs={12}>
                                <Field
                                    name="status"
                                    label="Status"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="discount"
                                    label="Rabatt"
                                    component={SelectGroup}
                                    margin="dense"
                                    fullWidth
                                    validate={[ this.required ]}
                                >
                                    {discounts}
                                </Field>
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={6}>
                                <Field
                                    name="turnoverMonth"
                                    label="Umsatz dieses Monat"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Field
                                    name="turnoverTotal"
                                    label="Umsatz gesamt"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item md={12} xs={12}>
                                <p style={{fontWeight: 'bold'}}>Zahlungsmethoden</p>
                                {this.getPaymentMethods()}
                            </Grid>

                        </Grid>

                        {
                            (!pristine || submitFailed) && !valid && <Grid style={{marginTop:20, marginBottom: 20, backgroundColor: '#f44336', padding: 5}}>
                                <Grid item xs={12} style={{color: '#FFF'}}>
                                    Es ist ein Fehler aufgetreten, überprüfen Sie Ihre Eingaben.
                                </Grid>
                            </Grid>
                        }

                        {
                            customer.status !== 'deleted' &&  <Grid container spacing={5}>
                                {isAllowed(this.props.user, 'users', 'update') &&
                                <Grid item xs={6}>
                                    <Button fullWidth onClick={handleSubmit(submitAll)} variant={'outlined'}
                                            color="primary">
                                        User sichern
                                    </Button>
                                </Grid>
                                }
                                {(isAllowed(this.props.user, 'users', 'delete')) && [
                                    <Grid item xs={6}>
                                        <Button fullWidth onClick={() => { customer.status === 'active' ? this.setState({deactivateModal: true}) : patchUser('enable')
                                        }} variant={'outlined'} color="error">
                                            {customer.status === 'active' ? 'User inaktiv setzen' : 'User aktiv setzen'}
                                        </Button>
                                    </Grid>,
                                    <Grid item xs={12}>
                                        <Button fullWidth onClick={() => {
                                            this.setState({deleteModal: true})
                                        }} variant={'outlined'} color="error">
                                            {'User löschen'}
                                        </Button>
                                    </Grid>
                                ]
                                }
                            </Grid>
                        }

                    </form>
                </Grid>
                {(this.state.deleteModal === true || this.state.deactivateModal === true) &&
                    <Dialog open={true}>
                        <DialogTitle>Sind Sie sicher?</DialogTitle>

                        <DialogContent>{this.state.deactivateModal ? 'Möchten Sie den Eintrag wirklich inaktiv setzen?' : 'Möchten Sie den Eintrag wirklich entfernen?'}</DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({deleteModal: false, deactivateModal: false})}} color="primary">
                                Abbrechen
                            </Button>
                            <Link to={"/intern/customers"}>
                                <Button onClick={() => {this.state.deactivateModal ? patchUser('disable') : patchUser('delete')}} color="primary">
                                    {this.state.deactivateModal ? 'Inaktiv setzen' : 'Entfernen' }
                                </Button>
                            </Link>
                        </DialogActions>

                    </Dialog>
                }
            </Grid>

        );
    }
}

function mapStateToProps(state) {

    const customer = state.customer.activeCustomer;

    return{
        customer: customer,
        settings: state.settings.data,
        user: state.auth.user,
        initialValues:{
            firstname: customer && customer.firstname,
            lastname: customer && customer.lastname,
            email: customer && customer.email,
            mobil: customer && customer.mobil,
            number: customer && customer.number,

            status: (function(){
                if(customer.status == 'active'){
                    return 'Aktiv';
                } else if(customer.status == 'inactive'){
                    return 'Inaktiv';
                } else if(customer.status == 'activation_code_sent'){
                    return 'Mail versendet';
                }
            }()),
            turnoverMonth: (function(){
                if(customer.turnover && customer.turnover.month){
                    return formatMoneyFromApi(state.customer.activeCustomer.turnover.month);
                } else {
                    return formatMoneyFromApi(0);
                }
            }()),
            turnoverTotal: (function(){
                if(customer.turnover && customer.turnover.total){
                    return formatMoneyFromApi(state.customer.activeCustomer.turnover.total);
                } else {
                    return formatMoneyFromApi(0);
                }
            }()),
            discount: (function(){
                return customer.discount && customer.discount.discount_value ? customer.discount.discount_value : 0
            }()),

            street: (customer&&customer.address_object) && customer.address_object.street,
            house_number: (customer&&customer.address_object) && customer.address_object.house_number,
            zip: (customer&&customer.address_object) && customer.address_object.zip,
            city: (customer&&customer.address_object) && customer.address_object.city,

        },
        submitErrors: getFormSubmitErrors('customer-form')(state)
    }
}
const mapDispatchToProps = {
    saveCustomer: saveCustomerRequest,
    patchCustomer: patchCustomerRequest,
    setActiveCustomer: setActiveCustomer,
    loadSettings: loadSettingsRequest,
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'customer-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(CustomersForm)