import {put, call, select} from "@redux-saga/core/effects";
import {
    accountingError,
    accountingPdfError,
    accountingPdfSuccess,
    accountingPdfZipError,
    accountingPdfZipSuccess,
    accountingStripeTransferError,
    accountingStripeTransferSuccess,
    accountingSuccess,
    loadAccountedMonthsSuccess,
    loadActiveMonthSuccess,
    loadActiveMonthRequest as loadActiveMonthRequestAction,
    accountingDetailsPdfSuccess,
    accountingDetailsPdfError,
    accountingPdfSummarySuccess, accountingPdfSummaryError
} from "../actions/accountingActions";
import {addFlashMessage} from "../actions/flashMessageActions";
import FileDownload from "react-file-download";

export function* loadAccountedMonthsRequest (api) {
    try {
        const DataTableOptions = state => state.accounting;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.monthsSortDirection && TableOptions.monthsSortField) {
            options['order_desc'] = TableOptions.monthsSortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.monthsSortField;
        }

        options['page'] = TableOptions.monthsPage;
        options['page_size'] = TableOptions.monthsPerPage;
        options['filter'] = TableOptions.filterValues;

        let result = yield call(api.loadAccountedMonths, options);

        if (result && result.data && result.data.accountings) {
            // TODO delete this
            if(result.data.accountings){
                result.data.accountings.reverse();
            }
            
            yield put(loadAccountedMonthsSuccess({accountings: result.data.accountings, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* loadActiveMonthRequest (api, {month}) {
    try {
        const DataTableOptions = state => state.accounting;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.detailsSortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.detailsSortField;
        }

        options['page'] = TableOptions.detailsPage;
        options['page_size'] = TableOptions.detailsPerPage;

        let result = yield call(api.loadActiveMonth, month);
        if (result && result.data && result.data.accountings) {
            yield put(loadActiveMonthSuccess({accountings: result.data.accountings, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* accountingRequest (api, data) {
    let success = false;

    try {
        let result = yield call(api.accountingRequest, data);
        success = result && result.data && result.data.success;
    } catch (e) {
        console.error(e);
    }

    if(success){
        yield put(accountingSuccess());
        yield put(addFlashMessage({message: 'Abrechnung erfolgreich erstellt', style: 'success'}))
    } else {
        yield put(accountingError());
        yield put(addFlashMessage({message: 'Fehler beim Erstellen der Abrechnung', style: 'error'}))
    }
}

export function* accountingPdfRequest (api, {id}) {
    let success = false;
    let result = null;
    try {
        result = yield call(api.accountingPdf, id);
        success = result && result.data && result.data.success;
        console.log(result);
    } catch (e) {
        console.error(e);
    }

    if(success && result){
        const buf = Buffer.from(result.data.pdf, 'base64');
        FileDownload(buf, result.data.accounting.invoice_number?(result.data.accounting.invoice_number)+'.pdf':'Abrechnung.pdf', 'application/pdf');

        yield put(accountingPdfSuccess({...result.data}));
        yield put(addFlashMessage({message: 'PDF erfolgreich erstellt', style: 'success'}))
    } else {
        yield put(accountingPdfError());
        yield put(addFlashMessage({message: 'Fehler beim Erstellen des PDFs', style: 'error'}))
    }
}

export function* accountingPdfSummaryRequest (api, {month}) {
        let success = false;
    let result = null;
    try {
        result = yield call(api.accountingPdfSummary, month);
        success = result && result.data && result.data.success;
        console.log(result);
    } catch (e) {
        console.error(e);
    }

    if(success && result){
        const buf = Buffer.from(result.data.pdf, 'base64');
        FileDownload(buf, 'Abrechnung.pdf', 'application/pdf');

        yield put(accountingPdfSummarySuccess({...result.data}));
        yield put(addFlashMessage({message: 'PDF erfolgreich erstellt', style: 'success'}))
    } else {
        yield put(accountingPdfSummaryError());
        yield put(addFlashMessage({message: 'Fehler beim Erstellen des PDFs', style: 'error'}))
    }
}

export function* accountingDetailsPdfRequest (api, {id}) {
    let success = false;
    let result = null;
    try {
        result = yield call(api.accountingDetailsPdf, id);
        success = result && result.data && result.data.success;
        console.log(result);
    } catch (e) {
        console.error(e);
    }

    if(success && result){
        const buf = Buffer.from(result.data.pdf, 'base64');

        const fileName = 'Details_'+(result.data.accounting.invoice_number?result.data.accounting.invoice_number:'Abrechnung')+'.pdf';
        FileDownload(buf,fileName, 'application/pdf');

        yield put(accountingDetailsPdfSuccess({...result.data}));
        yield put(addFlashMessage({message: 'Details PDF erfolgreich erstellt', style: 'success'}))
    } else {
        yield put(accountingDetailsPdfError());
        yield put(addFlashMessage({message: 'Fehler beim Erstellen des PDFs', style: 'error'}))
    }
}

export function* accountingPdfZipRequest (api, {month}) {
    let success = false;
    let result = null;
    try {
        result = yield call(api.accountingPdfZip, month);
        success = result && result.data;
    } catch (e) {
        console.error(e);
    }

    if(success && result){
        FileDownload(result.data, month+'.zip', 'application/zip');

        yield put(accountingPdfZipSuccess({...result.data}));
        yield put(addFlashMessage({message: 'ZIP erfolgreich erstellt', style: 'success'}))
    } else {
        yield put(accountingPdfZipError());
        yield put(addFlashMessage({message: 'Fehler beim Erstellen des ZIPs', style: 'error'}))
    }
}

export function* accountingStripeTransferRequest (api, data) {
    let result = null;
    if(data.month){
        result = yield call(api.multipleAccountingStripeTransfer, data.month);
    } else if(data.id){
        result = yield call(api.accountingStripeTransfer, data.id);
    }

    if(result && result.data && result.data.success){
        yield put(accountingStripeTransferSuccess({...result.data}));
        yield put(addFlashMessage({message: 'Stripe Umbuchung erfolgreich', style: 'success'}));
        yield put(loadActiveMonthRequestAction({month: data.month}));

    } else {
        yield put(accountingStripeTransferError());
        yield put(addFlashMessage({message: 'Fehler bei Stripe Umbuchung - siehe Logs', style: 'error'}))
    }
}

