import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Grid, Paper, Button } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import AccountedMonthDetailsView from "./AccountedMonthDetailsView";
import {
    accountingPdfZipRequest, accountingStripeTransferRequest,
    loadActiveMonthRequest
} from "../../../actions/accountingActions";
import moment from 'moment';
import 'moment/locale/de'
class AccountedMonthDetails extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.loadActiveMonth({month: this.props.match.params.month});
    }

    handleZipDownload = () => {
        this.props.accountingZipRequest({month: this.props.accountings?this.props.accountings.month:null});
    };

    handleStripeTransfer = () => {
        if(this.props.accountings && this.props.accountings.month){
            this.props.accountingStripeTransfer({month: this.props.accountings.month});
        }
    };



    render() {
        const { classes, accountings } = this.props;

        const accounting_time = moment(accountings.month?accountings.month.substring(3,7)+accountings.month.substring(0,2)+'01':'').locale('de').format('MMMM YYYY');
        return (
            <Fragment>
                <Grid container spacing={5}>
                    {(this.props.user && this.props.user.role !== 'restaurant') &&
                        <div style={{right: '10px', marginLeft: 'auto', top: '20px', padding: '20px'}}>
                            <Button onClick={this.handleStripeTransfer} color="primary" variant={'outlined'}
                                    style={{marginRight: '10px'}}>
                                STRIPE Umbuchung
                            </Button>
                            <Button onClick={this.handleZipDownload} color="primary" variant={'outlined'}>
                                Download ZIP
                            </Button>
                        </div>
                    }
                    <Grid item md={12}>
                        <h2>Abrechnungen {accountings.month ? 'für ' +accounting_time :''}</h2>
                        <AccountedMonthDetailsView/>
                    </Grid>
                </Grid>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        accountings: state.accounting.detailsData,
        user: state.auth.user
    }
}
const mapDispatchToProps = {
    loadActiveMonth: loadActiveMonthRequest,
    accountingZipRequest: accountingPdfZipRequest,
    accountingStripeTransfer: accountingStripeTransferRequest,
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(style)(AccountedMonthDetails));