import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {filterTable} from "../../../actions/helloActions";
import {Grid} from "@material-ui/core";
import DateRangeFilter from "../../../components/Filter/DateRangeFilter";
import RestaurantSelect from "../../../components/Filter/RestaurantSelect";

class HellosFilter extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                    <Grid item md={'auto'} xs={'auto'}>
                        <DateRangeFilter
                            labelFrom={'Erstellt am von'}
                            labelTo={'Erstellt am bis'}
                            fieldNameFrom={'created_at_from'}
                            fieldNameTo={'created_at_to'}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <RestaurantSelect
                            fieldName={'restaurants'}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return { }
}
const mapDispatchToProps = {
    setFilter: filterTable,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'hello-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {

            let created_at_time_range = [];
            if(values.created_at_from && values.created_at_to){
                created_at_time_range = [values.created_at_from, values.created_at_to];
            }

            let restaurants = [];
            if(values.restaurants) {
                restaurants = [...values.restaurants];
            }

            dispatch(props.setFilter({
                values: {
                    created_at: created_at_time_range,
                    restaurant_id: restaurants
                }
            }));
        },
    }),
    (withStyles(style)),
)(HellosFilter)