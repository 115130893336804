
/* Action types */
const PREFIX = "restaurant/";
export const LOAD_RESTAURANT_REQUEST = PREFIX + "LOAD_RESTAURANT_REQUEST";
export const LOAD_RESTAURANT_SUCCESS = PREFIX + "LOAD_RESTAURANT_SUCCESS";

export const SAVE_RESTAURANT_REQUEST = PREFIX + "SAVE_RESTAURANT_REQUEST";
export const SAVE_RESTAURANT_SUCCESS = PREFIX + "SAVE_RESTAURANT_SUCCESS";
export const SAVE_RESTAURANT_ERROR = PREFIX + "SAVE_RESTAURANT_ERROR";

export const SAVE_RESTAURANT_RKSV_REQUEST = PREFIX + "SAVE_RESTAURANT_RKSV_REQUEST";
export const SAVE_RESTAURANT_RKSV_SUCCESS = PREFIX + "SAVE_RESTAURANT_RKSV_SUCCESS";
export const SAVE_RESTAURANT_RKSV_ERROR = PREFIX + "SAVE_RESTAURANT_RKSV_ERROR";

export const SAVE_RESTAURANT_USER_REQUEST = PREFIX + "SAVE_RESTAURANT_USER_REQUEST";
export const SAVE_RESTAURANT_USER_SUCCESS = PREFIX + "SAVE_RESTAURANT_USER_SUCCESS";
export const SAVE_RESTAURANT_USER_ERROR = PREFIX + "SAVE_RESTAURANT_USER_ERROR";

export const LOAD_RESTAURANT_USER_REQUEST = PREFIX + "LOAD_RESTAURANT_USER_REQUEST";
export const LOAD_RESTAURANT_USER_SUCCESS = PREFIX + "LOAD_RESTAURANT_USER_SUCCESS";
export const LOAD_RESTAURANT_USER_ERROR = PREFIX + "LOAD_RESTAURANT_USER_ERROR";

export const DELETE_RESTAURANT_REQUEST = PREFIX + "DELETE_RESTAURANT_REQUEST";
export const DELETE_RESTAURANT_SUCCESS = PREFIX + "DELETE_RESTAURANT_SUCCESS";

export const SET_PROFILPICTURE_REQUEST = PREFIX + "SET_PROFILPICTURE_REQUEST";
export const SET_PROFILPICTURE_SUCCESS = PREFIX + "SET_PROFILPICTURE_SUCCESS";

export const RESTAURANT_INITIALIZE_DATATABLE = PREFIX + "RESTAURANT_INITIALIZE_DATATABLE";
export const RESTAURANT_NEXT_PAGE = PREFIX + "RESTAURANT_NEXT_PAGE";
export const RESTAURANT_PREVIOUS_PAGE = PREFIX + "RESTAURANT_PREVIOUS_PAGE";
export const RESTAURANT_CHANGE_PER_PAGE = PREFIX + "RESTAURANT_CHANGE_PER_PAGE";
export const RESTAURANT_SORT_TABLE = PREFIX + "RESTAURANT_SORT_TABLE";
export const RESTAURANT_FILTER_TABLE = PREFIX + "RESTAURANT_FILTER_TABLE";

export const SET_ACTIVE_RESTAURANT = PREFIX + "SET_ACTIVE_RESTAURANT";
export const SET_RESTAURANT= PREFIX + "SET_RESTAURANT";
export const UNSET_ACTIVE_RESTAURANT = PREFIX + "UNSET_ACTIVE_RESTAURANT";
export const SET_DELETE_MODAL = PREFIX + "SET_DELETE_MODAL";
export const UNSET_DELETE_MODAL = PREFIX + "UNSET_DELETE_MODAL";


/* initial state */
const initialState = {
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'asc',
    sortField: 'name',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    data: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,
    activeRestaurant: false,
    activeRestaurantUser: null,
    activeRestaurantData: {},
    deleteModal: false,

};

/* Reducer */
const restaurantReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_RESTAURANT_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_RESTAURANT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.restaurants,
                newRestaurant: false,
                totalRecords: action.totalRecords,
            };
        case SAVE_RESTAURANT_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_RESTAURANT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                activeRestaurant: true
            };

        case SAVE_RESTAURANT_ERROR:
            return {
                ...state,
                isSaving: false,
            };
        case DELETE_RESTAURANT_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_RESTAURANT_SUCCESS:
            return {
                ...state,
                isDeleting: false,
            };
        case SET_PROFILPICTURE_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SET_PROFILPICTURE_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        case RESTAURANT_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case RESTAURANT_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case RESTAURANT_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case RESTAURANT_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case RESTAURANT_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case RESTAURANT_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        case SET_ACTIVE_RESTAURANT:
            return {
                ...state,
                activeRestaurant: false,
            };
        case SET_RESTAURANT:
            return {
                ...state,
                activeRestaurant: true,
                activeRestaurantData: action.restaurant,
            };
        case UNSET_ACTIVE_RESTAURANT:
            return {
                ...state,
                activeRestaurant: false,
                activeRestaurantData: []
            };
        case SET_DELETE_MODAL:
            return {
                ...state,
                deleteModal: true,
            };
        case UNSET_DELETE_MODAL:
            return {
                ...state,
                deleteModal: false,
            };
        case SAVE_RESTAURANT_USER_REQUEST:
            return {
                ...state
            }
        case SAVE_RESTAURANT_USER_SUCCESS:
            return {
                ...state,
                activeRestaurantUser: action.user
            }
        case SAVE_RESTAURANT_USER_ERROR:
            return {
                ...state,
                activeRestaurantUser: null
            }
        case LOAD_RESTAURANT_USER_REQUEST:
            return {
                ...state
            }
        case LOAD_RESTAURANT_USER_SUCCESS:
            return {
                ...state,
                activeRestaurantUser: action.user
            }
        case LOAD_RESTAURANT_USER_ERROR:
            return {
                ...state,
                activeRestaurantUser: null
            }
        default:
            return state;
    }
};
export default restaurantReducer;