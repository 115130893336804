import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, Dialog, AppBar, Typography, Toolbar, IconButton} from '@material-ui/core'
import PostPictures from './PostPictures';
import {loadPicturesRequest, mediaLibraryRequest, setMediaLibrary} from "../../../actions/fileActions";
import AddIcon from '@material-ui/icons/Add';
import MediaLibrary from '../../../components/MediaLibrary'
import {
    deletePostRequest, savePostRequest, setPostPictures, unsetActivePost,
    unsetNewPost
} from "../../../actions/postActions";
import SwitchGroup from '../../../components/SwitchGroup';
import {sendNotificationRequest} from "../../../actions/notificationActions";
import NotificationsCreate from "../Notifications/NotificationsCreate";
import CloseIcon from '@material-ui/icons/Close';
import {isAllowed} from "../../../utils/permissionHelper";

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

class PostForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showNotification: false,
        }
    }

    componentWillUnmount(){
        //this.props.setPostPictures({pictures: null});
    }

    required = value => value ? undefined : 'Feld erforderlich';

    addImage = (id) => {
        let postPictures = this.props.post.pictures ? this.props.post.pictures : [];
        postPictures.push(id);
        const unique = postPictures.filter( onlyUnique );
        this.props.setPostPictures({
            pictures: unique
        });
    };

    render() {
        const { handleSubmit, classes, post, isNewPost, restaurant, mediaLibrary, postPictures, user} = this.props;
        let activePost = [];

        if(isNewPost === false && post){
            activePost = post
        }

        const submitAll = (values) => {
            if(isNewPost === false && post._id && post._id.length){
                this.props.savePost({
                    data:{
                        _id: post._id,
                        title: values.title,
                        content: values.content,
                        pictures: postPictures,
                        restaurant_id: restaurant._id,
                        status: values.status === true ? 2 : 1
                    }
                })
                this.props.unsetActivePost();
            } else {
                this.props.savePost({
                    data:{
                        title: values.title,
                        content: values.content,
                        pictures: postPictures,
                        restaurant_id: restaurant._id,
                        status: values.status === true ? 2 : 1
                    }
                })
                this.props.unsetNewPost()
            }
        };

        const deletePost = () => {
            if( post._id && post._id.length) {
                this.props.deletePost({
                    data:{
                        id: post._id,
                        restaurant_id: restaurant._id
                    }
                })
                this.props.unsetActivePost();

            }
        };


        const handleMediaLibrary = () => {
            this.props.loadMediaLibrary({id: this.props.restaurant._id});
            this.props.setMediaLibrary();
        }
        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '100px'}}>
                <Grid xs={12}>
                    <form>
                        <Grid container>
                            <Grid md={6} xs={12}>
                                <Field
                                    name="title"
                                    label="Überschrift"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            <Grid md={6} xs={12}>
                                <div style={{marginLeft: 100}}>
                                    <h3>Beitrag veröffentlichen?</h3>
                                    <Field
                                        name="status"
                                        component={SwitchGroup}
                                        label={'veröffentlicht'}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Field
                            name="content"
                            label="Inhalt"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            multiline
                            rows={10}
                            validate={[ this.required ]}
                        />

                        {isNewPost === false &&
                            <div style={{marginTop: '20px'}}>
                                <p>Wählen Sie hier ihre Beitragsbilder:
                                    <Button fullWidth text-left onClick={handleMediaLibrary} color="primary">
                                        <AddIcon/> Bilder hinzufügen
                                    </Button>
                                </p>
                                <PostPictures isNewPost={isNewPost ? isNewPost : false}/>
                            </div>
                        }


                        {isNewPost === true ?

                            (isAllowed(user, 'restaurants', 'update') &&
                                <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(submitAll)}
                                variant={'outlined'} color="primary">
                                Beitrag erstellen
                                </Button>
                            )
                            :
                            <Grid container spacing={5}>
                                {isAllowed(user, 'restaurants', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(submitAll)}
                                                variant={'outlined'} color="primary">
                                            Beitrag sichern
                                        </Button>
                                    </Grid>
                                }
                                {isAllowed(user, 'restaurants', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={() => {this.setState({showNotification: true})}}
                                                variant={'outlined'} color="primary">
                                            User benachrichtigen
                                        </Button>
                                    </Grid>
                                }
                                {isAllowed(user, 'restaurants', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={deletePost}
                                                variant={'outlined'} color="default">
                                            Beitrag löschen
                                        </Button>
                                    </Grid>
                                }
                            </Grid>

                        }
                    </form>
                </Grid>

                { mediaLibrary === true &&
                    <MediaLibrary ref_id={restaurant._id} dialog={true} onSelect={this.addImage}/>
                }

                {
                    this.props.post && (
                        <Dialog fullScreen open={this.state.showNotification} >
                            <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={() => {this.setState({showNotification: false})}} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        User benachrichtigen
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Grid container style={{justifyContent:'center'}}>
                                <Grid item xs={10} style={{paddingTop: '100px'}}>
                                    <NotificationsCreate title={null} messageValues={{restaurant_id: this.props.restaurant._id, post_id: this.props.post._id}} messageTitle={this.props.post.title} messageBody={this.props.post.content} messageType={'NEW_RESTAURANT_POST'}/>
                                </Grid>
                            </Grid>
                        </Dialog>
                    )
                }

            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return{
        post: state.post.activePostData,
        restaurant: state.restaurant.activeRestaurantData,
        mediaLibrary: state.file.mediaLibrary,
        postPictures: state.post.activePostPictures,
        user: state.auth.user,
        initialValues:{
            title: state.post.newPost === false ? state.post.activePostData.title : '',
            content: state.post.newPost === false ? state.post.activePostData.content : '',
            status: state.post.activePostData.status === 1 ? false : true

        }
    }
}
const mapDispatchToProps = {
    loadMediaLibrary: mediaLibraryRequest,
    setMediaLibrary: setMediaLibrary,
    setPostPictures: setPostPictures,
    savePost: savePostRequest,
    deletePost: deletePostRequest,
    unsetActivePost: unsetActivePost,
    unsetNewPost: unsetNewPost,
    sendNotificationRequest: sendNotificationRequest
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'restaurants-post-form',
    }),
    withStyles(style)
)(PostForm)