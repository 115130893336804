import React  from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePickerGroup = ({ containerStyle, selectionRange, onChange, open, startDate, endDate }) => {

    return <div style={containerStyle} >
        <DatePicker
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
        />
    </div>
}

export default DateRangePickerGroup;