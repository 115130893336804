import AppStyles from "../../AppStyles.js";
import MaterialTable from "material-table";
import React from "react";

const drawerWidth = 240;
const headerHeight = '64px'

const style = theme => {
    return {
        ...AppStyles,
        root: {
            display: 'flex',
            backgroundColor: '#ffffff',
            paddingLeft: '10px',
            paddingRight: '10px',
            minHeight: '100vh',
            paddingTop: '30px'

        },
        paper: {
            color: theme.palette.text.secondary,
        },
        header: {
            backgroundColor: '#FFFFFF',
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            height: '70px'
        },
        toolbar: theme.mixins.toolbar,
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: "#262c46",
            color: "#ffffff"
        },
        logo: {
            maxHeight: headerHeight,
            maxWidth: "80%",
            marginLeft: '20%',
            marginRight: 'auto',
            paddingTop: "2px"
        },
        logoBackground: {
            backgroundColor: '#fff'
        },
        timeStampClockContainer: {
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: "-9px",
            padding: "0 3px",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            backgroundColor: "#fff"
        },
        navIcon: {
            color: "#fff"
        },
        list:{
            paddingTop: '20px',
        },
        headerButton: {
            margin: theme.spacing(1)
        },
        iconMargin: {
            marginRight: theme.spacing(1)
        },
        rotate45: {
            transform: "rotate(45deg)"
        },
        rotate90: {
            transform: "rotate(90deg)"
        },
        rotate135: {
            transform: "rotate(135deg)"
        },
        rotate180: {
            transform: "rotate(180deg)"
        },
        rotate225: {
            transform: "rotate(225deg)"
        },
        rotate270: {
            transform: "rotate(270deg)"
        },
        container: {
            marginRight: 'auto',
            marginLeft: 'auto',
            marginTop: headerHeight,
        },
        Appbar:{
            height: headerHeight,
            backgroundColor: "#3f3f3f",
        },
        titleAppbar: {
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '28px'
        },
        grid: {
            marginTop: 'auto'
        },
        select: {
            color: '#3f3f3f',
            //border: '0.5px solid',
            borderRadius: '10px',
            margin: '5px',
            paddingLeft: '5px',
            borderColor: '#3f3f3f',
        },
        holidayForm:{
            padding: '50px'
        },
        gridHoliday: {
            marginTop: headerHeight
        },
        gridWorkday: {
            marginTop: headerHeight
        },
        overviewPaper: {

            boxShadow:'none',
            backgroundColor: 'transparent'

        },
        timesDivider: {

            backgroundColor: '#3f3f3f',
            opacity: '0.8',
            marginTop: '15px',
            marginBottom: '15px',
            height: '2px',

        },
        timesOverview: {
            padding: '20px'
        },
        customerDetail: {
            padding: "35px"
        },


        tooltip: {
            position: 'relative',

            "&:hover > div": {
                display: 'block'
            }
        },
        texttooltip: {
            display: 'none',
            position: 'absolute',
            top: '100%',
            left: '0',
            padding: '3px',
            background: '#eee',
            zIndex: '1000',
            color: '#000',
            borderRadius: '5px',
            width: '100%',
            opacity: '0.9',
            textAlign: 'center',
            justifyContent: 'center',

        },
        tabContainer:{
            marginLeft: '10px',
            //marginRight: '50px',
            width: '100%',
        },
        privateTabIndicator: {
            right: '80px',
            width: '3px'
        },

        statisticsIcon:{
            fontSize: '10rem',
            color: '#0c51b0e3',
        },
        cardMedia:{
            textAlign: 'center'
        },

        tableRow:{
            cursor: 'pointer',
            "&:hover": {
                backgroundColor: "#eeeeee75"
            }
        },
        fragmentContainer:{
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '50px',
        },

        imgContainer: {
            position: 'relative',
            width: '100%',
            maxWidth: '400px',
            cursor: 'pointer',
        },

        imgBtn :{

            color: '#000',
            position: 'absolute',
            background: '#ffffffa6',
            padding: '4px',

            "&:hover": {

                    background: '#000000a6',
                    color: '#fff'

            },
        },
        fileContainer: {
            float: 'left',
            width: '280px',
            height: '210px',
            textAlign: 'center',
            marginLeft:'2px',
            marginRight: '2px',
            marginBottom: '44px',
            background: '#dddddd7d'
        },

        fileActions: {
            background: 'rgba(0, 0, 0, 0.56)',
            width: '100%',
            height: '40px'
        },

        imageContainer: {
            height: '100%',
            maxWidth: '100%'
        },

        profilPictureBtn:{

            marginTop: '10px',
            fontSize: '14px',

            '&:hover': {
                color: '#000000 !important',
                backgroundColor: 'transparent'
            }
        },

        newRestaurantBtn: {
            '&:hover': {
                background: 'transparent'
            }
        }

    }

};

export default style;