import MyTable from '../../../components/MyTable';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort,
    setActiveVoucher
} from '../../../actions/voucherActions';
import VouchersFilter from "./VouchersFilter";
import {Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@material-ui/core";
import moment from "moment";
import {formatDateTime, formatMoneyFromApi, renderSort} from "../../../utils/helper";

class VouchersTable extends Component {

    componentWillMount() {
        this.props.initializeDataTable({
            options:{
                page: this.props.page,
                perPage: this.props.perPage,
                sortDirection: this.props.sortDirection,
                sortField: this.props.sortField
            }});
    }

    renderHead() {
        const {} = this.props;
        const sortOptions = {
            sortFields: this.props.sortFields,
            sortField: this.props.sortField,
            sortDirection: this.props.sortDirection,
            onSortClick: this.onSortClick
        };
        return (

            <TableHead>
                <TableRow>
                    <TableCell key={'0'} className={'col-md-1'}>{'Erstellt am'}<span>{renderSort('created_at', sortOptions)}</span></TableCell>
                    <TableCell key={'1'} className={'col-md-1'}>{'Benutzer'}</TableCell>
                    <TableCell key={'3'} className={'col-md-1'}>{'Email'}<span>{renderSort('email', sortOptions)}</span></TableCell>
                    <TableCell key={'4'} className={'col-md-1'}>{'Typ'}<span>{renderSort('basic_type', sortOptions)}</span></TableCell>
                    <TableCell key={'5'} className={'col-md-1'}>{'Wert'}</TableCell>
                    <TableCell key={'6'} className={'col-md-1'}>{'Gültigkeit'}</TableCell>
                    <TableCell key={'7'} className={'col-md-1'}>{'Status'}</TableCell>
                    <TableCell key={'8'} className={'col-md-1'}>{'Restwert'}</TableCell>
                    <TableCell key={'9'} className={'col-md-1'}>{'Gesendet am'}<span>{renderSort('sent_at', sortOptions)}</span></TableCell>
                </TableRow>
            </TableHead>
        );
    }

    onSortClick = (field, ascActive, descActive) => {
        if (ascActive) {
            this.props.sort(field, 'desc');
        } else if (descActive) {
            this.props.sort(null);
        } else {
            this.props.sort(field, 'asc')
        }
    }

    getTotalPages() {
        return Math.ceil(this.props.totalRecords / this.props.perPage);
    }

    renderPageButtons() {
        const totalPages = this.getTotalPages();
        const page = this.props.page?this.props.page:0;
        const rowPerPage = this.props.perPage?this.props.perPage:0

        return (
            <div>
                <TablePagination
                    component="nav"
                    page={page - 1}
                    count={this.props.totalRecords}
                    rowsPerPage={rowPerPage}
                    labelRowsPerPage={'Zeilen pro Seite'}

                    backIconButtonProps = {{
                        'onClick': this.props.previousPage,
                    }}
                    nextIconButtonProps={{
                        'onClick': this.props.nextPage,
                    }}
                    onChangeRowsPerPage={(event)=>{this.props.changePerPage(event.target.value)}}
                />

            </div>
        );
    }

    getStatus = (row) => {
        let value = 'Aktiv';
        if(row['status'] == 'solved' || row['status'] == 'cashed'){
            value = 'Eingelöst';
        } else if(row['status'] == 'inactive'){
            value = 'Gelöscht';
        }
        return value;
    }

    getBasicType = (row) => {
        if(row['basic_type'] == 'percentage'){
            return 'Rabattgutschein';
        } else if(row['basic_type'] == 'absolute'){
            return 'Wertgutschein';
        } else if(row['basic_type'] == 'status'){
            return "Statusänderung"
        }
    }

    getRest = (row) => {
        let value = formatMoneyFromApi(row['rest']);
        if(row['basic_type'] === 'percentage' || row['basic_type'] === 'status'){
            value = '-';
        } else {
            if(row['rest'] == 0){
                value = 'verbraucht';
            }
        }
        return value
    }

    getValue = (row) => {
        let value = formatMoneyFromApi(row['value']);
        if(row['basic_type'] == 'percentage'){
            value = (row['value'] / 100) + '%';
        } else if(row['basic_type'] == 'status'){
            value = row['value_status'];
        }
        return value;
    }

    renderBody() {
        const {} = this.props;
        const data = this.props.data;

        const tr = data.map((row) => {
            const toReturn = [];
            const hasSolvings = row.payments && row.payments.length > 0;
            toReturn.push(<TableRow key={row[this.props.idField]}  className={hasSolvings && 'solved-header-row'} onClick={() => this.props.onClickRow(row)} style={{ cursor: 'pointer' }}>
                <TableCell>{formatDateTime(row['created_at'])}</TableCell>
                <TableCell>
                    {!!row['customer'] ? [
                        <div>{row['customer']['firstname']} {row['customer']['lastname']} ({row['customer']['number']})</div>,
                        <div>Gesamtguthaben: {formatMoneyFromApi(row['customer']['voucherSum'])}</div>
                    ] : <div>{'-'}</div>
                    }
                </TableCell>
                <TableCell>{!!row['customer']?row['customer']['email']:'-'}</TableCell>
                <TableCell>{this.getBasicType(row)}</TableCell>
                <TableCell>{this.getValue(row)}</TableCell>
                <TableCell>{formatDateTime(row['validity'], false)}</TableCell>
                <TableCell>{this.getStatus(row)}</TableCell>
                <TableCell>{this.getRest(row)}</TableCell>
                <TableCell>{formatDateTime(row['sent_at'])}</TableCell>
            </TableRow>);

            if(hasSolvings){
                row.payments.forEach(x => {
                    toReturn.push(<TableRow className={'solved-row'} style={{ cursor: 'pointer' }}>
                        <TableCell colSpan={9}>
                            <div>
                                {' - '}Eingelöst am: {formatDateTime(x['created_at'])}
                                {'   |  '}{x['restaurant_name']}
                                {'   |  '}{formatMoneyFromApi(x['sum'])}
                            </div>
                        </TableCell>

                    </TableRow>)
                });
            }

            row['open'] = false;
            return toReturn;
        });
        return (
            <TableBody>
                {tr}
            </TableBody>
        );
    }

    render() {
        return (
            <div className='table-wrapper' >
                <Grid xs={8} md={12} mdOffset={8}>
                    {this.props.filterComponent()}
                </Grid>
                <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                    {this.renderHead()}
                    {this.renderBody()}
                </Table>

                <Grid xs={12}>
                    {this.renderPageButtons()}
                </Grid>

            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    let { page, perPage, totalRecords, isFetching, sortDirection, sortField } = state.voucher;
    let data = state.voucher.data ? state.voucher.data : [];
    const idField = "id";
    const click = true;
    return {
        page,
        perPage,
        totalRecords: !!totalRecords?totalRecords:0,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        idField,
        filterComponent: () => !ownProps.customer_id && <VouchersFilter/>,
        click,
        sortFields: ['created_at', 'email', 'sent_at', 'basic_type']
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable,
        nextPage: () => { dispatch(nextPage())},
        previousPage: () => {dispatch(previousPage())},
        changePerPage: (value => { dispatch(changePerPage(value))}),
        sort: (field, value) => { dispatch(sort(field, value))},
        onClickRow: (rowData) => {
            dispatch(setActiveVoucher(rowData));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(VouchersTable)
