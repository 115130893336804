import React, { Component } from 'react';


import { connect } from 'react-redux';
import { compose } from 'redux';
import {Field, reduxForm, reset } from "redux-form";
import {Dialog, DialogActions, DialogContent, DialogTitle, Button} from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../containers/app/AppContainerStyles";
import FileUpload from './FileUpload'
import {deleteFileRequest, getFileRequest, openEdit, unsetMediaLibrary} from "../actions/fileActions";
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import getBaseUrl from "../utils/getBaseUrl";

class MediaLibrary extends Component {

    constructor(props) {
        super(props);
    }

    imageClick = (pic) => {
        if(this.props.onSelect){
            this.props.onSelect(pic._id);
        }
        this.props.unsetMediaLibrary();
    };

    render() {
        const { classes, ref_id, ref_type, pictures, dialog, files } = this.props;

        let uploadFiles = files ? files : [];

        return (
            <div>
                {dialog === true ?
                    <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
                        <DialogTitle>Medienbibliothek</DialogTitle>

                        <DialogContent>
                            <div>
                                {pictures.map(pic => (
                                    <div className={classes.fileContainer}>
                                        <div className={classes.imageContainer}>
                                            <img style={{maxWidth: '100%', maxHeight: '100%'}} src={getBaseUrl()+'/api/files/'+ pic._id + '/file'} onClick={() => this.imageClick(pic)}/>
                                        </div>
                                        <div className={classes.fileActions} >
                                            <IconButton onClick={()=>{this.props.getFile({ id:pic._id, download: true})}}><GetAppIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                            <IconButton onClick={()=>{this.props.openEdit(pic)}}><EditIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                            <IconButton onClick={()=>{this.props.deleteFile(pic._id)}}><DeleteIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                        </div>
                                    </div>
                                ))}
                                <div style={{clear: "both"}}></div>
                            </div>
                            <FileUpload dialog={dialog} ref_id={ref_id} ref_type={!!ref_type?ref_type:'restaurant'}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.unsetMediaLibrary} color={'primary'}>Abbrechen</Button>
                        </DialogActions>

                    </Dialog>
                    :
                    <div>
                        <div>
                        {uploadFiles.map(pic => {
                            if(pic.content_type === 'image/png' || pic.content_type === 'image/jpg' || pic.content_type === 'image/jpeg'){
                                return (
                                    <div className={classes.fileContainer}>
                                        <div className={classes.imageContainer}>
                                            <img style={{maxWidth: '100%', maxHeight: '100%'}} src={getBaseUrl()+'/api/files/'+ pic._id + '/file'}/>
                                        </div>
                                        <div className={classes.fileActions} >
                                            <IconButton onClick={()=>{this.props.getFile({ id:pic._id, download: true})}}><GetAppIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                            <IconButton onClick={()=>{this.props.openEdit(pic)}}><EditIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                            <IconButton onClick={()=>{this.props.deleteFile(pic._id)}}><DeleteIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                        </div>
                                    </div>

                                )
                            } else {
                                return (
                                    <div className={classes.fileContainer}>
                                        <div className={classes.imageContainer} style={{paddingTop: '20%'}}>
                                            <DescriptionIcon fontSize={"large"} /><p>{pic.title}</p>
                                        </div>
                                        <div className={classes.fileActions}>
                                            <IconButton onClick={()=>{this.props.getFile({id:pic._id, download: true})}}><GetAppIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                            <IconButton onClick={()=>{this.props.openEdit(pic)}}><EditIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                            <IconButton onClick={()=>{this.props.deleteFile(pic._id)}}><DeleteIcon style={{color: '#ffffff'}} fontSize={"small"} /></IconButton>
                                        </div>
                                    </div>
                                )
                            }

                        })
                        }
                            <div style={{clear: "both"}}></div>
                        </div>
                        <FileUpload ref_id={ref_id} ref_type={!!ref_type?ref_type:'restaurant'}/>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pictures: state.file.pictures,
        postData: state.post.activePostData,
    }
}

const mapDispatchToProps = {
    unsetMediaLibrary: unsetMediaLibrary,
    deleteFile: deleteFileRequest,
    openEdit: openEdit,
    getFile: getFileRequest

};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(style))
(MediaLibrary);