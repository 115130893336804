export const isAllowed = (user, scope, permission) => {
    let permissionObj = (user && user.permissions) ? user.permissions :null;
    if(!permissionObj || !scope || !permission){
        return false;
    }
    let scopedPermission = permissionObj[scope];
    if(scopedPermission){
        return scopedPermission[permission] === true;
    }
    return false;
};

export const isAllowedRestaurant = (user, scope) => {
    if(user && user.role !== 'restaurant'){
        return true;
    } else {
        return scope === 'Stammdaten' || scope === 'Personal' || scope === 'Umsätze' || scope === 'QR';
    }
}
export const isReadOnly = (user, scope) => {
    if(isAllowed(user, scope, 'read')){
        return !isAllowed(user, scope, 'create') && !isAllowed(user, scope, 'udpate');
    }
}