import {put, call, select} from "@redux-saga/core/effects";
import {
    uploadFileSuccess, getFileSuccess, loadFileTypeSuccess, updateFileSuccess, deleteFileSuccess,
    loadPicturesSuccess, mediaLibrarySuccess, mediaLibraryRequest, unsetMediaLibrary,
} from "../actions/fileActions";
import FileDownload from "js-file-download"
import {addFlashMessage} from "../actions/flashMessageActions";

export function* uploadFileRequest (api, {files}) {

    try {
        if(files && files.length > 0) {

            let formData = new FormData();
            formData.append('type', files[0].type);
            formData.append('reference_id', files[0].reference_id);
            formData.append('files[]', files[0].data);
            formData.append('reference_type', files[0].reference_type);
            formData.append('content_type', files[0].type);
            formData.append('title', files[0].title);

            let result = false;
            result = yield call(api.uploadFile, formData);

            if (result) {
                yield put(uploadFileSuccess());

                const restaurant = state => state.restaurant;
                const {activeRestaurant, activeRestaurantData} = yield select(restaurant)

                if (activeRestaurant && !!activeRestaurantData._id) {
                    yield put(mediaLibraryRequest({id: activeRestaurantData._id}))
                } else {
                    yield put(mediaLibraryRequest({id: 'c7-invoice-settings'}))
                }

                yield put(addFlashMessage({message: 'Datei wurde hochgeladen', style: 'success'}))
            } else {
                console.log("FAIL")
            }
        }

    } catch (e) {
        console.log(e)
    }
}

export function* getFileRequest (api, id) {
    try {
        let result = false;
        let meta = false;

        result = yield call(api.getFile, id.id);
        meta = yield call(api.getMeta, id.id)


        FileDownload(result.data, meta.data.file.title, meta.data.file.content_type)

        if (result) {
            yield put(getFileSuccess());
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}

export function* loadFileTypeRequest (api) {
    try {
        let result = false;

        result = yield call(api.loadFileTypes);

        if (result) {
            yield put(loadFileTypeSuccess({types: result.data.types}));
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}

export function* updateFileRequest (api, {data}) {
    console.log('updateFileRequest')
    try {
        let result = false;
        result = yield call(api.updateFile, data.id, data);

        if (result) {
            yield put(updateFileSuccess());
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}

export function* deleteFileRequest (api, id) {
    try {
        if(id && id.id){
            let result = yield call(api.deleteFile, id.id);
            if (result) {
                yield put(deleteFileSuccess());

                const restaurant = state => state.restaurant;
                const {activeRestaurant, activeRestaurantData} = yield select(restaurant)
                if(activeRestaurant && activeRestaurantData._id){
                    yield put(mediaLibraryRequest( {id: activeRestaurantData._id}))
                }
            } else {
                console.log("FAIL")
            }
        }


    } catch (e) {
        console.log(e)
    }
}

export function* loadMediaLibraryRequest (api, {id}) {
    try {
        let result = false;
        result = yield call(api.loadPictures, id);
        if (result) {
            yield put(mediaLibrarySuccess({pictures: result.data.pictures}));
        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}




