import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem} from '@material-ui/core'
import {
    patchCheckinRequest, unsetActiveCheckin
} from "../../../actions/checkinActions";
import SelectGroup from '../../../components/SelectGroup';
import {isAllowed} from "../../../utils/permissionHelper";
import { formatCheckinStatus } from '../../../utils/helper';

class CheckinForm extends Component {
    constructor(props) {
        super(props);

    }

    required = value => value ? undefined : 'Feld erforderlich';
    passwordRequired = value => {
        const { isNewUser } = this.props;
        if(isNewUser){
            return value ? undefined : 'Feld erforderlich';
        } else {
            return undefined;
        }
    };



    render() {
        const { handleSubmit, classes, checkin } = this.props;

        const patch = (values) => {
            if( checkin._id ) {
                this.props.dispatch(patchCheckinRequest({
                  checkin_patch: {
                    id: checkin._id,
                    status: values.status,
                  }
                }));
            }
        };

        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '100px'}}>
                <Grid xs={12}>
                    <form>
                        <Grid container spacing={5}>
                            <Field
                                name="status"
                                label="Status"
                                component={SelectGroup}
                                margin="dense"
                                fullWidth
                                validate={[ this.required ]}
                            >
                                <MenuItem value={'to_invoice'}> {formatCheckinStatus('to_invoice')} </MenuItem>
                                <MenuItem value={'to_pay'}> {formatCheckinStatus('to_pay')} </MenuItem>
                                <MenuItem value={'cancel'}> {formatCheckinStatus('cancel')} </MenuItem>
                                <MenuItem value={'active'}> {formatCheckinStatus('active')} </MenuItem>
                                <MenuItem value={'paid'}> {formatCheckinStatus('paid')} </MenuItem>

                            </Field>
                        </Grid>
                        <Grid container spacing={5}>
                          <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(patch)} variant={'outlined'} color="primary">
                              Status ändern
                          </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return{
        checkin: state.checkin.activeCheckinData,
    }
}

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'checkin-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(CheckinForm)