import {
    CREATE_ACCOUNT_ERROR, CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR,
    GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS
} from "../reducers/stripeReducer";

export const createAccountRequest = (values) => ({
    type: CREATE_ACCOUNT_REQUEST,
    ...values
});

export const createAccountSuccess = (values) => ({
    type: CREATE_ACCOUNT_SUCCESS,
    ...values
});

export const createAccountError = (values) => ({
    type: CREATE_ACCOUNT_ERROR,
    ...values
});

export const getAccountRequest = (values) => ({
    type: GET_ACCOUNT_REQUEST,
    ...values
});

export const getAccountSuccess = (values) => ({
    type: GET_ACCOUNT_SUCCESS,
    ...values
});

export const getAccountError = (values) => ({
    type: GET_ACCOUNT_ERROR,
    ...values
});