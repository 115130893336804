import React, { Component } from 'react';
import {AppBar, Typography, Toolbar, IconButton, withStyles, Menu, MenuItem} from '@material-ui/core';
import {AccountCircle, PowerSettingsNew} from "@material-ui/icons";

import { connect } from 'react-redux';
import {compose} from "redux";

import style from "../AppContainerStyles"
import {logout} from "../../../actions/authActions";


function Header({user, classes, logout}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        logout();
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
    <AppBar className={classes.header} position="fixed">
      <Toolbar className={classes.toolbar}>
          <div style={{marginLeft:"auto"}}>
              {
                  user.restaurant_name ? <span style={{color: 'black'}}>{user.restaurant_name}</span> :
                      <span style={{color: 'black'}}>{user.firstname} {user.lastname}</span>
              }

              <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
              >
                  <AccountCircle />
              </IconButton>
              <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
              >
                  <MenuItem disabled>{user.restaurant_name ? user.restaurant_name : `${user.firstname} ${user.lastname}`}</MenuItem>
                  <MenuItem onClick={handleLogout}><PowerSettingsNew/> Logout</MenuItem>
              </Menu>
          </div>
      </Toolbar>
    </AppBar>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        device: state.device,
        branding: state.branding
    };
}
const mapDispatchToProps = {
    logout: logout,
};
export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps)
)(Header);
