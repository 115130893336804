import {put, call, select} from "@redux-saga/core/effects";
import { downloadPaymentError, downloadPaymentSuccess,
    loadPaymentsSuccess
} from "../actions/paymentActions";
import FileDownload from "react-file-download";
import moment from "moment";
import {addFlashMessage} from "../actions/flashMessageActions";

export function* loadPaymentsRequest (api, {data}) {
    try {
        const DataTableOptions = state => state.payment;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;

        options['from'] = TableOptions.from;
        options['to'] = TableOptions.to;
        options['restaurant_id'] = TableOptions.restaurant_id;
        options['customer_id'] = TableOptions.customer_id;
        options['check_in_type'] = TableOptions.check_in_type;

        let result = yield call(api.loadPayments, data, options);

        if (result) {
            yield put(loadPaymentsSuccess({payments: result.data.payments, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}


export function* downloadPaymentsRequest (api) {
    try {
        const DataTableOptions = state => state.payment;
        const TableOptions = yield select(DataTableOptions);
        const options = {};
        options['from'] = TableOptions.from;
        options['to'] = TableOptions.to;
        options['restaurant_id'] = TableOptions.restaurant_id;
        options['customer_id'] = TableOptions.customer_id;
        options['check_in_type'] = TableOptions.check_in_type;

        let _fromFormatted = options['from'] ? moment(options['from']).format('YYYY-MM-DD') : 'von';
        let _toFormatted = options['to'] ? moment(options['to']).format('YYYY-MM-DD') : 'bis';

        let result = yield call(api.downloadPayment, options);
        if(result.status === 400){
            yield put(addFlashMessage({message:'Keine Datensätze vorhanden', style:'error'}))
        } else {
            FileDownload(result.data, 'umsaetze_'+ _fromFormatted +'_'+ _toFormatted +'.csv', 'text/csv');
            yield put(addFlashMessage({message:'CSV Download erfolgreich', style: 'success'}))

        }

    } catch (e) {
        yield put(downloadPaymentError({}));
    }
}


