import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, FieldArray, formValueSelector, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem, IconButton} from '@material-ui/core'
import {
    deleteUserRequest
} from "../../../actions/userActions";
import {
    mailVoucherRequest,
    patchVoucherRequest, saveVoucherRequest, unsetActiveVoucher,
    unsetNewVoucher
} from "../../../actions/voucherActions";
import AsyncSelectSearch from "../../../components/AsyncSelectSearch";
import {loadSimpleCustomersRequest} from "../../../actions/customerActions";
import {formatDateTime, formatMoneyFromApi} from "../../../utils/helper";
import NotificationsFilter from "../Notifications/NotificationsFilter";
import SwitchGroup from "../../../components/SwitchGroup";
import {isAllowed} from "../../../utils/permissionHelper";
import SelectGroup from "../../../components/SelectGroup";
import {loadSettingsRequest} from "../../../actions/settingsActions";
import DatePickerGroup from "../../../components/DatePickerGroup";
import moment from "moment";
import NotificationsFilterList from "../Notifications/NotificationsFilterList";

class VouchersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {
                valid_from: '',
                valid_to: '',
            },
            customFilter: null
        };
    }

    componentDidMount(){
        let search = {
            searchValue: '',
            fields: ['firstname', 'lastname', 'email'],
            status: ['active', 'activation_code_sent']
        };
        this.props.loadSimpleCustomersRequest({search});
        this.props.loadSettings();
    }

    required = value => value ? undefined : 'Feld erforderlich';
    number = value => {
        let _value = Number(value);
        return Number.isInteger(_value) && Number(_value) >= 1 && Number(_value) <= 1000 ? undefined : 'Eingabe muss eine Zahl sein.';
    };
    percentageNumber = value => {
        let _value = Number(value);
        return Number.isInteger(_value) && Number(_value) >= 1 && Number(_value) <= 100 ? undefined : 'Eingabe muss eine Zahl zwischen 1 und 100 sein.';
    };

    submitAll = (values) => {
        this.setState({error: { valid_from: '', valid_to: ''}});

        if(values && values.basic_type === 'status'){

            if((!!values.valid_from) && (!!values.valid_to)){
                let valid_from = moment(values.valid_from);
                let valid_to = moment(values.valid_to);

                if(valid_from.isBefore(moment().startOf('day')) || valid_from.isAfter(valid_to)){
                    this.setState({error: { valid_from: 'Ungültiges Datum', valid_to: 'Ungültiges Datum'}});
                    return;
                }
            } else if((!!values.valid_from) && !(!!values.valid_to)) {
                this.setState({error: { valid_to: 'Ungültiges Datum'}});
                return;
            } else if(!(!!values.valid_from) && (!!values.valid_to)){
                this.setState({error: { valid_from: 'Ungültiges Datum'}});
                return;
            }
        }


        this.props.saveVoucher({
            _id: (this.props.voucher && this.props.voucher._id ? this.props.voucher._id: null),
            value: values.value * 100,
            value_status: values.value_status,
            customer_id: (() => {
                if(this.props.customer_id){
                    return this.props.customer_id
                } else {
                    return values.customer?values.customer.value:null
                }
            })(),
            filter: values.filter,
            reason: values.reason,
            send_mail: values.send_mail,
            basic_type: values.basic_type,
            valid_from: values.valid_from,
            valid_to: values.valid_to,
            validity: values.validity,
        });
        this.props.unsetNewVoucher();
        this.props.unsetActiveVoucher();
    };

    deleteVoucher = () => {
        if(this.props.voucher && this.props.voucher._id){
            this.props.patchVoucher({_id: this.props.voucher._id, action: 'disable'})
        }
        this.props.unsetActiveVoucher();
    };


    mailVoucher = () => {
        if(this.props.voucher && this.props.voucher._id){
            this.props.mailVoucher({_id: this.props.voucher._id});
            this.props.unsetActiveVoucher();
        }
    };

    setCustomerSearchOpen = (value) => {
        this.setState({open: value});
    };

    customerValueChanged = (value) => {
        let search = {
            searchValue: value,
            fields: ['firstname', 'lastname', 'email'],
            status: ['active', 'activation_code_sent']
        };
        this.props.loadSimpleCustomersRequest({search});
    };

    customerSelected = (value) => {
        this.props.change('customer', value);
    };

    changeFilter = (id) => {
        this.props.change('filter', id);
    };

    typeOptions = () => {
        return [
            <MenuItem value='absolute'>Wertgutschein</MenuItem>,
            <MenuItem value='percentage'>Rabattgutschein</MenuItem>,
            <MenuItem value='status'>Statusänderung</MenuItem>
        ];
    };

    customerOptions = () => {
        const { customers } = this.props;
        return (customers?customers:[]).map(customer =>{

            let number =  customer.number ? ' ' + customer.number : '';

            return {
                value: customer._id,
                label: customer.firstname + ' ' + customer.lastname + number +  ' ('+ formatMoneyFromApi(customer.voucherSum)+')'
            }
        });
    };

    getLabelForValue = () => {
        if(this.props.formValueType == 'percentage'){
            return 'Wert (%)';
        } if(this.props.formValueType == 'absolute') {
            return 'Wert (€)';
        } else {
            return 'Status'
        }
    };

    render() {
        const { handleSubmit, classes, voucher, isNewVoucher, isCustomerFetching, settings, formValueFilter } = this.props;
        const { open } = this.state;

        console.log('formValueFilter: ', formValueFilter);

        const discounts = ((settings&&settings.club7?settings.club7:[])).map(d => {
            return <MenuItem value={d.name}>{d.name} ({d.value}%)</MenuItem>;
        });

        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '100px'}}>
                <Grid xs={12}>
                    <form>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="basic_type"
                                    label="Typ"
                                    disabled={!isNewVoucher}
                                    component={SelectGroup}
                                    margin="dense"
                                    fullWidth
                                    validate={[ this.required ]}
                                >
                                    {this.typeOptions()}
                                </Field>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                {this.props.formValueType === 'status' ?


                                    <Field
                                        name="value_status"
                                        label="Status"
                                        component={SelectGroup}
                                        disabled={!isNewVoucher}
                                        margin="dense"
                                        fullWidth
                                        validate={[ this.required ]}
                                    >
                                        {discounts}
                                    </Field>
                                    :
                                    <Field
                                        name="value"
                                        label={this.getLabelForValue()}
                                        disabled={!isNewVoucher}
                                        component={TextFieldGroup}
                                        margin="dense"
                                        type="number"
                                        min={1}
                                        max={1000}
                                        placeholder={"Bitte ausfüllen..."}
                                        validate={[ this.required, this.number ]}
                                    />
                                }

                            </Grid>

                            { this.props.formValueType === 'status' ? [
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="valid_from"
                                        label="Gültig von"
                                        component={DatePickerGroup}
                                        margin="dense"
                                        error={this.state.error.valid_from}
                                        required={this.props.formValueType === 'status'}
                                    />
                                </Grid>,
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="valid_to"
                                        label="Gültig bis"
                                        component={DatePickerGroup}
                                        margin="dense"
                                        error={this.state.error.valid_to}
                                        required={this.props.formValueType === 'status'}
                                    />
                                </Grid>
                            ] : <Grid item md={6} xs={12}>
                                <Field
                                    name="validity"
                                    label="Gültigkeit"
                                    component={DatePickerGroup}
                                    margin="dense"
                                    error={this.state.error.validity}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            }

                            <Grid item md={12} xs={12}>
                                <Field
                                    name="reason"
                                    label="Grund"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                />
                            </Grid>
                            {
                                !isNewVoucher && <Grid item md={12} xs={12}>
                                    <Field
                                        name="filter"
                                        label="Filter"
                                        component={TextFieldGroup}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                            }

                            <Grid item md={12} xs={12}>
                                <Field
                                    name="send_mail"
                                    label="Mail sofort versenden"
                                    component={SwitchGroup}
                                    margin="dense"
                                />
                            </Grid>
                            {
                                !this.props.customer_id && <Fragment>
                                    <Grid item md={12} xs={12} style={{backgroundColor: 'rgba(40,40,40,0.03)'}}>
                                        <Field
                                            disabled={!isNewVoucher}
                                            name="customer"
                                            component={AsyncSelectSearch}
                                            label={'Einzelner Kunde'}
                                            fullWidth
                                            setOpen={this.setCustomerSearchOpen}
                                            onInputChange={this.customerValueChanged}
                                            onChange={this.customerSelected}
                                            open={open}
                                            loading={isCustomerFetching}
                                            options={this.customerOptions()}
                                        />
                                    </Grid>
                                    {isNewVoucher === true && [
                                        <Grid item md={12} xs={12} spacing={0} style={{paddingTop: 0, paddingBottom: 0, backgroundColor: 'rgba(40,40,40,0.03)'}}>
                                            oder
                                        </Grid>,
                                        <Grid item md={12} xs={12} spacing={0} style={{backgroundColor: 'rgba(40,40,40,0.03)'}}>
                                            <NotificationsFilter changeFilter={this.changeFilter}/>
                                            {
                                                !!formValueFilter && <NotificationsFilterList filter={formValueFilter} onSubmit={(values) => {this.setState({customFilter: values})}} />
                                            }
                                        </Grid>,
                                    ]}
                                </Fragment>
                            }

                        </Grid>

                        {isNewVoucher === true &&
                            <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)}
                                    variant={'outlined'} color="primary">
                                Gutschein erstellen
                            </Button>
                        }
                        {(voucher && voucher._id) &&
                            <Grid container spacing={5}>
                                {isAllowed(this.props.user, 'vouchers', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)}
                                                variant={'outlined'} color="primary">
                                            Gutschein speichern
                                        </Button>
                                    </Grid>
                                }
                                {isAllowed(this.props.user, 'vouchers', 'delete') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={this.deleteVoucher}
                                                variant={'outlined'} color="error">
                                            Gutschein löschen
                                        </Button>
                                    </Grid>
                                }
                                {isAllowed(this.props.user, 'vouchers', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={this.mailVoucher}
                                                disabled={voucher.sent_at != null} variant={'outlined'} color="primary">
                                            {voucher.sent_at != null ? 'Gesendet: ' + formatDateTime(voucher.sent_at) : 'Gutschein versenden'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </form>
                </Grid>
            </Grid>
        );
    }
}

const selector = formValueSelector('voucher-form');

function mapStateToProps(state) {
    let voucher = state.voucher.activeVoucher;
    let customer = state.voucher.newVoucher === false && voucher ? {value: voucher.customer_id, label: voucher.customer.firstname + ' ' + voucher.customer.lastname + '('+ formatMoneyFromApi(voucher.customer.voucherSum)+')'}: undefined;
    return {
        voucher: voucher,
        user: state.auth.user,
        customers: state.customer.simpleData,
        isCustomerFetching: state.customer.isFetching,
        initialValues: {
            value: state.voucher.newVoucher === false && voucher ? voucher.value / 100 : '10',
            value_status: state.voucher.newVoucher === false && voucher ? voucher.value_status : '7base',
            customer: customer,
            reason: state.voucher.newVoucher === false && voucher ? voucher.reason : '',
            basic_type: state.voucher.newVoucher === false && voucher ? voucher.basic_type : 'absolute',
            status: (function(){
                if(voucher && !state.voucher.newVoucher ){
                    if(voucher.status == 'active'){
                        return 'Aktiv';
                    } else if(voucher.status == 'inactive'){
                        return 'Gelöscht';
                    } else if(voucher.status == 'cashed'){
                        return 'Eingelöst';
                    }
                } else {
                   return '';
                }
            }()),
            valid_from: '',
            valid_to: '',
            filter: voucher && voucher.filter ? voucher.filter : null,
            validity: state.voucher.newVoucher === false && voucher ? voucher.validity : moment().add(1, 'year').endOf('day').toDate(),
        },
        filters: state.customer.filterData,
        filterSaved: state.customer.filterSaved,
        formValueType: selector(state, 'basic_type'),
        settings: state.settings.data,
        formValueFilter: selector(state, 'filter'),
    }
}
const mapDispatchToProps = {
    saveVoucher: saveVoucherRequest,
    patchVoucher: patchVoucherRequest,
    mailVoucher: mailVoucherRequest,
    unsetActiveVoucher: unsetActiveVoucher,
    unsetNewVoucher: unsetNewVoucher,
    loadSimpleCustomersRequest: loadSimpleCustomersRequest,
    loadSettings: loadSettingsRequest
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'voucher-form',
        enableReinitialize: true,
    }),
    withStyles(style)
)(VouchersForm)