import React from "react";
import {MenuItem} from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../SelectGroup";
import moment from "moment";

const required = value => value ? null : 'Feld erforderlich';

const MonthYearSelect = (props) => {

    const options = [];
    let i = 1;
    while (i < 13) {
        const monthObj = moment().set("date", 1).subtract({months: i});
        let text = monthObj.format("MMMM YYYY");
        options.push(
            <option value={ monthObj.format('MM-YYYY') }>{ text }</option>
        );
        i++
    }

    return <Field
        name={props.fieldName}
        label="Monat"
        disabled={!!props.disabled}
        component={SelectGroup}
        margin="dense"
        fullWidth
        validate={props.required && [ required ]}
    >
        {options}
    </Field>;

};

export default MonthYearSelect;