import moment from "moment";
import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export function formatDateTime(value, withTime = true){
    if(value){
        if(withTime){
            return moment(value).format('DD.MM.YYYY HH:mm')
        } else {
            return moment(value).format('DD.MM.YYYY')
        }
    } else {
        return '';
    }
}

export function round(num, dec) {
    num = parseFloat(num);
    if (!num) return 0;
    dec = parseInt(dec);
    if (!dec) dec=0;

    var value = Math.pow(10,dec);
    return Math.round(num * value) / value;

}

export function formatMoneyFromApi(m, showCurrency = true) {
    return formatMoney(m, showCurrency);
}

export function formatMoney(m, showCurrency = true, g, h, k) {
    var _m = m /100; //convert cent to euro
    var c = isNaN(g = Math.abs(g)) ? 2 : g,
        d = h == undefined ? "," : h,
        t = k == undefined ? "." : k,
        s = _m < 0 ? "-" : "",
        i = String(parseInt(_m = Math.abs(Number(_m) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return (showCurrency ? "€ " : "") + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(_m - i).toFixed(c).slice(2) : "");
}
export function formatDuration(value, units = ["hours", "minutes"],  unit = "s", formatType = "units") {
    if(value < 0 ){
        value = value * (-1)
    }

    let duration = moment.duration(value,unit).asMilliseconds();
    duration= Math.round(duration / 1000)

    const daySeconds = 86400;
    const hourSeconds = 3600;
    const minuteSeconds = 60;
    const secondSeconds = 1;

    let days = false;
    let hours = false;
    let minutes = false;
    let seconds = false;

    if (units.indexOf("days") !== -1) {
        if (duration >= daySeconds) {
            days = parseInt(duration / daySeconds);
            duration = duration - (days * daySeconds);
        } else {
            days = 0;
        }
    }

    //Get hours and subtract from duration
    if (units.indexOf("hours") !== -1) {
        if (duration >= hourSeconds) {
            hours = parseInt(duration / hourSeconds);
            duration = duration - (hours * hourSeconds);
        } else {
            hours = 0;
        }
    }

    //Get Minutes and subtract from duration
    if (units.indexOf("minutes") !== -1) {
        if (duration >= minuteSeconds) {
            minutes = parseInt(duration / minuteSeconds);
            duration = duration - (minutes * minuteSeconds);
        } else {
            minutes = 0;
        }
    }

    //Get Seconds and subtract from duration
    if (units.indexOf("seconds") !== -1) {
        if (duration >= secondSeconds) {
            seconds = parseInt(duration / secondSeconds);
        } else {
            seconds = 0;
        }
    }

    switch (formatType) {
        case "digital":
            return <span>
                    {days !== false && <span>{days}T </span>}
                    {hours !== false && <span>{hours}</span>}
                    {minutes !== false && <span>:{minutes < 10 && "0"}{minutes}</span>}
                    {seconds !== false && <span>:{seconds < 10 && "0"}{seconds}</span>}
                </span>;
            break;
        default:
            return <span>
                    {days !== false && <span>{days}T </span>}
                    {hours !== false && <span>{hours}h </span>}
                    {minutes !== false && <span>{minutes}m </span>}
                    {seconds !== false && <span>{seconds}s </span>}
                </span>;
            break;
    }
}

export function isArrEqual(arr1, arr2, withId = false) {
    // if the other array is a falsy value, return
    if (!arr1 || !arr2)
        return false;

    // compare lengths - can save a lot of time
    if (arr1.length != arr2.length)
        return false;

    for (let i = 0, l=arr1.length; i < l; i++) {
        // Check if we have nested arrays
        if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arr1[i].equals(arr2[i]))
                return false;
        } else {
            if (withId) {
                if (arr1[i].id && arr2[i].id) {
                    if (arr1[i].id !== arr2[i].id) {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                if (arr1[i] != arr2[i]) {
                    // Warning - two different object instances will never be equal: {x:20} != {x:20}
                    return false;
                }
            }
        }
    }
    return true;
}

export function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            <Fragment>{children}</Fragment>
        </Typography>
    );
}

export function renderSort(key, {sortFields, sortField, sortDirection, onSortClick}) {
    console.log('sortField: ', sortField, sortDirection)
    const ascActive = sortField === key && sortDirection === 'asc';
    const descActive = sortField === key && sortDirection === 'desc';
    const ascColor = ascActive ? 'black' : 'darkgray';
    const descColor = descActive ? 'black' : 'darkgray';

    if(!!(sortFields?sortFields:[]).find(entry => entry === key)){
        return (
            <a style={{
                marginLeft: '10px',
                color: 'darkgray'
            }} onClick={() => onSortClick(key, ascActive, descActive)}>
                <ArrowUpwardIcon key={key + '-up'} fontSize={'small'} style={{color: ascColor}} />
                <ArrowDownwardIcon key={key + '-down'} fontSize={'small'} style={{color: descColor}}/>
            </a>
        );
    }
}

export function formatCheckinStatus(status){
    switch (status) {
        case 'to_invoice': return 'Bereit zu Zahlen'; break;
        case 'to_pay': return 'Bereit zu Zahlen'; break;
        case 'cancel': return 'Storniert'; break;
        case 'active': return 'Offen'; break;
        case 'paid': return 'Bezahlt'; break;
        default: return 'Unknown Status';
    }
}