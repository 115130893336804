import {
    LOAD_OPENING_REQUEST,
    LOAD_OPENING_SUCCESS,
    SAVE_OPENING_REQUEST,
    SAVE_OPENING_SUCCESS,
} from "../reducers/openingReducer";

export const loadOpeningRequest = ({id}) => ({
    type: LOAD_OPENING_REQUEST,
    id
});

export const loadOpeningSuccess = ({openingHours}) => ({
    type: LOAD_OPENING_SUCCESS,
    openingHours,
});

export const saveOpeningRequest = ({data}) => ({
    type: SAVE_OPENING_REQUEST,
    data
});

export const saveOpeningSuccess = () => ({
    type: SAVE_OPENING_SUCCESS,
});
