
/* Action types */
const PREFIX = "payment/";

export const LOAD_PAYMENTS_REQUEST = PREFIX + "LOAD_PAYMENTS_REQUEST";
export const LOAD_PAYMENTS_SUCCESS = PREFIX + "LOAD_PAYMENTS_SUCCESS";

export const DOWNLOAD_PAYMENTS_REQUEST = PREFIX + "DOWNLOAD_PAYMENTS_REQUEST";
export const DOWNLOAD_PAYMENTS_SUCCESS = PREFIX + "DOWNLOAD_PAYMENTS_SUCCESS";
export const DOWNLOAD_PAYMENTS_ERROR = PREFIX + "DOWNLOAD_PAYMENTS_ERROR";

export const PAYMENT_INITIALIZE_DATATABLE = PREFIX + "PAYMENT_INITIALIZE_DATATABLE";
export const PAYMENT_NEXT_PAGE = PREFIX + "PAYMENT_NEXT_PAGE";
export const PAYMENT_PREVIOUS_PAGE = PREFIX + "PAYMENT_PREVIOUS_PAGE";
export const PAYMENT_CHANGE_PER_PAGE = PREFIX + "PAYMENT_CHANGE_PER_PAGE";
export const PAYMENT_SORT_TABLE = PREFIX + "PAYMENT_SORT_TABLE";
export const PAYMENT_FILTER_TABLE = PREFIX + "PAYMENT_FILTER_TABLE";


/* initial state */
const initialState = {

    isFetching: false,
    isSaving: false,
    isDeleting: false,
    data: [],

    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'created_at',

    from: '',
    to: '',
    restaurant_id: [],
    customer_id: [],
    check_in_type: []

};

/* Reducer */
const paymentReducer = (state = initialState, action) => {

    switch(action.type) {
        case DOWNLOAD_PAYMENTS_REQUEST:
            return {
                ...state,
            };
        case DOWNLOAD_PAYMENTS_SUCCESS:
            return {
                ...state,
            };
        case DOWNLOAD_PAYMENTS_ERROR:
            return {
                ...state,
            };
        case LOAD_PAYMENTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_PAYMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payments,
                totalRecords: action.totalRecords
            };
        case PAYMENT_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case PAYMENT_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case PAYMENT_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case PAYMENT_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case PAYMENT_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case PAYMENT_FILTER_TABLE:
            return {
                ...state,
                from: action.data.from,
                to: action.data.to,
                restaurant_id: action.data.restaurant_id,
                customer_id: action.data.customer_id,
                check_in_type: action.data.check_in_type,
            };
        default:
            return state;
    }
};
export default paymentReducer;