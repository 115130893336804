import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {filterTable} from "../../../actions/userActions";
import {Grid} from "@material-ui/core";
import UserStatusSelect from "../../../components/Filter/UserStatusSelect";
import UserRoleSelect from "../../../components/Filter/UserRoleSelect";
import VoucherTypeSelect from "../../../components/Filter/VoucherTypeSelect";

class UsersFilter extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <UserRoleSelect
                            fieldName={'role'}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <UserStatusSelect
                            fieldName={'status'}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initialValues:{
            status: ['active'],
            role: ['admin']
        }
    }
}
const mapDispatchToProps = {
    setFilter: filterTable,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'user-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {
            dispatch(props.setFilter({
                values: {
                    status: values.status?values.status:null,
                    role: values.role?values.role:null,
                }
            }));
        },
    }),
    (withStyles(style)),
)(UsersFilter)