import React, {Component} from "react";
import {compose} from "redux";
import {Grid, CssBaseline, Box, Paper, withStyles} from '@material-ui/core';
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom"
import LoginScreen from "../../views/start/LoginScreen";
import style from "./StartContainerStyles"
import Restaurants from "../../views/app/Restaurants/Restaurants";


class StartContainer extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Router>
                            <Route exact path="/intern/restaurants" name="Restaurant" component={Restaurants}/>
                            <Route path="/login" component={ LoginScreen } />
                            <Redirect from="/" to="/login" />
                        </Router>
                        <Box mt={5}>
                            (c)
                        </Box>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(style)
)(StartContainer);
