import {
    LOGIN_REQUEST,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_CURRENT_USER,
    LOAD_CURRENT_USER_REQUEST, LOAD_CURRENT_USER_SUCCESS,
} from "../reducers/authReducer";
import setAuthorizationToken from "../utils/setAuthorizationToken";

export const loginRequest = (user) => ({
    type: LOGIN_REQUEST,
    ...user
})

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    ...user
})

export const loginFailed = error => ({
    type: LOGIN_FAILED,
    error
})

export const logout = () => {
    localStorage.removeItem('jwtToken');
    setAuthorizationToken(false);

    return {
        type: LOGOUT
    }
}

export const setCurrentUser = (user) => ({
    type: SET_CURRENT_USER,
    user
})

export const loadCurrentUser = () => ({
    type: LOAD_CURRENT_USER_REQUEST
})

export const loadCurrentUserSuccess = (user) => ({
    type: LOAD_CURRENT_USER_SUCCESS,
    user,
})