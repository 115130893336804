import {put, call, select} from "@redux-saga/core/effects";
import {
    loadExtendedFiltersError,
    loadExtendedFiltersSuccess,
    loadFilterByIdError,
    loadFilterByIdSuccess,
    loadFiltersError,
    loadFiltersSuccess, patchFilterError, patchFilterSuccess, saveFiltersError, saveFiltersSuccess
} from "../actions/filterActions";
import {addFlashMessage} from "../actions/flashMessageActions";

export function* loadExtendedFilterRequest (api, data) {
    const options = { };
    options['page'] = 1;
    options['page_size'] = 200;
    options['filter'] = {
        status: ['active']
    };
    let result = yield call(api.loadFilters, options);

    if (result && result.data && result.data.success) {
        yield put(loadExtendedFiltersSuccess({filters: result.data.filters, totalRecords: result.data.totalRecords }));
    } else {
        yield put(loadExtendedFiltersError());
    }
}

export function* loadFilterRequest (api, data) {

    const DataTableOptions = state => state.filter;
    const TableOptions = yield select(DataTableOptions);
    const options = {
        extended: true
    };

    if(TableOptions.sortDirection) {
        options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
        options['order_id'] = TableOptions.sortField;
    }

    options['page'] = TableOptions.page;
    options['page_size'] = TableOptions.perPage;
    options['filter'] = TableOptions.filterValues;

    if(data && data.search){
        options['filter'] = data.search;
    }

    let result = yield call(api.loadFilters, options);

    if (result && result.data && result.data.success) {
        yield put(loadFiltersSuccess({filters: result.data.filters, totalRecords: result.data.totalRecords }));
    } else {
        yield put(loadFiltersError());
    }
}

export function* loadFilterByIdRequest (api, data) {
    let result = yield call(api.loadFilterById, data.id);

    if (result && result.data && result.data.success) {
        yield put(loadFilterByIdSuccess(result.data));
    } else {
        yield put(loadFilterByIdError());
    }
}

export function* saveFilterRequest (api, data) {
    let result = null;

    if(!!data._id){
        result = yield call(api.updateFilter, data._id, {filter: data.filter, description: data.description, save: data.save});
    } else {
        result = yield call(api.saveFilter, {filter: data.filter, description: data.description, save: data.save});
    }

    if (result && result.data && result.data.success) {
        yield put(saveFiltersSuccess({customers: result.data.customers, saved: result.data.saved }));
        if(data.save){
            yield put(addFlashMessage({message:'Filter wurde gespeichert', style:'success'}))
        }
    } else {
        yield put(saveFiltersError());
        if(data.save){
            yield put(addFlashMessage({message:'Fehler beim Speichern des Filters', style:'error'}))
        }
    }
}

export function* patchFilterRequest (api, data) {
    let result = yield call(api.patchFilter, data._id, {action: data.action});

    if (result && result.data && result.data.success) {
        yield put(patchFilterSuccess({}));
        yield put(addFlashMessage({message:'Status wurde geändert', style:'success'}))
    } else {
        yield put(patchFilterError());
        yield put(addFlashMessage({message:'Fehler bei der Statusänderung', style:'error'}))
    }
}