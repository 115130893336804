import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {reduxForm, Field, reset, FieldArray, formValueSelector, change} from 'redux-form';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Paper, Grid, IconButton, Button, Switch, MenuItem, Typography, Dialog, AppBar, Toolbar } from "@material-ui/core";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {sendNotificationRequest, setCustomersFilter} from "../../../actions/notificationActions";
import MultipleSelect from "../../../components/MultipleSelect";
import {loadTagsRequest} from "../../../sagas/tagSagas";
import {loadTypeRequest} from "../../../actions/typeActions";
import {loadTagRequest} from "../../../actions/tagActions";
import SelectGroup from "../../../components/SelectGroup";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FiltersForm from "../Filter/FiltersForm";
import {loadExtendedFiltersRequest, loadFiltersRequest, saveFiltersRequest} from "../../../actions/filterActions";

class NotificationsFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false
        }
    }

    componentDidMount(){
        this.props.loadCustomerFilter();
    }

    componentDidUpdate(prevProps){
        if(this.props.filterSaved != null && this.props.filterSaved._id != (prevProps.filterSaved?prevProps.filterSaved._id:"asdf")){
            this.props.changeFilter(this.props.filterSaved._id);
        }
    }

    getFilterTypes = () => {
        const {filters} = this.props;
        return (filters?filters:[]).map(option => {
            return <MenuItem value={option._id}>{option.description}</MenuItem>;
        })
    };

    render() {
        const {classes} = this.props;
        return <Fragment>
            <Dialog fullScreen open={this.state.showFilter} >
                <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {this.setState({showFilter: false})}} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Empfänger filtern
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container style={{justifyContent:'center'}}>
                    <Grid item xs={10}>
                        <FiltersForm onClose={() => {this.setState({showFilter: false})}}/>
                    </Grid>
                </Grid>
            </Dialog>

            <Grid container xs={12}>
                <Grid item xs={12} md={7}>
                    <Field
                        name={`filter`}
                        label="Filter"
                        component={SelectGroup}
                        margin="dense"
                        fullWidth
                    >
                        {this.getFilterTypes()}
                    </Field>
                </Grid>
                <Grid item xs={2} md={1} style={{textAlign: 'center', paddingLeft: '5px', marginTop: '20px'}}>
                    <IconButton edge="start" color="inherit" onClick={() => {this.props.changeFilter(null)}} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10} md={4} style={{textAlign: 'center', paddingLeft: '5px', marginTop: '30px'}}>
                    <Button onClick={() => {this.setState({showFilter: true})}} color={'secondary'} variant={'outlined'} size={'small'} fullWidth>Neuer Filter</Button>
                </Grid>
            </Grid>
        </Fragment>;
    }
}

function mapStateToProps(state) {
    return {
        filters: state.filter.dataExtended,
        filterSaved: state.filter.saved
    }
};

const mapDispatchToProps = {
    sendNotification: sendNotificationRequest,
    loadCustomerFilter: loadExtendedFiltersRequest,
    saveCustomerFilter: saveFiltersRequest,
    loadTags: loadTagRequest,
    loadTypes: loadTypeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(NotificationsFilter));