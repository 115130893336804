import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {TableCell, TableHead, TableRow, TableBody, Table} from "@material-ui/core";
import {formatDateTime, formatMoneyFromApi} from "../../../utils/helper";
import Customers from "./Customers";
import CustomersStatisticsFilter from "./CustomersStatisticsFilter";

class CustomersStatisticsView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {}

    setFilter = (params) => {
        this.setState({
            filter: params?params.values:null
        });
    }

    renderHead() {
        return (
            <TableHead>
                <TableRow>
                    <TableCell key={'2'} className={'col-md-2'}>{'Jahr'}</TableCell>
                    <TableCell key={'1'} className={'col-md-2'}>{'Monat'}</TableCell>
                    <TableCell key={'3'} className={'col-md-8'}>{'Umsatz'}</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderBody(data) {
        const tr = data.map((row) => {
            const toReturn = [];

            toReturn.push(<TableRow>
                <TableCell>{row['year']}</TableCell>
                <TableCell>{row['month']}</TableCell>
                <TableCell>{formatMoneyFromApi(row['sum_gross_with_discount'])}</TableCell>
            </TableRow>);

            return toReturn;
        });
        return (
            <TableBody>
                {tr}
            </TableBody>
        );
    }

    render() {

        const { data } = this.props;
        const { filter } = this.state;
        let filtered = [...data];
        if(!!filter && !!filter.year){
            filtered = data.filter(e => e['year'] == filter.year);
        }

        return (
            <Fragment>
                <CustomersStatisticsFilter setFilter={this.setFilter} />
                <div className='table-wrapper'>
                    <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                        {this.renderHead()}
                        {this.renderBody(filtered)}
                    </Table>
                </div>
            </Fragment>

        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CustomersStatisticsView)
