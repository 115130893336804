import React from "react";
import {Field} from "redux-form";
import MultipleSelect from "../MultipleSelect";

const options = [
    {_id: 'active', name: 'Aktiviert'},
    {_id: 'activation_code_sent', name: 'Aktivierungsode wurde versendet'},
    {_id: 'pending', name: 'Noch keine Aktivierung'},
    {_id: 'inactive', name: 'Inaktiv'},
];
const WaiterStatusSelect = (props) => {

    return  <Field
        format={value => Array.isArray(value) ? value : []}
        name={props.fieldName}
        component={MultipleSelect}
        label={'Status'}
        fullWidth
        accessorValue={"_id"}
        accessorLabel={'name'}
        options={options}
    />;
};

export default WaiterStatusSelect;