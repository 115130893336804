import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import RestaurantsForm from './RestaurantsForm'


class NewRestaurants extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <Fragment >
                <RestaurantsForm/>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
    }
}
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(NewRestaurants));



