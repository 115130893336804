import {put, call, select} from "@redux-saga/core/effects";
import {
  loadCustomersSuccess
} from "../actions/customerActions";
import {
    getNotificationError, getNotificationSuccess, sendNotificationError,
    sendNotificationSuccess
} from "../actions/notificationActions";
import {addFlashMessage} from "../actions/flashMessageActions";

export function* sendNotificationRequest (api, params) {
    try {
        let result = yield call(api.sendNotification, params.notificationType, {...params} );
        if (result) {
            yield put(sendNotificationSuccess());
            yield put(addFlashMessage({message:'Die Pushnachrichten wurden verschickt', style:'success'}))
        } else {
            yield put(sendNotificationError());
        }
    } catch (e) {
        console.log(e)
    }
}

export function* getNotificationRequest (api, params) {
    try {
        let result = yield call(api.getNotifications, params );
        if (result && result.data && result.data.notifications) {
            yield put(getNotificationSuccess({data: result.data.notifications}));
        } else {
            yield put(getNotificationError());
            yield put(addFlashMessage({message:'Fehler beim Laden der Notifications', style:'error'}))
        }
    } catch (e) {
        console.log(e)
    }
}

