import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import {
    deleteWaiterRequest, saveWaiterRequest, sendWaiterWelcomeRequest, unsetActiveWaiter,
    unsetNewWaiter
} from "../../../actions/waiterActions";
import {Link} from "react-router-dom";


class WaiterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            deactivateModal: false
        }
    }

    required = value => value ? undefined : 'Feld erforderlich';

    handleActivation = () =>{
        this.props.sendWaiterWelcome({
            data: {
                _id: this.props.waiter._id
            }
        })
    }

    render() {
        const { handleSubmit, classes, waiter, isNewWaiter, restaurant } = this.props;

        const submitAll = (values) => {
            if(isNewWaiter === false && waiter._id && waiter._id.length){
                this.props.saveWaiter({
                    data:{
                        _id: waiter._id,
                        firstname: values.firstname,
                        lastname: values.lastname,
                        email: values.email,
                        restaurant_id: restaurant._id
                    }
                })
                this.props.unsetActiveWaiter();
            } else {
                this.props.saveWaiter({
                    data:{
                        firstname: values.firstname,
                        lastname: values.lastname,
                        email: values.email,
                        restaurant_id: restaurant._id
                    }
                })
                this.props.unsetNewWaiter()
            }
        };

        const deleteWaiter = (action) => {
            if( waiter._id && waiter._id.length) {
                this.props.deleteWaiter({
                    data:{
                        _id: waiter._id,
                        restaurant_id: restaurant._id,
                        action: action
                    }
                })
                this.props.unsetActiveWaiter();

            }
        };

        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '100px'}}>
                <Grid xs={12}>
                    <form>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="firstname"
                                    label="Vorname"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="lastname"
                                    label="Nachname"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="email"
                                    label="Email"
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[ this.required ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                    <Field
                                        name="status"
                                        label="Status"
                                        component={TextFieldGroup}
                                        margin="dense"
                                        disabled
                                    />

                                    {waiter && waiter.status === 'pending' && isNewWaiter != true &&
                                        <div style={{width: '75%'}}>
                                            <Button fullWidth onClick={this.handleActivation} variant={'contained'} color="primary" size={'medium'}>
                                                Aktivierungscode versenden
                                            </Button>
                                        </div>
                                    }

                            </Grid>
                        </Grid>


                        {isNewWaiter === true ?
                            <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(submitAll)} variant={'outlined'} color="primary">
                                Personal erstellen
                            </Button>
                            :
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(submitAll)} variant={'outlined'} color="primary">
                                        Personal sichern
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth style={{marginTop: '50px'}} onClick={() => {waiter.status === 'active' ? this.setState({deactivateModal: true}) : deleteWaiter('enable')}} variant={'outlined'} color="default">
                                        {waiter.status === 'active' ? 'Personal inaktiv setzen' : 'Personal aktiv setzen'}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={() => {this.setState({deleteModal: true})}} variant={'outlined'} color="default">
                                        Personal löschen
                                    </Button>
                                </Grid>
                            </Grid>

                        }
                    </form>
                </Grid>

                {(this.state.deleteModal === true || this.state.deactivateModal === true) &&
                    <Dialog open={true}>
                        <DialogTitle>Sind Sie sicher?</DialogTitle>

                        <DialogContent>{this.state.deactivateModal ? 'Möchten Sie den Eintrag wirklich inaktiv setzen?' : 'Möchten Sie den Eintrag wirklich entfernen?'}</DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({deleteModal: false, deactivateModal: false})}} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={() => {this.state.deactivateModal ? deleteWaiter('disable') : deleteWaiter('delete')}} color="primary">
                                {this.state.deactivateModal ? 'Inaktiv setzen' : 'Entfernen' }
                            </Button>
                        </DialogActions>

                    </Dialog>
                }
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    let status = ' ';
    const waiter = state.waiter.activeWaiterData;

    if(waiter && waiter.status === 'pending'){
        status = 'Noch keine Aktivierung';
    } else if(waiter && waiter.status === 'activation_code_sent') {
        status = 'Aktivierungsode wurde versendet';
    } else if(waiter && waiter.status === 'active') {
        status = 'Aktiviert';
    } else if(waiter && waiter.status === 'inactive'){
        status = 'Inaktiv';
    }


    return{
        waiter: waiter,
        restaurant: state.restaurant.activeRestaurantData,

        initialValues:{
            firstname: waiter && state.waiter.newWaiter === false ? state.waiter.activeWaiterData.firstname : '',
            lastname: waiter && state.waiter.newWaiter === false ? state.waiter.activeWaiterData.lastname : '',
            email: waiter && state.waiter.newWaiter === false ? state.waiter.activeWaiterData.email : '',
            status:  state.waiter.newWaiter === false ? status : '',

        }
    }
}
const mapDispatchToProps = {
    saveWaiter: saveWaiterRequest,
    deleteWaiter: deleteWaiterRequest,
    unsetActiveWaiter: unsetActiveWaiter,
    unsetNewWaiter: unsetNewWaiter,
    sendWaiterWelcome: sendWaiterWelcomeRequest
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'waiter-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(WaiterForm)