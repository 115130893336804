import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Grid } from "@material-ui/core";
import NotificationsList from './NotificationsList';
import NotificationsCreate from './NotificationsCreate';
import {isAllowed} from "../../../utils/permissionHelper";


class Notifications extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Fragment>
                <Grid container spacing={10} item xs={12}>
                    {isAllowed(this.props.user, 'notifications', 'create') &&
                        <Grid item xs={12} md={6}>
                            <NotificationsCreate title={'Neue Notification versenden'} messageType={'ALL_CUSTOMER'}/>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <NotificationsList/>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.auth.user
    }
}

export default compose(
    connect(mapStateToProps, null),
    withStyles(style))
(Notifications);