import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    detailsInitializeDataTable,
    detailsNextPage,
    detailsPreviousPage,
    detailsChangePerPage,
    detailsSort,
    detailsFilterTable, accountingPdfRequest, accountingStripeTransferRequest, accountingDetailsPdfRequest
} from '../../../actions/accountingActions';


const mapStateToProps = (state) => {
    let { detailsPage, detailsPerPage, detailsTotalRecords, isFetching, detailsSortDirection, detailsSortField } = state.accounting;
    let data = state.accounting.detailsData && state.accounting.detailsData.accountings_entries ? state.accounting.detailsData.accountings_entries : [];

    const fields = {
        "Restaurant": "restaurant_name",
        "Rab. Umsatz (Netto)": "custom_sum_net_with_discount",
        "Ausgleichsbetrag (Netto)": "sum7_diff_net",
        "Rabatt (Netto)": "custom_sum7stat",
        "Provison (Netto)": "c7_provision_net",
        "Rechnungsbetrag (Netto)": "c7_provision_diff_net",
        "Summe Stripe Konto": "sum_gross_total_restaurant",
        "Summe eingelöster Gutscheine": "sum_vouchers",
        "Bruttosumme inkl. Gutscheine": "c7_provision_diff_gross",
        "Stripe": "stripe_transfer",
        "Aktionen": "pdf_action"
    };

    const click = true;

    return {
        page: detailsPage,
        perPage: detailsPerPage,
        totalRecords: detailsTotalRecords,
        data,
        loading: isFetching,
        detailsSortDirection,
        detailsSortField,
        fields,
        filterComponent: () => {},
        click,
        hideSort: true,
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable: (options => {dispatch(detailsInitializeDataTable(options))}),
        nextPage: () => { dispatch(detailsNextPage())},
        previousPage: () => {dispatch(detailsPreviousPage())},
        changePerPage: (value => { dispatch(detailsChangePerPage(value))}),
        sort: (field, value) => {
            dispatch(detailsSort(field, value))},
        onClickRow: (rowData) => {},
        accountingPdf: (rowData) => { dispatch(accountingPdfRequest({id: rowData['_id']}))},
        accountingDetailsPdf: (rowData) => { dispatch(accountingDetailsPdfRequest({id: rowData['_id']}))},
        accountingStripeTransfer: (rowData) => { dispatch(accountingStripeTransferRequest({id: rowData['_id'], month: rowData['month']}))}
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
