import {
    LOAD_TYPE_REQUEST,
    LOAD_TYPE_SUCCESS
} from "../reducers/typeReducer";

export const loadTypeRequest = () => ({
    type: LOAD_TYPE_REQUEST,
});

export const loadTypeSuccess = ({types}) => ({
    type: LOAD_TYPE_SUCCESS,
    types
});
