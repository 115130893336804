import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import authReducer from './reducers/authReducer';
import restaurantReducer from './reducers/restaurantReducer'
import fileReducer from './reducers/fileReducer';
import postReducer from './reducers/postReducer';
import openingHoursReducer from './reducers/openingReducer';
import tagReducer from './reducers/tagReducer';
import flashMessageReducer from './reducers/flashMessageReducer';
import typeReducer from './reducers/typeReducer';
import waiterReducer from './reducers/waiterReducer';
import userReducer from './reducers/userReducer';
import checkinReducer from './reducers/checkinReducer';
import paymentReducer from './reducers/paymentReducer';
import customerReducer from './reducers/customerReducer';
import notificationReducer from "./reducers/notificationReducer";
import accountingReducer from "./reducers/accountingReducer";
import voucherReducer from "./reducers/voucherReducer";
import settingsReducer from "./reducers/settingsReducer";
import stripeReducer from "./reducers/stripeReducer";
import helloReducer from "./reducers/helloReducer";
import filtersReducer from "./reducers/filtersReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    form: formReducer,
    restaurant: restaurantReducer,
    file: fileReducer,
    post: postReducer,
    opening: openingHoursReducer,
    tag: tagReducer,
    flashMessage: flashMessageReducer,
    type: typeReducer,
    waiter: waiterReducer,
    user: userReducer,
    checkin: checkinReducer,
    payment: paymentReducer,
    customer: customerReducer,
    notification: notificationReducer,
    accounting: accountingReducer,
    voucher: voucherReducer,
    settings: settingsReducer,
    stripe: stripeReducer,
    hello: helloReducer,
    filter: filtersReducer,
});

export default rootReducer;