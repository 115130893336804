import React, {Component, Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {Dialog, IconButton, AppBar, Toolbar, Typography, Grid} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {isAllowed} from "../../../utils/permissionHelper";
import FiltersForm from "./FiltersForm";
import FiltersView from "./FiltersView";
import {loadFiltersRequest, setActiveFilter} from "../../../actions/filterActions";

function Filters(props){

    const [newFilter, setNewFilter] = useState(false);

    useEffect(() => {
       props.loadFilters();
    }, [props]);

    useEffect(() => {
        props.loadFilters();
    }, [props, props.saved]);

    const onClose = () => {
        setNewFilter(false); props.setActiveFilter(null);
    }

    return (
        <Fragment>
            <h2>Filtermanagement</h2>
            { isAllowed(props.user, 'filters', 'create') &&
                <IconButton onClick={() => {setNewFilter(true)}} style={{float: 'right'}}>
                    <AddIcon fontSize={'large'}/>
                </IconButton>
            }
            <FiltersView />

            {(newFilter === true || !!props.activeFilter) &&
                <Dialog fullScreen open={true} >
                    <AppBar style={{backgroundColor: '#262c46'}} className={props.classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={props.classes.title}>
                                Neuen Filter erstellen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container style={{justifyContent:'center'}}>
                        <Grid item xs={10}>
                            <FiltersForm onClose={onClose} />
                        </Grid>
                    </Grid>
                </Dialog>
            }
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        filters: state.filter.data,
        saved: state.filter.saved,
        activeFilter: state.filter.activeFilter,
    }
}
const mapDispatchToProps = {
    loadFilters: loadFiltersRequest,
    setActiveFilter: setActiveFilter,
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Filters));