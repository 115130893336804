import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Grid, FormControl, Select, InputLabel, MenuItem} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {filterTable} from "../../../actions/paymentActions";
import MultipleSelect from "../../../components/MultipleSelect";
import {loadSimpleCustomersRequest} from "../../../actions/customerActions";
import AsyncSelectSearch from "../../../components/AsyncSelectSearch";
import {loadSettingsRequest} from "../../../actions/settingsActions";
import SelectGroup from "../../../components/SelectGroup";
import DateRangeFilter from "../../../components/Filter/DateRangeFilter";

const renderSelectField = ({
                               input,
                               label,
                               meta: { touched, error },
                               children,
                               fullWidth,
                               ...custom
                           }) => (
    <FormControl error={touched && error} fullWidth={fullWidth}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            native
            {...input}
            {...custom}
        >
            {children}
        </Select>
    </FormControl>

);

class PaymentsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentDidMount(){
        this.props.loadSettings();
    }

    setCustomerSearchOpen = (value) => {
        this.setState({open: value});
    };


    customerValueChanged = (value) => {
        let search = {
            searchValue: value,
            fields: ['firstname', 'lastname', 'email'],
            status: ['active', 'activation_code_sent', 'inactive'],
            dependency: {
                payment: {
                    from: null,
                    to: null,
                    restaurant: null,
                }
            }
        };
        this.props.loadSimpleCustomersRequest({search});
    };

    customerSelected = (value) => {
        this.props.change('customer', value?value.value:null);
    };

    getDiscounts = () => ((this.props.settings&&this.props.settings.club7?this.props.settings.club7:[])).map(d => {
        return <MenuItem value={d.name}>{d.name} ({d.value}%)</MenuItem>;
    });

    getWeekDays = () => {
        const weekdayOptions = [<option value={"Alle"}>Alle</option>];
        for(var i=1;i<=7;i++){
            const obj = moment().set("day", i);
            weekdayOptions.push(
                <option value={ i }>{ obj.format("dddd") }</option>
            );
        }
        return weekdayOptions;
    }


    render() {
        const {classes, restaurants, customers, isCustomerFetching} = this.props;
        const { open } = this.state;

        /*generate months fo filter*/
        const monthOptions = [<option value={"Alle"}>Alle</option>];
        let i = 0;
        while (i < 13) {
            const monthObj = moment().set("date", 1).subtract({months: i});
            monthOptions.push(
                <option value={ monthObj.format("YYYY-MM-DD") }>{ monthObj.format("MMMM YYYY") }</option>
            );
            i++
        }

        const restaurantOptions = (restaurants?restaurants:[]).map(restaurant => {
            return {
                ...restaurant
            }
        });

        const customerOptions = (customers?customers:[]).map(customer =>{
            return {
                value: customer._id,
                label: customer.firstname + ' ' + customer.lastname
            }
        });

        const discountOptions = [<option value={null}>Alle</option>];
        ((this.props.settings&&this.props.settings.club7?this.props.settings.club7:[])).forEach(d => {
            discountOptions.push(<option value={d.name}>{d.name} ({d.value}%)</option>);
        });

        const typeOptions = [
            <option value={ null }>{ 'Alle' }</option>,
            <option value={ 'club7' }>{ 'club7' }</option>,
            <option value={ 'take7' }>{ 'take7' }</option>,
        ];

        const taxOptions = [
            <option value={ null }>{ 'Alle' }</option>,
            <option value={ '0' }>{ '0%' }</option>,
            <option value={ '5' }>{ '5%' }</option>,
            <option value={ '10' }>{ '10%' }</option>,
            <option value={ '13' }>{ '13%' }</option>,
            <option value={ '19' }>{ '19%' }</option>,
            <option value={ '20' }>{ '20%' }</option>,
        ];

        return (
            <Fragment>
                <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                    <Grid item md={3} xs={12} style={{paddingTop: 35}}>
                        <DateRangeFilter
                            fieldNameFrom={'date_from'}
                            fieldNameTo={'date_to'}
                            labelFrom={'Erstellt am (von)'}
                            labelTo={'Erstell am (bis)'}
                        />
                    </Grid>
                    { (!this.props.restaurant_id && restaurantOptions) &&
                        <Grid item md={3} xs={12} style={{paddingTop: 35}}>
                            <Field
                                format={value => Array.isArray(value) ? value : []}
                                name="restaurants"
                                component={MultipleSelect}
                                label={'Restaurants'}
                                fullWidth
                                placeholder={'Alle'}
                                accessorValue={"_id"}
                                accessorLabel={"name"}
                                options={restaurantOptions}
                            />
                        </Grid>
                    }

                    {(!this.props.customer_id) &&
                        <Grid item md={3} xs={12} style={{paddingTop: 35}}>
                            <Field
                                name="customer"
                                component={AsyncSelectSearch}
                                label={'7user'}
                                fullWidth
                                setOpen={this.setCustomerSearchOpen}
                                onInputChange={this.customerValueChanged}
                                onChange={this.customerSelected}
                                open={open}
                                loading={isCustomerFetching}
                                options={customerOptions}
                            />
                        </Grid>
                    }
                    <Grid item md={3} xs={12}>
                        <div style={{marginTop: 25}}>
                            <Field
                                name="check_in_type"
                                fullWidth
                                component={renderSelectField}
                                label="Kategorie"
                            >
                                { typeOptions }
                            </Field>
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div style={{marginTop: 25}}>
                            <Field
                                name="discount_name"
                                label="Status"
                                component={renderSelectField}
                                margin="dense"
                                fullWidth
                            >
                                {discountOptions}
                            </Field>
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div style={{marginTop: 25}}>
                            <Field
                                name="month"
                                fullWidth
                                component={renderSelectField}
                                label="Monat"
                            >
                                { monthOptions }
                            </Field>
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div style={{marginTop: 25}}>
                            <Field
                                name="weekday"
                                fullWidth
                                component={renderSelectField}
                                label="Tag"
                            >
                                { this.getWeekDays() }
                            </Field>
                        </div>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        restaurants: state.restaurant.data,
        customers: state.customer.simpleData,
        settings: state.settings.data,
        isCustomerFetching: state.customer.isFetching,
        initialValues: {
            month: "Alle",
            restaurants: [],
            customer: '',
            check_in_type: ''
        },
    }
}
const mapDispatchToProps = {
    setFilter: filterTable,
    loadSettings: loadSettingsRequest,
    loadSimpleCustomersRequest: loadSimpleCustomersRequest,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'payment-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {
            let from = values.month ? moment(values.month, "YYYY-MM-DD").startOf('month').format("YYYY-MM-DD") : moment().startOf('month').format("YYYY-MM-DD");
            let to = values.month ? moment(values.month, "YYYY-MM-DD").endOf('month').format("YYYY-MM-DD") :  moment().endOf('month').format("YYYY-MM-DD");
            if(values.month === 'Alle'){
                from = null;
                to = null;
            }

            /* if restaurant_id in props (for Restaurant -> Payments)*/
            let restaurant = [];
            if(props.restaurant_id && props.restaurant_id.length){
                restaurant.push(props.restaurant_id)
            }

            let customers = [];
            if(props.customer_id) {
                customers = [props.customer_id];
            } else if(values.customer) {
                customers = [values.customer.value];
            } else {
                customers = '';
            }

            let restaurants = [];
            if(props.restaurant_id){
                restaurants = [props.restaurant_id];
            } else if(values.restaurants) {
                restaurants = [...values.restaurants];
            }

            let discount_name = [];
            if(values.discount_name && values.discount_name !== 'Alle') {
                discount_name = [values.discount_name];
            }

            let tax = [];
            if(values.tax){
                tax = [...values.tax];
            }

            let weekday = [];
            if(values.weekday && values.weekday !== 'Alle'){
                weekday = [...values.weekday];
            }

            let created_at_time_range = [];
            if(values.date_from && values.date_to){
                created_at_time_range = [values.date_from, values.date_to];
            }


            /* set filter in state and loadPayments with sagas after setFilter*/
            dispatch(props.setFilter({
                data: {
                    tax: tax,
                    from: values.date_from?values.date_from:from,
                    to: values.date_to?values.date_to:to,
                    discount_name: discount_name,
                    restaurant_id: restaurants,
                    customer_id: customers,
                    check_in_type: values.check_in_type,
                    weekday: weekday
                }
            }));

            dispatch(props.loadSimpleCustomersRequest({search: {
                searchValue: '',
                fields: ['firstname', 'lastname', 'email'],
                status: ['active', 'activation_code_sent', 'inactive'],
                dependency: {
                    payment: {
                        from: from,
                        to: to,
                        restaurants: restaurants,
                    }
                }
            }}));


        },
    }),
    (withStyles(style)),
)(PaymentsFilter)