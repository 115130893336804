import React from 'react';
import PropTypes from 'prop-types';
import {TextField, withStyles} from "@material-ui/core";

import AppStyles from "../AppStyles";

const TextFieldGroup = ({ input, field, value, label, margin, classes, type, onChange, disabled, onBlur, onFocus, fullWidth, placeholder, required, autoComplete, multiline, autoFocus, maxLength, size, inline, labelWidth, className, style, min, max, steps, unit, rows, help, inputProps, variant, id, disable,  meta: { touched, error, warning } }) => {

	return (
		<>
			<TextField
				{...input}
				label={label}
				margin={margin}
				required={required}
				onBlur={onBlur}
				onFocus={onFocus}
				type={type}
				disabled={disabled?disabled:false}
				placeholder={placeholder}
				autoFocus={autoFocus}
				maxLength={maxLength}
				size={size}
				id={id}
				error={touched && error}
				fullWidth={fullWidth}
				rows={rows}
				multiline={multiline}
				style={style}
				variant = {variant}
				autoComplete={{autoComplete}}

				classes={{ disabled: classes.disabledInput, root: classes.text }}

				InputProps={{
                    disableUnderline: disable,
					classes: {
						root: classes.input,
						disabled: classes.disabledInput
					},
					inputProps: {
						min,
						max,
						step: steps
					},
					style:{
                    	paddingTop: '5px',
						paddingLeft: '1px'
					}
				}}
				InputLabelProps={{
					shrink: true,

				}}
			/>
			<small>
				{touched && ((error && <span style={{color: '#f44336'}}>{error}</span>) || (warning && <span>{warning}</span>))}
			</small>
		</>
	)
}

TextFieldGroup.propTypes = {
	label: PropTypes.string.isRequired,
	error: PropTypes.string,
	size: PropTypes.string,
	type: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	autoFocus: PropTypes.bool.isRequired,
	autoComplete: PropTypes.bool,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func
}

TextFieldGroup.defaultProps = {
	type: 'text',
	placeholder: '',
	disabled: false,
	autoFocus: false,
	maxLength: 255,
	size: "",
	inline: false,
	labelWidth: 3,
	min: false,
	max: false,
	steps: false,
	className: "",
	onBlur: null,
	onFocus: null,
	rows: 4,
	help: false,
	autoComplete: false,
	required: false,
	fullWidth: true,
	margin: "dense",
}

const styles = theme => ({
    textField: {
		disabled: {
        	backgroundColor: "transparent"
		}
    },
    input: {

    },
	disabledInput: {
        backgroundColor: "#transparent!important",
		color: theme.palette.grey[900],
		"&:before": {
        	borderBottom: "none!important"
		}
	},
});

export default withStyles(styles)(TextFieldGroup);