import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Select, FormControl, InputLabel, withStyles, MenuItem} from "@material-ui/core";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const SelectGroup = ({ input, label, children, variant, disabled, classes, fullWidth, margin, autoWidth, native, multiple = false,  meta: { touched, error, warning }}) => {
    let selectTheme = false;

    if(disabled === true){
        selectTheme = createTheme({
            overrides: {
                MuiFilledInput: {
                    root: {
                        backgroundColor: "transparent !important",
                        "&:hover": {
                            backgroundColor: "transparent !important"
                        },
                    }
                },
                MuiInputBase: {
                    root: {
                        "&:before": {
                            display: "none"
                        },
                    }
                }
            }
        })
    }

    return (
        <MuiThemeProvider theme={selectTheme}>
            { disabled === true ?
                <FormControl
                    variant={variant}
                    fullWidth={fullWidth}
                    margin={margin}
                    classes={{
                        root: classes.disabledInput,
                    }}
                >
                    <InputLabel shrink={true}>{label}</InputLabel>
                    <Select
                        {...input}
                        variant={variant}
                        children={children}
                        disabled={disabled}
                        style={{color: "#212121", paddingTop: '5px', paddingLeft: '1px'}}
                        native={native}
                        fullWidth={fullWidth}
                    />
                </FormControl>
            :
                <FormControl
                    variant={variant}
                    fullWidth={fullWidth}
                    margin={margin}
                    classes={{
                        root: classes.input,
                    }}
                >

                    {touched && error ?
                        <InputLabel shrink={true}>
                            {touched && ((error && <span style={{color: '#f44336'}}> {label}</span>) || (warning &&
                                <span>{warning}</span>))}
                        </InputLabel>
                        :
                        <InputLabel shrink={true}>
                           {label}
                        </InputLabel>
                    }
                    <Select
                        {...input}
                        variant={variant}
                        children={children}
                        disabled={disabled}
                        style={{paddingTop: '5px', paddingLeft: '1px'}}
                        native={native}
                        fullWidth={fullWidth}

                    />
                    <small>
                        {touched && ((error && <span style={{color: '#f44336'}}>{error}</span>) || (warning && <span>{warning}</span>))}
                    </small>
                </FormControl>

            }
        </MuiThemeProvider>
    )
}

const styles = theme => ({
    input: {
        backgroundColor: "#transparent!important"
    },
    disabledInput: {
        backgroundColor: "transparent !important",
        "&::before": {
            borderColor: "transparent",
        },
    }
});



export default withStyles(styles)(SelectGroup);