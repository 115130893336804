import React from 'react'
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';

import TextFieldGroup from "../../../components/TextFieldGroup";
import {Button} from "@material-ui/core";
import {loginRequest} from "../../../actions/authActions";

let LoginForm = props => {
    const { handleSubmit, login } = props;
    return (
        <form onSubmit={handleSubmit(login)}>
            <Field
                name="email"
                label="Email"
                component={TextFieldGroup}
                type="email"
                required={true}
            />
            <Field
                name="password"
                label="Passwort"
                component={TextFieldGroup}
                type="password"
                required={true}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
            >
                Einloggen
            </Button>
        </form>
    );
};

const mapDispatchToProps = {
    login: loginRequest
};

export default compose(
    connect(null, mapDispatchToProps),
    reduxForm({
        form: 'login-form'
    }),
)(LoginForm)