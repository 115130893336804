import {
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    GET_FILE_REQUEST,
    GET_FILE_SUCCESS,
    LOAD_FILE_TYPE_REQUEST,
    LOAD_FILE_TYPE_SUCCESS,
    OPEN_EDIT,
    CLOSE_EDIT,
    UPDATE_FILE_REQUEST,
    UPDATE_FILE_SUCCESS,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    LOAD_PICTURES_REQUEST,
    LOAD_PICTURES_SUCCESS,
    MEDIA_LIBRARY_REQUEST,
    MEDIA_LIBRARY_SUCCESS,
    SET_MEDIA_LIBRARY,
    UNSET_MEDIA_LIBRARY,
} from "../reducers/fileReducer";

export const uploadFileRequest = ({files}) =>({
    type: UPLOAD_FILE_REQUEST,
    files
});

export const uploadFileSuccess = () => ({
    type: UPLOAD_FILE_SUCCESS,
});

export const getFileRequest = ({id}) =>({
    type: GET_FILE_REQUEST,
    id
});

export const getFileSuccess = () => ({
    type: GET_FILE_SUCCESS,
});

export const loadFileTypeRequest = () =>({
    type: LOAD_FILE_TYPE_REQUEST,
});

export const loadFileTypeSuccess = ({types}) => ({
    type: LOAD_FILE_TYPE_SUCCESS,
    types
});

export const openEdit = (file) => ({
    type: OPEN_EDIT,
    file
});

export const closeEdit = () => ({
    type: CLOSE_EDIT,

});

export const updateFileRequest = ({data}) =>({
    type: UPDATE_FILE_REQUEST,
    data
});

export const updateFileSuccess = () => ({
    type: UPDATE_FILE_SUCCESS,
});

export const deleteFileRequest = (id) =>({
    type: DELETE_FILE_REQUEST,
    id
});

export const deleteFileSuccess = () => ({
    type: DELETE_FILE_SUCCESS,
});

export const loadPicturesRequest = ({id}) =>({
    type: LOAD_PICTURES_REQUEST,
    id
});

export const loadPicturesSuccess = ({pictures}) => ({
    type: LOAD_PICTURES_SUCCESS,
    pictures
});

export const mediaLibraryRequest = (values) =>({
    type: MEDIA_LIBRARY_REQUEST,
    ...values
});

export const mediaLibrarySuccess = ({pictures}) => ({
    type: MEDIA_LIBRARY_SUCCESS,
    pictures
});

export const setMediaLibrary = () => ({
    type: SET_MEDIA_LIBRARY,
});

export const unsetMediaLibrary = () => ({
    type: UNSET_MEDIA_LIBRARY,
});
