import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem, Tabs, Tab, IconButton} from '@material-ui/core'
import {
    deleteUserRequest, patchUserRequest, saveUserRequest, unsetActiveUser,
    unsetNewUser
} from "../../../actions/userActions";
import SelectGroup from '../../../components/SelectGroup';
import SwitchGroup from "../../../components/SwitchGroup";
import {updateSettingsRequest} from "../../../actions/settingsActions";
import {a11yProps, round, TabPanel} from "../../../utils/helper";
import DatePickerGroup from "../../../components/DatePickerGroup";
import {loadMediaLibraryRequest} from "../../../sagas/fileSagas";
import {mediaLibraryRequest, setMediaLibrary} from "../../../actions/fileActions";
import getBaseUrl from "../../../utils/getBaseUrl";
import EditIcon from "@material-ui/icons/Edit";
import MediaLibrary from "../../../components/MediaLibrary";

class InvoiceSettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            uploadType: 'logo',
            logo: null,
            stamp: null
        }
    }

    required = value => value ? undefined : 'Feld erforderlich';

    number = value => {
        let _value = Number(value);
        return Number.isInteger(_value) && Number(_value) >= 0 && Number(_value) <= 1000 ? undefined : 'Eingabe muss eine Zahl sein.';
    };

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };


    submitAll = (values) => {
        if(values.invoice){
            values.invoice.logo_id = this.getLogoId();
            values.invoice.stamp_id = this.getStampId();
        }
        this.props.updateSettings({
            invoice: values.invoice,
        });
    };

    handleMediaLibrary = (uploadType) => {
        this.setState({uploadType: uploadType}, () => {
            this.props.loadMediaLibrary({id: 'c7-invoice-settings'})
            this.props.setMediaLibrary();
        })
    };

    addPicture = (id) => {
        const { uploadType } = this.state;
        this.setState({
            [uploadType]: id
        });
    };

    getLogoId = () => {
        return !!this.state.logo?this.state.logo:(this.props.settings && this.props.settings.invoice ? this.props.settings.invoice.logo_id : null);
    }

    getStampId = () => {
        return !!this.state.stamp?this.state.stamp:(this.props.settings && this.props.settings.invoice ? this.props.settings.invoice.stamp_id : null);
    }

    getPicturePreview = (type) => {
        const id = type=='logo'?this.getLogoId():this.getStampId();
        if(!!id){
            return <img src={getBaseUrl()+"/api/files/" + id + "/file"} height="auto" style={{maxWidth: '80%'}}/>;
        } else {
            return null;
        }
    };

    render() {
        const { handleSubmit, classes, settings, mediaLibrary } = this.props;

        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '50px', padding: '30px'}}>
                <Grid item xs={12}>
                    <h2>Rechnungsdaten</h2>
                    <Grid item>
                        <Grid xs={6}>
                            <Field
                                name={`invoice.name`}
                                label={"Firmenname"}
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                validate={[this.required ]}
                            />
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <Field
                                    name={`invoice.street`}
                                    label={"Strasse"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                            <Grid xs={1} />
                            <Grid xs={5}>
                                <Field
                                    name={`invoice.house_number`}
                                    label={"Hausnummer"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <Field
                                    name={`invoice.postal_code`}
                                    label={"PLZ"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                            <Grid xs={1} />
                            <Grid xs={5}>
                                <Field
                                    name={`invoice.city`}
                                    label={"Ort"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>

                            <Grid xs={6}>
                                <Field
                                    name={`invoice.email`}
                                    label={"E-Mail"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                            <Grid xs={1} />
                            <Grid xs={5}>
                                <Field
                                    name={`invoice.website`}
                                    label={"Website"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                        </Grid>


                        <Grid xs={6}>
                            <Field
                                name={`invoice.vat_number`}
                                label={"UID"}
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                validate={[this.required ]}
                            />
                        </Grid>
                        <Grid container xs={12}>
                        <Grid xs={6}>
                            <Field
                                name={`invoice.bank`}
                                label={"Bankname"}
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                validate={[this.required ]}
                            />
                        </Grid>
                            <Grid xs={1} />
                            <Grid xs={5}>
                            <Field
                                name={`invoice.iban`}
                                label={"IBAN"}
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                validate={[this.required ]}
                            />
                        </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <Field
                                    name={`invoice.tax_rate`}
                                    label={"USt-Satz"}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    placeholder={"Bitte ausfüllen..."}
                                    validate={[this.required ]}
                                />
                            </Grid>
                        </Grid>
                        </Grid>
                    <Grid container style={{marginTop: 20}}>
                        <Grid xs={5}>
                            {this.getPicturePreview('logo')}
                            <IconButton className={classes.profilPictureBtn} onClick={() => this.handleMediaLibrary('logo')}><EditIcon style={{marginRight: '10px'}} fontSize={'small'}/>Logo ändern</IconButton>
                        </Grid>
                        <Grid xs={5}>
                            {this.getPicturePreview('stamp')}
                            <IconButton className={classes.profilPictureBtn} onClick={() => this.handleMediaLibrary('stamp')}><EditIcon style={{marginRight: '10px'}} fontSize={'small'}/>Stempel ändern</IconButton>
                        </Grid>
                    </Grid>
                    { !!mediaLibrary && <MediaLibrary ref_id={'c7-invoice-settings'} ref_type={'invoice'} profilPicture={true} dialog={true} onSelect={this.addPicture}/> }
                    <Button disabled={(settings && settings.service_mode == false)} style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)} variant={'outlined'} color="primary">
                        Stammdaten speichern
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const settings = state.settings.data;
    return {
        settings: settings,
        mediaLibrary: state.file.mediaLibrary,
        initialValues: {
            invoice: settings?settings.invoice:{},
        }
    }
}

const mapDispatchToProps = {
    updateSettings: updateSettingsRequest,
    loadMediaLibrary: mediaLibraryRequest,
    setMediaLibrary: setMediaLibrary
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'invoice-settings-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(InvoiceSettingsForm)