import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort, setActiveFilter,
} from '../../../actions/filterActions';
import FiltersFilter from "./FiltersFilter";
import CustomersFilter from "../Customers/CustomersFilter";

const mapStateToProps = (state) => {
    let { page, perPage, totalRecords, isFetching, sortDirection, sortField } = state.filter;
    let data = state.filter.data ? state.filter.data : [];

    const fields = {
        "Name": "description",
        "Status": "status",
        "Erstellt am": "created_at"
    };

    const idField = "id";

    const click = true;

    return {
        page,
        perPage,
        totalRecords,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        fields,
        idField,
        filterComponent: () => <FiltersFilter/>,
        click,
        sortFields: ['created_at', 'description']
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable: (options => {dispatch(initializeDataTable(options))}),
        nextPage: () => { dispatch(nextPage())},
        previousPage: () => {dispatch(previousPage())},
        changePerPage: (value => { dispatch(changePerPage(value))}),
        sort: (field, value) => { dispatch(sort(field, value))},
        onClickRow: (rowData) => {
            dispatch(setActiveFilter(rowData));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
