
/* Action types */
const PREFIX = "settings/";

export const LOAD_SETTINGS_REQUEST = PREFIX + "LOAD_SETTINGS_REQUEST";
export const LOAD_SETTINGS_SUCCESS = PREFIX + "LOAD_SETTINGS_SUCCESS";
export const LOAD_SETTINGS_ERROR = PREFIX + "LOAD_SETTINGS_ERROR";

export const UPDATE_SETTINGS_REQUEST = PREFIX + "UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = PREFIX + "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_ERROR = PREFIX + "UPDATE_SETTINGS_ERROR";

export const STRIPE_PRICE_REQUEST = PREFIX + "STRIPE_PRICE_REQUEST";
export const STRIPE_PRICE_SUCCESS = PREFIX + "STRIPE_PRICE_SUCCESS";
export const STRIPE_PRICE_ERROR = PREFIX + "STRIPE_PRICE_ERROR";

export const LOAD_STRIPE_SESSION_REQUEST = PREFIX + "LOAD_STRIPE_SESSION_REQUEST";
export const LOAD_STRIPE_SESSION_SUCCESS = PREFIX + "LOAD_STRIPE_SESSION_SUCCESS";
export const LOAD_STRIPE_SESSION_ERROR = PREFIX + "LOAD_STRIPE_SESSION_ERROR";

export const UPDATE_STRIPE_SESSION_REQUEST = PREFIX + "UPDATE_STRIPE_SESSION_REQUEST";
export const UPDATE_STRIPE_SESSION_SUCCESS = PREFIX + "UPDATE_STRIPE_SESSION_SUCCESS";
export const UPDATE_STRIPE_SESSION_ERROR = PREFIX + "UPDATE_STRIPE_SESSION_ERROR";


/* initial state */
const initialState = {
    data: null,
    sessionId: null,
    sessions: null,
};

/* Reducer */
const settingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_SETTINGS_REQUEST:
            return {
                ...state,
            };
        case LOAD_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.settings,
            };
        case LOAD_SETTINGS_ERROR:
            return {
                ...state,
                data: null,
            };
        case UPDATE_SETTINGS_REQUEST:
            return {
                ...state,
            };
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
            };
        case UPDATE_SETTINGS_ERROR:
            return {
                ...state,
            };
        case STRIPE_PRICE_REQUEST:
            return {
                ...state,
            };
        case STRIPE_PRICE_SUCCESS:
            return {
                ...state,
                sessionId: action.sessionId
            };
        case STRIPE_PRICE_ERROR:
            return {
                ...state,
                sessionId: null
            };
        case LOAD_STRIPE_SESSION_REQUEST:
            return {
                ...state,
            };
        case LOAD_STRIPE_SESSION_SUCCESS:
            return {
                ...state,
                sessions: action.sessions
            };
        case LOAD_STRIPE_SESSION_ERROR:
            return {
                ...state,
                sessions: null
            };

        default:
            return state;
    }
};
export default settingsReducer;