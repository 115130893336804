
/* Action types */
const PREFIX = "hello/";

export const LOAD_HELLOS_REQUEST = PREFIX + "LOAD_HELLOS_REQUEST";
export const LOAD_HELLOS_SUCCESS = PREFIX + "LOAD_HELLOS_SUCCESS";
export const LOAD_HELLOS_ERROR = PREFIX + "LOAD_HELLOS_ERROR";

export const LOAD_HELLO_REQUEST = PREFIX + "LOAD_HELLO_REQUEST";
export const LOAD_HELLO_SUCCESS = PREFIX + "LOAD_HELLO_SUCCESS";
export const LOAD_HELLO_ERROR = PREFIX + "LOAD_HELLO_ERROR";

export const PATCH_HELLO_REQUEST = PREFIX + "PATCH_HELLO_REQUEST";
export const PATCH_HELLO_SUCCESS = PREFIX + "PATCH_HELLO_SUCCESS";
export const PATCH_HELLO_ERROR = PREFIX + "PATCH_HELLO_ERROR";

export const HELLO_INITIALIZE_DATATABLE = PREFIX + "HELLO_INITIALIZE_DATATABLE";
export const HELLO_NEXT_PAGE = PREFIX + "HELLO_NEXT_PAGE";
export const HELLO_PREVIOUS_PAGE = PREFIX + "HELLO_PREVIOUS_PAGE";
export const HELLO_CHANGE_PER_PAGE = PREFIX + "HELLO_CHANGE_PER_PAGE";
export const HELLO_SORT_TABLE = PREFIX + "HELLO_SORT_TABLE";
export const HELLO_FILTER_TABLE = PREFIX + "HELLO_FILTER_TABLE";

export const SET_NEW_HELLO = PREFIX + "SET_NEW_HELLO";
export const UNSET_NEW_HELLO = PREFIX + "UNSET_NEW_HELLO";

export const SET_ACTIVE_HELLO = PREFIX + "SET_ACTIVE_HELLO";
export const UNSET_ACTIVE_HELLO = PREFIX + "UNSET_ACTIVE_HELLO";

/* initial state */
const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'name',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    activeHello: null,
    newHello: false,
};

/* Reducer */
const helloReducer = (state = initialState, action) => {

    switch(action.type) {

        case LOAD_HELLOS_REQUEST:
            return {
                ...state,
            };
        case LOAD_HELLOS_SUCCESS:
            return {
                ...state,
                data: action.hellos,
                totalRecords: action.totalRecords,
            };
        case LOAD_HELLOS_ERROR:
            return {
                ...state,
                data: action.hellos,
                totalRecords: 0,
            };
        case PATCH_HELLO_REQUEST:
            return {
                ...state,
            };
        case PATCH_HELLO_SUCCESS:
            return {
                ...state,
            };
        case PATCH_HELLO_ERROR:
            return {
                ...state,
            };
        case LOAD_HELLO_REQUEST:
            return {
                ...state,
            };
        case LOAD_HELLO_SUCCESS:
            return {
                ...state,
                activeHello: action.hello
            };
        case LOAD_HELLO_ERROR:
            return {
                ...state,
                activeHello: null,
            };

        case HELLO_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case HELLO_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case HELLO_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case HELLO_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case HELLO_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case HELLO_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };

        case SET_NEW_HELLO:
            return {
                ...state,
                newHello: true
            };
        case UNSET_NEW_HELLO:
            return {
                ...state,
                newHello: false
            };
        case SET_ACTIVE_HELLO:
            return {
                ...state,
                activeHello: action.activeHello,
            };
        case UNSET_ACTIVE_HELLO:
            return {
                ...state,
                activeHello: null
            };
        default:
            return state;
    }
};
export default helloReducer;