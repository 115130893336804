import React, {Component, Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {
    Grid,
    Typography,
    Dialog,
    AppBar, Toolbar, IconButton, List, ListItem, ListItemText, Divider, Button
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import {Edit as EditIcon} from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import {loadFilterByIdRequest} from "../../../actions/filterActions";

function NotificationsFilterList(props) {

    const [showDetail, setShowDetail] = useState(false);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        setChecked(props.filteredCustomers?props.filteredCustomers:[])
    }, [props.filteredCustomers]);

    useEffect(() => {
        if(props.filter){
            props.loadFilter({id: props.filter});
        }
    }, [props]);

    const resultLength = () => {
        return props.filteredCustomers ? props.filteredCustomers.length : 0;
    }

    const selectedLength = () => {
        return checked?checked.length:0;
    }

    const items = (props.filteredCustomers?props.filteredCustomers:[]).map(n => {
        return [
            <ListItem key={n} role={undefined} dense button onClick={() => handleToggle(n)}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked.indexOf(n) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': n }}
                    />
                </ListItemIcon>
                <ListItemText primary={`${n.number}`} secondary={<div>{`${n.email}`}<div>{`${n.firstname} ${n.lastname}`}</div></div>}/>
            </ListItem>
        ];
    });

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const submit = () => {
        setShowDetail(false);
        props.onSubmit(checked?[...checked]:[]);
    }

    return (
        <div style={{marginBottom: 10}}>
            <div>
                {`Gewählte Einträge ${selectedLength()} von ${resultLength()}`}
                <IconButton onClick={() => setShowDetail(true)}><EditIcon/></IconButton>
            </div>

            <Dialog open={showDetail}>
                <DialogTitle style={{backgroundColor: '#262c46'}}>
                    <Typography variant="h6" style={{color: '#FFF'}}>
                        Filtereinträge bearbeiten
                    </Typography>
                    <IconButton style={{position: 'absolute', top: 10, right: 10, color: '#FFF'}} edge="start" color="white" onClick={() => {setShowDetail(false); setChecked(props.filteredCustomers);}} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Grid container style={{padding: 20}}>
                    <Grid item xs={12}>
                        <List>
                            {items}
                        </List>
                    </Grid>
                    <Button style={{marginTop: '10px'}} onClick={submit} color={'primary'} variant={'outlined'}>Auswahl übernehmen</Button>
                </Grid>
            </Dialog>

        </div>
    );
}

function mapStateToProps(state) {
    return {
        filteredCustomers: state.filter.customers
    }
}
const mapDispatchToProps = {
    loadFilter: loadFilterByIdRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(style))
(NotificationsFilterList);