import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem} from '@material-ui/core'
import {
    saveRestaurantRequest, setProfilPictureRequest,
    unsetActiveRestaurant, deleteRestaurantRequest, setDeleteModal, unsetDeleteModal, setActiveRestaurant
} from "../../../actions/restaurantActions";
import {mediaLibraryRequest, setMediaLibrary} from "../../../actions/fileActions";
import MediaLibrary from '../../../components/MediaLibrary';
import CreateSelect from "../../../components/CreatableSelect";
import {loadTagRequest, saveTagRequest} from "../../../actions/tagActions";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {Link} from 'react-router-dom';
import SelectGroup from "../../../components/SelectGroup";
import { withRouter } from "react-router-dom";
import getBaseUrl from "../../../utils/getBaseUrl";
import SwitchGroup from "../../../components/SwitchGroup";
import {isAllowed, isAllowedRestaurant, isReadOnly} from "../../../utils/permissionHelper";


class RestaurantsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileImage: undefined
        };
    }

    required = value =>  value ? undefined : 'Feld erforderlich';

    componentWillUnmount(){
        this.props.unsetActiveRestaurant();
        this.props.loadTags();
    }

    componentDidUpdate(prevProps){
        if(this.props.activeRestaurantData._id && !prevProps.activeRestaurantData._id){
            //this.props.history.push("/intern/restaurants/" + this.props.activeRestaurantData._id);
        }
    }

    delete = (action) =>{
        this.props.deleteRestaurant({id: this.props.activeRestaurantData._id, action})
        this.props.unsetActiveRestaurant();
        this.props.unsetDeleteModal();
        this.props.history.push("/intern/restaurants/");
    };

    submitAll = (values) => {
        if(this.props.activeRestaurant === true && this.props.activeRestaurantData._id && this.props.activeRestaurantData._id.length){
            this.props.saveRestaurants({
                data: {
                    _id: this.props.activeRestaurantData._id,
                    number: values.number,
                    name: values.name,
                    type: values.type,
                    street: values.street,
                    house_number: values.house_number,
                    postal_code: values.postal_code,
                    city: values.city,
                    tags: values.tags,
                    kitchen: values.kitchen,
                    website: values.website,
                    telefon: values.telefon,
                    email: values.email,
                    text: values.text,
                    uid: values.uid,
                    account: values.account,
                    iban: values.iban,
                    take7_enabled: values.take7_enabled,
                    profil_picture: this.state.profileImage,
                }
            })
        } else {
            this.props.saveRestaurants({
                data:{
                    number: values.number,
                    name: values.name,
                    type: values.type,
                    street: values.street,
                    house_number: values.house_number,
                    postal_code: values.postal_code,
                    city: values.city,
                    tags: values.tags,
                    kitchen: values.kitchen,
                    website: values.website,
                    telefon: values.telefon,
                    email: values.email,
                    text: values.text,
                    uid: values.uid,
                    account: values.account,
                    iban: values.iban,
                    take7_enabled: values.take7_enabled,
                    profil_picture: this.state.profileImage,
                }
            });
        }
    };

    renderOptions = (options) => {
        return options.map( option => this.renderOption(option))
    };

    renderOption = (option) => {
        return <MenuItem value={option._id}>{option.name}</MenuItem>
    };

    handleMediaLibrary = () => {
        this.props.loadMediaLibrary({id: this.props.activeRestaurantData._id})
        this.props.setMediaLibrary();
    };

    deletePicture = () =>{
        this.setState({
            profileImage: null
        });
    };

    addPicture = (id) => {
        this.setState({
            profileImage: id
        });
    };

    getPicturePreview = () => {
        const {activeRestaurantData} = this.props;
        const {profileImage} = this.state;
        const id = profileImage!==undefined?profileImage:(activeRestaurantData&&activeRestaurantData.profil_picture?activeRestaurantData.profil_picture:null);
        if(id){
            return <img src={getBaseUrl()+"/api/files/" + id + "/file"} height="auto" style={{maxWidth: '100%'}}/>;
        } else {
            return null;
        }
    };

    isFormValueDisabled = () => {
        const { user } = this.props;
        return isReadOnly(user, 'restaurants') || isReadOnly(user, 'my-restaurant');
    }

    render() {
        const {handleSubmit, classes, activeRestaurant, activeRestaurantData, mediaLibrary, tags, kitchen, types, user} = this.props;

        return (
            <form>
                <Grid container className={classes.fragmentContainer} spacing={6}>
                    <Grid item xs={12} md={6}>
                        <h2> Profilbild </h2>

                        {this.getPicturePreview() ?
                            <div>
                                {this.getPicturePreview()}
                                <br/>
                                {
                                    isAllowedRestaurant(user, "Profilbild") && <IconButton className={classes.profilPictureBtn} onClick={this.handleMediaLibrary}><EditIcon style={{marginRight: '10px'}} fontSize={'small'}/>Profilbild ändern</IconButton>
                                }
                                {
                                    isAllowedRestaurant(user, "Profilbild") && <IconButton className={classes.profilPictureBtn} onClick={this.deletePicture}><DeleteIcon style={{marginRight: '10px'}} fontSize={'small'}/>Profilbild entfernen </IconButton>
                                }


                            </div>
                        :
                            <span>
                                {activeRestaurantData && activeRestaurantData._id ?
                                    <div>
                                        <IconButton className={classes.profilPictureBtn} onClick={this.handleMediaLibrary}><EditIcon style={{marginRight: '10px'}} fontSize={'small'}/> Profilbild hinzufügen</IconButton>
                                    </div>
                                :
                                    <div>
                                        Bitte legen Sie zuerst ein Restaurant an um danach ein Profilbild festlegen zu können.
                                    </div>
                                }
                            </span>
                        }

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h2> Daten </h2>
                        <Field
                            name="name"
                            label="Name"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                            validate={[ this.required ]}
                        />
                        <Field
                            name="number"
                            label="Kundennummer"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                            validate={[ this.required ]}
                        />
                        <Field
                            name="type"
                            label="Type"
                            component={SelectGroup}
                            margin="dense"
                            fullWidth
                            validate={[ this.required ]}
                            disabled={this.isFormValueDisabled()}
                        >
                            {this.renderOptions(types)}
                        </Field>
                        <Field
                            name="street"
                            label="Straße"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                            validate={[ this.required ]}
                        />
                        <Field
                            name="house_number"
                            label="Nr"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                            validate={[ this.required ]}
                        />
                        <Field
                            name="postal_code"
                            label="Postleitzahl"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                            validate={[ this.required ]}
                        />
                        <Field
                            name="city"
                            label="Ort"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                            validate={[ this.required ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div style={{zIndex: 3, position: 'relative'}}>
                            <h2> Küche </h2>
                            <Field
                                name="kitchen"
                                label="Küche"
                                component={CreateSelect}
                                options={kitchen}
                                multi={true}
                                disabled={this.isFormValueDisabled()}
                                validate={[ this.required ]}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div style={{zIndex: 2, position: 'relative'}}>
                            <h2> Tags </h2>
                            <Field
                                name="tags"
                                label="Tags"
                                component={CreateSelect}
                                options={tags}
                                multi={true}
                                validate={[ this.required ]}
                                disabled={this.isFormValueDisabled()}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <h2>take7</h2>
                            <Field
                                name="take7_enabled"
                                component={SwitchGroup}
                                label={'take7 vefügbar'}
                                disabled={this.isFormValueDisabled()}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div style={{zIndex: 1, position: 'relative'}}>
                            <h2>Kontakt</h2>
                            <Field
                                name="website"
                                label="Webseite"
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                autoComplete={false}
                                disabled={this.isFormValueDisabled()}
                            />
                            <Field
                                name="telefon"
                                label="Telefon"
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                autoComplete={false}
                                disabled={this.isFormValueDisabled()}
                            />
                            <Field
                                name="email"
                                label="E-Mail"
                                component={TextFieldGroup}
                                margin="dense"
                                placeholder={"Bitte ausfüllen..."}
                                autoComplete={false}
                                disabled={this.isFormValueDisabled()}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div style={{zIndex: 1, position: 'relative'}}>
                            <h2>Sontiges</h2>
                            <Field
                                name="text"
                                label="Informationstext"
                                component={TextFieldGroup}
                                margin="dense"
                                multiline
                                rows={10}
                                placeholder={"Bitte ausfüllen..."}
                                autoComplete={false}
                                disabled={this.isFormValueDisabled()}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <h2>Rechnungsinformationen</h2>
                        <Field
                            name="uid"
                            label="UID-Nummer"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                        />
                        <Field
                            name="account"
                            label="Bankname"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                        />
                        <Field
                            name="iban"
                            label="IBAN"
                            component={TextFieldGroup}
                            margin="dense"
                            placeholder={"Bitte ausfüllen..."}
                            autoComplete={false}
                            disabled={this.isFormValueDisabled()}
                        />
                    </Grid>
                    { isAllowed(this.props.user, 'restaurants', 'update') &&
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleSubmit(this.submitAll)} color="primary" variant={"outlined"}>
                                Sichern
                            </Button>
                        </Grid>
                    }
                    { isAllowed(this.props.user, 'restaurants', 'delete') &&
                        <Grid item xs={6}>
                            {(activeRestaurantData && activeRestaurantData.status === true) ?
                                <Button fullWidth color={'default'} variant={"outlined"} onClick={this.props.setDeleteModal}> Restaurant inaktiv setzen</Button> :
                                <Button fullWidth color={'default'} variant={"outlined"} onClick={() => {this.delete('enable')}}> Restaurant aktiv setzen</Button>
                            }
                        </Grid>
                    }



                    { mediaLibrary === true && <MediaLibrary ref_id={activeRestaurantData._id} profilPicture={false} dialog={true} onSelect={this.addPicture}/> }
                </Grid>

                {this.props.deleteModal === true &&
                    <Dialog open={true}>
                        <DialogTitle>Sind Sie sicher?</DialogTitle>

                        <DialogContent>Möchten Sie den Eintrag wirklich inaktiv setzen?</DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.unsetDeleteModal} color="primary">
                                Abbrechen
                            </Button>
                            <Link to={"/intern/restaurants"}>
                                <Button onClick={() => {this.delete('disable')}} color="primary">
                                    INAKTIV SETZEN
                                </Button>
                            </Link>
                        </DialogActions>

                    </Dialog>
                }
            </form>
        );
    }
}

function mapStateToProps(state) {
    let initial_tags = [];
    let initial_kitchen_tags = [];
    let initial_type;

    const restaurant = state.restaurant.activeRestaurant === true ? state.restaurant.activeRestaurantData : {};

    if(restaurant.tags && restaurant.tags.length){
        initial_tags = restaurant.tags.map(tag =>{
            if(tag){
                return {label: tag.name, value: tag._id}
            }
        })
    }

    if(restaurant.kitchen && restaurant.kitchen.length){
        initial_kitchen_tags = restaurant.kitchen.map(kitchen =>{
            if(kitchen.type === 'kitchen'){
                return {label: kitchen.name, value: kitchen._id}
            }
        })
    }

    if(restaurant.type){
        initial_type = restaurant.type.value

    }

    return {
        activeRestaurant:  state.restaurant.activeRestaurant,
        activeRestaurantData: restaurant,
        mediaLibrary: state.file.mediaLibrary,
        tags: state.tag.data ? state.tag.data : [],
        kitchen: state.tag.kitchen ? state.tag.kitchen : [],
        deleteModal: state.restaurant.deleteModal,
        types: state.type.data,
        user: state.auth.user,
        initialValues: {
            name: restaurant.name,
            number: restaurant.number,
            type: initial_type,
            street: restaurant.street,
            house_number: restaurant.house_number,
            postal_code: restaurant.postal_code,
            city: restaurant.city,
            tags: initial_tags,
            kitchen: initial_kitchen_tags,
            website: restaurant.website,
            telefon: restaurant.telefon,
            email: restaurant.email,
            text: restaurant.text,
            uid: restaurant.uid,
            account: restaurant.account,
            iban: restaurant.iban,
            take7_enabled: restaurant.take7_enabled
        }
    }
}
const mapDispatchToProps = {
    saveRestaurants: saveRestaurantRequest,
    unsetActiveRestaurant: unsetActiveRestaurant,
    loadMediaLibrary: mediaLibraryRequest,
    disableProfilPicture: setProfilPictureRequest,
    loadTags: loadTagRequest,
    deleteRestaurant: deleteRestaurantRequest,
    setDeleteModal: setDeleteModal,
    unsetDeleteModal: unsetDeleteModal,
    setActiveRestaurant: setActiveRestaurant,
    setMediaLibrary: setMediaLibrary
};


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'restaurants-form',
        enableReinitialize:true,
    }),
    withStyles(style)
)(RestaurantsForm)