import axios from "../axios";
import moment from "moment";

async function login(email, password) {
    const body = { email, password };

    const response = await axios.post('/auth/sign-in', body);

    return response;
}

async function loadUser() {
    const params = {};
    const response = await axios.get('/auth/me', params);

    return response;
}

async function loadRestaurant(params) {
    const response = await axios.get('/restaurants', {params: params});

    return response;
}

async function getRestaurant(id) {
    const params = {};
    const response = await axios.get('/restaurants/' +id.id, {params: params});

    return response;
}

async function createRestaurant(data) {
    const body = {
        ...data
    };
    try {
        return await axios.post('/restaurants', body);
    } catch(e){
        return e.response
    }
}

async function updateRestaurant(id, data) {
    const body = {
        ...data
    };
    try {
        return await axios.put('/restaurants/' + id, body);
    } catch(e){
        return e.response
    }
}

async function updateRestaurantRksv(id, data) {
    try {
        return await axios.put('/restaurants/' + id + '/rksv-settings', data);
    } catch(e){
        return e.response
    }
}

async function updateRestaurantUser(id, data) {
    try {
        return await axios.put('/restaurants/' + id + '/users', data);
    } catch(e){
        return e.response
    }
}

async function loadRestaurantUser(id) {
    try {
        return await axios.get('/restaurants/' + id + '/users');
    } catch(e){
        return e.response
    }
}

async function deleteRestaurant(_data) {
    const data = {
        action: _data.action
    }
    const response = await axios.patch('/restaurants/' + _data.id, data);

    return response;
}

async function uploadFile(file) {
    const response = await axios.post('/files', file);

    return response;
}

async function getFile(id) {
    let route = '/files/' + id + '/file';
    const response = await axios.get(route, {responseType: 'arraybuffer'});

    return response;
}

async function getMeta(id) {
    let route = '/files/' + id ;
    const response = await axios.get(route);

    return response;
}

async function loadFileTypes() {
    const params = {};
    const response = await axios.get('/files/types', params);

    return response;
}

async function loadPictures(id) {
    const params ={};
    const response = await axios.get('/files/pictures/'+ id, {params: params});

    return response;
}

async function updateFile(id, data) {
    const response = await axios.put('/files/' + id, data);

    return response;
}

async function deleteFile(id) {
    try {
        return await axios.patch('/files/' + id, { action: "disable" });
    } catch(e){
        return e.response;
    }
}

async function loadPost(id, params) {
       const response = await axios.get('/posts/' + id, {params:params});

    return response;
}

async function updatePost(data) {
    const body = {
        ...data
    };
    const response = await axios.put('/posts/' + data._id , body);

    return response;
}

async function createPost(data) {
    const body = {
        ...data
    };
    const response = await axios.post('/posts', body);
    return response;
}

async function deletePost(id, restaurant_id) {

    const data = {
        action: "disable",
        restaurant_id: restaurant_id
    }
    const response = await axios.patch('/posts/' + id, data);

    return response;
}

async function setProfilPicture(restaurant_id, picture_id) {
    const body = {
        picture_id:  picture_id
    };
    const response = await axios.put('/restaurants/profilpicture/' + restaurant_id, body );

    return response;
}

async function createOpening(restaurant_id, data) {
    const body = {
        restaurant_id: restaurant_id,
        data: data
    };
    const response = await axios.post('/opening_hours' , body);
    return response;
}

async function updateOpening(id, restaurant_id, data) {
    const body = {
        restaurant_id: restaurant_id,
        data: data
    };
    const response = await axios.put('/opening_hours/' + id , body);
    return response;
}

async function loadOpening(id) {
    const params = {};
    const response = await axios.get('/opening_hours/' + id , {params:params});
    return response;
}

async function saveTag(data) {
    try {
        return await axios.post('/tags' , data);
    } catch(e){
        return e.response;
    }
}

async function updateTag(data) {
    const response = await axios.put('/tags/' + data._id , data);
    return response;
}

async function loadTag(params) {
    const response = await axios.get('/tags' , {params:params});
    return response;
}

async function deleteTag(id) {
    const body = {
        action: 'disable'
    };
    const response = await axios.patch('/tags/' + id, body );
    return response;
}

async function loadTypes(params) {
    const response = await axios.get('/types' , {params:params});
    return response;
}

async function createWaiter(data) {
    const body = { ...data };

    try {
        return await axios.post('/waiter', body);
    } catch(e){
        return e.response;
    }
}

async function loadWaiter(options) {
    const params = {
        ...options
    }
    const response = await axios.get('/waiter', {params: params});

    return response;
}

async function updateWaiter(id, data) {
    const body = {
        ...data
    };
    const response = await axios.put('/waiter/' + id, body);

    return response;
}

async function deleteWaiter(id, data) {

    const params = {
        action: data.action,
        restaurant_id: data.restaurant_id
    }
    const response = await axios.patch('/waiter/' + id, params);

    return response;
}

async function sendWelcome(data) {
    const body = {
        ...data
    };
    const response = await axios.post('/waiter/' + data._id + '/send-welcome', body);

    return response;
}

async function getWaiter (id) {
    const params = {};
    const response = await axios.get('/waiter/' + id, {params: params});

    return response;
}

async function createUser(data) {
    const body = {
        ...data
    };
    const response = await axios.post('/user', body);

    return response;
}

async function loadUsers(options) {
    const params = {
        ...options
    }
    const response = await axios.get('/user', {params: params});

    return response;
}

async function updateUser(id, data) {
    const body = {
        ...data
    };
    const response = await axios.put('/user/' + id, body);

    return response;
}

async function patchUser(id, params) {
    try {
        return await axios.patch('/user/' + id, params);
    } catch(e){
        return e.response;
    }
}

async function patchCheckin(id, params) {
    try {
        return await axios.patch('/adminCheckIns/' + id, params);
    } catch(e){
        return e.response;
    }
}

async function loadCheckIns(options) {
    const params = {
        ...options
    }
    const response = await axios.get('/adminCheckIns', {params: params});

    return response;
}

async function loadPayments(data, options) {

    const params = {
        ...data,
        ...options
    }
    const response = await axios.get('/payments', {params: params});

    return response;
}

async function downloadPayment(options) {
    try {
        const params = {
            ...options
        };
        return await axios.get('/payments/csv', {params: params});
    } catch(e){
        return e.response;
    }
}

async function loadCustomers(params) {
    try {
        return  await axios.get('/customers', {params: params});
    } catch(e){
        return e.response;
    }
}

async function loadFilters(params) {
    try {
        return  await axios.get('/filters', {params: params});
    } catch(e){
        return e.response;
    }
}

async function loadFilterById(id) {
    try {
        return  await axios.get('/filters/'+id);
    } catch(e){
        return e.response;
    }
}

async function saveFilter(data) {
    try {
        return  await axios.post('/filters', data);
    } catch(e){
        return e.response;
    }
}

async function patchFilter(id, data) {
    try {
        return  await axios.patch('/filters/' + id, data);
    } catch(e){
        return e.response;
    }
}

async function updateFilter(id, data) {
    try {
        return  await axios.put('/filters/' + id, data);
    } catch(e){
        return e.response;
    }
}

async function createCustomer(data) {
    try {
        return await axios.post('/customers', { ...data });
    } catch(e) {
        return e.response;
    }
}


async function updateCustomer(id, data) {
    try {
        return await axios.put('/customers/' + id, { ...data });
    } catch(e){
        return e.response;
    }
}

async function patchCustomer(id, params) {
    try {
        return await axios.patch('/customers/' + id, params);
    } catch(e){
        return e.response;
    }
}

async function sendNotification(type, body) {
    if(type){
        return await axios.post('/notifications/'+type, body);
    } else {
        return await axios.post('/notifications', body);
    }
}

async function getNotifications(data) {
    const params = {
        ...data,
    };
    return await axios.get('/notifications', {params: params});
}

async function loadAccountedMonths(data) {

    const params = {
        ...data,
    };
    const response = await axios.get('/restaurant_accounting', {params: params});

    return response;
}

async function loadActiveMonth(data) {

    const params = {
        ...data,
    };
    const response = await axios.get('restaurant_accounting/' + data, {params: params});

    return response;
}

async function accountingRequest(data) {
    try {
        return await axios.post('restaurant_accounting', data);
    } catch(e){
        return e.response;
    }
}

async function accountingPdf(id) {
    return await axios.get('restaurant_accounting/'+id+'/pdf', {
        data: {},
        responseType: 'buffer'
    });
}

async function accountingPdfSummary(month) {
    return await axios.get(`restaurant_accounting/${month}/summary-pdf`, {
        data: {},
        responseType: 'buffer'
    });
}

async function accountingDetailsPdf(id) {
    return await axios.get('restaurant_accounting/'+id+'/pdf-details', {
        data: {},
        responseType: 'buffer'
    });
}

async function accountingStripeTransfer(id) {
    try {
        return await axios.post('restaurant_accounting/'+id+'/stripe-transfer', {});
    } catch(e){
        return e.response;
    }
}

async function multipleAccountingStripeTransfer(month) {
    try {
        return await axios.post('restaurant_accounting/stripe-transfer', {month: month});
    } catch(e){
        return e.response;
    }
}


async function accountingPdfZip(month) {
    return await axios.get('restaurant_accounting/zip?month='+month, {
        data: {},
        responseType: 'blob',
    });
}

async function createVoucher(body) {
    try {
        return await axios.post('/vouchers', body);
    } catch(e){
        return e.response;
    }
}

async function updateVoucher(id, params) {
    try {
        return await axios.put('/vouchers/'+id, params);
    } catch(e){
        return e.response;
    }
}

async function mailVoucher(id, params) {
    try {
        return await axios.post('/vouchers/'+id+'/send-mail', params);
    } catch(e){
        return e.response;
    }
}

async function patchVoucher(id, action) {
    try {
        return await axios.patch('/vouchers/'+id, {action: action});
    } catch(e){
        return e.response;
    }
}

async function loadVouchers(options) {
    try {
        return await axios.get('/vouchers', { params: {...options} });
    } catch(e){
        return e.response;
    }
}

async function loadVoucher(id, options) {
    try {
        return await axios.get('/vouchers/'+id, { params: {...options} });
    } catch(e){
        return e.response;
    }
}

async function patchHello(id, action) {
    try {
        return await axios.patch('/hellos/'+id, {action: action});
    } catch(e){
        return e.response;
    }
}

async function loadHellos(options) {
    try {
        return await axios.get('/hellos', { params: {...options} });
    } catch(e){
        return e.response;
    }
}

async function loadHello(id, options) {
    try {
        return await axios.get('/hellos/'+id, { params: {...options} });
    } catch(e){
        return e.response;
    }
}

async function loadSettings() {
    try {
        return await axios.get('/settings');
    } catch(e){
        return e.response;
    }
}

async function updateSettings(body) {
    try {
        return await axios.put('/settings', body);
    } catch(e){
        return e.response;
    }
}

async function stripePrice(body) {
    try {
        return await axios.post('/settings/stripe', body);
    } catch(e){
        return e.response;
    }
}

async function stripeSessions() {
    try {
        return await axios.get('/settings/stripe');
    } catch(e){
        return e.response;
    }
}
async function updateStripeSession(id, body) {
    try {
        return await axios.put(`/settings/stripe/${id}`, body);
    } catch(e){
        return e.response;
    }
}

async function createStripeAccount(body) {
    try {
        return await axios.post('/stripe/create-account', body);
    } catch(e){
        return e.response;
    }
}

async function getStripeAccount(body) {
    try {
        return await axios.post('/stripe/get-account', body);
    } catch(e){
        return e.response;
    }
}


export default {
    login,
    loadUser,

    uploadFile,
    getFile,
    getMeta,
    loadFileTypes,
    updateFile,
    deleteFile,
    loadPictures,

    loadRestaurant,
    getRestaurant,
    createRestaurant,
    updateRestaurant,
    deleteRestaurant,
    setProfilPicture,
    updateRestaurantRksv,
    updateRestaurantUser,
    loadRestaurantUser,

    createPost,
    updatePost,
    loadPost,
    deletePost,

    createOpening,
    loadOpening,
    updateOpening,

    saveTag,
    updateTag,
    loadTag,
    deleteTag,

    loadTypes,

    createWaiter,
    loadWaiter,
    updateWaiter,
    deleteWaiter,
    sendWelcome,
    getWaiter,

    createUser,
    loadUsers,
    updateUser,
    patchUser,

    loadCheckIns,
    patchCheckin,

    loadPayments,
    downloadPayment,

    loadCustomers,
    createCustomer,
    updateCustomer,
    patchCustomer,
    sendNotification,
    getNotifications,

    loadFilters,
    loadFilterById,
    saveFilter,
    patchFilter,
    updateFilter,

    loadAccountedMonths,
    loadActiveMonth,
    accountingRequest,
    accountingPdf,
    accountingPdfZip,
    accountingDetailsPdf,
    accountingPdfSummary,

    createVoucher,
    patchVoucher,
    loadVouchers,
    loadVoucher,
    updateVoucher,
    mailVoucher,

    loadSettings,
    updateSettings,

    createStripeAccount,
    getStripeAccount,
    accountingStripeTransfer,
    multipleAccountingStripeTransfer,
    stripeSessions,

    patchHello,
    loadHellos,
    loadHello,

    stripePrice,
    updateStripeSession
}