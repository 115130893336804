
/* Action types */
const PREFIX = "notification/";

export const SEND_NOTIFICATION_REQUEST = PREFIX + "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = PREFIX + "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_ERROR = PREFIX + "SEND_NOTIFICATION_ERROR";

export const GET_NOTIFICATION_REQUEST = PREFIX + "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = PREFIX + "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = PREFIX + "GET_NOTIFICATION_ERROR";

/* initial state */
const initialState = {
    data: [],
    customersFilter: null,
};

/* Reducer */
const notificationReducer = (state = initialState, action) => {

    switch(action.type) {
        case SEND_NOTIFICATION_REQUEST:
            return {
                ...state,
            };
        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state
            };
        case SEND_NOTIFICATION_ERROR:
            return {
                ...state
            };
        case GET_NOTIFICATION_REQUEST:
            return {
                ...state,
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                data: action.data
            };
        case GET_NOTIFICATION_ERROR:
            return {
                ...state
            };
        default:
            return state;
    }
};
export default notificationReducer;