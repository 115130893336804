import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, FieldArray, reduxForm, reset} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {Paper, Grid, Button, MenuItem, IconButton} from '@material-ui/core'
import {
    patchHelloRequest, unsetActiveHello,
    unsetNewHello
} from "../../../actions/helloActions";
import moment from 'moment';
import {formatDateTime} from "../../../utils/helper";
import {isAllowed} from "../../../utils/permissionHelper";

class HellosForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){}

    required = value => value ? undefined : 'Feld erforderlich';

    _patchHello = (action) => {
        if(this.props.hello && this.props.hello._id){
            this.props.patchHello({_id: this.props.hello._id, action: action})
        }
        this.props.unsetActiveHello();
    };

    render() {
        const { classes, hello, isNew } = this.props;

        return (
            <Grid container className={classes.fragmentContainer} style={{marginTop: '100px'}}>
                <Grid xs={12}>
                    <form>
                        <Grid container spacing={10}>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="restaurant_name"
                                    label="Restaurant"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="restaurant_address"
                                    label="Adresse"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="contact_firstname"
                                    label="Kontakt Vorname"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="contact_lastname"
                                    label="Kontakt Nachname"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="contact_email"
                                    label="Kontakt Email"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="status"
                                    label="Status"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="created_at"
                                    label="Erstellt am"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    name="updated_at"
                                    label="Bearbeitet am"
                                    disabled={true}
                                    component={TextFieldGroup}
                                    margin="dense"
                                    validate={[ ]}
                                />
                            </Grid>
                        </Grid>

                        {isNew === true}
                        {(hello && hello._id) &&
                            <Grid container spacing={5}>
                                {isAllowed(this.props.user, 'hellos', 'delete') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={() => {
                                            this._patchHello('disable')
                                        }}
                                                variant={'outlined'} color="error">
                                            Empfehlung löschen
                                        </Button>
                                    </Grid>
                                }
                                {isAllowed(this.props.user, 'hellos', 'update') &&
                                    <Grid item xs={4}>
                                        <Button fullWidth style={{marginTop: '50px'}} onClick={() => {this._patchHello('seen')}} disabled={hello.status == 'seen'} variant={'outlined'} color="primary">
                                            {hello.status == 'seen' ? 'Bereits gesehen' : 'Empfehlung gesehen' }
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </form>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    let hello = state.hello.activeHello;
    return {
        hello: hello,
        user: state.auth.user,
        initialValues: {


            restaurant_name: hello&&hello.restaurant_name,
            restaurant_address: hello&&hello.restaurant_address,
            contact_firstname: hello&&hello.contact_firstname,
            contact_lastname: hello&&hello.contact_lastname,
            contact_email: hello&&hello.contact_email,
            created_at: hello&&formatDateTime(hello.created_at),
            updated_at: hello&&formatDateTime(hello.updated_at),


            status: (function(){
                if(hello && !state.hello.newHello ){
                    if(hello.status == 'active'){
                        return 'Aktiv';
                    } else if(hello.status == 'inactive'){
                        return 'Gelöscht';
                    } else if(hello.status == 'seen'){
                        return 'Gesehen';
                    }
                } else {
                   return '';
                }
            }())
        }
    }
}
const mapDispatchToProps = {
    patchHello: patchHelloRequest,
    unsetActiveHello: unsetActiveHello,
    unsetNewHello: unsetNewHello,
};


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'hello-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(HellosForm)