import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Grid, Paper, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import moment from 'moment';
import 'moment/locale/de'

class StripeLogs extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { classes, close, logs } = this.props;

        return (
            <Dialog open={true}>
                <DialogTitle>Logs</DialogTitle>

                <DialogContent>{JSON.stringify(logs)}</DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} color="primary">
                        Schließen
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {}
}
const mapDispatchToProps = {};

export default (withStyles(style)(StripeLogs));




