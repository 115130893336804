import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {loadUsersRequest, setNewUser, unsetActiveUser, unsetNewUser} from "../../../actions/userActions";
import EditRestaurants from "../Restaurants/EditRestaurants";

class MyRestaurant extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){}

    render() {
        const { classes, user } = this.props;
        return (
            <EditRestaurants restaurantId={user.restaurant_id}/>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.auth.user
    }
}
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(MyRestaurant));