import {
    CREATE_VOUCHER_ERROR,
    CREATE_VOUCHER_REQUEST, CREATE_VOUCHER_SUCCESS, LOAD_VOUCHER_ERROR, LOAD_VOUCHER_REQUEST, LOAD_VOUCHER_SUCCESS,
    LOAD_VOUCHERS_ERROR,
    LOAD_VOUCHERS_REQUEST,
    LOAD_VOUCHERS_SUCCESS, MAIL_VOUCHER_ERROR, MAIL_VOUCHER_REQUEST, MAIL_VOUCHER_SUCCESS, PATCH_VOUCHER_ERROR,
    PATCH_VOUCHER_REQUEST,
    PATCH_VOUCHER_SUCCESS,
    SET_ACTIVE_VOUCHER,
    SET_NEW_VOUCHER,
    UNSET_ACTIVE_VOUCHER,
    UNSET_NEW_VOUCHER,
    VOUCHER_CHANGE_PER_PAGE,
    VOUCHER_FILTER_TABLE,
    VOUCHER_INITIALIZE_DATATABLE,
    VOUCHER_NEXT_PAGE,
    VOUCHER_PREVIOUS_PAGE,
    VOUCHER_SORT_TABLE
} from "../reducers/voucherReducer";

export const loadVouchersRequest = () => ({
    type: LOAD_VOUCHERS_REQUEST,
});

export const loadVouchersSuccess = (values) => ({
    type: LOAD_VOUCHERS_SUCCESS,
    ...values
});

export const loadVouchersError = (values) => ({
    type: LOAD_VOUCHERS_ERROR,
    ...values
});

export const loadVoucherRequest = () => ({
    type: LOAD_VOUCHER_REQUEST,
});

export const loadVoucherSuccess = (values) => ({
    type: LOAD_VOUCHER_SUCCESS,
    ...values
});

export const loadVoucherError = (values) => ({
    type: LOAD_VOUCHER_ERROR,
    ...values
});

export const saveVoucherRequest = (values) => ({
    type: CREATE_VOUCHER_REQUEST,
    ...values
});

export const saveVoucherSuccess = (values) => ({
    type: CREATE_VOUCHER_SUCCESS,
    ...values
});

export const saveVoucherError = (values) => ({
    type: CREATE_VOUCHER_ERROR,
    ...values
});

export const mailVoucherRequest = (values) => ({
    type: MAIL_VOUCHER_REQUEST,
    ...values
});

export const mailVoucherSuccess = (values) => ({
    type: MAIL_VOUCHER_SUCCESS,
    ...values
});

export const mailVoucherError = (values) => ({
    type: MAIL_VOUCHER_ERROR,
    ...values
});

export const patchVoucherRequest = (values) => ({
    type: PATCH_VOUCHER_REQUEST,
    ...values
});

export const patchVoucherSuccess = (values) => ({
    type: PATCH_VOUCHER_SUCCESS,
    ...values
});

export const patchVoucherError = (values) => ({
    type: PATCH_VOUCHER_ERROR,
    ...values
});

export const initializeDataTable = (values) => ({
    type: VOUCHER_INITIALIZE_DATATABLE,
    ...values,
});

export const nextPage = () => ({
    type: VOUCHER_NEXT_PAGE,
});

export const previousPage = () => ({
    type: VOUCHER_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: VOUCHER_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: VOUCHER_SORT_TABLE,
    field,
    value
});

export const filterTable = ({values}) => ({
    type: VOUCHER_FILTER_TABLE,
    values
});

export const setNewVoucher = () => ({
    type: SET_NEW_VOUCHER
});

export const unsetNewVoucher = () => ({
    type: UNSET_NEW_VOUCHER
});

export const setActiveVoucher = (activeVoucher) => ({
    type: SET_ACTIVE_VOUCHER,
    activeVoucher

});

export const unsetActiveVoucher = () => ({
    type: UNSET_ACTIVE_VOUCHER
});
