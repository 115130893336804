import React from 'react';

import {compose} from "redux";
import { withRouter } from "react-router-dom";

import {connect} from "react-redux";
import MyTable from '../../../components/MyTable';

import LiveFilter from "./LiveFilter";
import { setActiveCheckin } from '../../../actions/checkinActions';

import {
  initializeDataTable,
  nextPage,
  previousPage,
  changePerPage,
  sort,
} from '../../../actions/checkinActions';

const mapStateToProps = (state) => {
  return {
    page : state.checkin.page,
    perPage : state.checkin.perPage,
    totalRecords : state.checkin.totalRecords,
    loading : state.checkin.isFetching,
    sortDirection : state.checkin.sortDirection,
    sortField : state.checkin.sortField,
    data: state.checkin.data || [],
    fields: {
      "Kunde": "customer.name",
      "Restaurant": "restaurant.name",
      "Status": "checkin_status",
      "Eingecheckt": 'check_in_at'
    },
    idField : "id",
    click : true,
    filterComponent: () => <LiveFilter />,
    sortFields: ['check_in_at']
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initializeDataTable,
    nextPage: () => { dispatch(nextPage())},
    previousPage: () => {dispatch(previousPage())},
    changePerPage: (value => { dispatch(changePerPage(value))}),
    sort: (field, value) => { dispatch(sort(field, value))},
    onClickRow: (rowData) => {
      dispatch(setActiveCheckin(rowData));
    },
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MyTable);