import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort,
    filterTable,
    setActiveWaiter
} from '../../../actions/waiterActions';
import VouchersFilter from "../Vouchers/VouchersFilter";
import WaitersFilter from "./WaitersFilter";


const mapStateToProps = (state) => {
    let { page, perPage, totalRecords, isFetching, sortDirection, sortField, filter } = state.waiter;
    let data = state.waiter.data ? state.waiter.data : [];

    const fields = {
        "Vorname": "firstname",
        "Nachname": "lastname",
        "Email": "email",
        "Status": "waiter_status"
    };

    const idField = "id";

    const click = true;

    return {
        page,
        perPage,
        totalRecords,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        filter,
        fields,
        idField,
        filterComponent: () => {},
        click
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable,
        nextPage: () => { dispatch(nextPage())},
        previousPage: () => {dispatch(previousPage())},
        changePerPage: (value => { dispatch(changePerPage(value))}),
        sort: (field, value) => { dispatch(sort(field, value))},
        onClickRow: (rowData) => {
            dispatch(setActiveWaiter(rowData));
        },
        filterComponent: () => <WaitersFilter restaurant_id={ownProps.restaurant_id}/>,

    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
