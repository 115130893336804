import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {reduxForm, Field, FieldArray} from 'redux-form';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Paper, Grid, IconButton, Button, Switch } from "@material-ui/core";
import {loadTagRequest, saveTagRequest, deleteTagRequest} from "../../../actions/tagActions";
import DescriptionIcon from '@material-ui/icons/Description';
import TextFieldGroup from "../../../components/TextFieldGroup";
import DeleteIcon from '@material-ui/icons/Delete';
import SwitchGroup from "../../../components/SwitchGroup";
import {isAllowed} from "../../../utils/permissionHelper";
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

class Tags extends Component {
    constructor(props) {
        super(props);
    }

    submitAll = (values) => {
        const tags = (values.tags?values.tags:[]).map(tag => {
            return {
                _id: tag.value,
                ...tag
            };
        });

        this.props.saveTag({
            tags: tags,
            tag_type: this.props.type,
        });
    };

    handleAddTag = (fields) => {
        fields.unshift({
            label: '',
            filter: false,
            status: true,
        });
    };

    required = value => value ? undefined : 'Feld erforderlich';

    renderTag = ({ user, fields, tags, meta: { error, submitFailed }}) => (
        <div>
            <Grid container spacing={5}>
                {isAllowed(this.props.user, 'tags', 'create') &&
                    <Grid item xs={12}>
                        <Button variant={'outlined'} color={'primary'} onClick={() => {
                            this.handleAddTag(fields)
                        }}>
                            Hinzufügen
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}>
                    {fields.map((tag, index) => {
                        return <Grid container style={{marginTop: '20px'}}>
                            <Grid xs={1} style={{right: '0', paddingTop: '8px'}}>
                                <DescriptionIcon color={'primary'} fontSize={"medium"}/>
                            </Grid>

                            <Grid xs={11}>
                                <form>
                                    <Field
                                        placeholder={'Titel'}
                                        name={`${tag}.label`}
                                        component={TextFieldGroup}
                                        style={{
                                            fontSize: 'medium',
                                            color: '#828182',
                                            width: '50%',
                                            textUnderlineColor: 'red'
                                        }}
                                        validate={[ this.required ]}
                                    />
                                    {isAllowed(user, 'tags', 'update') &&
                                        <Field
                                            name={`${tag}.filter`}
                                            component={SwitchGroup}
                                            label={'filterbar'}
                                        />
                                    }
                                    {isAllowed(user, 'tags', 'delete') &&
                                        <Field
                                            name={`${tag}.status`}
                                            component={({input}) => {
                                                if(input.value == true){
                                                    return  <IconButton
                                                        onClick={() => input.onChange(!input.value)}>
                                                        <DeleteIcon fontSize={"small"}/>
                                                    </IconButton>
                                                } else {
                                                    return <IconButton
                                                        style={{backgroundColor: 'rgba(239,51,64, 0.3)'}}
                                                        onClick={() => input.onChange(!input.value)}>
                                                        <RestoreFromTrashIcon fontSize={"small"}/>
                                                    </IconButton>
                                                }
                                            }}
                                        />
                                    }
                                </form>
                            </Grid>

                        </Grid>
                    })}
                </Grid>
            </Grid>
        </div>
    );

    render() {
        const {handleSubmit, tags, type} = this.props;
        return (
            <Fragment>
                <h2>{type == 'kitchen' ? 'Küchentypen' : 'Tags'}</h2>
                <form>
                    <FieldArray name="tags" component={this.renderTag} tags={tags} props={this.props} deleteTag={this.props.deleteTag} saveTag={this.props.saveTag}/>
                    <Button fullWidth style={{marginTop: '50px'}} onClick={handleSubmit(this.submitAll)} variant={'contained'} color="primary">
                        Speichern
                    </Button>
                </form>
            </Fragment>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        initialValues: {
            tags: props.tags,
        },
        form: props.formName,
        user: state.auth.user,
    }
}
const mapDispatchToProps = {
    loadTags: loadTagRequest,
    saveTag: saveTagRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        enableReinitialize: true,
    }),
    withStyles(style))
(Tags);