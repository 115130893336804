import React from "react";
import {Field} from "redux-form";
import MultipleSelect from "../MultipleSelect";
import {MenuItem} from "@material-ui/core";

const options = [
    {_id: 'admin', name: 'Administrator'},
    {_id: 'finance', name: 'Finanz'},
    {_id: 'marketing', name: 'Marketing'},
    {_id: 'reader', name: 'Abfrage'},
];
const UserRoleSelect = (props) => {

    return  <Field
        format={value => Array.isArray(value) ? value : []}
        name={props.fieldName}
        component={MultipleSelect}
        label={'Rolle'}
        fullWidth
        accessorValue={"_id"}
        accessorLabel={'name'}
        options={options}
    />;
};

export default UserRoleSelect;