import React from "react";
import {MenuItem} from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../SelectGroup";

const typeOptions = [
    <MenuItem value='absolute'>Wertgutschein</MenuItem>,
    <MenuItem value='percentage'>Rabattgutschein</MenuItem>,
    <MenuItem value='status'>Statusänderung</MenuItem>
];

const required = value => value ? null : 'Feld erforderlich';

const VoucherTypeSelect = (props) => {
    return <Field
        name={props.fieldName}
        label="Typ"
        disabled={!!props.disabled}
        component={SelectGroup}
        margin="dense"
        fullWidth
        validate={props.required && [ required ]}
    >
        {typeOptions}
    </Field>;

};

export default VoucherTypeSelect;