
/* Action types */
const PREFIX = "waiter/";
export const LOAD_WAITER_REQUEST = PREFIX + "LOAD_WAITER_REQUEST";
export const LOAD_WAITER_SUCCESS = PREFIX + "LOAD_WAITER_SUCCESS";

export const GET_WAITER_REQUEST = PREFIX + "GET_WAITER_REQUEST";
export const GET_WAITER_SUCCESS = PREFIX + "GET_WAITER_SUCCESS";

export const SAVE_WAITER_REQUEST = PREFIX + "SAVE_WAITER_REQUEST";
export const SAVE_WAITER_SUCCESS = PREFIX + "SAVE_WAITER_SUCCESS";

export const DELETE_WAITER_REQUEST = PREFIX + "DELETE_WAITER_REQUEST";
export const DELETE_WAITER_SUCCESS = PREFIX + "DELETE_WAITER_SUCCESS";

export const SET_NEW_WAITER = PREFIX + "SET_NEW_WAITER";
export const UNSET_NEW_WAITER = PREFIX + "UNSET_NEW_WAITER";

export const SET_ACTIVE_WAITER = PREFIX + "SET_ACTIVE_WAITER";
export const UNSET_ACTIVE_WAITER = PREFIX + "UNSET_ACTIVE_WAITER";

export const SEND_WAITER_WELCOME_REQUEST = PREFIX + "SEND_WAITER_WELCOME_REQUEST";
export const SEND_WAITER_WELCOME_SUCCESS = PREFIX + "SEND_WAITER_WELCOME_SUCCESS";

export const WAITER_INITIALIZE_DATATABLE = PREFIX + "WAITER_INITIALIZE_DATATABLE";
export const WAITER_NEXT_PAGE = PREFIX + "WAITER_NEXT_PAGE";
export const WAITER_PREVIOUS_PAGE = PREFIX + "WAITER_PREVIOUS_PAGE";
export const WAITER_CHANGE_PER_PAGE = PREFIX + "WAITER_CHANGE_PER_PAGE";
export const WAITER_SORT_TABLE = PREFIX + "WAITER_SORT_TABLE";
export const WAITER_FILTER_TABLE = PREFIX + "WAITER_FILTER_TABLE";



/* initial state */
const initialState = {
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'created_at',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    data: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,

    activeWaiter: false,
    activeWaiterData: {},
    newWaiter: false,

};

/* Reducer */
const waiterReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_WAITER_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_WAITER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.waiters,
                totalRecords: action.totalRecords,
                newWaiter: false,
            };
        case SAVE_WAITER_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_WAITER_SUCCESS:
            return {
                ...state,
                isSaving: false,
                activeWaiter: true,
                activeWaiterData: action.waiter
            };
        case DELETE_WAITER_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_WAITER_SUCCESS:
            return {
                ...state,
                isDeleting: false,
            };
        case WAITER_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case WAITER_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case WAITER_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case WAITER_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case WAITER_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case WAITER_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        case SET_NEW_WAITER:
            return {
                ...state,
                newWaiter: true
            };
        case UNSET_NEW_WAITER:
            return {
                ...state,
                newWaiter: false
            };
        case SET_ACTIVE_WAITER:
            return {
                ...state,
                activeWaiter: true,
                activeWaiterData: action.activeWaiter
            };
        case UNSET_ACTIVE_WAITER:
            return {
                ...state,
                activeWaiter: false
            };
        case SEND_WAITER_WELCOME_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case SEND_WAITER_WELCOME_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case GET_WAITER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_WAITER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                activeWaiterData: action.waiter
            };
        default:
            return state;
    }
};
export default waiterReducer;