import React  from 'react';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'
import { InputLabel } from '@material-ui/core'


const DatePickerGroup = ({ error, helperText, input, label, required, disablePast, dateFormat = "dd.MM.yyyy", variant, inputVariant, style, disable, disabled, autoOk, containerStyle}) => {

    return (
        <div style={containerStyle}>
            <InputLabel shrink={true}>{label}</InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    helperText={helperText}
                    error={error}
                    value={input.value}
                    onChange={input.onChange}
                    autoOk={autoOk}
                    variant={variant}
                    inputVariant={inputVariant}
                    required={required}
                    disablePast = {disablePast}
                    format={dateFormat}
                    disabled={disabled}
                    style={style}
                    InputProps={{
                        disableUnderline: disable,
                        style: {
                            paddingTop: '5px',
                            paddingLeft: '1px',
                            color: '#000'
                        }
                    }}
                    InputLabelProps={{
                        style: {
                        }
                    }}
                />
            </MuiPickersUtilsProvider>
            {error && <div style={{marginTop: 5, color: 'red', fontSize: 10}}>{error}</div>}
        </div>
    )
}

export default DatePickerGroup;