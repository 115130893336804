import {
    LOAD_POST_REQUEST,
    LOAD_POST_SUCCESS,
    SET_ACTIVE_POST,
    UNSET_ACTIVE_POST,
    SET_NEW_POST,
    UNSET_NEW_POST,
    SAVE_POST_REQUEST,
    SAVE_POST_SUCCESS,
    POST_INITIALIZE_DATATABLE,
    POST_NEXT_PAGE,
    POST_PREVIOUS_PAGE,
    POST_CHANGE_PER_PAGE,
    POST_SORT_TABLE,
    POST_FILTER_TABLE,
    SET_POST_PICTURES,
    DELETE_POST_REQUEST,
    DELETE_POST_SUCCESS
} from "../reducers/postReducer";

export const loadPostRequest = ({id}) => ({
    type: LOAD_POST_REQUEST,
    id
});

export const loadPostSuccess = ({posts, totalRecords}) => ({
    type: LOAD_POST_SUCCESS,
    posts,
    totalRecords
});

export const setPostPictures = ({pictures}) => ({
    type: SET_POST_PICTURES,
    pictures
});


export const setActivePost = (activePost) => ({
    type: SET_ACTIVE_POST,
    activePost
});

export const unsetActivePost = (id) => ({
    type: UNSET_ACTIVE_POST
});

export const setNewPost = () => ({
    type: SET_NEW_POST,

});

export const unsetNewPost = () => ({
    type: UNSET_NEW_POST
});

export const savePostRequest = ({data}) => ({
    type: SAVE_POST_REQUEST,
    data
});

export const savePostSuccess = () => ({
    type: SAVE_POST_SUCCESS,
});

export const deletePostRequest = ({data}) => ({
    type: DELETE_POST_REQUEST,
    data
});

export const deletePostSuccess = () => ({
    type: DELETE_POST_SUCCESS,
});

export const initializeDataTable = ({options}) => ({
    type: POST_INITIALIZE_DATATABLE,
    ...options,
});

export const nextPage = () => { console.log('In Actions')
    return({
    type: POST_NEXT_PAGE,
})};

export const previousPage = () => ({
    type: POST_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: POST_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: POST_SORT_TABLE,
    field,
    value
});

export const filterTable = (values) => ({
    type: POST_FILTER_TABLE,
    ...values
});