
/* Action types */
const PREFIX = "stripe/";

export const CREATE_ACCOUNT_REQUEST = PREFIX + "CREATE_ACCOUNT_REQUEST";
export const CREATE_ACCOUNT_SUCCESS = PREFIX + "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_ERROR = PREFIX + "CREATE_ACCOUNT_ERROR";

export const GET_ACCOUNT_REQUEST = PREFIX + "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = PREFIX + "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = PREFIX + "GET_ACCOUNT_ERROR";

/* initial state */
const initialState = {
    link: null,
    account: null,
};

/* Reducer */
const stripeReducer = (state = initialState, action) => {

    switch(action.type) {
        case CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
            };
        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                link: action.link
            };
        case CREATE_ACCOUNT_ERROR:
            return {
                ...state,
                link: null
            };
        case GET_ACCOUNT_REQUEST:
            return {
                ...state,
            };
        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.account
            };
        case GET_ACCOUNT_ERROR:
            return {
                ...state,
                account: null
            };
        default:
            return state;
    }
};
export default stripeReducer;