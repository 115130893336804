import React from "react";
import {MenuItem} from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../SelectGroup";
import moment from "moment";

const required = value => value ? null : 'Feld erforderlich';

const YearSelect = (props) => {

    const options = [
        <MenuItem id="1" value={'Alle'}>Alle</MenuItem>,
        <MenuItem id="2" value={2020}>{'2020'}</MenuItem>,
        <MenuItem id="3" value={2021}>{'2021'}</MenuItem>
    ];

    return <Field
        name={props.fieldName}
        label="Jahr"
        disabled={!!props.disabled}
        component={SelectGroup}
        margin="dense"
        fullWidth
        validate={props.required && [ required ]}
    >
        {options}
    </Field>;

};

export default YearSelect;