import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {reduxForm} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Grid, Typography, Button, Box, List, ListItemText, ListItem, Divider, ListItemAvatar, Avatar, ListItemIcon
} from "@material-ui/core";
import {getNotificationRequest} from "../../../actions/notificationActions";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from "moment";


const classes = {
    root: {
        width: '100%',
        maxWidth: '36ch',
    },
    inline: {
        display: 'inline',
    },
    block: {
        display: 'block',
    },
};


class Notifications extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.loadNotifications();
    }

    render() {
        const { notifications } = this.props;

        const notificationListitems = (notifications?notifications:[]).map(n => {
            return [
                <ListItem>
                    <ListItemText
                        primary={n.title}
                        secondary={
                            <React.Fragment>
                                <Typography component="div" style={{color: 'black'}}>
                                    {n.message}
                                </Typography>
                                <Typography component="div" style={{fontSize: '11px', marginTop: '5px'}}>
                                    <AccessTimeIcon style={{fontSize: '11px', marginRight: '5px', marginTop: '4px'}}/>
                                    {moment(n.created_at).format('DD.MM.YYYY HH:mm') }
                                </Typography>
                                <Typography component="div" style={{fontSize: '11px'}}>
                                    Typ: {n.type}
                                </Typography>
                                {
                                    (n.filter) && <Typography component="div" style={{fontSize: '11px'}}>
                                        Filter: {n.filter.description}
                                    </Typography>
                                }


                            </React.Fragment>
                        }
                    />
                </ListItem>,
                <Divider component="li" style={{backgroundColor:'rgba(0,0,0,0.1)'}} />
            ];
        });

        return (
            <Fragment>
                <h2>Gesendete Notifications</h2>
                <div style={{marginTop: '-15px', color: 'rgba(0,0,0,0.2)'}}>Die letzten 5 Nachrichten</div>
                <List className={classes.root}>
                    {notificationListitems}
                </List>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return{
        notifications: state.notification.data ? state.notification.data : [],
    }
}
const mapDispatchToProps = {
    loadNotifications: getNotificationRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'notifications-filter-form',
        enableReinitialize: true,
    }),
    withStyles(style))
(Notifications);