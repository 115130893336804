import { LOAD_HELLO_ERROR, LOAD_HELLO_REQUEST, LOAD_HELLO_SUCCESS,
    LOAD_HELLOS_ERROR,
    LOAD_HELLOS_REQUEST,
    LOAD_HELLOS_SUCCESS, PATCH_HELLO_ERROR,
    PATCH_HELLO_REQUEST,
    PATCH_HELLO_SUCCESS,
    SET_ACTIVE_HELLO,
    SET_NEW_HELLO,
    UNSET_ACTIVE_HELLO,
    UNSET_NEW_HELLO,
    HELLO_CHANGE_PER_PAGE,
    HELLO_FILTER_TABLE,
    HELLO_INITIALIZE_DATATABLE,
    HELLO_NEXT_PAGE,
    HELLO_PREVIOUS_PAGE,
    HELLO_SORT_TABLE
} from "../reducers/helloReducer";

export const loadHellosRequest = () => ({
    type: LOAD_HELLOS_REQUEST,
});

export const loadHellosSuccess = (values) => ({
    type: LOAD_HELLOS_SUCCESS,
    ...values
});

export const loadHellosError = (values) => ({
    type: LOAD_HELLOS_ERROR,
    ...values
});

export const loadHelloRequest = () => ({
    type: LOAD_HELLO_REQUEST,
});

export const loadHelloSuccess = (values) => ({
    type: LOAD_HELLO_SUCCESS,
    ...values
});

export const loadHelloError = (values) => ({
    type: LOAD_HELLO_ERROR,
    ...values
});

export const patchHelloRequest = (values) => ({
    type: PATCH_HELLO_REQUEST,
    ...values
});

export const patchHelloSuccess = (values) => ({
    type: PATCH_HELLO_SUCCESS,
    ...values
});

export const patchHelloError = (values) => ({
    type: PATCH_HELLO_ERROR,
    ...values
});

export const initializeDataTable = (values) => ({
    type: HELLO_INITIALIZE_DATATABLE,
    ...values,
});

export const nextPage = () => ({
    type: HELLO_NEXT_PAGE,
});

export const previousPage = () => ({
    type: HELLO_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: HELLO_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: HELLO_SORT_TABLE,
    field,
    value
});

export const filterTable = ({values}) => ({
    type: HELLO_FILTER_TABLE,
    values
});

export const setNewHello = () => ({
    type: SET_NEW_HELLO
});

export const unsetNewHello = () => ({
    type: UNSET_NEW_HELLO
});

export const setActiveHello = (activeHello) => ({
    type: SET_ACTIVE_HELLO,
    activeHello

});

export const unsetActiveHello = () => ({
    type: UNSET_ACTIVE_HELLO
});
