import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Grid, Paper, FormControl, Select, MenuItem } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {filterTable} from "../../../actions/voucherActions";
import MultipleSelect from "../../../components/MultipleSelect";
import {loadSimpleCustomersRequest} from "../../../actions/customerActions";
import AsyncSelectSearch from "../../../components/AsyncSelectSearch";
import DateRangePickerGroup from "../../../components/DateRangePickerGroup";
import DatePickerGroup from "../../../components/DatePickerGroup";
import VoucherTypeSelect from "../../../components/Filter/VoucherTypeSelect";
import VoucherStatusSelect from "../../../components/Filter/VoucherStatusSelect";
import NumericRangeFilter from "../../../components/Filter/NumericRangeFilter";
import DateRangeFilter from "../../../components/Filter/DateRangeFilter";
import {loadSettingsRequest} from "../../../actions/settingsActions";

class VouchersFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    setCustomerSearchOpen = (value) => {
        this.setState({open: value});
    };

    customerValueChanged = (value) => {
        let search = {
            searchValue: value,
            fields: ['firstname', 'lastname', 'email'],
            status: ['active', 'activation_code_sent', 'inactive']
        };
        this.props.loadSimpleCustomersRequest({search});
    };

    customerSelected = (value) => {
        this.props.change('customer', value?value.value:null);
    };

    render() {
        const {customers, isCustomerFetching} = this.props;
        const { open } = this.state;

        let customerOptions = (customers?customers:[]).map(customer =>{
            return {
                value: customer._id,
                label: customer.firstname + ' ' + customer.lastname
            }
        });

        return (
            <Fragment>
                <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <Field
                            name="customer"
                            component={AsyncSelectSearch}
                            label={'Benutzer'}
                            fullWidth
                            setOpen={this.setCustomerSearchOpen}
                            onInputChange={this.customerValueChanged}
                            onChange={this.customerSelected}
                            open={open}
                            loading={isCustomerFetching}
                            options={customerOptions}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <VoucherTypeSelect
                            fieldName={'basic_type'}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <VoucherStatusSelect
                            fieldName={'status'}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'}>
                        <NumericRangeFilter
                            labelFrom={'Restwert von (€)'}
                            labelTo={'Restwert bis (€)'}
                            fieldNameFrom={'rest_from'}
                            fieldNameTo={'rest_to'}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'}>
                        <DateRangeFilter
                            labelFrom={'Erstellt am von'}
                            labelTo={'Erstellt am bis'}
                            fieldNameFrom={'created_at_from'}
                            fieldNameTo={'created_at_to'}
                        />
                    </Grid>
                    <Grid item md={'auto'} xs={'auto'}>
                        <DateRangeFilter
                            labelFrom={'Gesendet am von'}
                            labelTo={'Gesendet am bis'}
                            fieldNameFrom={'sent_at_from'}
                            fieldNameTo={'sent_at_to'}
                        />
                    </Grid>
                </Grid>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        restaurants: state.restaurant.data,
        customers: state.customer.simpleData,
        isCustomerFetching: state.customer.isFetching,
        initialValues: {
            customer: '',
            status: ['active']
        },
    }
}
const mapDispatchToProps = {
    setFilter: filterTable,
    loadSimpleCustomersRequest: loadSimpleCustomersRequest,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'voucher-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {

            let created_at_time_range = [];
            if(values.created_at_from && values.created_at_to){
                created_at_time_range = [values.created_at_from, values.created_at_to];
            }

            let sent_at_time_range = [];
            if(values.sent_at_from && values.sent_at_to){
                sent_at_time_range = [values.sent_at_from, values.sent_at_to];
            }

            dispatch(props.setFilter({
                values: {
                    customer_id: values.customer ? [values.customer.value] : '',
                    created_at: created_at_time_range,
                    sent_at: sent_at_time_range,
                    status: values.status?values.status:null,
                    basic_type: values.basic_type?values.basic_type:null,
                    rest_from: values.rest_from?Number(values.rest_from)*100:null,
                    rest_to: values.rest_to?Number(values.rest_to)*100:null,

                }
            }));
        },
    }),
    (withStyles(style)),
)(VouchersFilter)