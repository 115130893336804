import React from "react";
import {Field} from "redux-form";
import DatePickerGroup from "../DatePickerGroup";

const DateFilter = (props) => {
    return [
        <Field
            name={props.fieldName}
            label={props.labelFrom}
            component={DatePickerGroup}
        />,
    ]
};

export default DateFilter;