
/* Action types */
const PREFIX = "customer/";

export const LOAD_CUSTOMERS_REQUEST = PREFIX + "LOAD_CUSTOMERS_REQUEST";
export const LOAD_CUSTOMERS_SUCCESS = PREFIX + "LOAD_CUSTOMERS_SUCCESS";

export const LOAD_SIMPLE_CUSTOMERS_REQUEST = PREFIX + "LOAD_SIMPLE_CUSTOMERS_REQUEST";
export const LOAD_SIMPLE_CUSTOMERS_RESULT = PREFIX + "LOAD_SIMPLE_CUSTOMERS_RESULT";

export const SET_ACTIVE_CUSTOMER = PREFIX + "SET_ACTIVE_CUSTOMER";

export const SAVE_CUSTOMER_REQUEST = PREFIX + "SAVE_CUSTOMER_REQUEST";
export const SAVE_CUSTOMER_RESULT = PREFIX + "SAVE_CUSTOMER_RESULT";

export const PATCH_CUSTOMER_REQUEST = PREFIX + "PATCH_CUSTOMER_REQUEST";
export const PATCH_CUSTOMER_RESULT = PREFIX + "PATCH_CUSTOMER_RESULT";

export const CUSTOMER_INITIALIZE_DATATABLE = PREFIX + "CUSTOMER_INITIALIZE_DATATABLE";
export const CUSTOMER_NEXT_PAGE = PREFIX + "CUSTOMER_NEXT_PAGE";
export const CUSTOMER_PREVIOUS_PAGE = PREFIX + "CUSTOMER_PREVIOUS_PAGE";
export const CUSTOMER_CHANGE_PER_PAGE = PREFIX + "CUSTOMER_CHANGE_PER_PAGE";
export const CUSTOMER_SORT_TABLE = PREFIX + "CUSTOMER_SORT_TABLE";
export const CUSTOMER_FILTER_TABLE = PREFIX + "CUSTOMER_FILTER_TABLE";

/* initial state */
const initialState = {
    isFetching: false,
    isSaving: false,
    isDeleting: false,
    data: [],
    simpleData: [],
    activeCustomer: null,
    filterValues: [],

    filterFilteredCustomers: null,
    filterData: null,
    filterSaved: null,

    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'created_at',
};

/* Reducer */
const customerReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_CUSTOMERS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.customers,
                totalRecords: action.totalRecords
            };
        case LOAD_SIMPLE_CUSTOMERS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_SIMPLE_CUSTOMERS_RESULT:
            return {
                ...state,
                isFetching: false,
                simpleData: action.customers,
            };
        case SET_ACTIVE_CUSTOMER:
            return {
                ...state,
                isFetching: false,
                activeCustomer: action.activeCustomer,
            };
        case CUSTOMER_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
            };
        case CUSTOMER_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case CUSTOMER_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case CUSTOMER_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case CUSTOMER_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case CUSTOMER_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        default:
            return state;
    }
};
export default customerReducer;