import React, { Component, Fragment } from 'react';
import CheckinStatusSelect from '../../../components/Filter/CheckinStatusSelect';
import {compose} from 'redux';
import {connect} from 'react-redux';
import { filter } from '../../../actions/checkinActions';
import { Field ,reduxForm } from 'redux-form';
import {Grid} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";

class LiveFilter extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Fragment>
                <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                    <Grid item md={'auto'} xs={'auto'} style={{minWidth: 300}}>
                        <CheckinStatusSelect fieldName="status"/>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

export default compose(
    connect(function(){
        return {
            initialValues: {
                status: ['to_invoice', 'to_pay', 'active']
            }
        }    
    }),
    reduxForm({
        form: 'live-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch) => {
            dispatch(filter({
                status: values.status ? values.status : null,
            }));
        }
    }),
    withStyles(style),
)(LiveFilter)
