import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {reduxForm, Field, reset, FieldArray, formValueSelector} from 'redux-form';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {
    Paper,
    Grid,
    IconButton,
    Button,
    Switch,
    MenuItem,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions
} from "@material-ui/core";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {sendNotificationRequest, setCustomersFilter} from "../../../actions/notificationActions";
import MultipleSelect from "../../../components/MultipleSelect";
import {loadTagsRequest} from "../../../sagas/tagSagas";
import {loadTypeRequest} from "../../../actions/typeActions";
import {loadTagRequest} from "../../../actions/tagActions";
import {loadCustomersFilterRequest, saveCustomersFilterRequest} from "../../../actions/customerActions";
import SelectGroup from "../../../components/SelectGroup";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {loadRestaurantRequest} from "../../../actions/restaurantActions";
import {loadFiltersRequest, patchFilterRequest, saveFiltersRequest} from "../../../actions/filterActions";
import {isAllowed} from "../../../utils/permissionHelper";

class FiltersForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            deleteModal: false
        }
    }

    componentDidMount(){
        this.props.loadTags();
        this.props.loadTypes();
        this.props.loadRestaurants();
    }

    required = value => value ? undefined : 'Feld erforderlich';

    submit = (values) =>{
        this.setState({submitted: true});
        this.props.saveFilter({save: false, ...values});
    };

    patch = action => {
        if(this.props.activeFilter && this.props.activeFilter._id){
            this.props.patchFilter({_id: this.props.activeFilter._id, action});
            this.setState({deleteModal: false})
            this.props.onClose();
        }
    }

    setFilter = (values) =>{
        this.setState({submitted: true});
        if(this.props.activeFilter && this.props.activeFilter._id){
            this.props.saveFilter({save: true, ...values, _id: this.props.activeFilter._id});
        } else {
            this.props.saveFilter({save: true, ...values});
        }

        this.props.onClose();
    };

    getFilterTypes = () => {
        return [
            {value: 'restaurant', label: 'Restaurant'},
            {value: 'address', label: 'Ort und Umkreis'},
            {value: 'type', label: 'Kategorie'},
            {value: 'tags', label: 'Tags'},
            {value: 'kitchen', label: 'Küche'},
            {value: 'weekday', label: 'Wochentag'},
            {value: 'time', label: 'Uhrzeit'},
            {value: 'turnover', label: 'Umsatz'},
            {value: 'discount', label: 'User - Status'},
            {value: 'customer_address', label: 'Wohnort'},
        ].map(option => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
        })
    };

    getComparatorTypes = () => {
        return [
            {value: 'and', label: 'und'},
            {value: 'or', label: 'oder'},
        ].map(option => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
        })
    };

    getTurnoverRangeTypes = () => {
        return [
            {label: 'aktuelle Woche', value: 'week'},
            {label: 'aktuelles Monat', value: 'month'},
            {label: 'aktuelles Jahr', value: 'year'},
            {label: 'Gesamt', value: 'total'},

        ].map(option => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
        })
    };

    getComparatorField = (entry, value) => {
        let type = value.field;//'category';
        if(type == 'type' || type == 'tags' || type == 'kitchen' || type == 'weekday' || type == 'time'|| type == 'restaurant' || type == 'discount'){
            return <Field
                name={`${entry}.operator`}
                label="Vergleich"
                component={SelectGroup}
                margin="dense"
                fullWidth
                validate={[ this.required ]}
            >
                {this.getComparatorTypes()}
            </Field>
        } else if(type == 'turnover'){
            return <Field
                name={`${entry}.aggregation`}
                label="Zeitraum"
                component={SelectGroup}
                margin="dense"
                fullWidth
                validate={[ this.required ]}
            >
                {this.getTurnoverRangeTypes()}
            </Field>
        } else {
            return <Fragment></Fragment>;
        }
    };

    getField = (entry, value) => {
        const {restaurant_types, restaurant_kitchen, restaurant_tags, restaurants} = this.props;
        let type = value.field;//'category';

        if(type == 'type'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Kategorie'}
                fullWidth
                accessorValue={"_id"}
                accessorLabel={'name'}
                options={restaurant_types}
                validate={[ this.required ]}
            />
        } else if(type == 'tags'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Tags'}
                fullWidth
                accessorValue={"value"}
                accessorLabel={'label'}
                options={restaurant_tags}
            />
        } else if(type == 'kitchen'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Küche'}
                fullWidth
                accessorValue={"value"}
                accessorLabel={'label'}
                options={restaurant_kitchen}
            />
        } else if(type == 'weekday'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Wochentage'}
                fullWidth
                accessorValue={"value"}
                accessorLabel={'label'}
                options={[{value: 0, label: 'Sonntag'}, {value: 1, label: 'Montag'}, {value: 2, label: 'Dienstag'}, {value: 3, label: 'Mittwoch'}, {value: 4, label: 'Donnerstag'}, {value: 5, label: 'Freitag'}, {value: 6, label: 'Samstag'}]}
            />
        }  else if(type == 'time'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Uhrzeit'}
                fullWidth
                accessorValue={"value"}
                accessorLabel={'label'}
                options={[{value: 1, label: 'Frühstück'}, {value: 2, label: 'Mittagessen'}, {value: 3, label: 'Abend'}]}
            />
        } else if(type == 'turnover'){
            return <div>
                <Field
                    name={`${entry}.values[0]`}
                    label="Umsatz von"
                    component={TextFieldGroup}
                    margin="dense"
                    disabled={false}
                    placeholder={"von"}
                    type={"number"}
                    autoComplete={false}
                />
                <Field
                    name={`${entry}.values[1]`}
                    label="Umsatz bis"
                    component={TextFieldGroup}
                    type={"number"}
                    disabled={false}
                    margin="dense"
                    placeholder={"bis"}
                    autoComplete={false}
                />
            </div>
        } else if(type == 'address'){
            return <div>
                <Field
                    name={`${entry}.values[0]`}
                    label="PLZ"
                    component={TextFieldGroup}
                    margin="dense"
                    disabled={false}
                    placeholder={""}
                    type={"number"}
                    autoComplete={false}
                />
                <Field
                    name={`${entry}.values[1]`}
                    label="Umkreis (km)"
                    component={TextFieldGroup}
                    margin="dense"
                    disabled={false}
                    placeholder={""}
                    type={"number"}
                    autoComplete={false}
                />
            </div>
        } else if(type == 'restaurant'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Restaurant bereits besucht'}
                fullWidth
                accessorValue={"_id"}
                accessorLabel={"name"}
                options={restaurants}
            />
        } else if(type == 'discount'){
            return <Field
                format={value => Array.isArray(value) ? value : []}
                name={`${entry}.values`}
                component={MultipleSelect}
                label={'Status'}
                fullWidth
                accessorValue={"value"}
                accessorLabel={'label'}
                options={[{value: '7base', label: '7base'}, {value: '7bronze', label: '7bronze'}, {value: '7silver', label: '7silver'}, {value: '7gold', label: '7gold'}, {value: '7platin', label: '7platin'}]}
            />
        } else if(type == 'customer_address'){
            return <div>
                <Field
                    name={`${entry}.values[0]`}
                    label="PLZ"
                    component={TextFieldGroup}
                    margin="dense"
                    disabled={false}
                    placeholder={""}
                    type={"number"}
                    autoComplete={false}
                />
                <Field
                    name={`${entry}.values[1]`}
                    label="Umkreis (km)"
                    component={TextFieldGroup}
                    margin="dense"
                    disabled={false}
                    placeholder={""}
                    type={"number"}
                    autoComplete={false}
                />
            </div>
        }
    };

    renderFilterGroups = ({values, fields, meta: { error, submitFailed } }) => {
        return(
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    {fields.map((entry, index) => (
                        <div key={index}>
                            <Grid container className={this.props.classes.fragmentContainer} style={{position: 'relative'}}>
                                <IconButton edge="start" color="inherit" onClick={() => fields.remove(index)} aria-label="close" style={{position: 'absolute', top: 0, right:0}}>
                                    <CloseIcon />
                                </IconButton>

                                <Grid item xs={12} md={6}>
                                    <Field
                                        name={`${entry}.operator`}
                                        label="Wie sollen die Filter innerhalb der Gruppe verknüpft werden"
                                        component={SelectGroup}
                                        margin="dense"
                                        fullWidth
                                        validate={[ this.required ]}
                                    >
                                        {this.getComparatorTypes()}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        values && <FieldArray name={`${entry}.values`} component={this.renderFilterRows} values={values[index].values} />
                                    }
                                </Grid>
                            </Grid>
                            { (fields.length > 1 && index != (fields.length - 1)) &&
                                <Typography style={{padding: '15px', textAlign:'center', color: 'rgba(0,0,0,0.4)'}}>- UND -</Typography>
                            }
                        </div>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth onClick={() => fields.push({operator: 'and', values: null})} size={'small'} color={'secondary'} variant={'outlined'}>Gruppe hinzufügen</Button>
                    {submitFailed && error && <span>{error}</span>}
                </Grid>
            </Grid>
        );
    };

    renderFilterRows = ({values, fields, meta: { error, submitFailed } }) => {
        return(
            <Grid container spacing={5} >
                <Grid item xs={12}>
                    {fields.map((entry, index) => (
                        <div key={index}>
                            <Grid container>
                                <Grid item xs={2} md={2}>
                                    <Field
                                        name={`${entry}.field`}
                                        label="Filter"
                                        component={SelectGroup}
                                        margin="dense"
                                        fullWidth
                                        validate={[ this.required ]}
                                    >
                                        {this.getFilterTypes()}
                                    </Field>
                                </Grid>
                                <Grid xs={1}>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    {this.getField(entry, values[index])}
                                </Grid>
                                <Grid xs={1}>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    {this.getComparatorField(entry, values[index])}
                                </Grid>
                                <Grid xs={1}>
                                </Grid>
                                <Grid xs={1} style={{textAlign: 'center'}}>
                                    <Button onClick={() => fields.remove(index)} size={'small'}> Entfernen </Button>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth onClick={() => fields.push({operator: 'and', field: 'type', values: [], aggregation: 'week'})} size={'small'} color={'secondary'} variant={'outlined'}>Bedingung hinzufügen</Button>
                    {submitFailed && error && <span>{error}</span>}
                </Grid>
            </Grid>
        );
    };



    render() {
        const {classes, handleSubmit, formValueFilter, filteredCustomers, activeFilter} = this.props;
        return <Fragment>
                <form>

                    <Grid container style={{marginTop: '100px'}}>
                        <Field
                            name="description"
                            label="Beschreibung"
                            component={TextFieldGroup}
                            margin="dense"
                            fullWidth={true}
                            validate={[ this.required ]}
                        />
                    </Grid>


                    <Grid container style={{marginTop: '20px'}}>
                        <Grid xs={12}>
                            <FieldArray name={`filter.values`} component={this.renderFilterGroups} values={formValueFilter} />
                        </Grid>
                    </Grid>


                    <Grid container spacing={5} style={{marginTop: '20px'}}>
                        <Grid item xs={6}>
                                <Button fullWidth onClick={handleSubmit(this.submit)} color={'primary'} variant={'outlined'}>Filter überprüfen</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleSubmit(this.setFilter)} color={'primary'} variant={'contained'}>Filter übernehmen</Button>
                        </Grid>
                        {(isAllowed(this.props.user, 'filters', 'delete') && this.props.activeFilter && this.props.activeFilter._id) &&
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    onClick={() => {activeFilter.status === 'active' ? this.setState({deleteModal: true}):this.patch('enable')}}
                                    color={'error'}
                                    variant={'outlined'}>
                                    {activeFilter.status === 'active' ? 'Filter inaktiv setzen' : 'Filter aktiv setzen'}
                                </Button>
                            </Grid>
                        }


                        { (this.state.submitted && filteredCustomers != null) &&
                            <Grid item xs={12}>
                                <Typography>Zutreffende App-Benutzer: {filteredCustomers.length}</Typography>
                            </Grid>
                        }

                    </Grid>
                </form>
            {(this.state.deleteModal === true) &&
                <Dialog open={true}>
                    <DialogTitle>Sind Sie sicher?</DialogTitle>

                    <DialogContent>{'Möchten Sie den Eintrag wirklich inaktiv setzen?'}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => {this.setState({deleteModal: false})}} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={() => {this.patch('disable')}} color="primary">
                            {'Inaktiv setzen'}
                        </Button>
                    </DialogActions>

                </Dialog>
            }
        </Fragment>;
    }
}

const selector = formValueSelector('notifications-filter-form');

function mapStateToProps(state) {
    const activeFilter = state.filter.activeFilter;
    return {
        user: state.auth.user,
        activeFilter: activeFilter,
        restaurant_tags: state.tag.data ? state.tag.data : [],
        restaurant_kitchen: state.tag.kitchen ? state.tag.kitchen : [],
        restaurant_types: state.type.data,
        restaurants: state.restaurant.data,
        initialValues: {
            description: activeFilter?activeFilter.description:'',
            filter: activeFilter ? activeFilter.filter : {
                values: [],
                operator: 'and'
            }
        },
        formValueFilter: selector(state, 'filter.values'),
        filteredCustomers: state.filter.customers
    }
};

const mapDispatchToProps = {
    sendNotification: sendNotificationRequest,
    saveFilter: saveFiltersRequest,
    patchFilter: patchFilterRequest,
    loadTags: loadTagRequest,
    loadTypes: loadTypeRequest,
    loadRestaurants: loadRestaurantRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'notifications-filter-form',
    }),
    withStyles(style))
(FiltersForm);