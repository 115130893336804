import React, { Component } from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {TableCell, TableHead, TableRow, TableBody, Table} from "@material-ui/core";
import {formatDateTime, formatMoneyFromApi} from "../../../utils/helper";

class CustomersVoucherView extends Component {

    componentWillMount() {}

    renderHead() {
        return (
            <TableHead>
                <TableRow>
                    <TableCell key={'1'} className={'col-md-2'}>{'Erstellt am'}</TableCell>
                    <TableCell key={'1'} className={'col-md-3'}>{'Wert'}</TableCell>
                    <TableCell key={'2'} className={'col-md-3'}>{'Status'}</TableCell>
                    <TableCell key={'3'} className={'col-md-3'}>{'Eingelöst am'}</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderBody() {
        const data = this.props.data;
        const tr = data.map((row) => {
            const toReturn = [];

            toReturn.push(<TableRow>
                <TableCell>{formatDateTime(row['created_at'])}</TableCell>
                <TableCell>{(() => {
                    let value = formatMoneyFromApi(row['value']);
                    if(row['basic_type'] == 'percentage') {
                        value = (row['value'] / 100) + '%';
                    }
                    return value;
                })()}
                </TableCell>
                <TableCell>{(() => {
                    let value = 'Aktiv';
                    if(row['status'] == 'solved'){
                        value = 'Eingelöst';
                    } else if(row['status'] == 'inactive'){
                        value = 'Gelöscht';
                    }
                    return value;
                })()}</TableCell>
                <TableCell>{formatDateTime(row['cashed_at'])}</TableCell>
            </TableRow>);

            return toReturn;
        });
        return (
            <TableBody>
                {tr}
            </TableBody>
        );
    }

    render() {
        return (
            <div className='table-wrapper' >
                <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                    {this.renderHead()}
                    {this.renderBody()}
                </Table>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CustomersVoucherView)
