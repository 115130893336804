import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import UserView from './UsersView';
import {Dialog, IconButton, AppBar, Toolbar, Typography, Grid} from "@material-ui/core";
import UsersForm from './UsersForm';
import {loadUsersRequest, setNewUser, unsetActiveUser, unsetNewUser} from "../../../actions/userActions";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {isAllowed} from "../../../utils/permissionHelper";


class Users extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        this.props.loadUsers();
    }

    render() {
        const { classes, activeUser, newUser } = this.props;


        return (
            <Fragment>
                { isAllowed(this.props.user, 'backendUsers', 'create') &&
                    <IconButton onClick={this.props.setNewUser} style={{left: '95%'}}>
                        <AddIcon fontSize={'large'}/>
                    </IconButton>
                }
                <UserView/>

                {activeUser === true &&
                <Dialog fullScreen open={true} >
                    <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.props.unsetActiveUser} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                User
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container style={{justifyContent:'center'}}>
                        <Grid item xs={6}>
                            <UsersForm isNewUser={false}/>
                        </Grid>
                    </Grid>
                </Dialog>
                }

                {newUser === true &&
                <Dialog fullScreen open={true} >
                    <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.props.unsetNewUser} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Neuen User erstellen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container style={{justifyContent:'center'}}>
                        <Grid item xs={6}>
                            <UsersForm isNewUser={true}/>
                        </Grid>
                    </Grid>
                </Dialog>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        activeUser: state.user.activeUser,
        newUser: state.user.newUser,
        user: state.auth.user
    }
}
const mapDispatchToProps = {
    setNewUser: setNewUser,
    unsetActiveUser: unsetActiveUser,
    unsetNewUser: unsetNewUser,
    loadUsers: loadUsersRequest
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Users));