import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    monthsInitializeDataTable,
    monthsNextPage,
    monthsPreviousPage,
    monthsChangePerPage,
    monthsSort,
    monthsFilterTable,
    setAccountedMonth
} from '../../../actions/accountingActions';
import VouchersFilter from "../Vouchers/VouchersFilter";
import AccountingsFilter from "./AccountingsFilter";


const mapStateToProps = (state) => {
    let { monthsPage, monthsPerPage, monthsTotalRecords, isFetching, monthsSortDirection, monthsSortField } = state.accounting;
    let data = state.accounting ? state.accounting.monthsData : [];
    let user = state.auth.user;

    let fields = {
        "Umsatz von": "month",
    };

    if(user && user.role !== 'restaurant'){
        fields = {
            ...fields,
            ...{
                "Unrab. Umsatz": "sum7",
                "Rab. Umsatz": "sum_net_with_discount",
                "Rabatt": "sum7stat",
                "Provision": "provision",}
            }
        }

    const click = true;

    return {
        page: monthsPage,
        perPage: monthsPerPage,
        totalRecords: monthsTotalRecords,
        data,
        loading: isFetching,
        sortDirection: monthsSortDirection,
        sortField: monthsSortField,
        fields,
        filterComponent: () => <AccountingsFilter />,
        click,
        sortFields: ['month']
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable: (options => { dispatch(monthsInitializeDataTable(options))}),
        nextPage: () => { dispatch(monthsNextPage())},
        previousPage: () => {dispatch(monthsPreviousPage())},
        changePerPage: (value => { dispatch(monthsChangePerPage(value))}),
        sort: (field, value) => {
            dispatch(monthsSort(field, value))},
        onClickRow: (rowData) => {
            dispatch(setAccountedMonth({month: rowData.month}))
            ownProps.history.push("/intern/accountings/" + rowData.month);
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
