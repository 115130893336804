import React, { Component, Fragment } from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Grid} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import DateRangeFilter from "../../../../components/Filter/DateRangeFilter";
import {filterTable} from "../../../../actions/postActions";
import style from "../../../../containers/app/AppContainerStyles";

function RestaurantsPostFilter(){

    return (
        <Fragment>
            <Grid container spacing={5} style={{border: '1px solid #262c46', width: '100%', margin: 0}}>
                <Grid item md={3} xs={12}>
                    <DateRangeFilter
                        fieldNameFrom={'date_from'}
                        fieldNameTo={'date_to'}
                        labelFrom={'Erstellt am (von)'}
                        labelTo={'Erstell am (bis)'}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        initialValues: {
            date_from: null,
            date_to: null
        },
    }
}
const mapDispatchToProps = {
    setFilter: filterTable
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'post-filter-form',
        enableReinitialize: true,
        onChange: (values, dispatch, props, previousValues) => {

            let created_at_time_range = [];
            if(values.date_from && values.date_to){
                created_at_time_range = [values.date_from, values.date_to];
            }

            dispatch(props.setFilter({
                values: {
                    created_at: created_at_time_range
                }
            }));
        },
    }),
    (withStyles(style)),
)(RestaurantsPostFilter)