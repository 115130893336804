import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Grid, Tabs, Tab, Typography } from "@material-ui/core";
import TagsList from './TagsList';
import PropTypes from 'prop-types';
import {loadTagRequest} from "../../../actions/tagActions";
import {Prompt} from "react-router";
import {isDirty} from "redux-form";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{maxWidth: "1200px"}}
            {...other}
        >
            <Fragment p={3}>{children}</Fragment>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        }
    }

    componentDidMount(){
        this.props.loadTags();
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    render() {
        const { classes, tags, kitchen } = this.props;

        return (
            <Fragment>
                <Prompt
                    when={(this.props.isTagsFormDirty || this.props.isKitchenFormDirty)}
                    message='Sie haben ungespeicherte Änderungen, wollen Sie die Seite trotzdem verlassen?'
                />
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                        indicatorColor="primary"
                    >
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Tags" {...a11yProps(0)} />
                        <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Küche" {...a11yProps(1)} />

                    </Tabs>

                    <TabPanel className={classes.tabContainer} value={this.state.value} index={0}>
                        <Grid container spacing={10} item xs={12}>
                            <Grid item xs={12} md={12}>
                                <TagsList tags={tags} formName={'tags-filter-form-default'} type={'default'}/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={1}>
                        <Grid container spacing={10} item xs={12}>
                            <Grid item xs={12} md={12}>
                                <TagsList tags={kitchen} formName={'tags-filter-form-kitchen'} type={'kitchen'} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    loadTags: loadTagRequest
};

function mapStateToProps(state) {
    const tags = state.tag.data ? state.tag.data : [];
    const kitchen = state.tag.kitchen ? state.tag.kitchen : [];
    return {
        tags: tags,
        kitchen: kitchen,
        user: state.auth.user,
        isTagsFormDirty: isDirty('tags-filter-form-default')(state),
        isKitchenFormDirty: isDirty('tags-filter-form-kitchen')(state),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(style))
(Tags);