import MyTable from '../../../components/MyTable';
import React from 'react';
import { connect } from 'react-redux';
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    initializeDataTable,
    nextPage,
    previousPage,
    changePerPage,
    sort,
    filterTable,
    setActivePost
} from '../../../actions/postActions';
import RestaurantsPostFilter from "./Filter/RestaurantPostsFilter";


const mapStateToProps = (state) => {
    let { page, perPage, totalRecords, isFetching, sortDirection, sortField, filter } = state.post;
    let data = state.post.data ? state.post.data : [];

    const fields = {
        "Titel": "title",
        "Content": "content",
        "erstellt am": "created_at",
        "Veröffentlichen": "public"
    };

    const idField = "id";

    const click = true;

    return {
        page,
        perPage,
        totalRecords,
        data,
        loading: isFetching,
        sortDirection,
        sortField,
        filter,
        fields,
        idField,
        filterComponent: () => <RestaurantsPostFilter />,
        click
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeDataTable,
        nextPage: () => { dispatch(nextPage())},
        previousPage: () => {dispatch(previousPage())},
        changePerPage: (value => { dispatch(changePerPage(value))}),
        sort: (field, value) => { dispatch(sort(field, value))},
        onClickRow: (rowData) => {
            dispatch(setActivePost(rowData));
        },
        filterTable,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MyTable)
