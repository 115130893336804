import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {reduxForm, Field, reset, formValueSelector} from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import { Grid, IconButton, Button} from "@material-ui/core";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {sendNotificationRequest} from "../../../actions/notificationActions";
import NotificationsFilter from "./NotificationsFilter";
import CloseIcon from '@material-ui/icons/Close';
import {isAllowed} from "../../../utils/permissionHelper";
import NotificationsFilterList from "./NotificationsFilterList";


class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customFilter: null
        };
    }

    required = value => value ? undefined : 'Feld erforderlich';

    submit = (values) => {
        this.props.sendNotification(this.props.messageType, { title: values.title, customFilter: this.state.customFilter, message: values.message, filter: values.filter, ...(this.props.messageValues?this.props.messageValues:{}) });
        this.setState({customFilter: null});
    };

    changeFilter = (id) => {
        this.props.change('filter', id);
    };

    render() {
        const {handleSubmit, title, user, formValueFilter} = this.props;

        return (
            <Fragment>
                {title && <h2>{title}</h2> }
                <form>
                    <NotificationsFilter changeFilter={this.changeFilter}/>
                    {
                        !!formValueFilter && <NotificationsFilterList filter={formValueFilter} onSubmit={(values) => {this.setState({customFilter: values})}} />
                    }
                    <Field
                        name='title'
                        label='Titel'
                        component={TextFieldGroup}
                        disabled={false}
                        validate={[ this.required ]}
                    />
                    <Field
                        name='message'
                        label='Text'
                        disabled={false}
                        component={TextFieldGroup}
                        multiline
                        rows={3}
                        validate={[ this.required ]}
                    />
                    <br/>
                    {isAllowed(user, 'notifications', 'create') &&
                        <Button style={{marginTop: '10px'}} onClick={handleSubmit(this.submit)} color={'primary'} variant={'outlined'}>Versenden</Button>
                    }

                </form>
            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {

    const form = formValueSelector('notifications-form');
    return {
        user: state.auth.user,
        initialValues: {
            title: ownProps.messageTitle?ownProps.messageTitle:'',
            message: ownProps.messageBody?ownProps.messageBody:'',
        },
        formValueFilter: form(state, 'filter'),
    }
};

const mapDispatchToProps = {
    sendNotification: sendNotificationRequest,
};

const afterSubmit = (result, dispatch) =>
    dispatch(reset('notifications-form'));

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'notifications-form',
        onSubmitSuccess: afterSubmit,
    }),
    withStyles(style))
(Notifications);