import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {compose} from "redux";
import {Divider, ListSubheader, withStyles} from "@material-ui/core";
import nav from './_nav'

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logoDefault from './logo.png'

import style from "../AppContainerStyles"
import {isAllowed} from "../../../utils/permissionHelper";

class Sidebar extends Component {

    activeRoute(routeName, props) {
        return props.location.pathname.indexOf(routeName) > -1;
    }

    filterNav(items){
        return (items?items:[]).filter(i => {
            return i.key==='divider' ? true : isAllowed(this.props.user, i.key, 'read');
        });
    }

    render() {
        const {classes, user} = this.props;
        const activeRoute = this.activeRoute;
        // nav list divider
        const divider = (divider, key) => (
            <Divider/>
        );

        // nav list title
        const title = (title, key) => (
            <ListSubheader component="div">
                {title}
            </ListSubheader>
        );

        //default navitem
        const navItem = (item, key) => (
            <ListItem button component={Link} to={item.url} selected={activeRoute(item.url, this.props)}>
                <ListItemIcon className={classes.navIcon}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name}/>
            </ListItem>
        );

        // nav link
        const navLink = (item, idx) => {
            if (!item.role || item.role == user.role) {
                return (
                    item.title ? title(item, idx) :
                        item.divider ? divider(item, idx) :
                            item.children ? navItem(item, idx)
                                : navItem(item, idx)
                );
            } else {
                return null;
            }
        };

        // nav list
        const navList = (items) => {
            return items.map((item, index) => navLink(item, index));
        };

        let logo = logoDefault;

        return (
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant={"persistent"}
                open={true}
            >
                <div className={classes.logoBackground}>
                 <img className={classes.logo} src={logo} alt="Logo"/>
                </div>
                <List className={classes.list}>
                    {navList(this.filterNav(nav.items))}
                </List>
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

Sidebar.contextTypes = {
    router: PropTypes.object.isRequired
}

export default compose(
    withStyles(style),
    connect(mapStateToProps)
)(withRouter(Sidebar));