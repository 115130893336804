
/* Action types */
const PREFIX = "post/";

export const LOAD_POST_REQUEST = PREFIX + "LOAD_POST_REQUEST";
export const LOAD_POST_SUCCESS= PREFIX + "LOAD_POST_SUCCESS";

export const SET_ACTIVE_POST = PREFIX + "SET_ACTIVE_POST";
export const UNSET_ACTIVE_POST= PREFIX + "UNSET_ACTIVE_POST";

export const SET_NEW_POST = PREFIX + "SET_NEW_POST";
export const UNSET_NEW_POST= PREFIX + "UNSET_NEW_POST";

export const SAVE_POST_REQUEST = PREFIX + "SAVE_POST_REQUEST";
export const SAVE_POST_SUCCESS= PREFIX + "SAVE_POST_SUCCESS";

export const DELETE_POST_REQUEST = PREFIX + "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = PREFIX + "DELETE_POST_SUCCESS";

export const SET_POST_PICTURES = PREFIX + "SET_POST_PICTURES";


export const POST_INITIALIZE_DATATABLE = PREFIX + "POST_INITIALIZE_DATATABLE";
export const POST_NEXT_PAGE = PREFIX + "POST_NEXT_PAGE";
export const POST_PREVIOUS_PAGE = PREFIX + "POST_PREVIOUS_PAGE";
export const POST_CHANGE_PER_PAGE = PREFIX + "POST_CHANGE_PER_PAGE";
export const POST_SORT_TABLE = PREFIX + "POST_SORT_TABLE";
export const POST_FILTER_TABLE = PREFIX + "POST_FILTER_TABLE";

/* initial state */
const initialState = {
    page: 1,
    perPage: 10,
    totalRecords: '',
    loading: false,
    sortDirection: 'desc',
    sortField: 'name',
    filter: '',
    fields: [],
    idField:'',
    filterValues: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,

    data: [],

    activePost: false,
    activePostData: [],
    activePostPictures:[],
    newPost: false,
};

/* Reducer */
const postReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_POST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_POST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.posts,
                totalRecords: action.totalRecords
            };
        case SET_POST_PICTURES:
            return {
                ...state,
                activePostPictures: action.pictures,
            };
        case SET_ACTIVE_POST:
            return {
                ...state,
                activePost: true,
                activePostData: action.activePost,
                activePostPictures: action.activePost.pictures
            };
        case UNSET_ACTIVE_POST:
            return {
                ...state,
                activePost: false,
            };
        case SET_NEW_POST:
            return {
                ...state,
                newPost: true,
            };
        case UNSET_NEW_POST:
            return {
                ...state,
                newPost: false,
            };
        case SAVE_POST_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_POST_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        case DELETE_POST_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_POST_SUCCESS:
            return {
                ...state,
                isDeleting: false,
            };
        case POST_INITIALIZE_DATATABLE:
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                loading: true,
                sortDirection: action.sortDirection,
                sortField: action.sortField,
                filter: action.filter,
            };
        case POST_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case POST_PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1,
            };
        case POST_CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value,
                page: 1
            };
        case POST_SORT_TABLE:
            return {
                ...state,
                sortField: action.field,
                sortDirection: action.value,
            };
        case POST_FILTER_TABLE:
            return {
                ...state,
                filterValues: action.values
            };
        default:
            return state;
    }
};
export default postReducer;