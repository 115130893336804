
/* Action types */
const PREFIX = "tag/";

export const LOAD_TAGS_REQUEST = PREFIX + "LOAD_TAGS_REQUEST";
export const LOAD_TAGS_SUCCESS = PREFIX + "LOAD_TAGS_SUCCESS";

export const SAVE_TAGS_REQUEST = PREFIX + "SAVE_TAGS_REQUEST";
export const SAVE_TAGS_SUCCESS = PREFIX + "SAVE_TAGS_SUCCESS";

/* initial state */
const initialState = {

    isFetching: false,
    isSaving: false,
    data: [],
    kitchen: [],
};

/* Reducer */
const tagReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_TAGS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_TAGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.tags,
                kitchen: action.kitchen,
            };
        case SAVE_TAGS_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_TAGS_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        default:
            return state;
    }
};
export default tagReducer;