const PREFIX = "checkin/";
export const LOAD_CHECKINS_REQUEST = PREFIX + "LOAD_CHECKINS_REQUEST";
export const LOAD_CHECKINS_SUCCESS = PREFIX + "LOAD_CHECKINS_SUCCESS";

export const CHECKIN_INITIALIZE_DATATABLE = PREFIX + "CHECKIN_INITIALIZE_DATATABLE";

export const CHECKIN_NEXT_PAGE = PREFIX + "CHECKIN_NEXT_PAGE";
export const CHECKIN_PREVIOUS_PAGE = PREFIX + "CHECKIN_PREVIOUS_PAGE";
export const CHECKIN_CHANGE_PER_PAGE = PREFIX + "CHECKIN_CHANGE_PER_PAGE";
export const CHECKIN_FILTER_TABLE = PREFIX + "CHECKIN_FILTER_TABLE";
export const CHECKIN_SORT_TABLE = PREFIX + "CHECKIN_SORT_TABLE";
export const SET_ACTIVE_CHECKIN = PREFIX + "SET_ACTIVE_CHECKIN";
export const UNSET_ACTIVE_CHECKIN = PREFIX + "UNSET_ACTIVE_CHECKIN";
export const PATCH_CHECKIN_REQUEST = PREFIX + "PATCH_CHECKIN_REQUEST";
export const PATCH_CHECKIN_SUCCESS = PREFIX + "PATCH_CHECKIN_SUCCESS";
export const PATCH_CHECKIN_ERROR = PREFIX + "PATCH_CHECKIN_ERROR";


/* initial state */
const initialState = {
  page: 1,
  perPage: 25,
  totalRecords: '',
  loading: false,
  sortDirection: 'desc',
  sortField: 'check_in_at',
  filter: '',
  fields: [],
  idField:'',
  filter: [],
  data: [],
  isFetching: false,
  isSaving: false,
  isDeleting: false,

  activeCheckin: false,
  activeCheckinData: {},
};

const checkinReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CHECKINS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case LOAD_CHECKINS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.checkin,
        totalRecords: action.totalRecords,
    };
    case CHECKIN_FILTER_TABLE: 
      return {
        ...state,
        filter: action.values,
      }
    case CHECKIN_SORT_TABLE:
      return {
          ...state,
          sortField: action.field,
          sortDirection: action.value,
      };
    case CHECKIN_NEXT_PAGE:
        return {
            ...state,
            page: state.page + 1,
        };
    case CHECKIN_PREVIOUS_PAGE:
        return {
            ...state,
            page: state.page - 1,
        };
    case CHECKIN_CHANGE_PER_PAGE:
        return {
            ...state,
            perPage: action.value,
            page: 1
        };      
        
    case SET_ACTIVE_CHECKIN:
        return {
            ...state,
            activeCheckin: true,
            activeCheckinData: action.checkin
        };
    case UNSET_ACTIVE_CHECKIN:
        return {
            ...state,
            activeCheckin: false
        };

    case PATCH_CHECKIN_REQUEST:
        return {
            ...state,
            isDeleting: true,
        };
    case PATCH_CHECKIN_SUCCESS:
        return {
            ...state,
            isDeleting: false,
            activeCheckinData: action.checkin
        };
    case PATCH_CHECKIN_ERROR:
        return {
            ...state,
            isDeleting: false,
        };

    default:
      return state;
  }
}

export default checkinReducer;