import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import {FormControl,InputLabel } from '@material-ui/core'

const CreateSelect = ({ input, options, label, multi, onBlur, onFocus, disabled = false}) => {


    return (

        <FormControl fullWidth={true}>
            <InputLabel shrink={true}>{label}</InputLabel>
          <br/>
            <CreatableSelect
                {...input}
                isMulti={multi}
                options={options}
                onBlur={onBlur}
                onFocus={onFocus}
                isDisabled={disabled}
            />
        </FormControl>

    )

}
export default (CreateSelect);
