import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";
import {
    loadVoucherError, loadVouchersError, loadVouchersSuccess, loadVoucherSuccess, mailVoucherError, mailVoucherSuccess,
    patchVoucherError,
    patchVoucherSuccess, saveVoucherError,
    saveVoucherSuccess
} from "../actions/voucherActions";

export function* loadVouchersRequest (api) {
    try {

        const DataTableOptions = state => state.voucher;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        let result = yield call(api.loadVouchers, options);

        if (result) {
            yield put(loadVouchersSuccess({vouchers: result.data.vouchers, totalRecords: result.data.totalRecords}));
        } else {
            yield put(loadVouchersError({}));
        }
    } catch (e) {
        console.log(e)
    }
}

export function* loadVoucherRequest (api, id) {
    try {
        let result = yield call(api.loadVoucher, id);

        if (result && result.data && result.data.success) {
            yield put(loadVoucherSuccess({voucher: result.data.voucher}));
        } else {
            yield put(loadVoucherError({}));
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveVoucherRequest (api, data) {
    try {
        let result = null;
        if(data._id){
            result = yield call(api.updateVoucher, data._id, data);
        } else {
            result = yield call(api.createVoucher, data);
        }

        if (result && result.data && result.data.success) {
            yield put(saveVoucherSuccess());

            if(result.data.created_count !== undefined){
                yield put(addFlashMessage({message: (result.data.created_count + ' Gutscheine wurden erfolgreich gespeichert'), style:'success'}))
            } else {
                yield put(addFlashMessage({message:'Die Gutscheine wurden erfolgreich gespeichert', style:'success'}))
            }

        } else {
            yield put(saveVoucherError());
            yield put(addFlashMessage({message:'Fehler beim Anlegen der Gutscheine', style:'error'}))
        }

    } catch (e) {
        console.log(e)
    }
}

export function* mailVoucherRequest (api, data) {
    try {
        let result = yield call(api.mailVoucher, data._id, data);;

        if (result && result.data && result.data.success) {
            yield put(mailVoucherSuccess());
            yield put(addFlashMessage({message:'Der Gutschein wurde erfolgreich versendet.', style:'success'}))
        } else {
            yield put(mailVoucherError());
            yield put(addFlashMessage({message:'Fehler beim Versenden der Gutscheine', style:'error'}))
        }

    } catch (e) {
        console.log(e)
    }
}

export function* patchVoucherRequest (api, data) {
    try {

        let result = yield call(api.patchVoucher, data._id, data.action);

        if (result && result.data && result.data.success) {
            yield put(patchVoucherSuccess());
            yield put(addFlashMessage({message:'Der Gutschein wurde erfolgreich gelöscht', style:'success'}))
        } else {
            yield put(patchVoucherError());
            yield put(addFlashMessage({message:'Fehler beim Löschen des Gutscheins', style:'error'}))
        }

    } catch (e) {
        console.log(e)
    }
}

