import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../actions/flashMessageActions";
import {
    deleteWaiterSuccess, getWaiterSuccess, loadWaitersSuccess, saveWaiterSuccess,
    sendWaiterWelcomeSuccess
} from "../actions/waiterActions";

export function* loadWaitersRequest (api, {data}) {
    try {
        const Restaurant = state => state.restaurant;
        const RestaurantOptions = yield select(Restaurant)

        const restaurant_id = RestaurantOptions.activeRestaurantData._id;

        const DataTableOptions = state => state.waiter;
        const TableOptions = yield select(DataTableOptions);
        const options = {};

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        if(!!data && !!data.restaurant_id){
            options['restaurant_id'] = data.restaurant_id;
        } else if(!!restaurant_id){
            options['restaurant_id'] = restaurant_id;
        } else if(options['filter']){
            options['restaurant_id'] = options['filter']['restaurant_id'];
        }

        console.log(options)

        const result = yield call(api.loadWaiter, options);

        if (result) {
            yield put(loadWaitersSuccess({waiters: result.data.waiter, totalRecords: result.data.totalRecords}));
        }
    } catch (e) {
        console.log(e)
    }
}

export function* getWaiterRequest (api) {
    try {

        const Waiter = state => state.waiter;
        const _waiter = yield select(Waiter)

        const waiter_id = _waiter.activeWaiterData._id
        const result = yield call(api.getWaiter, waiter_id);

        if (result) {
            yield put(getWaiterSuccess({waiter: result.data.waiter}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveWaiterRequest (api, {data}) {
    try {
        let result = false;

        if(data._id && data._id.length){
            result = yield call(api.updateWaiter, data._id, data);
        }else{
            result = yield call(api.createWaiter, data);
        }

        if (result && result.data && result.data.success) {
            yield put(saveWaiterSuccess({waiter: result.data.waiter}));
            yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style:'success'}))
        } else {

            let errorMessage = (() => {
                if(result.data && result.data.error_message){
                    return 'Fehler beim Speichern: ' + result.data.error_message;
                }
                return 'Fehler beim Speichern';
            })();

            yield put(addFlashMessage({message: errorMessage, style:'error'}))
        }

    } catch (e) {
        console.log(e)
    }
}

export function* deleteWaiterRequest (api, {data}) {
    try {
        const result = yield call(api.deleteWaiter, data._id, data );

        if (result) {
            yield put(deleteWaiterSuccess())
            yield put(addFlashMessage({message:'Status wurde geändert', style:'success'}))
        } else {
            yield put(addFlashMessage({message:'Fehler bei der Statusänderung', style:'error'}))
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* sendWaiterWelcomeRequest (api, {data}) {
    try {
        let result = false;

        result = yield call(api.sendWelcome, data);

        if (result) {
            yield put(sendWaiterWelcomeSuccess());
            yield put(addFlashMessage({message:'Aktivierungscode wurde versendet', style:'success'}))
        } else {
            yield put(addFlashMessage({message:'Fehler beim Senden des Aktivierungscode', style:'error'}))
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}







