import React from "react";
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from "redux-saga";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import rootReducer from "./rootReducer";
import AppContainer from "./containers/app"
import StartContainer from "./containers/start"
import requireNoAuth from "./utils/requireNoAuth";
import requireAuth from "./utils/requireAuth";
import rootSagas from "./sagas/rootSagas";
import setAuthorizationToken from "./utils/setAuthorizationToken";
import {loadCurrentUser} from './actions/authActions';
import "./App.css";

// THEME
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from './themes/defaultTheme';

const sagaMiddleware = createSagaMiddleware();


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(sagaMiddleware)
    )
);
sagaMiddleware.run(rootSagas);

//set current user if jwt token exists
if (localStorage.jwtToken) {
    setAuthorizationToken(localStorage.jwtToken);
    store.dispatch(loadCurrentUser());
}

function App() {
  return (
      <Provider store={store}>
          <MuiThemeProvider theme={defaultTheme}>
                <Router>
                    <Route exact path="/" component={ requireNoAuth(StartContainer) }/>
                    <Route path="/login" component={ requireNoAuth(StartContainer) }/>



                <Route path="/intern" component={ requireAuth(AppContainer) }/>
                </Router>
          </MuiThemeProvider>
      </Provider>
  );
}

export default App;
