import {
    CUSTOMER_CHANGE_PER_PAGE,
    CUSTOMER_FILTER_TABLE,
    CUSTOMER_INITIALIZE_DATATABLE,
    CUSTOMER_NEXT_PAGE,
    CUSTOMER_PREVIOUS_PAGE,
    CUSTOMER_SORT_TABLE,
    LOAD_CUSTOMERS_REQUEST,
    LOAD_CUSTOMERS_SUCCESS,
    LOAD_SIMPLE_CUSTOMERS_REQUEST,
    LOAD_SIMPLE_CUSTOMERS_RESULT,
    PATCH_CUSTOMER_REQUEST,
    PATCH_CUSTOMER_RESULT,
    SAVE_CUSTOMER_REQUEST,
    SAVE_CUSTOMER_RESULT,
    SET_ACTIVE_CUSTOMER
} from "../reducers/customerReducer";

export const loadCustomersRequest = (values) => ({
    type: LOAD_CUSTOMERS_REQUEST,
    ...values
});

export const loadCustomersSuccess = (values) => ({
    type: LOAD_CUSTOMERS_SUCCESS,
    ...values
});

export const loadSimpleCustomersRequest = (values) => ({
    type: LOAD_SIMPLE_CUSTOMERS_REQUEST,
    ...values
});

export const loadSimpleCustomersResult = (values) => ({
    type: LOAD_SIMPLE_CUSTOMERS_RESULT,
    ...values
});

export const setActiveCustomer = (values) => ({
    type: SET_ACTIVE_CUSTOMER,
    ...values
});

export const saveCustomerRequest = (values) => ({
    type: SAVE_CUSTOMER_REQUEST,
    ...values
});

export const saveCustomerResult = (values) => ({
    type: SAVE_CUSTOMER_RESULT,
    ...values
});

export const patchCustomerRequest = (values) => ({
    type: PATCH_CUSTOMER_REQUEST,
    ...values
});

export const patchCustomerResult = (values) => ({
    type: PATCH_CUSTOMER_RESULT,
    ...values
});

export const initializeDataTable = ({options}) => ({
    type: CUSTOMER_INITIALIZE_DATATABLE,
    ...options,
});

export const nextPage = () => ({
    type: CUSTOMER_NEXT_PAGE,
});

export const previousPage = () => ({
    type: CUSTOMER_PREVIOUS_PAGE,
});

export const changePerPage = (value) => ({
    type: CUSTOMER_CHANGE_PER_PAGE,
    value
});

export const sort = (field, value) => ({
    type: CUSTOMER_SORT_TABLE,
    field,
    value
});

export const filterTable = (values) => ({
    type: CUSTOMER_FILTER_TABLE,
    ...values
});