import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import SettingsForm from "./SettingsForm";
import InvoiceSettingsForm from "./InvoiceSettingsForm";


class Settings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, settings } = this.props;

        return (
            <Fragment>
                <SettingsForm />
                <InvoiceSettingsForm />
            </Fragment>
        );
    }
}


export default (withStyles(style)(Settings));