import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FFDF80',
            main: '#F2B63D',
            dark: '#DBA13B',
            contrastText: '#fff',
        }
    },
    shape: {
        borderRadius: 8,
    },
    overrides: {
        MuiDrawer: {
            paper: {
                minWidth: 256,
            },
            paperAnchorDockedLeft: {
                borderRight: 'none',
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: 'rgba(255,255,255,0.1)',
                marginLeft: 15,
                marginRight: 15,
                marginTop: 5,
                marginBottom: 5
            },
        },
        MuiListItem: {
            "root": {
                "&$selected": {
                    "backgroundColor": "rgba(255,255,255,0.15)"
                }
            }
        }
    },
});

export default theme;