import React, {Component, Fragment} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import Container from "@material-ui/core/Container";
import {withStyles} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import Sidebar from "./Sidebar";
import Header from "./Header";
import style from "./AppContainerStyles";
import Restaurants from "../../views/app/Restaurants/Restaurants"
import NewRestaurants from "../../views/app/Restaurants/NewRestaurants"
import EditRestaurants from "../../views/app/Restaurants/EditRestaurants";
import Tags from "../../views/app/Tags/Tags";
import FlashMessageRoot from '../../components/FlashMessage/FlashMessageRoot'
import Users from '../../views/app/Users/Users';
import Payments from '../../views/app/Payments/Payments';
import AccountedMonths from '../../views/app/Accountings/AccountedMonths';
import AccountedMonthDetails from '../../views/app/Accountings/AccountedMonthDetails'
import Notifications from "../../views/app/Notifications/Notifications";
import Vouchers from "../../views/app/Vouchers/Vouchers";
import Settings from "../../views/app/Settings/Settings";
import Customers from "../../views/app/Customers/Customers";
import Hellos from "../../views/app/Hellos/Hellos";
import {isAllowed} from "../../utils/permissionHelper";
import MyRestaurant from "../../views/app/MyRestaurant/MyRestaurant";
import Filters from "../../views/app/Filter/Filters";
import CashManagement from "../../views/app/CashManagement/CashManagement";
import Maintenance from "../../views/app/Maintenance/Maintenance";
import Live from "../../views/app/Live/Live";

class AppContainer extends Component {

    render() {
        const { classes, user} = this.props;

        return (
            <div className={classes.root}>
                {user && user._id ?
                    <>
                        <CssBaseline/>
                        <Header/>
                        <Sidebar/>
                        <Container className={classes.container} maxWidth={false}>
                            <Switch>

                                {isAllowed(user, 'restaurants', 'read') && [
                                    <Route exact path="/intern/restaurants" name="Restaurant" component={Restaurants}/>,
                                    <Route exact path="/intern/restaurants/:id" name="Restaurant" component={EditRestaurants}/>
                                ]}

                                {isAllowed(user, 'restaurants', 'create') && [
                                    <Route exact path="/intern/restaurants/new" name="Restaurant" component={NewRestaurants}/>
                                ]}

                                {isAllowed(user, 'backendUsers', 'read') && [
                                    <Route exact path="/intern/users" name="User" component={Users}/>
                                ]}

                                {isAllowed(user, 'payments', 'read') && [
                                    <Route exact path="/intern/payments" name="Payments" component={Payments}/>
                                ]}

                                {isAllowed(user, 'accountings', 'read') && [
                                    <Route exact path="/intern/accountings" name="AccountedMonths" component={AccountedMonths}/>,
                                    <Route exact path="/intern/accountings/:month" name="AccountedMonthDetails" component={AccountedMonthDetails}/>
                                ]}

                                {isAllowed(user, 'live', 'read') && [
                                    <Route exact path="/intern/live" name="Live" component={Live}/>,
                                ]}

                                {isAllowed(user, 'tags', 'read') && [
                                    <Route exact path="/intern/tags" name="Tags" component={Tags}/>
                                ]}


                                {isAllowed(user, 'notifications', 'read') && [
                                    <Route exact path="/intern/notifications" name="Notifications" component={Notifications}/>
                                ]}

                                {isAllowed(user, 'vouchers', 'read') && [
                                    <Route exact path="/intern/vouchers" name="Vouchers" component={Vouchers}/>
                                ]}

                                {isAllowed(user, 'settings', 'read') && [
                                    <Route exact path="/intern/settings" name="Settings" component={Settings}/>
                                ]}

                                {isAllowed(user, 'users', 'read') && [
                                    <Route exact path="/intern/customers" name="User" component={Customers}/>
                                ]}

                                {isAllowed(user, 'hellos', 'read') && [
                                    <Route exact path="/intern/hellos" name="Hellos" component={Hellos}/>
                                ]}

                                {isAllowed(user, 'filters', 'read') && [
                                    <Route exact path="/intern/filters" name="Filter" component={Filters}/>
                                ]}

                                {isAllowed(user, 'maintenance', 'read') && [
                                    <Route exact path="/intern/wartung" name="Wartung" component={Maintenance}/>
                                ]}

                                {isAllowed(user, 'cash', 'read') && [
                                    <Route exact path="/intern/cash" name="Cash Management" component={CashManagement}/>
                                ]}

                                {isAllowed(user, 'my-restaurant', 'read') && [
                                    <Route exact path="/intern/mein-restaurant" name="Mein Restaurant" component={MyRestaurant}/>,
                                    <Redirect from="/intern" to="/intern/mein-restaurant"/>
                                ]}

                                <Redirect from="/intern" to="/intern/restaurants"/>
                            </Switch>
                        </Container>
                    <FlashMessageRoot/>
                    </>
                    :
                    <>
                        Lädt...
                    </>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return{
        user: state.auth.user,
        restaurant: state.restaurant.activeRestaurant,
    }
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AppContainer));