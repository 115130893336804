import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import RestaurantsForm from './RestaurantsForm'
import { AppBar, Tabs, Tab, Grid, Typography, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, IconButton } from '@material-ui/core';
import {Field, reduxForm, formValueSelector, isDirty, isSubmitting, isPristine} from "redux-form";
import PropTypes from 'prop-types';
import FileUpload from '../../../components/FileUpload';
import {
    unsetActiveRestaurant,
    setActiveRestaurant, loadRestaurantUserRequest
} from "../../../actions/restaurantActions";
import {loadPostRequest, setNewPost, unsetActivePost, unsetNewPost} from "../../../actions/postActions";
import RestaurantsPost from './RestaurantsPost';
import CloseIcon from '@material-ui/icons/Close';
import PostForm from './PostForm';
import AddIcon from '@material-ui/icons/Add';
import OpeningHours from './OpeningHours';
import MediaLibrary  from '../../../components/MediaLibrary';
import {loadOpeningRequest} from "../../../actions/openingActions";
import {loadTagRequest} from "../../../actions/tagActions";
import {loadTypeRequest} from "../../../actions/typeActions";
import Waiters from '../Waiters/Waiters';
import {loadWaitersRequest, setNewWaiter, unsetActiveWaiter, unsetNewWaiter} from "../../../actions/waiterActions";
import WaiterForm from '../Waiters/WaiterForm';
import Payments from "../Payments/Payments";
import QRCode from 'qrcode-react';
import NotificationsCreate from "../Notifications/NotificationsCreate";
import RestaurantsStripeView from "./RestaurantsStripeView";
import {Prompt} from "react-router";
import RestaurantsRKSVForm from "./RestaurantsRKSVForm";
import {isAllowed, isAllowedRestaurant} from "../../../utils/permissionHelper";
import RestaurantsLoginForm from "./RestaurantsLoginForm";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{maxWidth: "1200px"}}
            {...other}
        >
            <Fragment>{children}</Fragment>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        value: index,
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

class EditRestaurants extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
        }

        this.reload = this.reload.bind(this);
    }

    getRestaurantId = () => {
        return this.props.restaurantId ? this.props.restaurantId : this.props.match.params.id;
    }

    getPrevRestaurantId = (prevProps) => {
        return prevProps.restaurantId ? prevProps.restaurantId : prevProps.match.params.id;
    }

    componentDidMount() {
        this.reload(this.getRestaurantId());
    }

    componentDidUpdate(prevProps){
        if (this.getRestaurantId() && this.getPrevRestaurantId(prevProps) !== this.getRestaurantId()) {
            this.reload(this.getRestaurantId());
        }
    }

    reload(restaurantId) {
        if (restaurantId) {
            this.props.setActiveRestaurant(restaurantId);
            this.props.loadPosts({id:  restaurantId});
            this.props.loadOpeningHours({id: restaurantId});
            this.props.loadTags();
            this.props.loadTypes();
            this.props.loadWaiters({restaurant_id: restaurantId});
            this.props.loadRestaurantUser({_id: restaurantId});
        }
    }

    downloadQR = () => {
        const canvas = document.getElementsByTagName("canvas")[0];
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    render() {

        const { classes, activePost, newPost, newWaiter, activeWaiter, activeRestaurantData, user } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Fragment>
                <Prompt
                    when={(this.props.isGeneralFormDirty || this.props.isOpeneningHoursFormDirty)}
                    message='Sie haben ungespeicherte Änderungen, wollen Sie die Seite trotzdem verlassen?'
                />
                <h1>Restaurant: {this.props.activeRestaurantData.name}</h1>

                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                        indicatorColor="primary"
                    >
                        { isAllowedRestaurant(user, "Stammdaten") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Stammdaten" {...a11yProps(0)} />}
                        { isAllowedRestaurant(user, "Inhalte") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Inhalte" {...a11yProps(1)} /> }
                        { isAllowedRestaurant(user, "Öffnungszeiten") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Öffnungszeiten" {...a11yProps(2)} /> }
                        { isAllowedRestaurant(user, "Upload") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Upload" {...a11yProps(3)} /> }
                        { isAllowedRestaurant(user, "Personal") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Personal" {...a11yProps(4)} /> }
                        { isAllowedRestaurant(user, "Umsätze") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Umsätze" {...a11yProps(5)} /> }
                        { isAllowedRestaurant(user, "Aktionen") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Aktionen" {...a11yProps(6)} /> }
                        { isAllowedRestaurant(user, "QR") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="QR" {...a11yProps(7)} /> }
                        { isAllowedRestaurant(user, "Stripe") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Stripe" {...a11yProps(8)} /> }
                        { isAllowedRestaurant(user, "RKSV") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="RKSV" {...a11yProps(9)} /> }
                        { isAllowedRestaurant(user, "Zugänge") &&  <Tab style={{fontSize: '1rem', marginRight: '20px'}} label="Zugänge" {...a11yProps(10)} /> }
                    </Tabs>


                    <TabPanel className={classes.tabContainer} value={this.state.value} index={0}>
                       <RestaurantsForm/>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={1}>
                        {isAllowed(this.props.user, 'restaurants', 'update') &&
                            <IconButton onClick={this.props.setNewPost} style={{left: '95%'}}>
                                <AddIcon fontSize={'large'}/>
                            </IconButton>
                        }
                        {(activeRestaurantData && !!activeRestaurantData._id) && <RestaurantsPost/> }

                            {activePost === true &&
                                <Dialog fullScreen open={true} >
                                    <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                                        <Toolbar>
                                            <IconButton edge="start" color="inherit" onClick={this.props.unsetActivePost} aria-label="close">
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography variant="h6" className={classes.title}>
                                                Beitrag
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <Grid container style={{justifyContent:'center'}}>
                                        <Grid item xs={6}>
                                            <PostForm isNewPost={false}/>
                                        </Grid>
                                    </Grid>
                                </Dialog>
                            }

                            {newPost === true &&
                            <Dialog fullScreen open={true} >
                                <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={this.props.unsetNewPost} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" className={classes.title}>
                                            Neuen Beitrag erstellen
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <Grid container style={{justifyContent:'center'}}>
                                    <Grid item xs={6}>
                                        <PostForm isNewPost={true}/>
                                    </Grid>
                                </Grid>
                            </Dialog>
                            }
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={2}>
                        <OpeningHours/>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={3}>
                        <MediaLibrary files={this.props.activeRestaurantData.files} ref_id={this.props.activeRestaurantData._id}/>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={4}>
                        {isAllowed(this.props.user, 'waiters', 'update') &&
                            <IconButton onClick={this.props.setNewWaiter} style={{left: '95%'}}>
                                <AddIcon fontSize={'large'}/>
                            </IconButton>
                        }
                        {
                            (activeRestaurantData && !!activeRestaurantData._id) && <Waiters restaurant_id={activeRestaurantData._id}/>
                        }


                        {activeWaiter === true &&
                        <Dialog fullScreen open={true} >
                            <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.props.unsetActiveWaiter} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Personal
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Grid container style={{justifyContent:'center'}}>
                                <Grid item xs={6}>
                                    <WaiterForm isNewWaiter={false}/>
                                </Grid>
                            </Grid>
                        </Dialog>
                        }

                        {newWaiter === true &&
                        <Dialog fullScreen open={true} >
                            <AppBar style={{backgroundColor: '#262c46'}} className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.props.unsetNewWaiter} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Neues Personal erstellen
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Grid container style={{justifyContent:'center'}}>
                                <Grid item xs={6}>
                                    <WaiterForm isNewWaiter={true}/>
                                </Grid>
                            </Grid>
                        </Dialog>
                        }
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={5}>
                        {activeRestaurantData && activeRestaurantData._id &&
                            <Payments showTotal={false} restaurantView={true} restaurant_id={activeRestaurantData._id}/>
                        }
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={6}>
                        <Grid item xs>
                            <Paper style={{padding: '20px'}}>
                                <Typography variant="h6" className={classes.title}>
                                    User über Restaurant informieren
                                </Typography>
                                <div style={{marginBottom: '10px'}}>
                                    Hier können Sie Appbenutzer über dieses Restaurant via Pushnachricht informieren. Diese erhalten eine Nachricht das dieses Restaurant neuer Circled7 Partner ist.
                                </div>
                                <NotificationsCreate title={null} messageValues={{restaurant_id: activeRestaurantData._id}} messageTitle={'Neues Restaurant'} messageBody={'Das Restaurant ###NAME### ist jetzt circled7 Mitglied. Schau vorbei und hole dir deinen Rabatt.'} messageType={'NEW_RESTAURANT'}/>
                            </Paper>
                        </Grid>


                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={7}>
                        <Grid item xs>
                                {
                                    (activeRestaurantData && activeRestaurantData.qr && activeRestaurantData.qr.qr) &&
                                        <Paper style={{padding: '20px'}}>
                                            <QRCode value={activeRestaurantData.qr.qr} fgColor={'#262C46'} logo={require('./../../../assets/logo_round.png')} logoWidth={30} logoHeight={30}/>
                                            <div>
                                                <Button onClick={this.downloadQR} label={'Download QR '} variant="contained" color="primary" >Download QR</Button>
                                            </div>
                                        </Paper>
                                }
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={8}>
                        <Grid item xs>
                            <RestaurantsStripeView restaurant={activeRestaurantData} />
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={9}>
                        <Grid item xs>
                            <RestaurantsRKSVForm/>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabContainer} value={this.state.value} index={10}>
                        <Grid item xs>
                            <RestaurantsLoginForm/>
                        </Grid>
                    </TabPanel>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        restaurant: state.restaurant.activeRestaurant,
        activeRestaurantData: state.restaurant.activeRestaurantData,
        activePost: state.post.activePost,
        newPost: state.post.newPost,
        newWaiter: state.waiter.newWaiter,
        activeWaiter: state.waiter.activeWaiter,
        isGeneralFormDirty: isDirty('restaurants-form')(state),
        isGeneralFormSubmitting: isSubmitting('restaurants-form')(state),
        isGeneralFormPristine: isPristine('restaurants-form')(state),
        // TODO delete: false
        isOpeneningHoursFormDirty: false && isDirty('opening-hours-form')(state),
        user: state.auth.user,
    }
}
const mapDispatchToProps = {
    unsetActivePost: unsetActivePost,
    setNewPost: setNewPost,
    unsetNewPost: unsetNewPost,
    loadPosts: loadPostRequest,
    setActiveRestaurant: setActiveRestaurant,
    unsetActiveRestaurant: unsetActiveRestaurant,
    loadOpeningHours: loadOpeningRequest,
    loadTags: loadTagRequest,
    loadTypes: loadTypeRequest,
    setNewWaiter: setNewWaiter,
    unsetNewWaiter: unsetNewWaiter,
    loadWaiters: loadWaitersRequest,
    unsetActiveWaiter: unsetActiveWaiter,
    loadRestaurantUser: loadRestaurantUserRequest

};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EditRestaurants));