
/* Action types */
const PREFIX = "type/";

export const LOAD_TYPE_REQUEST = PREFIX + "LOAD_TYPE_REQUEST";
export const LOAD_TYPE_SUCCESS = PREFIX + "LOAD_TYPE_SUCCESS";


/* initial state */
const initialState = {

    isFetching: false,
    isSaving: false,
    isDeleting: false,
    data: [],

};

/* Reducer */
const typeReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_TYPE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_TYPE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.types,
            };
        default:
            return state;
    }
};
export default typeReducer;