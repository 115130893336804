
/* Action types */
const PREFIX = "openingHours/";

export const LOAD_OPENING_REQUEST = PREFIX + "LOAD_OPENING_REQUEST";
export const LOAD_OPENING_SUCCESS= PREFIX + "LOAD_OPENING_SUCCESS";

export const SAVE_OPENING_REQUEST = PREFIX + "SAVE_OPENING_REQUEST";
export const SAVE_OPENING_SUCCESS= PREFIX + "SAVE_OPENING_SUCCESS";

/* initial state */
const initialState = {

    isFetching: false,
    isSaving: false,
    data: [],
    reset: false,

};

/* Reducer */
const openingReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_OPENING_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case LOAD_OPENING_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.openingHours,
            };
        case SAVE_OPENING_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_OPENING_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        default:
            return state;
    }
};
export default openingReducer;