import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import WaitersView from './WaitersView';

class Waiters extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { classes, restaurant_id } = this.props;

        return (
            <Fragment>
                <WaitersView restaurant_id={restaurant_id}/>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
    }
}
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Waiters));