import React, {Component, Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../../containers/app/AppContainerStyles";
import {
    Dialog,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Grid,
    Button,
    Table,
    TableHead,
    TableRow, TableBody
} from "@material-ui/core";
import {
    loadStripeSessionsRequest,
    stripePriceRequest,
    updateStripeSessionRequest
} from "../../../actions/settingsActions";
import { loadStripe } from '@stripe/stripe-js';
import {Field, reduxForm} from "redux-form";
import TextFieldGroup from "../../../components/TextFieldGroup";
import {compose} from "redux";
import {addFlashMessage} from "../../../actions/flashMessageActions";
import TableCell from "@material-ui/core/TableCell";
import {formatDateTime, formatMoneyFromApi} from "../../../utils/helper";

const number = value => {
    let _value = Number(value);
    return Number.isInteger(_value) && Number(_value) >= 0 && Number(_value) <= 1000 ? undefined : 'Eingabe muss eine Zahl sein und kleiner gleich 1000.';
};

function CashManagement(props){

    useEffect(() => {
        if(props.sessionId){
            doIt(props.sessionId);
        }
    }, [doIt, props.sessionId]);

    useEffect(() => {
        const string = props.location.search;
        let id = "";
        if(string && string.indexOf('id=') > 0){
            let index = props.location.search.indexOf('id=');
            id = string.substring(index+3);
        }

        if(string && string.indexOf('stripeSuccess=true') > 0 && !!id){
            props.addFlashMessage({message:'Einzahlung erfolgreich durchgeführt', style:'success'});
            props.updateSession({id: id, data: {status: 'success'}});
        } else if(string && string.indexOf('stripeSuccess=false') > 0 && !!id){
            props.addFlashMessage({message:'Fehler bei der Einzahlung', style:'error'})
            props.updateSession({id: id, data: {status: 'error'}});
        }

        props.loadSessions();
    }, [props]);

    const doIt = async (sessionId) => {
        const stripe = await loadStripe(props.user.config.stripe.pk);
        const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId
        });
    }

    const submitAll = (values) => {
        if(values && values.amount){
            props.stripePrice({amount: values.amount * 100})
        }
    }

    const getStatus = (status) => {
        switch(status){
            case "pending": return "Wartend";
            case "success": return "Erfolgreich";
            case "error": return "Abgebrochen";
        }
    }

    const renderBody = () => {
        const {sessions} = props;
        let rows = [];
        if(sessions){
            rows = sessions.map(entry => {
                return <TableRow>
                    <TableCell>{formatDateTime(entry['created_at'])}</TableCell>
                    <TableCell>{entry['updated_from_object']['email']}</TableCell>
                    <TableCell>{formatMoneyFromApi(entry['amount'])}</TableCell>
                    <TableCell>{getStatus(entry['status'])}</TableCell>
                </TableRow>;
            });
        }

        return <TableBody>
            {rows}
        </TableBody>
    }

    const renderHead = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>Erstellt am</TableCell>
                    <TableCell>Erstellt von</TableCell>
                    <TableCell>Betrag</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Fragment>
            <h2>Cash Management</h2>

            <form>
                <Grid container spacing={2} md={12}>
                    <Grid item md={10} xs={12}>
                        Hier können Sie einen beliebigen Betrag zu Stripe hochladen. Geben Sie den Betrag ein und klicken auf "Stripe Zahlung". Daraufhin werden Sie zur Zahlung weitergeleitet.
                        <br/>Sie benötigen eine gültige Kreditkarte für diese Funktion.
                        <br/>War diese erfolgreich scheint wie gewohnt ein grüner Balken auf.
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Field
                            name="amount"
                            label="Betrag in €"
                            component={TextFieldGroup}
                            margin="dense"
                            validate={[ number ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button color={'primary'} variant={'outlined'} onClick={props.handleSubmit(submitAll)} >
                            Stripe Zahlung
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Grid container spacing={2} md={12}>
                <Table style={{backgroundColor: '#fff', marginTop: '30px'}}>
                    {renderHead()}
                    {renderBody()}
                </Table>
            </Grid>


        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        sessionId: state.settings.sessionId,
        sessions: state.settings.sessions,
    }
}
const mapDispatchToProps = {
    stripePrice: stripePriceRequest,
    addFlashMessage: addFlashMessage,
    loadSessions: loadStripeSessionsRequest,
    updateSession: updateStripeSessionRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'cash-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(CashManagement)