import {
    LOAD_TAGS_REQUEST,
    LOAD_TAGS_SUCCESS,
    SAVE_TAGS_REQUEST,
    SAVE_TAGS_SUCCESS

} from "../reducers/tagReducer";

export const loadTagRequest = () => ({
    type: LOAD_TAGS_REQUEST,
});

export const loadTagSuccess = (values) => ({
    type: LOAD_TAGS_SUCCESS,
    ...values,
});

export const saveTagRequest = (values) => ({
    type: SAVE_TAGS_REQUEST,
    ...values
});

export const saveTagSuccess = () => ({
    type: SAVE_TAGS_SUCCESS,
});