import {put, call, select} from "@redux-saga/core/effects";
import {
    loadCustomersSuccess, loadSimpleCustomersResult, patchCustomerResult,
    saveCustomerResult,
} from "../actions/customerActions";
import {addFlashMessage} from "../actions/flashMessageActions";

export function* loadCustomersRequest (api, data) {
    try {

        const DataTableOptions = state => state.customer;
        const TableOptions = yield select(DataTableOptions);
        const options = {
            extended: true
        };

        if(TableOptions.sortDirection) {
            options['order_desc'] = TableOptions.sortDirection === "desc" ? true : false;
            options['order_id'] = TableOptions.sortField;
        }

        options['page'] = TableOptions.page;
        options['page_size'] = TableOptions.perPage;
        options['filter'] = TableOptions.filterValues;

        if(data && data.search){
            options['filter'] = data.search;
        }

        console.log('search: ', options['filter'])

        let result = yield call(api.loadCustomers, options);

        if (result) {
            yield put(loadCustomersSuccess({customers: result.data.customers, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* loadCustomersSimpleRequest (api, data) {
    try {
        const options = {
            order_desc: true,
            order_id: 'created_at',
            page: 1,
            page_size: 20,
            filter: data.search,
            extended: false,
            voucher_sum: true,
        };

        let result = yield call(api.loadCustomers, options);

        if (result) {
            yield put(loadSimpleCustomersResult({customers: result.data.customers, totalRecords: result.data.totalRecords}));
        } else {
            console.log("FAIL")
        }
    } catch (e) {
        console.log(e)
    }
}

export function* saveCustomerRequest (api, data) {
    let result = null;
    if(data._id && data._id.length){
        result = yield call(api.updateCustomer, data._id, data);
    }else{
        result = yield call(api.createCustomer, data);
    }

    if (result) {
        yield put(saveCustomerResult({customer: result.data.customer}));
        yield put(addFlashMessage({message:'Ihre Änderung wurde gespeichert', style: 'success'}))
    } else {
        yield put(saveCustomerResult({customer: null}));
        yield put(addFlashMessage({message:'Fehler beim Speichern', style:'error'}))
    }
}

export function* patchCustomerRequest (api, data) {
    try {
        const result = yield call(api.patchCustomer, data._id, data);

        if (result && result.data && result.data.success) {
            yield put(patchCustomerResult({customer: result.data.customer}));
            yield put(addFlashMessage({message:'Status wurde geändert', style:'success'}))
        } else {
            yield put(patchCustomerResult());
            yield put(addFlashMessage({message:'Fehler beim Speichern', style:'error'}))
        }
    } catch (e) {
        console.log(e)
    }
}